<template>
  <Popup v-model="open">
    <div class="sm:flex sm:items-start">
      <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto dark:bg-yellow-950 bg-yellow-100  rounded-full sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationTriangleIcon class="w-6 h-6 text-yellow-600" aria-hidden="true" />
      </div>
      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
        <DialogTitle v-if="props.title" as="h3" class="text-base font-semibold leading-6 dark:text-white">{{ props.title }}</DialogTitle>
        <div class="flex flex-col gap-2 mt-2">
          <p class="text-sm text-gray-500 dark:text-neutral-400">{{ props.message }}</p>
        </div>
      </div>
    </div>
    <div class="flex gap-4 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <ButtonPrimary :text="props.primary_action_text" size="lg" @click="emit('confirmed')" />
      <ButtonSecondary :text="props.secondary_action_text" @click="open = false" />
    </div>
  </Popup>
</template>

<script setup>
// Components
import Popup from '@/components/applicationui/AppPopup.vue'
import ButtonPrimary from '@/components/applicationui/AppButtonPrimary.vue';
import ButtonSecondary from '@/components/applicationui/AppButtonSecondary.vue';

// Libraries
import { DialogTitle } from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

// Emits
const emit = defineEmits(['confirmed'])

// Model
const open = defineModel({ type: Boolean, default: false })

// Props
const props = defineProps({
  title: { type: String, required: false, default: null },
  message: { type: String, required: true, default: null },
  primary_action_text: { type: String, required: false, default: 'Confirm' },
  secondary_action_text: { type: String, required: false, default: 'Cancel' },
})
</script>