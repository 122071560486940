<template>
  <div
    class="flex flex-col shadow-opera dark:shadow dark:text-neutral-300 dark:border dark:border-neutral-800 w-80 background"
  >
    <div
      v-if="loading"
      class="flex justify-between items-center border-solid border-b skeleton rounded-t-md"
    ></div>
    <div v-else>
      <!-- Partner Card -->
      <div
        class="flex justify-between items-center border-solid border-b dark:border-neutral-800 bg-gray-100 dark:bg-neutral-800 rounded-t-md"
      >
        <!-- Logo & Title -->
        <PartnerLogo size="large" :partner="partner" class="ml-3" />
        <!-- Eclipse Options -->
        <Menu as="div" class="relative ml-auto p-6">
          <MenuButton
            class="-m-2.5 flex flex-col items-end p-1 text-gray-400 dark:text-neutral-400 dark:hover:text-neutral-300 hover:text-gray-500"
          >
            <span class="sr-only">Open options</span>
            <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
          </MenuButton>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-4 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white dark:bg-neutral-700 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
            >
              <MenuItem v-slot="{ active }">
                <div
                  :class="[
                    active ? 'bg-gray-100 dark:bg-neutral-800' : '',
                    'px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300 cursor-pointer flex items-center gap-1',
                  ]"
                  @click="handleClicked"
                >
                  <PencilSquareIcon class="w-4 h-4" />Edit<span class="sr-only">
                  </span>
                </div>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <div
                  :class="[
                    active ? 'bg-red-600 text-white' : '',
                    'flex items-center gap-1 px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer',
                  ]"
                >
                  <TrashIcon class="w-4 h-4" />Delete<span
                    class="sr-only"
                  ></span>
                </div>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <!-- Bottom Half -->
      <dl
        class="-my-3 divide-y divide-gray-100 dark:divide-neutral-700 px-6 py-4 text-sm leading-6"
      >
        <div class="flex justify-between items-center gap-x-4 py-3">
          <dt class="text-gray-500 dark:text-neutral-300">Login Link</dt>
          <div class="">
            <div class="button_secondary" @click="copyLink()">
              {{ copyText }}
              <ClipboardIcon
                v-if="copyText == 'Copy Link'"
                class="w-4 h-4 text-gray-900 dark:text-white"
              />
              <CheckIcon v-else class="w-4 h-4 text-gray-900 dark:text-white" />
            </div>
          </div>
        </div>
        <div class="flex justify-between gap-x-4 py-3">
          <div :class="p.partners_protected.partner_type">
            {{ getPartnerType(p.partners_protected.partner_type) }}
          </div>
          <dd
            class="flex text-xs items-center gap-x-2 text-gray-700 dark:text-neutral-300"
          >
            {{ formatDate(partner.created_at) }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";
// Components
import PartnerLogo from "@/components/partners/PartnerLogo.vue";
// Libraries
import {
  PencilSquareIcon,
  TrashIcon,
  ClipboardIcon,
  CheckIcon,
} from "@heroicons/vue/24/outline";
import { EllipsisHorizontalIcon } from "@heroicons/vue/20/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
// Stores
// Props
const props = defineProps({
  partner: {
    type: Object,
    required: true,
    default: null,
  },
});
// Globals
const p = ref({});
const copyText = ref("Copy Link");
const loading = ref(true);
const loginLink = ref("");
// Mounted
onMounted(() => {
  p.value = props.partner;
  loginLink.value = `${window.location.origin}/login?partner=${p.value.meta_name}`;
  loading.value = false;
});
// Emits
const emit = defineEmits(["clicked"]);
// Functions
const getPartnerType = (partner_type) => {
  if (partner_type === "dib") return "DIB";
  else if (partner_type === "dab") return "DAB";
  else if (partner_type === "maas") return "MAAS";
  else if (partner_type === "custody") return "Custody";
  else if (partner_type === "mixed") return "Mixed";
  else return "Mixed";
};

const copyLink = async () => {
  navigator.clipboard.writeText(loginLink.value);
  copyText.value = "Copied";
  await new Promise((resolve) => setTimeout(resolve, 1000));
  copyText.value = "Copy Link";
};

const formatDate = (d) => {
  const date = new Date(d);
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const weekday = weekdays[date.getDay()]; // Get the weekday
  const month = months[date.getMonth()]; // Get the month
  const day = date.getDate(); // Get the day
  const year = date.getFullYear(); // Get the year
  return `${weekday}, ${month} ${day}, ${year}`; // Combine all the parts into the desired format
};

const handleClicked = () => {
  emit("clicked", p.value);
};
</script>
