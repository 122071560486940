<template>
  <div class="px-4 lg:px-8">
    <!-- Popups -->
    <!-- Update Accounts Popup -->
    <UpdateAccount
      v-if="open"
      v-model="open"
      :account="selected_account"
      @updated="accountsStore.setCustodyAccounts()"
    />
    <!-- Popups -->

    <!-- Loading -->
    <div
      v-if="loading"
      class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center"
    >
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <div v-else>
      <!-- Header -->
      <div>
        <div
          class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700"
        >
          <!-- Tab Header -->
          <h3 class="primary_header">Custody Accounts</h3>

          <div class="flex items-center gap-4">
            <!-- Search -->
            <div class="flex items-center gap-2">
              <div>
                <div class="relative rounded-md">
                  <div
                    class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                  >
                    <MagnifyingGlassIcon
                      class="w-4 h-4 text-gray-400 dark:text-neutral-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    v-model="query"
                    type="text"
                    name="search"
                    class="pl-8 input"
                    placeholder="Quick Search"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Custody Accounts Table -->
      <TablePrimary
        :rows="filteredAccounts"
        :columns="columns"
        :is-clickable="clickable"
        class=""
        export_title="custody_accounts"
        @click-primary="handleAccountClick"
      />
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from "vue";
import { useAccountsStore } from "@/stores/accounts";
import { useUserStore } from "@/stores/user";
// Components
import UpdateAccount from "@/components/custody/accounts/UpdateAccount.vue";
import TablePrimary from "@/components/applicationui/tables/LegacyTablePrimary.vue";
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
// Libaries
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
// Stores
const userStore = useUserStore();
const accountsStore = useAccountsStore();
// Globals
const loading = ref(true);
const open = ref(false);
const clickable = ref(false);
const selected_account = ref(null);
const query = ref("");
const columns = [
  { key: "name", label: "Name" },
  { key: "tapi_account_id", label: "Account #" },
  { key: "type", label: "Type", type: "badge" },
  { key: "accounts_protected.approval_status", label: "Status", type: "badge" },
  {
    key: "accounts_protected.accredited_status",
    label: "Accreditation",
    type: "badge",
  },
  { key: "created_at", label: "Created", type: "date" },
];

// Mounted
onMounted(async () => {
  // Get Custody Accounts
  await accountsStore.setCustodyAccounts();
  // Set Clickable
  if (userStore.user.profiles_protected.role == "super_admin")
    clickable.value = true;
  // Ready
  loading.value = false;
});

// Computed
const filteredAccounts = computed(() => {
  return query.value === ""
    ? accountsStore.custodyAccounts
    : accountsStore.custodyAccounts.filter((account) => {
        if (account?.id.toLowerCase().includes(query.value.toLowerCase()))
          return true;
        if (
          account?.tapi_account_id
            ?.toLowerCase()
            .includes(query.value.toLowerCase())
        )
          return true;
        if (account?.name?.toLowerCase().includes(query.value.toLowerCase()))
          return true;
        if (account?.type?.toLowerCase().includes(query.value.toLowerCase()))
          return true;
        if (
          account?.accounts_protected?.accredited_status
            ?.toLowerCase()
            .includes(query.value.toLowerCase())
        )
          return true;
      });
});

// Simple Functions
const handleAccountClick = (account) => {
  selected_account.value = account;
  open.value = true;
};
</script>
