<template>
  <div>
    <!-- Loading -->
    <div
      v-if="loading"
      class="w-full md:h-[60vh] h-[50vh] flex items-center justify-center"
    >
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Positions Table -->
    <TablePrimary
      v-else
      :rows="positions"
      :columns="columns"
      :is-clickable="false"
      export_title="positions"
      empty_message="No Positions"
      class="-mt-5"
    />
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";
import { useTransactionsStore } from "@/stores/transactions";
// Components
import TablePrimary from "@/components/applicationui/tables/LegacyTablePrimary.vue";
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
// Props
const props = defineProps({
  account: {
    type: Object,
    required: true,
    default: null,
  },
});
// Stores
const transactionsStore = useTransactionsStore();
// Globals
const positions = ref([]);
const loading = ref(true);
const columns = [
  { key: "offerings.name", label: "Investment" },
  { key: "amount", label: "Amount", type: "money" },
  { key: "price_per_unit", label: "Price Per Unit", type: "money" },
  { key: "amount", label: "Total", type: "total", key2: "price_per_unit" },
  { key: "units", label: "Units", type: "badge" },
  { key: "created_at", label: "Settled Date", type: "date" },
];
// Mounted
onMounted(async () => {
  await setPositions(props.account.id);
  loading.value = false;
});

// TODO move this to a store or remove completely with a prop
async function setPositions(account_id) {
  positions.value =
    await transactionsStore.getAllSettledTransactionsByAccountId(account_id);
  if (!positions.value) positions.value = []; //TODO handle the potential error better
  positions.value = positions.value.map((position) => {
    if (!position.offerings && position.units == "cash")
      position.offerings = { name: "$ Cash" };
    return position;
  });
}
</script>
