import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import * as Sentry from "@sentry/vue";

const pinia = createPinia();
const app = createApp(App);

let tracesSampleRate = 0;
if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "production")
  tracesSampleRate = 1.0;
else if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "sandbox")
  tracesSampleRate = 0.8;
else if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "qa")
  tracesSampleRate = 1.0;

let replaysSessionSampleRate = 0;
if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "production")
  replaysSessionSampleRate = 1.0;
else if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "sandbox")
  replaysSessionSampleRate = 0.8;
else if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "qa")
  replaysSessionSampleRate = 0.5;

let replaysOnErrorSampleRate = 0;
if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "production")
  replaysOnErrorSampleRate = 1.0;
else if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "sandbox")
  replaysOnErrorSampleRate = 1.0;
else if (import.meta.env.VITE_SENTRY_ENVIRONMENT === "qa")
  replaysOnErrorSampleRate = 1.0;

if (import.meta.env.VITE_SENTRY_ENVIRONMENT != "dev") {
  Sentry.init({
    app,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    dsn: "https://74dbf5a63068e840c5e7d4a4875a6f0c@o4506865052221440.ingest.us.sentry.io/4508580654219264",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        unmask: ["sentry-unmask", ".sentry-unmask"],
      }),
    ],
    // Tracing
    tracesSampleRate: tracesSampleRate, //  Capture x% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [""],
    // Session Replay
    replaysSessionSampleRate: replaysSessionSampleRate, // This sets the sample rate at 50%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(router);
app.use(pinia);
app.mount("#app");
