<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-1 h-screen min-h-full bg-white dark:bg-neutral-950">
    <!-- Popups -->
    <NotificationPopup
      ref="notification"
      :type="notificationType"
      :title="notificationTitle"
      :text="notificationText"
    />
    <!-- Popups -->

    <!-- Loading -->
    <div
      v-if="loading"
      class="flex items-center justify-center w-screen h-screen"
    >
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Content -->
    <div v-else class="flex flex-1 w-full h-full">
      <div
        v-if="!showOtp"
        class="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
      >
        <div class="w-full max-w-sm mx-auto lg:w-96">
          <div>
            <!-- Partner Logo -->
            <PartnerLogo :partner="temp_partner" size="large" />
            <!-- Sing in Header -->
            <h2
              class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white"
              v-html="titleText"
            ></h2>
            <p
              v-if="showToggle"
              class="mt-2 text-sm leading-6 text-gray-500 dark:text-neutral-400"
            >
              {{ `${toggleText} ` }}
              <a
                class="font-semibold cursor-pointer text-operacolor hover:text-operacolorlight"
                @click="toggleSignInOrLogin(showLoginView)"
                >{{ toggleLink }}</a
              >
            </p>
          </div>

          <div class="mt-10">
            <div>
              <form class="space-y-6" @submit.prevent="handleSignIn">
                <div>
                  <label
                    for="email"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-100"
                    >Email address</label
                  >
                  <div class="mt-2">
                    <input
                      id="email"
                      v-model="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      required=""
                      class="input"
                    />
                  </div>
                </div>

                <div class="flex items-center justify-between">
                  <div v-if="showLoginView" class="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      class="w-4 h-4 border-gray-300 rounded text-operacolor focus:ring-operacolor"
                    />
                    <label
                      for="remember-me"
                      class="block ml-3 text-sm leading-6 text-gray-700 dark:text-neutral-300"
                      >Remember me</label
                    >
                  </div>
                  <div v-else>
                    <div class="flex items-center">
                      <input
                        id="terms"
                        v-model="terms"
                        name="terms"
                        type="checkbox"
                        class="w-4 h-4 border-gray-300 rounded text-operacolor focus:ring-operacolor"
                      />
                      <label
                        for="terms"
                        class="block ml-3 text-sm leading-6 text-gray-700 dark:text-neutral-300"
                        >I agree to the
                        <a
                          class="text-operacolor"
                          target="_blank"
                          :href="termsOfUse"
                          >Terms and Conditions</a
                        >
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input
                        id="privacy"
                        v-model="privacy"
                        name="privacy"
                        type="checkbox"
                        class="w-4 h-4 border-gray-300 rounded text-operacolor focus:ring-operacolor"
                      />
                      <label
                        for="privacy"
                        class="block ml-3 text-sm leading-6 text-gray-700 dark:text-neutral-300"
                        >I agree to the
                        <a
                          class="text-operacolor"
                          target="_blank"
                          href="https://app.hubspot.com/documents/4042879/view/473022400?accessId=2a9568"
                          >Privacy Policy</a
                        ></label
                      >
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    class="flex items-center gap-2.5"
                    :class="isEnabled"
                  >
                    {{ buttonText }}
                    <LoadGifButton v-if="showButtonSpinner" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- OTP -->
      <div v-else class="absolute w-screen h-screen bg-transparent antialiased">
        <main
          class="relative h-full flex flex-col justify-center overflow-hidden z-10"
        >
          <div class="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
            <div class="flex justify-center">
              <div
                class="max-w-lg mx-auto text-center bg-white px-4 sm:px-10 py-12 rounded-xl shadow"
              >
                <header class="mb-10">
                  <h1 class="text-2xl font-bold mb-3 text-gray-900">
                    One Time Passcode
                  </h1>
                  <p class="text-[15px] text-gray-500">
                    Enter the 6-digit verification code that was sent to <br />
                    <span class="text-gray-800">{{
                      email ?? "No Email Enter"
                    }}</span>
                  </p>
                  <div class="text-sm text-gray-500 mt-2">
                    Back to sign in page?
                    <a
                      class="font-medium text-operacolor hover:text-operacolor cursor-pointer"
                      @click="showOtp = false"
                      >Sign in</a
                    >
                  </div>
                </header>
                <form id="otp-form">
                  <div class="flex items-center justify-center gap-2">
                    <input
                      v-model="otp_1"
                      type="text"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      pattern="\d*"
                      maxlength="1"
                      @input="handleInput(1)"
                      @paste="handlePaste"
                    />
                    <input
                      v-model="otp_2"
                      type="text"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      maxlength="1"
                      @input="handleInput(2)"
                    />
                    <input
                      v-model="otp_3"
                      type="text"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      maxlength="1"
                      @input="handleInput(3)"
                    />
                    <input
                      v-model="otp_4"
                      type="text"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      maxlength="1"
                      @input="handleInput(4)"
                    />
                    <input
                      v-model="otp_5"
                      type="text"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      maxlength="1"
                      @input="handleInput(5)"
                    />
                    <input
                      v-model="otp_6"
                      type="text"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      maxlength="1"
                      @input="handleInput(6)"
                    />
                  </div>
                  <div class="max-w-[420px] mt-10">
                    <ButtonPrimary
                      text="Verify Email"
                      class="w-full"
                      :loading="loadingOtp"
                    />
                  </div>
                </form>
                <div class="text-sm text-gray-500 mt-8">
                  Didn't receive code?
                  <a
                    class="font-medium text-operacolor hover:text-operacolor cursor-pointer"
                    @click="handleSignIn"
                    >Resend</a
                  >
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <!-- Background Image -->
      <div class="relative flex-1 hidden w-0 lg:block">
        <img
          class="absolute inset-0 object-cover w-full h-full bg-gray-200"
          :src="temp_partner_background"
          alt="Background Image"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, watch, onMounted } from "vue";
import { supabase } from "@/lib/supabaseClient";
import { useRouter } from "vue-router";
const router = useRouter();

// Stores
import { useUserStore } from "@/stores/user.js";
import { usePartnerStore } from "@/stores/partner";
const userStore = useUserStore();
const partnerStore = usePartnerStore();

// Components
import PartnerLogo from "@/components/partners/PartnerLogo.vue";
import LoadGifButton from "@/components/loading/LoadGifButton.vue";
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import NotificationPopup from "@/components/applicationui/AppNotification.vue";
import ButtonPrimary from "@/components/applicationui/AppButtonPrimary.vue";

// Globals
const loading = ref(true);
const isEnabled = ref("disabled");
const email = ref("");
const showButtonSpinner = ref(false);
const showOtp = ref(false);

const showToggle = ref(true);
const showLoginView = ref(true);
const titleText = ref("Sign in to your account");
const toggleText = ref("Don't have an account?");
const toggleLink = ref("Sign up");
const buttonText = ref("Sign in");

const termsOfUse = ref(
  "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/termsOfUse/termsofuse.pdf",
);
const privacy = ref(false);
const terms = ref(false);

// Handle Sign In
async function handleSignIn() {
  if (!email.value) {
    showOtp.value = false;
    showButtonSpinner.value = false;
    return;
  }
  showButtonSpinner.value = true;

  // Set sign in type
  let sign_in_type = "magic-link";
  if (
    temp_partner.value.partners_protected.allowed_signin_methods &&
    temp_partner.value.partners_protected.allowed_signin_methods.includes("otp")
  ) {
    sign_in_type = "otp";
  }

  const { error } = userStore.sendMagicLink(
    email.value,
    `${window.location.origin}/landing`,
    temp_partner.value,
    window.location.origin,
    temp_partner_logo.value,
    sign_in_type,
  );

  // Handle Failures
  if (error) {
    let errorParsed = null;
    if (error.context && error.context.body instanceof ReadableStream) {
      const response = new Response(error.context.body);
      const text = await response.text();
      errorParsed = JSON.parse(text);
      if (errorParsed?.code == 402) {
        buttonText.value = "Retry Email";
        showButtonSpinner.value = false;
        notify(
          "failure",
          "Sign up disabled",
          "Sign up is currently disabled for this partner. Please contact support for more information.",
        );
        return;
      }
    }
    buttonText.value = "Retry Email";
    showButtonSpinner.value = false;
    notify(
      "failure",
      "Sorry",
      "It looks like there was an issue sending your email. Please make sure your email is correct, if the issue persists contact tech support.",
    );
    return;
  }

  // OTP naviagte to verify page
  if (sign_in_type == "otp") {
    showOtp.value = true;
    showButtonSpinner.value = false;
    notify(
      "success",
      "Email Sent",
      "Please check your inbox. If you don't see the email, please check your spam inbox.",
    );
    return;
  }

  // Magic Link
  buttonText.value = "Resend Email";
  showButtonSpinner.value = false;
  notify(
    "success",
    "Email Sent",
    "Please check your inbox. If you don't see the email, please check your spam inbox.",
  );
}

// Computed
const toggleSignInOrLogin = (loginOrSignup) => {
  if (loginOrSignup) {
    titleText.value = "Sign up for an account";
    toggleText.value = "Already have an account?";
    toggleLink.value = "Sign in";
    buttonText.value = "Sign up";
    showLoginView.value = false;
    checkReady();
  } else {
    titleText.value = "Sign in to your account";
    toggleText.value = "Don't have an account?";
    toggleLink.value = "Sign up";
    buttonText.value = "Sign in";
    showLoginView.value = true;
  }
};

const checkReady = () => {
  if (showLoginView.value) {
    if (
      email.value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
      isEnabled.value = "enabled";
    } else {
      isEnabled.value = "disabled";
    }
  } else {
    if (terms.value && privacy.value) {
      if (
        email.value
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          )
      ) {
        isEnabled.value = "enabled";
      } else {
        isEnabled.value = "disabled";
      }
    } else {
      isEnabled.value = "disabled";
    }
  }
};

// OTP START
const otp_1 = ref("");
const otp_2 = ref("");
const otp_3 = ref("");
const otp_4 = ref("");
const otp_5 = ref("");
const otp_6 = ref("");
const loadingOtp = ref(false);

const handlePaste = (e) => {
  e.preventDefault();
  const text = e.clipboardData.getData("text");
  if (text.length == 6) {
    otp_1.value = text[0];
    otp_2.value = text[1];
    otp_3.value = text[2];
    otp_4.value = text[3];
    otp_5.value = text[4];
    otp_6.value = text[5];
    handleOtpSubmit();
  }
};

const handleInput = (index) => {
  if (index == 1) {
    if (otp_1.value.length > 0) {
      otp_2.value = "";
      document.getElementById("otp-form").elements[1].focus();
    }
  } else if (index == 2) {
    if (otp_2.value.length > 0) {
      otp_3.value = "";
      document.getElementById("otp-form").elements[2].focus();
    }
  } else if (index == 3) {
    if (otp_3.value.length > 0) {
      otp_4.value = "";
      document.getElementById("otp-form").elements[3].focus();
    }
  } else if (index == 4) {
    if (otp_4.value.length > 0) {
      otp_5.value = "";
      document.getElementById("otp-form").elements[4].focus();
    }
  } else if (index == 5) {
    if (otp_5.value.length > 0) {
      otp_6.value = "";
      document.getElementById("otp-form").elements[5].focus();
    }
  } else if (index == 6) {
    if (otp_6.value.length > 0) {
      handleOtpSubmit();
    }
  }
};

const handleOtpSubmit = async () => {
  if (loadingOtp.value) return;
  loadingOtp.value = true;
  const otp =
    otp_1.value +
    otp_2.value +
    otp_3.value +
    otp_4.value +
    otp_5.value +
    otp_6.value;
  if (otp.length != 6) {
    notify("failure", "Invalid OTP", "Please enter a valid 6 digit OTP");
    otp_1.value = "";
    otp_2.value = "";
    otp_3.value = "";
    otp_4.value = "";
    otp_5.value = "";
    otp_6.value = "";
    loadingOtp.value = false;
    return;
  }
  const success = await userStore.otpVerify(email.value, otp);
  if (!success) {
    notify(
      "failure",
      "Incorrect OTP",
      "Incorrect OTP entered. Please try again.",
    );
    loadingOtp.value = false;
    return;
  }
  router.push({ name: "landing-validate" });
  loadingOtp.value = false;
};
// OTP END

// Partner Setup START
const temp_partner = ref(null);
const temp_partner_background = ref(null);
const temp_partner_logo = ref(null);
async function setTempPartner(meta_name) {
  // Get Partner if exists
  const response = await partnerStore.getPartnerByMetaName(meta_name);
  if (!response) {
    router.push("nopartner");
    return;
  }
  temp_partner.value = response;
  // Set Partner Colors
  if (temp_partner.value.primary_color)
    document.documentElement.style.setProperty(
      "--operacolor",
      temp_partner.value.primary_color,
    );
  if (temp_partner.value.primary_color_light)
    document.documentElement.style.setProperty(
      "--operacolorlight",
      temp_partner.value.primary_color_light,
    );
  if (temp_partner.value.primary_color_dark)
    document.documentElement.style.setProperty(
      "--operacolordark",
      temp_partner.value.primary_color_dark,
    );
  if (temp_partner.value.title_color)
    document.documentElement.style.setProperty(
      "--titlecolor",
      temp_partner.value.title_color,
    );
  if (temp_partner.value.title_color_dark)
    document.documentElement.style.setProperty(
      "--titlecolordark",
      temp_partner.value.title_color_dark,
    );
  if (temp_partner.value.border_color)
    document.documentElement.style.setProperty(
      "--border",
      temp_partner.value.border_color,
    );

  // One Time Passcode Setup
  if (
    temp_partner.value.partners_protected.allowed_signin_methods &&
    temp_partner.value.partners_protected.allowed_signin_methods.includes("otp")
  ) {
    buttonText.value = "Verify Email";
    showToggle.value = false;
  }
  // Set Partner Logo and Background
  getTempBackground();
  getTempLogo();
}

function getTempBackground() {
  const { data } = supabase.storage
    .from("partners")
    .getPublicUrl(`${temp_partner.value?.meta_name}/background`);
  temp_partner_background.value = data.publicUrl;
}

function getTempLogo() {
  const { data } = supabase.storage
    .from("partners")
    .getPublicUrl(`${temp_partner.value?.meta_name}/logo`);
  temp_partner_logo.value = data.publicUrl;
}
// Partner Setup END

// Notification START
const notification = ref(null);
const notificationType = ref("success");
const notificationTitle = ref("Email Sent");
const notificationText = ref(
  "Please check your inbox. If you don't see the email, please check your spam inbox.",
);
const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};
// Notification END

// Mounted START
onMounted(async () => {
  if (router.currentRoute.value.path == "/signup")
    toggleSignInOrLogin(showLoginView.value);

  // Get partner meta name from url parameter
  const meta_name = router.currentRoute.value.query.partner;
  if (!meta_name) {
    // navigate to 404 page
    router.push("nopartner");
    return;
  }
  // Set partner theme
  await setTempPartner(meta_name);

  // Get user theme
  if (localStorage.getItem("theme") == "dark")
    document.documentElement.classList.add("dark");
  else if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  )
    document.documentElement.classList.add("dark");

  loading.value = false;
});
// Mounted END

// Watchers
watch([email, terms, privacy], () => {
  checkReady();
});
</script>

<style scoped>
.enabled {
  @apply flex w-full justify-center rounded-md bg-operacolor px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-operacolorlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-operacolor;
}

.disabled {
  @apply flex w-full justify-center rounded-md bg-gray-200 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-400 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-not-allowed dark:bg-neutral-700 dark:text-neutral-500;
}
</style>
