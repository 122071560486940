<template>

  <!-- Loading -->
  <div v-if="loading" class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[50vh] w-[90vw]">
    <div class="h-40 w-40 -mt-20">
      <SpinnerJump />
    </div>
  </div>

  <!-- Accreditation -->
  <div v-else class="p-5 max-w-2xl dark:text-neutral-300">

    <div class="mb-5 dark:text-white">{{ getAccreditedLabel }}</div>

    <div
class="flex items-center justify-between w-full cursor-pointer mb-2 py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
      @click="expandIndividualInstructions = !expandIndividualInstructions">
      <span class="font-semibold text_color_primary">
        Individual Investors
      </span>
      <PlusIcon v-if="!expandIndividualInstructions" class="w-5 h-5 dark:text-neutral-300" />
      <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
    </div>

    <div v-if="expandIndividualInstructions" class="px-5">
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">1. Income Verification:</div>
        <div class="pl-5 text_color_secondary">
          W2's, 1099's, or tax returns for the past two years to show your individual income exceeds $200,000 (or joint income exceeds $300,000) for each year.
        </div>
      </div>
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">2. Asset Verification:</div>
        <div class="pl-5 text_color_secondary">
          Provide recent account statements or third-party appraisals that show the value of your assets exceed $1,000,000 excluding your primary residence.
        </div>
      </div>
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">3. Professional Attestation:</div>
        <div class="pl-5 text_color_secondary">
          Provide an official written communication from any of the following stating that the professional service provider has a reasonable belief that you are an Accredited Investor
          (Must be dated within the last 3 months): A licensed CPA, Attorney, Investment Advisor, or Registered broker-dealer.
        </div>
      </div>
    </div>

    <div
class="flex items-center justify-between w-full cursor-pointer py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
      @click="expandEntityInstructions = !expandEntityInstructions">
      <span class="font-semibold text_color_primary">
        Entity Investors
      </span>
      <PlusIcon v-if="!expandEntityInstructions" class="w-5 h-5 dark:text-neutral-300" />
      <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
    </div>

    <div v-if="expandEntityInstructions" class="px-5">
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">1. Balance Sheet Verification:</div>
        <div class="pl-5 text_color_secondary">
          Provide recent financial statements showing the entity's total assets exceed $5,000,000.
        </div>
      </div>
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">2. Equity Owners Verification:</div>
        <div class="pl-5 text_color_secondary">
          If the entity is owned by equity owners, provide documentation that each of the equity owners is an accredited investor. This can include the documentation listed above for
          individual investors.
        </div>
      </div>
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">3. Professional Attestation:</div>
        <div class="pl-5 text_color_secondary">
          Provide an official written communication from any of the following professionals stating that the professional service provider has a
          reasonable belief that the
          entity is an Accredited Investor (Must be dated within the last 3 months): A licensed CPA, Attorney, Investment Advisor, or Registered broker-dealer.
        </div>
      </div>
    </div>


    <div class="sm:m-7 m-1 mt-5">
      <DragDrop @changed-files="updateDataTransferObj" />
    </div>

    <div class="flex flex-row-reverse items-center justify-between">
      <button class="self-end button_primary" @click="handleAccreditedClick()">{{ nextButtonText }}
        <ArrowRightIcon class="w-4 h-4" />
      </button>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed } from 'vue'
// Stores
import { useUserStore } from '@/stores/user';
import { useAccountsStore } from '@/stores/accounts';
const accountsStore = useAccountsStore();
const userStore = useUserStore();

// Libraries
import { ArrowRightIcon, PlusIcon, MinusIcon } from "@heroicons/vue/24/outline";

// Components
import DragDrop from "@/components/applicationinputs/documents/DragDrop.vue";
import SpinnerJump from "@/components/loading/SpinnerJump.vue";

// Props
const props = defineProps({
  isInvesting: { type: Boolean, required: false, default: false },
  isEntity: { type: Boolean, required: false, default: false },
  isForOther: { type: Boolean, required: false, default: false },
  account: { type: Object, required: true, default: null },
})

// Emits
const emit = defineEmits(['next'])

// Upload Accreditation Documents
const loading = ref(false)
async function handleAccreditedClick() {
  // If we don't have any documents, skip
  if (noDocuments.value) { emit('next'); return }

  // Set loading message
  loading.value = true

  await uploadAccreditedDocs(props.account, userStore.user.id, dt.value)
  emit('next')
}

async function uploadAccreditedDocs(account, user_id, dtArray) {
  // Update AI Request Status
  if (!account?.accounts_protected?.ai_request_id) await accountsStore.requestAiVerification(account.id, account.tapi_account_id)
  else await accountsStore.updateAiRequest(account.id, account?.accounts_protected?.ai_request_id)

  const promises = [];

  for (let newFile of dtArray.files) {
    // Push upload to tapi promise to array
    const promise = accountsStore.uploadAccreditationDocumentToTapi(account.id, account.tapi_account_id, newFile)
    promises.push(promise);
    // Push upload to supabase promise to array
    const promise2 = accountsStore.uploadAccreditationDocumentToSupabase(user_id, account.id, newFile)
    promises.push(promise2);
  }

  // Wait for all promises to resolve
  await Promise.all(promises);
}

// Documents
const noDocuments = ref(true)
const dt = ref(new DataTransfer())
const updateDataTransferObj = (dtObj) => {
  dt.value = dtObj;
  if (dt.value.files.length > 0) noDocuments.value = false;
  else noDocuments.value = true;
}

// Display Booleans
const expandIndividualInstructions = ref(false)
const expandEntityInstructions = ref(false)

// Computed
const getAccreditedLabel = computed(() => {
  let label = ''
  if (props.isInvesting) label += "This investment is for accredited investors only. "
  if (props.isEntity) label += "Please choose one option and follow the instructions below to verify the entity's accredited status:"
  else if (props.isForOther) label += "Please choose one option and follow the instructions below to verify your client's accredited status:"
  else label += "Please choose one option and follow the instructions below to verify your accredited status:"
  return label
})

const nextButtonText = computed(() => {
  if (noDocuments.value) return "Do This Later"
  return "Next"
})
</script>