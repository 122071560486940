<template>
  <Slideover
    v-model="offering.show_tapi"
    header="Offering Transact API Settings"
  >
    <div
      class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full"
    >
      <!-- Content -->
      <div class="flex flex-col gap-5">
        <!-- Tapi Section -->

        <div class="flex flex-wrap gap-10">
          <!-- Start Date -->
          <div class="w-64">
            <label
              for="inception"
              class="!flex justify-between items-end input_label"
            >
              <span class="flex items-center gap-2"
                >Start Date
                <Tooltip
                  content="Leave empty to have Opera omit this information from the offering. Transact API will be given default values."
                >
                  <QuestionMarkCircleIcon
                    class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400"
                  />
                </Tooltip>
              </span>
            </label>
            <div class="input_wrapper">
              <input
                v-model="offering.start_date"
                type="date"
                name="inception"
                class="input"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
          <!-- End Date -->
          <div class="w-64">
            <label
              for="end_date"
              class="!flex justify-between items-end input_label"
            >
              <span class="flex items-center gap-2"
                >End Date
                <Tooltip
                  content="Leave empty to have Opera omit this information from the offering. Transact API will be given default values."
                >
                  <QuestionMarkCircleIcon
                    class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400"
                  />
                </Tooltip>
              </span>
            </label>
            <div class="input_wrapper">
              <input
                v-model="offering.end_date"
                type="date"
                name="end_date"
                class="input"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
        </div>

        <!-- Minimum Investment Amount -->
        <div class="flex flex-wrap gap-10">
          <div class="w-64">
            <label for="minimum" class="input_label !flex items-center gap-2">
              Minimum Investment Amount
              <Tooltip
                content="The minimum amount that can be invested at one time"
              >
                <QuestionMarkCircleIcon
                  class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400"
                />
              </Tooltip>
            </label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <span class="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                v-model="offering.minimum"
                type="text"
                name="minimum"
                class="dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-800 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-operacolor dark:disabled:bg-neutral-800 dark:disabled:text-neutral-500 block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-operacolor sm:text-sm sm:leading-6"
                placeholder="0"
                aria-describedby="price-currency"
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
              >
                <span class="text-gray-500 dark:text-neutral-400 sm:text-sm"
                  >USD</span
                >
              </div>
            </div>
          </div>

          <!-- Maximum Investment Amount -->
          <div class="w-64">
            <label for="maximum" class="input_label !flex items-center gap-2">
              Maximum Amount
              <Tooltip
                content="The maximum amount of the offering (cap amount)"
              >
                <QuestionMarkCircleIcon
                  class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400"
                />
              </Tooltip>
            </label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <span class="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                v-model="offering.maximum"
                type="text"
                name="maximum"
                class="dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-800 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-operacolor dark:disabled:bg-neutral-800 dark:disabled:text-neutral-500 block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-operacolor sm:text-sm sm:leading-6"
                placeholder="0"
                aria-describedby="price-currency"
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
              >
                <span class="text-gray-500 dark:text-neutral-400 sm:text-sm"
                  >USD</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Target Amount -->
        <div class="flex flex-wrap gap-10">
          <div class="w-64">
            <label
              for="target_amount"
              class="input_label !flex items-center gap-2"
            >
              Target Amount
              <Tooltip
                content="The target amount of the raise (must be less than the maximum amount)"
              >
                <QuestionMarkCircleIcon
                  class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400"
                />
              </Tooltip>
            </label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <span class="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                v-model="offering.target_amount"
                type="text"
                name="target_amount"
                class="dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-800 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-operacolor dark:disabled:bg-neutral-800 dark:disabled:text-neutral-500 block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-operacolor sm:text-sm sm:leading-6"
                placeholder="0"
                aria-describedby="price-currency"
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
              >
                <span class="text-gray-500 dark:text-neutral-400 sm:text-sm"
                  >USD</span
                >
              </div>
            </div>
          </div>

          <!-- Unit Price -->
          <div class="w-64">
            <label
              for="unit_price"
              class="input_label !flex items-center gap-2"
            >
              Unit Price
              <Tooltip
                content="Price per unit (unit/share price). An investment can only be made in increments of this number."
              >
                <QuestionMarkCircleIcon
                  class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400"
                />
              </Tooltip>
            </label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <span class="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                v-model="offering.unit_price"
                type="text"
                name="unit_price"
                class="dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-800 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-operacolor dark:disabled:bg-neutral-800 dark:disabled:text-neutral-500 block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-operacolor sm:text-sm sm:leading-6"
                placeholder="0"
                aria-describedby="price-currency"
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
              >
                <span class="text-gray-500 dark:text-neutral-400 sm:text-sm"
                  >USD</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <ButtonSecondary
        text="Close"
        size="lg"
        class="w-full mt-10"
        @click="offering.show_tapi = false"
      />
    </div>
  </Slideover>
</template>

<script setup>
// Components
import Slideover from "@/components/applicationui/AppSlideover.vue";
import ButtonSecondary from "@/components/applicationui/AppButtonSecondary.vue";
import Tooltip from "@/components/applicationui/AppTooltip.vue";

// Libraries
import { QuestionMarkCircleIcon } from "@heroicons/vue/24/outline";

// Model
const offering = defineModel({ type: Object });
</script>
