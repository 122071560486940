<template>
  <div class="relative h-4">
    <QuestionMarkCircleIcon
      class="w-4 h-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight"
      @mouseover="infoShow = true"
      @mouseleave="infoShow = false"
    />
    <div v-if="infoShow" class="absolute inset-y-0 z-50 my-auto h-fit left-8">
      <div
        class="@apply relative text-[rgba(0,0,0,0.75)] dark:text-neutral-300 text-xs bg-white dark:bg-neutral-700 border border-border dark:border-neutral-600 w-[160px] p-2.5 rounded-md after:absolute after:w-[11px] after:h-[11px] after:border-t-border dark:after:border-t-neutral-600 after:border-b-border dark:after:border-b-neutral-600 after:border-l-border dark:after:border-l-neutral-600 after:border-r-border dark:after:border-r-neutral-600 after:ml-[-7px] after:content-[''] after:rotate-45 after:bg-white dark:after:bg-neutral-700 after:-mt-1.5 after:border-r-0 after:border-t-0 after:border-solid after:border-b after:border-l after:left-0 after:top-2/4"
      >
        {{ props.info }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { QuestionMarkCircleIcon } from "@heroicons/vue/24/outline";
const props = defineProps({
  info: {
    type: String,
    default: "",
  },
});
const infoShow = ref(false);
</script>
