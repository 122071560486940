<template>
  <div class="flex flex-col gap-5 p-5 mt-2">
    <div class="text-lg dark:text-white font-semibold">Account Type</div>
    <RadioGroup
      v-model="selected_account_type"
      label="Account Type"
      :options="availableAccountTypes"
      class="mt-2 mb-8 max-w-xl"
    />

    <div class="flex flex-row-reverse items-center justify-between">
      <button class="self-end button_primary" @click="handleClick('next')">
        Next
        <ArrowRightIcon class="w-4 h-4" />
      </button>
      <button
        v-if="props.showPreviousButton"
        class="self-end button_secondary"
        @click="handleClick('previous')"
      >
        <ArrowLeftIcon class="w-4 h-4" />
        Previous
      </button>
    </div>
  </div>
</template>

<script setup>
// Components
import RadioGroup from "@/components/applicationinputs/AppRadioGroup.vue";

// Libraries
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/vue/24/outline";
import { onMounted, ref } from "vue";

// Globals
const availableAccountTypes = ref([
  {
    name: "Individual",
    value: "individual",
    description: "An individual account is owned by a single person.",
  },
  {
    name: "Joint",
    value: "joint",
    description: "A joint account is owned by a two people.",
  },
  {
    name: "Entity",
    value: "entity",
    description:
      "An entity account represents a company or group but will still require the personal information of the agent.",
  },
]);

// Models
const selected_account_type = defineModel({ type: Object });

// Props
const props = defineProps({
  showPreviousButton: { type: Boolean, required: true, default: false },
  offering_allowed_account_types: { type: Array, required: false, default: null },
  partner_allowed_account_types: { type: Array, required: false, default: null },
});

// Emits
const emit = defineEmits(["next", "previous"]);

onMounted(() => {
  if (props.partner_allowed_account_types) {
    availableAccountTypes.value = availableAccountTypes.value.filter(
      (accountType) =>
        props.partner_allowed_account_types.includes(accountType.value),
    );
  }
  if (props.offering_allowed_account_types) {
    availableAccountTypes.value = availableAccountTypes.value.filter(
      (accountType) =>
        props.offering_allowed_account_types.includes(accountType.value),
    );
  }
  if (availableAccountTypes.value.length === 0) {
    availableAccountTypes.value = [
      {
        name: "Individual",
        value: "individual",
        description: "An individual account is owned by a single person.",
      },
    ];
  }
  selected_account_type.value = availableAccountTypes.value[0];
});

// Functions
const handleClick = (emitted) => {
  emit(emitted);
};
</script>
