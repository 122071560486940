<template>
  <Popup v-model="open">
    <div class="flex items-start min-w-fit">
      <div
        class="flex items-center justify-center flex-shrink-0 mx-auto bg-blue-100 dark:bg-blue-950 rounded-full h-10 w-10"
      >
        <ClipboardDocumentListIcon
          class="w-6 h-6 text-blue-500 dark:text-blue-600"
          aria-hidden="true"
        />
      </div>
      <div class="flex-shrink mt-3 mr-8 ml-4 text-left">
        <DialogTitle
          as="h3"
          class="text-base font-semibold leading-6 dark:text-white mr-10"
          >Update Transaction Status</DialogTitle
        >

        <div class="my-5 max-w-52">
          <!-- Approval Status -->
          <div class="select_menu_label">Status</div>
          <SelectMenu v-model="status" :items="statuses" />
        </div>

        <div class="flex flex-col gap-2 my-8">
          <p
            v-if="transaction?.offering_id"
            class="text-sm text-gray-500 dark:text-neutral-400"
          >
            <span class="text-gray-600 dark:text-neutral-300">Offering:</span>
            {{ transaction?.offerings?.name ?? "-" }}
          </p>
          <p class="text-sm text-gray-500 dark:text-neutral-400">
            <span class="text-gray-600 dark:text-neutral-300 capitalize"
              >Transaction Type:</span
            >
            <span :class="['capitalize', getBadgeClass(transaction?.type)]">{{
              transaction?.type ?? "-"
            }}</span>
          </p>
          <p class="text-sm text-gray-500 dark:text-neutral-400">
            <span class="text-gray-600 dark:text-neutral-300"
              >Transaction #:</span
            >
            {{ transaction?.tapi_trade_id ?? "-" }}
          </p>
          <p class="text-sm text-gray-500 dark:text-neutral-400">
            <span class="text-gray-600 dark:text-neutral-300">Account:</span>
            {{ transaction?.accounts?.name ?? "-" }}
          </p>
          <p class="text-sm text-gray-500 dark:text-neutral-400">
            <span class="text-gray-600 dark:text-neutral-300"
              >Total Value:</span
            >
            {{ formatMoney(transaction?.amount * transaction?.price_per_unit) }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex gap-4 mt-5 sm:mt-4 justify-end">
      <ButtonSecondary text="Cancel" size="lg" @click="open = false" />
      <ButtonPrimary
        text="Save"
        :loading="updating"
        size="lg"
        @click="handleSavedChanges(transaction, status)"
      />
    </div>
  </Popup>
</template>

<script setup>
// Essentials
import { ref, onMounted, watch } from "vue";
import { useTransactionsStore } from "@/stores/transactions";
// Components
import SelectMenu from "@/components/applicationinputs/SelectMenu.vue";
import Popup from "@/components/applicationui/AppPopup.vue";
import ButtonPrimary from "@/components/applicationui/AppButtonPrimary.vue";
import ButtonSecondary from "@/components/applicationui/AppButtonSecondary.vue";
// Libraries
import { DialogTitle } from "@headlessui/vue";
import { formatMoney, getBadgeClass } from "@/helper/helper";
import { ClipboardDocumentListIcon } from "@heroicons/vue/24/outline";
// Props
const props = defineProps({
  show: { type: Boolean, required: true, default: false },
});
// Emits
const emit = defineEmits(["closed", "updated", "error"]);
// Models
const transaction = defineModel({ type: Object });
// Stores
const transactionsStore = useTransactionsStore();
// Globals
const open = ref(false);
const updating = ref(false);
const status = ref({ id: 2, label: "Pending", value: "pending" });
const statuses = [
  { id: 1, label: "Pending", value: "pending", tapi_value: "CREATED" },
  { id: 2, label: "Funded", value: "funded", tapi_value: "FUNDED" },
  { id: 3, label: "Settled", value: "settled", tapi_value: "SETTLED" },
  {
    id: 4,
    label: "Refund Pending",
    value: "refund_pending",
    tapi_value: "UNWIND_PENDING",
  },
  { id: 5, label: "Refunded", value: "refunded", tapi_value: "UNWIND_SETTLED" },
  { id: 6, label: "Canceled", value: "canceled", tapi_value: "CANCELED" },
  { id: 7, label: "Archived", value: "archived", tapi_value: "CANCELED" },
];
// Mounted
onMounted(() => {
  open.value = props.show;
  if (transaction.value.status == "pending") status.value = statuses[0];
  else if (transaction.value.status == "funded") status.value = statuses[1];
  else if (transaction.value.status == "settled") status.value = statuses[2];
  else if (transaction.value.status == "refund_pending")
    status.value = statuses[3];
  else if (transaction.value.status == "refunded") status.value = statuses[4];
});

// Functions
async function handleSavedChanges(currentTransaction, newStatus) {
  // Check if status is the same as current status and return if it is
  if (newStatus.value == currentTransaction.status) return;

  updating.value = true;

  // Update transaction status
  const updateSuccessful = await transactionsStore.updateTransactionStatus(
    currentTransaction.id,
    newStatus.value,
  );
  if (!updateSuccessful) {
    emit("error");
    return;
  }

  // Update Trade Status Dates
  if (newStatus.value == "settled")
    await transactionsStore.updateTransactionSettledAt(currentTransaction.id);
  if (newStatus.value == "refunded")
    await transactionsStore.updateTransactionRefundedAt(currentTransaction.id);

  // Update Trade Status in TAPI
  // TODO add partner Id
  await transactionsStore.updateTradeStatus(
    currentTransaction?.accounts?.id,
    currentTransaction?.tapi_trade_id,
    currentTransaction?.accounts?.tapi_account_id,
    newStatus.tapi_value,
    currentTransaction?.partner_id,
  );

  // If the status is not settled return successful
  if (newStatus.value != "settled") {
    updating.value = false;
    emit("updated");
    open.value = false;
    return;
  }

  // Create position in supabase if the status is settled
  updating.value = false;
  emit("updated");
  open.value = false;
}

// Watchers
watch(open, async () => {
  if (!open.value) {
    await new Promise((resolve) => setTimeout(resolve, 400));
    emit("closed");
  }
});
</script>
