<template>
  <div>
    <!-- Popups -->
    <NotificationPopup
      ref="notification"
      :type="notificationType"
      :title="notificationTitle"
      :text="notificationText"
    />

    <AlphaFlow
      v-if="showFlow"
      v-model="showFlow"
      need="create-party-for-self"
      @completed="refreshStores"
      @closed="refreshStores"
    />
    <!-- Popups -->

    <!-- Loading -->
    <div
      v-if="loading"
      class="flex flex-grow items-center justify-center h-[70vh]"
    >
      <div class="h-52 w-52 -mt-20">
        <SpinnerJump />
      </div>
    </div>

    <div v-else-if="!accountsStore.holdingAccount">
      <div class="text-center mt-10">
        <svg
          class="mx-auto h-12 w-12 text-gray-400 dark:text-neutral-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3
          class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300"
        >
          No Documents
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Get started by creating your account
        </p>
        <div class="mt-6">
          <button type="button" class="button_primary" @click="showFlow = true">
            Create Profile
            <UserPlusIcon class="w-5 h-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div v-else class="px-4 sm:px-6 lg:px-8">
      <!-- Header -->
      <div>
        <div
          class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700"
        >
          <!-- Tab Header -->
          <h3 class="primary_header">Documents</h3>
        </div>
      </div>

      <div class="flex flex-col gap-5">
        <!-- KYC/AML Docs -->
        <InvestorDocuments
          v-if="!partnerStore.partner.partners_protected.kyc_disabled"
          :party="userStore.user.parties"
          :user_id="userStore.user.id"
          :partner_type="partnerStore.partner.partners_protected.partner_type"
        />
        <!-- Entity Documents -->
        <EntityDocuments
          v-if="accountsStore?.holdingAccount?.type == 'entity'"
          :user_id="userStore.user.id"
          :party="userStore.user.parties"
        />
        <!-- Accredited Investors Docs -->
        <AccreditedDocuments
          :account="accountsStore.holdingAccount"
          :user_id="userStore.user.id"
          :partner_type="partnerStore.partner.partners_protected.partner_type"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";
import { useUserStore } from "@/stores/user";
import { usePartnerStore } from "@/stores/partner";
import { useAccountsStore } from "@/stores/accounts";
// Components
import SpinnerJump from "@/components/loading/SpinnerJump.vue";
import NotificationPopup from "@/components/applicationui/AppNotification.vue";
import AlphaFlow from "@/components/flows/AlphaFlow.vue";
import EntityDocuments from "@/components/investors/EntityDocuments.vue";
import InvestorDocuments from "@/components/investors/InvestorDocuments.vue";
import AccreditedDocuments from "@/components/investors/AccreditedDocuments.vue";
// Libraries
import { UserPlusIcon } from "@heroicons/vue/24/outline";
// Stores
const userStore = useUserStore();
const partnerStore = usePartnerStore();
const accountsStore = useAccountsStore();
// Globals
const loading = ref(true);
const showFlow = ref(false);
const notification = ref(null);
const notificationType = ref("success");
const notificationTitle = ref("Success");
const notificationText = ref("");

// Mounted
onMounted(async () => {
  // Show Create Party if DAB user and has no party
  if (
    !userStore.user?.party_id &&
    partnerStore.partner.partners_protected.partner_type == "dab"
  )
    showFlow.value = true;
  // Ready
  loading.value = false;
});

// Functions
async function refreshStores() {
  showFlow.value = false;
  loading.value = true;
  await userStore.setUser();
  await partnerStore.setPartner();
  await accountsStore.setHoldingAccount();
  loading.value = false;
}

// Simple Functions
// const notify = (type, title, text) => {
//   notificationType.value = type;
//   notificationTitle.value = title;
//   notificationText.value = text;
//   notification.value.show();
// };
</script>
