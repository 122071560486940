<template>
  <div class="p-5">
    <div class="text-lg dark:text-white font-semibold">Payment Method</div>
    <RadioGroup
      v-model="selected_payment_method"
      label="Payment Method"
      :options="availablePaymentOptions"
      class="mt-2 mb-8 max-w-xl"
    />

    <div class="flex flex-row-reverse items-center justify-between">
      <button
        v-if="selected_payment_method?.id != 'tbd'"
        class="self-end button_primary"
        @click="handleClick('next')"
      >
        Next
        <ArrowRightIcon class="w-4 h-4" />
      </button>
      <button
        class="self-end button_secondary"
        @click="handleClick('previous')"
      >
        <ArrowLeftIcon class="w-4 h-4" />
        Previous
      </button>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { computed, onMounted } from "vue";

// Components
import RadioGroup from "@/components/applicationinputs/AppRadioGroup.vue";

// Libraries
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/vue/24/outline";

// Models
const selected_payment_method = defineModel({
  type: [Object, undefined],
  required: false,
});

// Props
const props = defineProps({
  offering: { type: Object, required: true, default: null },
});

// Emits
const emit = defineEmits(["next", "previous"]);

// OnMounted
onMounted(() => {
  selected_payment_method.value = availablePaymentOptions.value[0];
});

// Computed
const availablePaymentOptions = computed(() => {
  let options = [];
  if (props.offering?.accept_ach)
    options.push({
      name: "ACH",
      id: "ach",
      description:
        "Connect directly to your bank securely using Plaid (Recommended)",
    });
  if (props.offering?.accept_cc)
    options.push({
      name: "Credit Card",
      id: "creditcard",
      description: "Purchase with your credit or debit card",
    });
  if (props.offering?.accept_check)
    options.push({
      name: "Check",
      id: "check",
      description: "Mail a check (The old fashioned way)",
    });
  if (props.offering?.accept_wire)
    options.push({
      name: "Wire",
      id: "wire",
      description: "Receive wire instructions and pay directly",
    });
  if (options.length <= 0)
    options.push({
      name: "No Payment Method Available",
      id: "tbd",
      description: "No payment method available for this offering",
    });
  return options;
});

// Functions
const handleClick = (action) => {
  emit(action);
};
</script>
