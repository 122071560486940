<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-25 dark:bg-neutral-500 dark:bg-opacity-25 transition-opacity"
        ></div>
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div
                  class="flex h-full flex-col overflow-y-scroll bg-gray-50 dark:bg-neutral-950 py-6 shadow-xl"
                >
                  <!-- Header -->
                  <div v-if="props.useHeader" class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle
                        class="text-base font-semibold leading-6 text-gray-900"
                      >
                        <PartnerLogo
                          v-if="props?.partner"
                          size="small"
                          :partner="props.partner"
                          class=""
                        />
                        <h3
                          v-else-if="props.header"
                          class="text-xl font-bold text-gray-900 sm:text-2xl dark:text-white"
                        >
                          {{ props.header }}
                        </h3>
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          class="relative rounded-md text-gray-400 hover:text-gray-500 dark:text-neutral-400 dark:hover:text-neutral-300 focus:outline-none"
                          @click="open = false"
                        >
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Header -->

                  <!-- Content -->
                  <div class="flex-1 flex flex-col">
                    <slot></slot>
                  </div>
                  <!-- Content -->
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import PartnerLogo from "@/components/partners/PartnerLogo.vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
const props = defineProps({
  useHeader: { type: Boolean, required: false, default: true },
  header: { type: String, required: false, default: null },
  partner: { type: Object, required: false, default: null },
});
const open = defineModel({
  type: Boolean,
  required: true,
});
</script>
