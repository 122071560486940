<template>
  <div
    class="relative bg-white rounded-lg shadow-md ring-1 ring-gray-100 h-fit w-full max-w-6xl dark:bg-neutral-800 dark:ring-neutral-700"
    @mouseenter="animation_ref.play()"
    @mouseleave="animation_ref.stop()"
  >
    <div
      class="flex justify-between items-center px-4 py-4 bg-white w-full max-w-6xl rounded-t-lg border-b border-gray-200 border-dashed dark:bg-neutral-800 dark:border-neutral-700"
    >
      <div class="flex items-center gap-x-2">
        <Vue3Lottie
          ref="animation_ref"
          animation-link="https://lottie.host/06850320-8818-4425-9bdc-fa4b3472aaab/pNavNpRBHw.json"
          :height="40"
          :width="40"
          :loop="false"
          :auto-play="true"
          class="-ml-2 -mt-1 -mb-2 -mr-1"
        />
        <h2
          class="max-w-6xl text-base font-semibold text-gray-900 dark:text-neutral-100"
        >
          Total Distributions
        </h2>
      </div>
      <div
        class="flex items-center gap-x-1 underline text-operacolor hover:text-operacolorlight text-sm cursor-pointer"
        @click="emit('show-all')"
      >
        View all distributions
        <ArrowTopRightOnSquareIcon class="size-4" />
      </div>
    </div>
    <div class="overflow-y-scroll max-h-96 h-fit w-full max-w-6xl">
      <div class="">
        <div class="mx-4">
          <table class="w-full text-left">
            <tbody>
              <template
                v-for="distribution in props.distributions"
                :key="distribution.id"
              >
                <tr
                  class="border-t border-gray-100 first:border-t-0 dark:border-neutral-600"
                >
                  <td class="relative py-5 pr-6">
                    <div class="flex gap-x-6">
                      <component
                        :is="getPositionIcon(distribution)"
                        class="h-5 w-5 mt-0.5 flex-none text-green-600 block"
                        aria-hidden="true"
                      />
                      <div class="flex-auto">
                        <div class="flex items-start gap-x-3">
                          <div
                            class="text-sm/6 font-medium text-gray-900 dark:text-neutral-100"
                          >
                            {{ formatMoney(distribution.total_value) }} USD
                          </div>
                        </div>
                        <div
                          v-if="distribution.units == 'dollars'"
                          class="mt-1 text-xs/5 text-gray-500 dark:text-neutral-400"
                        >
                          {{ distribution.units }}
                        </div>
                        <div
                          v-else
                          class="mt-1 text-xs/5 text-gray-500 dark:text-neutral-400"
                        >
                          {{ distribution.total_units }}
                          {{ distribution.units }}
                          <i class="text-[10px]"
                            >({{
                              formatMoney(distribution.price_per_unit)
                            }}
                            each)</i
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-5 pr-6 table-cell">
                    <div
                      class="text-sm/6 text-gray-900 font-semibold dark:text-neutral-100"
                    >
                      {{ distribution.offerings.name }}
                    </div>
                    <div class="mt-1 text-xs/5 flex items-center gap-x-1">
                      <span
                        class="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400"
                        >Distribution
                        <ArrowUturnUpIcon class="size-4 text-green-600 ml-1" />
                      </span>
                    </div>
                  </td>
                  <td class="py-5 text-right">
                    <div
                      class="flex justify-end items-center gap-x-1 text-operacolor hover:text-operacolorlight"
                      @click="emit('distribution-details', distribution)"
                    >
                      <div class="text-sm/6 font-medium cursor-pointer">
                        View<span class="inline"> distribution</span>
                      </div>
                      <ArrowTopRightOnSquareIcon class="size-4" />
                    </div>
                    <div
                      class="mt-1 text-xs/5 text-gray-500 dark:text-neutral-400"
                    >
                      {{ formatDateMonthYear(distribution.latest_date) }}
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref } from "vue";

// Icons
const animation_ref = ref(null);
import { Vue3Lottie } from "vue3-lottie";
import { WalletIcon, BanknotesIcon } from "@heroicons/vue/20/solid";
import {
  ArrowTopRightOnSquareIcon,
  ArrowUturnUpIcon,
} from "@heroicons/vue/24/outline";
// Libraries
import { formatMoney, formatDateMonthYear } from "@/helper/helper";

// Emits
const emit = defineEmits(["show-all", "distribution-details"]);

// Props
const props = defineProps({
  distributions: { type: Array, required: false, default: null },
});

// distribution Icons
const getPositionIcon = (distribution) => {
  if (distribution.type == "distribution" && distribution.units == "dollars")
    return BanknotesIcon;
  return WalletIcon;
};
</script>
