<template>
  <div>
    <!-- Normal Switch -->
    <SwitchGroup
      as="div"
      class="flex items-center justify-between flex-wrap sm:flex-nowrap gap-y-3 gap-x-2"
    >
      <span class="flex flex-grow flex-col">
        <SwitchLabel as="span" :class="labelStyles" passive>{{
          props.label
        }}</SwitchLabel>
        <SwitchDescription
          as="span"
          class="text-sm text-gray-500 dark:text-neutral-400"
          >{{ props.description }}</SwitchDescription
        >
      </span>
      <Switch
        v-model="bool"
        :disabled="props.disabled"
        :class="[
          switchStyles,
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-2 dark:focus:ring-neutral-400 dark:focus:ring-offset-0',
        ]"
      >
        <span
          aria-hidden="true"
          :class="[
            bool ? 'translate-x-5' : 'translate-x-0 !bg-white',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            { '!bg-gray-300': props.disabled },
          ]"
        />
      </Switch>
    </SwitchGroup>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import {
  Switch,
  SwitchGroup,
  SwitchLabel,
  SwitchDescription,
} from "@headlessui/vue";

const props = defineProps({
  label: { type: String, required: false, default: "" },
  description: { type: String, required: false, default: "" },
  disabled: { type: Boolean, required: false, default: false },
  color: { type: String, required: false, default: null },
});

const bool = defineModel({ type: Boolean, default: true });

const switchStyles = computed(() => {
  if (!bool.value && !props.disabled)
    return "bg-gray-200 dark:bg-neutral-700 focus:ring-operacolor";
  if (!bool.value && props.disabled) return "!cursor-not-allowed opacity-50";
  if (bool.value && props.disabled)
    return "!cursor-not-allowed bg-gray-700 opacity-50";
  if (bool.value && !props.disabled && !props.color)
    return "bg-operacolor focus:ring-operacolor";
  if (bool.value && !props.disabled && props.color == "red")
    return "bg-red-600 focus:ring-red-600";
  if (bool.value && !props.disabled && props.color == "green")
    return "bg-green-600 focus:ring-green-600";
  if (bool.value && !props.disabled && props.color == "yellow")
    return "bg-yellow-500 focus:ring-yellow-500";
  // Add more colors as needed
  return "bg-operacolor focus:ring-operacolor";
});

const labelStyles = computed(() => {
  if (ping.value)
    return "animate-bounce text-sm font-medium leading-6 text-black dark:text-white underline decoration-red-500";
  return "text-sm font-medium leading-6 text-gray-900 dark:text-neutral-100";
});

// Set error message
const ping = ref(false);
const showError = (message = "This field is required") => {
  ping.value = true;
  setTimeout(() => {
    ping.value = false;
  }, 3500);
  console.error(message);
};
defineExpose({ showError });
// Parent forms should show an error toast and scroll to this switch
</script>
