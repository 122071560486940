<template>
  <div>
    <svg
      v-if="computedCardType == 'vias'"
      aria-hidden="true"
      width="36"
      height="24"
      viewBox="0 0 36 24"
      class="h-6 w-auto"
    >
      <rect width="36" height="24" rx="4" fill="#224DBA" />
      <path
        d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
        fill="#fff"
      />
    </svg>
    <div
      v-else-if="computedCardType == 'master'"
      class="rounded shadow-opera px-1 py-0.5 bg-white dark:bg-neutral-950"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="24"
        viewBox="0 0 152.407 108"
      >
        <g>
          <rect width="36" height="24" style="fill: none" />
          <g>
            <rect
              x="60.4117"
              y="25.6968"
              width="31.5"
              height="56.6064"
              style="fill: #ff5f00"
            />
            <path
              d="M382.20839,306a35.9375,35.9375,0,0,1,13.7499-28.3032,36,36,0,1,0,0,56.6064A35.938,35.938,0,0,1,382.20839,306Z"
              transform="translate(-319.79649 -252)"
              style="fill: #eb001b"
            />
            <path
              d="M454.20349,306a35.99867,35.99867,0,0,1-58.2452,28.3032,36.00518,36.00518,0,0,0,0-56.6064A35.99867,35.99867,0,0,1,454.20349,306Z"
              transform="translate(-319.79649 -252)"
              style="fill: #f79e1b"
            />
            <path
              d="M450.76889,328.3077v-1.1589h.4673v-.2361h-1.1901v.2361h.4675v1.1589Zm2.3105,0v-1.3973h-.3648l-.41959.9611-.41971-.9611h-.365v1.3973h.2576v-1.054l.3935.9087h.2671l.39351-.911v1.0563Z"
              transform="translate(-319.79649 -252)"
              style="fill: #f79e1b"
            />
          </g>
        </g>
      </svg>
    </div>
    <div v-else-if="computedCardType == 'discover'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="34"
        viewBox="0 0 48 48"
        class="-m-1"
      >
        <path
          fill="#E1E7EA"
          d="M45,35c0,2.2-1.8,4-4,4H7c-2.2,0-4-1.8-4-4V13c0-2.2,1.8-4,4-4h34c2.2,0,4,1.8,4,4V35z"
        ></path>
        <path
          fill="#FF6D00"
          d="M45,35c0,2.2-1.8,4-4,4H16c0,0,23.6-3.8,29-15V35z M22,24c0,1.7,1.3,3,3,3s3-1.3,3-3c0-1.7-1.3-3-3-3S22,22.3,22,24z"
        ></path>
        <path
          d="M11.2,21h1.1v6h-1.1V21z M17.2,24c0,1.7,1.3,3,3,3c0.5,0,0.9-0.1,1.4-0.3v-1.3c-0.4,0.4-0.8,0.6-1.4,0.6c-1.1,0-1.9-0.8-1.9-2c0-1.1,0.8-2,1.9-2c0.5,0,0.9,0.2,1.4,0.6v-1.3c-0.5-0.2-0.9-0.4-1.4-0.4C18.5,21,17.2,22.4,17.2,24z M30.6,24.9L29,21h-1.2l2.5,6h0.6l2.5-6h-1.2L30.6,24.9z M33.9,27h3.2v-1H35v-1.6h2v-1h-2V22h2.1v-1h-3.2V27z M41.5,22.8c0-1.1-0.7-1.8-2-1.8h-1.7v6h1.1v-2.4h0.1l1.6,2.4H42l-1.8-2.5C41,24.3,41.5,23.7,41.5,22.8z M39.2,23.8h-0.3v-1.8h0.3c0.7,0,1.1,0.3,1.1,0.9C40.3,23.4,40,23.8,39.2,23.8z M7.7,21H6v6h1.6c2.5,0,3.1-2.1,3.1-3C10.8,22.2,9.5,21,7.7,21z M7.4,26H7.1v-4h0.4c1.5,0,2.1,1,2.1,2C9.6,24.4,9.5,26,7.4,26z M15.3,23.3c-0.7-0.3-0.9-0.4-0.9-0.7c0-0.4,0.4-0.6,0.8-0.6c0.3,0,0.6,0.1,0.9,0.5l0.6-0.8C16.2,21.2,15.7,21,15,21c-1,0-1.8,0.7-1.8,1.7c0,0.8,0.4,1.2,1.4,1.6c0.6,0.2,1.1,0.4,1.1,0.9c0,0.5-0.4,0.8-0.9,0.8c-0.5,0-1-0.3-1.2-0.8l-0.7,0.7c0.5,0.8,1.1,1.1,2,1.1c1.2,0,2-0.8,2-1.9C16.9,24.2,16.5,23.8,15.3,23.3z"
        ></path>
      </svg>
    </div>
    <div v-else-if="computedCardType == 'amex'">
      <svg
        id="Layer_1"
        height="35"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 291.764 291.764"
        xml:space="preserve"
        class="-my-0.5"
      >
        <g>
          <path
            style="fill: #26a6d1"
            d="M18.235,41.025h255.294c10.066,0,18.235,8.169,18.235,18.244v173.235
		c0,10.066-8.169,18.235-18.235,18.235H18.235C8.16,250.74,0,242.57,0,232.505V59.269C0,49.194,8.169,41.025,18.235,41.025z"
          />
          <path
            style="fill: #ffffff"
            d="M47.047,113.966l-28.812,63.76h34.492l4.276-10.166h9.774l4.276,10.166h37.966v-7.759l3.383,7.759
		h19.639l3.383-7.923v7.923h78.959l9.601-9.902l8.99,9.902l40.555,0.082l-28.903-31.784l28.903-32.058h-39.926l-9.346,9.719
		l-8.707-9.719h-85.897l-7.376,16.457l-7.549-16.457h-34.42v7.495l-3.829-7.495C76.479,113.966,47.047,113.966,47.047,113.966z
		 M53.721,123.02h16.813l19.111,43.236V123.02h18.418l14.761,31l13.604-31h18.326v45.752h-11.151l-0.091-35.851l-16.257,35.851
		h-9.975l-16.348-35.851v35.851h-22.94l-4.349-10.257H50.147l-4.34,10.248H33.516C33.516,168.763,53.721,123.02,53.721,123.02z
		 M164.956,123.02h45.342L224.166,138l14.315-14.98h13.868l-21.071,22.995l21.071,22.73h-14.497l-13.868-15.154l-14.388,15.154
		h-44.64L164.956,123.02L164.956,123.02z M61.9,130.761l-7.741,18.272h15.473L61.9,130.761z M176.153,132.493v8.352h24.736v9.309
		h-24.736v9.118h27.745l12.892-13.43l-12.345-13.357h-28.292L176.153,132.493z"
          />
        </g>
      </svg>
    </div>
    <div v-else class="bg-blue-600 px-2 rounded">
      <CreditCardIcon class="w-6 h-6 text-white" />
    </div>
  </div>
</template>

<script setup>
// Essentials
import { computed } from "vue";

// Components
import { CreditCardIcon } from "@heroicons/vue/20/solid";

// Props
const props = defineProps({
  cardType: { type: String, required: true, default: null },
});

// Computed
const computedCardType = computed(() => {
  if (props.cardType === null) return "default";
  if (props.cardType?.toLowerCase().includes("visa")) return "visa";
  if (props.cardType?.toLowerCase().includes("master")) return "master";
  if (props.cardType?.toLowerCase().includes("american")) return "amex";
  if (props.cardType?.toLowerCase().includes("amex")) return "amex";
  if (props.cardType?.toLowerCase().includes("discover")) return "discover";
  return "default";
});
</script>
