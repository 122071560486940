<template>
  <div class="dark:text-neutral-300">
    <div class="mb-5 dark:text-white">
      Please choose one option and follow the instructions below to verify your
      accredited status:
    </div>

    <div
      class="flex items-center justify-between w-full cursor-pointer mb-2 py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
      @click="expandIndividualInstructions = !expandIndividualInstructions"
    >
      <span class="font-semibold text_color_primary">
        Individual Investors
      </span>
      <PlusIcon
        v-if="!expandIndividualInstructions"
        class="w-5 h-5 dark:text-neutral-300"
      />
      <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
    </div>

    <div v-if="expandIndividualInstructions" class="px-5">
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">1. Income Verification:</div>
        <div class="pl-5 text_color_secondary">
          W2's, 1099's, or tax returns for the past two years to show your
          individual income exceeds $200,000 (or joint income exceeds $300,000)
          for each year.
        </div>
      </div>
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">2. Asset Verification:</div>
        <div class="pl-5 text_color_secondary">
          Provide recent account statements or third-party appraisals that show
          the value of your assets exceed $1,000,000 excluding your primary
          residence.
        </div>
      </div>
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">3. Professional Attestation:</div>
        <div class="pl-5 text_color_secondary">
          Provide an official written communication from any of the following
          stating that the professional service provider has a reasonable belief
          that you are an Accredited Investor (Must be dated within the last 3
          months): A licensed CPA, Attorney, Investment Advisor, or Registered
          broker-dealer.
        </div>
      </div>
    </div>

    <div
      class="flex items-center justify-between w-full cursor-pointer py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
      @click="expandEntityInstructions = !expandEntityInstructions"
    >
      <span class="font-semibold text_color_primary"> Entity Investors </span>
      <PlusIcon
        v-if="!expandEntityInstructions"
        class="w-5 h-5 dark:text-neutral-300"
      />
      <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
    </div>

    <div v-if="expandEntityInstructions" class="px-5">
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">1. Balance Sheet Verification:</div>
        <div class="pl-5 text_color_secondary">
          Provide recent financial statements showing the entity's total assets
          exceed $5,000,000.
        </div>
      </div>
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">2. Equity Owners Verification:</div>
        <div class="pl-5 text_color_secondary">
          If the entity is owned by equity owners, provide documentation that
          each of the equity owners is an accredited investor. This can include
          the documentation listed above for individual investors.
        </div>
      </div>
      <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
        <div class="secondary_subheader">3. Professional Attestation:</div>
        <div class="pl-5 text_color_secondary">
          Provide an official written communication from any of the following
          professionals stating that the professional service provider has a
          reasonable belief that the entity is an Accredited Investor (Must be
          dated within the last 3 months): A licensed CPA, Attorney, Investment
          Advisor, or Registered broker-dealer.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref } from "vue";
// Libraries
import { PlusIcon, MinusIcon } from "@heroicons/vue/20/solid";
// Globals
const expandEntityInstructions = ref(false);
const expandIndividualInstructions = ref(false);
</script>
