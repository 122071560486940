<template>
  <div>
    <div
      v-if="loading"
      class="flex items-center justify-center w-screen h-screen"
    >
      <div class="sm:size-52 size-32 -mt-20">
        <SpinnerJump />
      </div>
    </div>

    <div v-else>
      <!-- MOBILE -->
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog
          as="div"
          class="relative z-50 lg:hidden"
          @close="sidebarOpen = false"
        >
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-gray-900/80 dark:bg-neutral-900/80" />
          </TransitionChild>

          <div class="fixed inset-0 flex">
            <TransitionChild
              as="template"
              enter="transition ease-in-out duration-300 transform"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <DialogPanel class="relative flex flex-1 w-full max-w-xs mr-16">
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-300"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-300"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div
                    class="absolute top-0 flex justify-center w-16 pt-5 left-full"
                  >
                    <button
                      type="button"
                      class="-m-2.5 p-2.5"
                      @click="sidebarOpen = false"
                    >
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon
                        class="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </TransitionChild>
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div
                  class="flex flex-col px-6 pb-4 overflow-y-auto bg-white dark:bg-neutral-950 grow gap-y-5"
                >
                  <div class="flex items-center h-16 shrink-0">
                    <PartnerLogo size="small" :partner="partnerStore.partner" />
                  </div>
                  <nav class="flex flex-col flex-1">
                    <ul role="list" class="flex flex-col flex-1 gap-y-7">
                      <li
                        v-if="
                          userStore.user.profiles_protected.role !=
                            'partner_admin' &&
                          userStore.user.profiles_protected.role !=
                            'super_admin'
                        "
                      >
                        <ul role="list" class="-mx-2 space-y-1">
                          <li v-for="item in navigation" :key="item.name">
                            <RouterLink
                              :to="item.href"
                              :class="[
                                item.current
                                  ? 'bg-gray-100 text-operacolor dark:bg-neutral-700'
                                  : 'text-gray-700 dark:text-neutral-200 dark:hover:bg-neutral-700 dark:hover:text-operacolor hover:text-operacolor hover:bg-gray-100',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                              ]"
                              @click="sidebarOpen = false"
                            >
                              <component
                                :is="item.icon"
                                :class="[
                                  item.current
                                    ? 'text-operacolor'
                                    : 'text-gray-400 dark:text-neutral-200 group-hover:text-operacolor',
                                  'h-6 w-6 shrink-0',
                                ]"
                                aria-hidden="true"
                              />
                              {{ item.name }}
                            </RouterLink>
                          </li>
                        </ul>
                      </li>
                      <li
                        v-if="
                          userStore.user.profiles_protected.role == 'advisor'
                        "
                      >
                        <div
                          class="text-xs font-semibold leading-6 text-gray-400"
                        >
                          Advisor
                        </div>
                        <ul role="list" class="mt-2 -mx-2 space-y-1">
                          <li v-for="item in advisor" :key="item.name">
                            <RouterLink
                              :to="item.href"
                              :class="[
                                item.current
                                  ? 'bg-gray-100 text-operacolor dark:bg-neutral-700'
                                  : 'dark:text-neutral-200 dark:hover:bg-neutral-700 text-gray-700 hover:text-operacolor dark:hover:text-operacolor hover:bg-gray-100',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                              ]"
                              @click="sidebarOpen = false"
                            >
                              <component
                                :is="item.icon"
                                :class="[
                                  item.current
                                    ? 'text-operacolor'
                                    : 'text-gray-400 dark:text-neutral-200 group-hover:text-operacolor',
                                  'h-6 w-6 shrink-0',
                                ]"
                                aria-hidden="true"
                              />
                              {{ item.name }}
                            </RouterLink>
                          </li>
                        </ul>
                      </li>
                      <li
                        v-if="
                          userStore.user.profiles_protected.role ==
                            'partner_admin' ||
                          userStore.user.profiles_protected.role ==
                            'super_admin'
                        "
                      >
                        <div
                          class="text-xs font-semibold leading-6 text-gray-400"
                        >
                          Admin
                        </div>
                        <ul role="list" class="mt-2 -mx-2 space-y-1">
                          <li v-for="item in admin" :key="item.name">
                            <RouterLink
                              :to="item.href"
                              :class="[
                                item.current
                                  ? 'bg-gray-100 text-operacolor dark:bg-neutral-700'
                                  : 'dark:text-neutral-200 dark:hover:bg-neutral-700 text-gray-700 hover:text-operacolor dark:hover:text-operacolor hover:bg-gray-100',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                              ]"
                              @click="sidebarOpen = false"
                            >
                              <component
                                :is="item.icon"
                                :class="[
                                  item.current
                                    ? 'text-operacolor'
                                    : 'text-gray-400 dark:text-neutral-200 group-hover:text-operacolor',
                                  'h-6 w-6 shrink-0',
                                ]"
                                aria-hidden="true"
                              />
                              {{ item.name }}
                            </RouterLink>
                          </li>
                        </ul>
                      </li>
                      <li
                        v-if="
                          userStore.user.profiles_protected.role ==
                          'super_admin'
                        "
                      >
                        <div
                          class="text-xs font-semibold leading-6 text-gray-400"
                        >
                          Super Admin
                        </div>
                        <ul role="list" class="mt-2 -mx-2 space-y-1">
                          <li v-for="item in superAdmin" :key="item.name">
                            <RouterLink
                              :to="item.href"
                              :class="[
                                item.current
                                  ? 'bg-gray-100 text-operacolor dark:bg-neutral-700'
                                  : 'dark:text-neutral-200 dark:hover:bg-neutral-700 text-gray-700 hover:text-operacolor dark:hover:text-operacolor hover:bg-gray-100',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                              ]"
                              @click="sidebarOpen = false"
                            >
                              <component
                                :is="item.icon"
                                :class="[
                                  item.current
                                    ? 'text-operacolor'
                                    : 'text-gray-400 dark:text-neutral-200 group-hover:text-operacolor',
                                  'h-6 w-6 shrink-0',
                                ]"
                                aria-hidden="true"
                              />
                              {{ item.name }}
                            </RouterLink>
                          </li>
                        </ul>
                      </li>
                      <li
                        v-for="item in subNavigation"
                        :key="item.name"
                        class="mt-auto"
                      >
                        <RouterLink
                          :to="item.href"
                          :class="[
                            item.current
                              ? 'bg-gray-100 text-operacolor dark:bg-neutral-700'
                              : 'dark:text-neutral-200 dark:hover:bg-neutral-700 text-gray-700 dark:hover:text-operacolor hover:text-operacolor hover:bg-gray-100',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                          ]"
                          @click="sidebarOpen = false"
                        >
                          <component
                            :is="item.icon"
                            :class="[
                              item.current
                                ? 'text-operacolor'
                                : 'text-gray-400 dark:text-neutral-200 group-hover:text-operacolor',
                              'h-6 w-6 shrink-0',
                            ]"
                            aria-hidden="true"
                          />
                          {{ item.name }}
                        </RouterLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
      <!-- MOBILE -->

      <!-- Static sidebar for desktop -->
      <div
        class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
      >
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div
          class="flex flex-col px-6 pb-4 overflow-y-auto bg-white border-r border-border dark:border-neutral-800 dark:bg-neutral-950 grow gap-y-5"
        >
          <PartnerLogo size="large" :partner="partnerStore.partner" />
          <nav class="flex flex-col flex-1">
            <ul role="list" class="flex flex-col flex-1 gap-y-7">
              <li
                v-if="
                  userStore.user.profiles_protected.role != 'partner_admin' &&
                  userStore.user.profiles_protected.role != 'super_admin'
                "
              >
                <ul role="list" class="-mx-2 space-y-1">
                  <li v-for="item in navigation" :key="item.name">
                    <RouterLink
                      :to="item.href"
                      :class="[
                        item.current
                          ? 'bg-gray-100 text-operacolor dark:bg-neutral-900'
                          : 'text-gray-700 dark:text-neutral-200 hover:text-operacolor dark:hover:text-operacolor hover:bg-gray-100 dark:hover:bg-neutral-900',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        :class="[
                          item.current
                            ? 'text-operacolor'
                            : 'text-gray-400 group-hover:text-operacolor dark:text-neutral-200',
                          'h-6 w-6 shrink-0',
                        ]"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </RouterLink>
                  </li>
                </ul>
              </li>
              <li v-if="userStore.user.profiles_protected.role == 'advisor'">
                <div class="text-xs font-semibold leading-6 text-gray-400">
                  Advisor
                </div>
                <ul role="list" class="mt-2 -mx-2 space-y-1">
                  <li v-for="item in advisor" :key="item.name">
                    <RouterLink
                      :to="item.href"
                      :class="[
                        item.current
                          ? 'bg-gray-100 text-operacolor dark:bg-neutral-900'
                          : 'text-gray-700 dark:text-neutral-200 dark:hover:text-operacolor hover:text-operacolor hover:bg-gray-100 dark:hover:bg-neutral-900',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        :class="[
                          item.current
                            ? 'text-operacolor'
                            : 'text-gray-400 group-hover:text-operacolor dark:text-neutral-200',
                          'h-6 w-6 shrink-0',
                        ]"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </RouterLink>
                  </li>
                </ul>
              </li>
              <li
                v-if="
                  userStore.user.profiles_protected.role == 'partner_admin' ||
                  userStore.user.profiles_protected.role == 'super_admin'
                "
              >
                <div class="text-xs font-semibold leading-6 text-gray-400">
                  Admin
                </div>
                <ul role="list" class="mt-2 -mx-2 space-y-1">
                  <li v-for="item in admin" :key="item.name">
                    <RouterLink
                      :to="item.href"
                      :class="[
                        item.current
                          ? 'bg-gray-100 text-operacolor dark:bg-neutral-900'
                          : 'text-gray-700 dark:text-neutral-200 dark:hover:text-operacolor hover:text-operacolor hover:bg-gray-100 dark:hover:bg-neutral-900',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        :class="[
                          item.current
                            ? 'text-operacolor'
                            : 'text-gray-400 group-hover:text-operacolor dark:text-neutral-200',
                          'h-6 w-6 shrink-0',
                        ]"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </RouterLink>
                  </li>
                </ul>
              </li>
              <li
                v-if="userStore.user.profiles_protected.role == 'super_admin'"
              >
                <div class="text-xs font-semibold leading-6 text-gray-400">
                  Super Admin
                </div>
                <ul role="list" class="mt-2 -mx-2 space-y-1">
                  <li v-for="item in superAdmin" :key="item.name">
                    <RouterLink
                      :to="item.href"
                      :class="[
                        item.current
                          ? 'bg-gray-100 text-operacolor dark:bg-neutral-900'
                          : 'text-gray-700 dark:text-neutral-200 dark:hover:text-operacolor hover:text-operacolor hover:bg-gray-100 dark:hover:bg-neutral-900',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        :class="[
                          item.current
                            ? 'text-operacolor'
                            : 'text-gray-400 group-hover:text-operacolor dark:text-neutral-200',
                          'h-6 w-6 shrink-0',
                        ]"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </RouterLink>
                  </li>
                </ul>
              </li>
              <li
                v-for="item in subNavigation"
                :key="item.name"
                class="mt-auto"
              >
                <RouterLink
                  :to="item.href"
                  :class="[
                    item.current
                      ? 'bg-gray-100 text-operacolor dark:bg-neutral-900'
                      : 'text-gray-700 dark:text-neutral-200 dark:hover:text-operacolor hover:text-operacolor hover:bg-gray-100 dark:hover:bg-neutral-900',
                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-operacolor'
                        : 'text-gray-400 group-hover:text-operacolor dark:text-neutral-200',
                      'h-6 w-6 shrink-0',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </RouterLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div class="lg:pl-72">
        <div
          class="sticky top-0 z-40 flex items-center h-16 px-4 bg-white border-b shadow-sm dark:border-neutral-800 dark:bg-neutral-950 shrink-0 gap-x-4 sm:gap-x-6 sm:px-6 lg:px-8"
        >
          <button
            type="button"
            class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <Bars3Icon
              class="w-6 h-6 dark:text-neutral-600"
              aria-hidden="true"
            />
          </button>

          <!-- Separator -->
          <div
            class="w-px h-6 bg-gray-200 dark:bg-neutral-600 lg:hidden"
            aria-hidden="true"
          />

          <div class="flex self-stretch flex-1 gap-x-4 lg:gap-x-6">
            <form class="relative flex flex-1" action="#" method="GET">
              <label for="search-field" class="sr-only">Search</label>
              <MagnifyingGlassIcon
                class="absolute inset-y-0 left-0 w-5 h-full text-gray-400 pointer-events-none dark:text-neutral-200"
                aria-hidden="true"
              />
              <input
                id="search-field"
                v-model="val"
                class="block w-full h-full py-0 pl-8 pr-0 text-gray-900 border-0 dark:border-neutral-800 dark:bg-neutral-950 placeholder:text-gray-400 dark:placeholder:text-neutral-400 focus:ring-0 sm:text-sm"
                placeholder="Search... ⌘K"
                type="search"
                name="search"
                @click="open = !open"
                @input="
                  val = '';
                  open = !open;
                "
              />
            </form>
            <div class="flex items-center gap-x-4 lg:gap-x-6">
              <!-- Feedback -->
              <button
                type="button"
                class="button_secondary"
                @click="showFeedback = !showFeedback"
              >
                Feedback
                <ChatBubbleOvalLeftIcon class="w-4 h-4" />
              </button>
              <!-- Feedback -->

              <!-- Separator -->
              <div
                class="hidden lg:block lg:h-6 lg:w-px dark:bg-neutral-600"
                aria-hidden="true"
              />

              <!-- Profile dropdown -->
              <Menu as="div" class="relative">
                <MenuButton class="-m-1.5 flex items-center p-1.5">
                  <span class="sr-only">Open user menu</span>
                  <UserAvatar
                    :avatar="userStore.user.avatar"
                    :user_id="userStore.user.id"
                    :first_name="userStore.user?.parties?.first_name"
                    :last_name="userStore.user?.parties?.last_name"
                    size="xs"
                  />
                  <span class="hidden lg:flex lg:items-center">
                    <span
                      class="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-neutral-200"
                      aria-hidden="true"
                      >{{ fullName }}</span
                    >
                    <ChevronDownIcon
                      class="w-5 h-5 ml-2 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </MenuButton>
                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="dark:bg-neutral-800 bg-white absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-whitepy-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                  >
                    <MenuItem
                      v-for="item in userNavigation"
                      :key="item.name"
                      v-slot="{ active }"
                    >
                      <a
                        class="cursor-pointer"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900 dark:hover:bg-neutral-700 dark:text-neutral-300 rounded-md',
                        ]"
                        @click="checkForSignOut(item)"
                        >{{ item.name }}</a
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
              <!-- Profile dropdown -->
            </div>
          </div>
        </div>

        <!-- Feedback Popup -->
        <TransitionRoot as="template" :show="showFeedback">
          <Dialog as="div" class="relative z-50" @close="showFeedback = false">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in duration-200"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 transition-opacity" />
            </TransitionChild>
            <div class="fixed inset-0 z-10 overflow-y-auto">
              <div
                class="flex items-start justify-end min-h-full p-4 text-center sm:p-0"
              >
                <TransitionChild
                  as="template"
                  enter="ease-out duration-300"
                  enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enter-to="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leave-from="opacity-100 translate-y-0 sm:scale-100"
                  leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel
                    class="relative w-full max-w-sm overflow-hidden text-left transition-all transform bg-white border rounded-md shadow-md border-border dark:border-neutral-800 dark:bg-neutral-950 sm:my-14 sm:mx-5"
                  >
                    <div class="w-full sm:flex sm:items-start">
                      <div
                        class="flex-col items-start w-full dark:bg-neutral-900"
                      >
                        <div
                          class="w-full min-w-0 p-4 border-b border-border dark:border-neutral-800"
                        >
                          <!-- Textarea -->
                          <form action="#" class="relative w-full">
                            <div
                              class="w-full overflow-hidden rounded-lg ring-1 ring-inset ring-gray-300 dark:ring-neutral-800 dark:bg-neutral-900"
                            >
                              <label for="comment" class="sr-only"
                                >Add your feedback</label
                              >
                              <textarea
                                id="comment"
                                v-model="feedbackStr"
                                rows="3"
                                name="comment"
                                class="block w-full resize-y border-0 bg-transparent py-1.5 text-gray-900 dark:text-neutral-300 placeholder:text-gray-400 dark:placeholder:text-neutral-500 dark:caret-neutral-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="Add your comment..."
                              />
                            </div>
                          </form>
                        </div>
                        <!-- Buttons -->
                        <div class="flex justify-between p-4">
                          <button
                            type="button"
                            class="button_secondary"
                            @click="showFeedback = false"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            class="button_primary"
                            @click="
                              showFeedback = false;
                              sendFeedback();
                            "
                          >
                            Send Feedback
                          </button>
                        </div>
                        <!-- Support Text -->
                        <div
                          class="px-4 pb-4 text-xs text-gray-500 dark:text-neutral-500"
                        >
                          Have a technical issue?
                          <a
                            href="mailto:support@northcapital.com"
                            target="_blank"
                            class="text-operacolorlight cursor-pointer"
                            >Contact tech support.</a
                          >
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>

        <!-- Search Command Pallete -->
        <TransitionRoot
          :show="open"
          as="template"
          appear
          @after-leave="query = ''"
        >
          <Dialog as="div" class="relative z-50" @close="open = false">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in duration-200"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div
                class="fixed inset-0 transition-opacity bg-gray-500 dark:bg-neutral-500 dark:bg-opacity-25 bg-opacity-25"
              />
            </TransitionChild>

            <div class="fixed inset-0 z-10 p-4 overflow-y-auto sm:p-6 md:p-20">
              <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="max-w-xl mx-auto overflow-hidden transition-all transform bg-white divide-y divide-gray-100 shadow-2xl dark:divide-neutral-800 dark:bg-neutral-950 rounded-xl ring-1 ring-black ring-opacity-5"
                >
                  <Combobox @update:model-value="onSelect">
                    <div class="relative">
                      <MagnifyingGlassIcon
                        class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <ComboboxInput
                        class="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 dark:text-neutral-300 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search..."
                        @change="query = $event.target.value"
                      />
                    </div>

                    <ComboboxOptions
                      v-if="filteredItems.length > 0"
                      static
                      class="py-2 overflow-y-auto text-sm text-gray-800 dark:text-neutral-300 max-h-72 scroll-py-2"
                    >
                      <ComboboxOption
                        v-for="(item, index) in filteredItems"
                        :key="index"
                        v-slot="{ active }"
                        :value="item"
                        as="template"
                      >
                        <li
                          :class="[
                            'cursor-pointer select-none px-4 py-2',
                            active && 'bg-operacolor text-white',
                          ]"
                        >
                          {{ item.name }}
                        </li>
                      </ComboboxOption>
                    </ComboboxOptions>

                    <p
                      v-if="query !== '' && filteredItems.length === 0"
                      class="p-4 text-sm text-gray-500 dark:text-neutral-500"
                    >
                      No results found.
                    </p>
                  </Combobox>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </TransitionRoot>

        <main class="py-10">
          <div
            class="flex flex-col justify-between min-h-[calc(100vh_-_145px)]"
          >
            <!-- Your content -->
            <router-view v-slot="{ Component }">
              <keep-alive>
                <component :is="Component" />
              </keep-alive>
              <DisclosureView class="py-5 text-xs" />
            </router-view>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted, watchEffect, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { supabase } from "@/lib/supabaseClient";
import { usePartnerStore } from "@/stores/partner";
import { useUserStore } from "@/stores/user";
import { useEmailsStore } from "@/stores/emails";
// Components
import PartnerLogo from "@/components/partners/PartnerLogo.vue";
import DisclosureView from "@/components/partners/DisclosureView.vue";
import SpinnerJump from "@/components/loading/SpinnerJump.vue";
// Libaries
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3Icon,
  Cog6ToothIcon,
  FolderIcon,
  FolderOpenIcon,
  UsersIcon,
  XMarkIcon,
  GlobeAmericasIcon,
  BookOpenIcon,
  ChatBubbleOvalLeftIcon,
  CubeTransparentIcon,
  Square3Stack3DIcon,
  CurrencyDollarIcon,
  UserCircleIcon,
  WalletIcon,
  CreditCardIcon,
  UserGroupIcon,
  WrenchScrewdriverIcon,
  DocumentIcon,
  ShieldExclamationIcon,
  NewspaperIcon,
  ReceiptPercentIcon,
  ChartBarIcon,
  EnvelopeIcon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import UserAvatar from '@/components/users/UserAvatar.vue';
// Stores
const userStore = useUserStore();
const partnerStore = usePartnerStore();
const emailsStore = useEmailsStore();
// Globals
const router = useRouter();
const route = useRoute();
const val = ref("");
const query = ref("");
const searchable = ref([]);
const open = ref(false);
const loading = ref(true);
const sidebarOpen = ref(false);
const showFeedback = ref(false);
const feedbackStr = ref("");
const tabs = ref([
  { id: 1, name: 'Invest', href: '/dashboard/investing', icon: GlobeAmericasIcon, current: true },
  { id: 6, name: 'Accounts', href: '/dashboard/accounts', icon: FolderIcon, current: false },
  { id: 2, name: 'Holdings', href: '/dashboard/holdings', icon: WalletIcon, current: false },
  { id: 3, name: 'Payment Methods', href: '/dashboard/payment-methods', icon: CreditCardIcon, current: false },
  { id: 4, name: 'Documents', href: '/dashboard/documents', icon: DocumentIcon, current: false },
  { id: 5, name: 'Education', href: '/dashboard/education', icon: BookOpenIcon, current: false },
  { id: 11, name: 'Managed Accounts', href: '/dashboard/managed-accounts', icon: FolderOpenIcon, current: false },
  { id: 12, name: 'Clients', href: '/dashboard/parties', icon: UsersIcon, current: false },
  { id: 30, name: 'Insights', href: '/dashboard/insights', icon: ChartBarIcon, current: false },
  { id: 21, name: 'Users', href: '/dashboard/users', icon: UserCircleIcon, current: false },
  { id: 22, name: 'Investors', href: '/dashboard/investors', icon: UsersIcon, current: false },
  { id: 23, name: 'Transactions', href: '/dashboard/admin-transactions', icon: CurrencyDollarIcon, current: false },
  { id: 29, name: 'Distributions', href: '/dashboard/distributions', icon: ReceiptPercentIcon, current: false },
  { id: 24, name: 'Offerings', href: '/dashboard/offerings', icon: CubeTransparentIcon, current: false },
  { id: 25, name: 'Site Settings', href: '/dashboard/site-settings', icon: WrenchScrewdriverIcon, current: false },
  { id: 26, name: 'Custody Accounts', href: '/dashboard/custody', icon: Square3Stack3DIcon, current: false },
  { id: 27, name: 'Investor Verification', href: '/dashboard/investor-verification', icon: ShieldExclamationIcon, current: false },
  { id: 28, name: 'Articles Editor', href: '/dashboard/articles', icon: NewspaperIcon, current: false },
  { id: 32, name: 'Email Broadcast', href: '/dashboard/email-broadcast', icon: EnvelopeIcon, current: false },
  { id: 31, name: 'Partners', href: '/dashboard/partners', icon: UserGroupIcon, current: false },
]);
const subNavigation = ref([
  {
    id: 0,
    name: "Settings",
    href: "/dashboard/settings",
    icon: Cog6ToothIcon,
    current: false,
  },
]);
const userNavigation = ref([
  {
    id: 0,
    name: "Settings",
    href: "/dashboard/settings",
    icon: Cog6ToothIcon,
    current: false,
  },
  { name: "Sign out", href: "/login" },
]);
const navigation = ref([]);
const advisor = ref([]);
const admin = ref([]);
const superAdmin = ref([]);
const fullName = ref("");
// Mounted
onMounted(async () => {
  // Add search command k listener
  window.addEventListener("keydown", keyHandler);
  // Get user's tabs
  getTabs();
  // Set globally searchable items
  setSearchable();
  loading.value = false;
});
// Computed
const filteredItems = computed(() => {
  return query.value === ""
    ? searchable.value
    : searchable.value.filter((item) => {
        return item.name.toLowerCase().includes(query.value.toLowerCase());
      });
});
// Functions
const keyHandler = (e) => {
  if (e.keyCode === 75 && e.metaKey) {
    e.preventDefault();
    open.value = !open.value;
  }
};

const checkForSignOut = async (item) => {
  if (item.name == "Sign out") {
    let partner = "";
    if (partnerStore.partner.id)
      partner = `?partner=${partnerStore.partner.meta_name}`;
    await supabase.auth.signOut();
    let loginUrl = "/login" + partner;
    router.push(loginUrl);
  } else {
    router.push("/dashboard/settings");
  }
};

const sendFeedback = async () => {
  emailsStore.sendFeedback(
    feedbackStr.value,
    route.path,
    `${userStore.user.parties.first_name} ${userStore.user.parties.last_name}`,
    userStore.user.id,
    userStore.user.email,
  );
  feedbackStr.value = "";
};

const chooseCorrectMenuOption = () => {
  var currentPath = route.path;
  navigation.value.forEach((item) => {
    if (currentPath.includes(item.href)) {
      item.current = true;
    } else {
      item.current = false;
    }
  });
  advisor.value.forEach((item) => {
    if (currentPath.includes(item.href)) {
      item.current = true;
    } else {
      item.current = false;
    }
  });
  admin.value.forEach((item) => {
    if (currentPath.includes(item.href)) {
      item.current = true;
    } else {
      item.current = false;
    }
  });
  superAdmin.value.forEach((item) => {
    if (currentPath.includes(item.href)) {
      item.current = true;
    } else {
      item.current = false;
    }
  });
  subNavigation.value.forEach((item) => {
    if (currentPath.includes(item.href)) {
      item.current = true;
    } else {
      item.current = false;
    }
  });
};

const getTabs = () => {
  if (userStore.user.profiles_protected.role == 'investor') {
    navigation.value = tabs.value.filter(tab => partnerStore.partner.partners_protected.investor_tabs.includes(tab.id));
  }
  else if (userStore.user.profiles_protected.role == 'advisor') {
    navigation.value = tabs.value.filter(tab => partnerStore.partner.partners_protected.investor_tabs.includes(tab.id));
    advisor.value = tabs.value.filter(tab => partnerStore.partner.partners_protected.advisor_tabs.includes(tab.id));
  }
  else if (userStore.user.profiles_protected.role == 'partner_admin') {
    admin.value = tabs.value.filter(tab => partnerStore.partner.partners_protected.admin_tabs.includes(tab.id));
  }
  else if (userStore.user.profiles_protected.role == 'super_admin') {
    admin.value = tabs.value.filter(tab => partnerStore.partner.partners_protected.admin_tabs.includes(tab.id));
    admin.value = admin.value.filter(tab => tab.id !== 29 && tab.id !== 30 && tab.id !== 32);
    superAdmin.value = tabs.value.filter(tab => [31].includes(tab.id));
  }
};

const setSearchable = () => {
  searchable.value = [
    ...navigation.value,
    ...advisor.value,
    ...admin.value,
    ...superAdmin.value,
    ...subNavigation.value,
  ];
};

const onSelect = (item) => {
  router.push(item.href);
  open.value = false;
};

// Watchers
watchEffect(() => {
  chooseCorrectMenuOption();
});
</script>
