<template>
  <div>
    <!-- Small -->
    <div v-if="props.size === 'sm'">
      <div v-if="props.label" class="flex justify-between mb-2">
        <label
          v-if="props.label"
          :for="props.id"
          class="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          {{ props.label }}
          <Tooltip v-if="props.tooltip" :content="props.tooltip">
            <QuestionMarkCircleIcon
              class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight"
            />
          </Tooltip>
        </label>
        <span
          v-if="props.optional"
          :id="`${id}-optional`"
          class="text-sm leading-6 text-gray-400 dark:text-neutral-500"
          >Optional</span
        >
      </div>
      <div class="relative">
        <input
          :id="props.id"
          ref="input_ref"
          v-model="text"
          :type="props.type"
          :name="props.id"
          :placeholder="props.placeholder"
          :autocomplete="autocomplete"
          :disabled="props.disabled"
          :aria-describedby="`${id}`"
          class="input_custom_small"
          @input="resetCustomValidity"
        />
      </div>
    </div>

    <!-- Medium/Regular -->
    <div v-else>
      <div v-if="props.label" class="flex justify-between mb-2">
        <label
          v-if="props.label"
          :for="props.id"
          class="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          {{ props.label }}
          <Tooltip v-if="props.tooltip" :content="props.tooltip">
            <QuestionMarkCircleIcon
              class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight"
            />
          </Tooltip>
        </label>
        <span
          v-if="props.optional"
          :id="`${id}-optional`"
          class="text-sm leading-6 text-gray-400 dark:text-neutral-500"
          >Optional</span
        >
      </div>
      <div class="relative">
        <input
          :id="props.id"
          ref="input_ref"
          v-model="text"
          :type="props.type"
          :name="props.id"
          :placeholder="props.placeholder"
          :autocomplete="autocomplete"
          :disabled="props.disabled"
          :aria-describedby="`${id}`"
          :required="!props.optional"
          class="input_custom"
          @input="resetCustomValidity"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Tooltip from "@/components/applicationui/AppTooltip.vue";
import { QuestionMarkCircleIcon } from "@heroicons/vue/24/outline";

// Model

// eslint-disable-next-line vue/require-prop-types
const text = defineModel();

// Props
const props = defineProps({
  id: { type: String, required: true, default: "text" },
  label: { type: String, required: false, default: null },
  type: { type: String, required: false, default: "text" },
  placeholder: { type: String, required: false, default: "" },
  disabled: { type: Boolean, required: false, default: false },
  optional: { type: Boolean, required: false, default: false },
  autocomplete: { type: String, required: false, default: "off" },
  tooltip: { type: String, required: false, default: null },
  size: { type: String, required: false, default: "md" },
});

// Set error message
const input_ref = ref(null);
const resetCustomValidity = () => {
  input_ref.value.setCustomValidity("");
};
const setErrorMessage = (message = "This field is required") => {
  input_ref.value.setCustomValidity(message);
  input_ref.value.reportValidity();
};
defineExpose({ setErrorMessage });
</script>

<style scoped>
.input_custom {
  @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-operacolor dark:text-neutral-100 dark:ring-neutral-700 dark:bg-neutral-800 dark:focus:ring-operacolorlight dark:placeholder:text-neutral-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 dark:disabled:cursor-not-allowed dark:disabled:bg-neutral-700 dark:disabled:text-neutral-400;
}
.input_custom_small {
  @apply block w-full rounded-md border-0 py-0.5 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:leading-6 bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-operacolor dark:text-neutral-100 dark:ring-neutral-700 dark:bg-neutral-800 dark:focus:ring-operacolorlight dark:placeholder:text-neutral-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 dark:disabled:cursor-not-allowed dark:disabled:bg-neutral-700 dark:disabled:text-neutral-400;
}

input[type="number"].input_custom_small::-webkit-inner-spin-button,
input[type="number"].input_custom_small::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].input_custom_small {
  appearance: textfield;
  -moz-appearance: textfield; /* For Firefox */
}
</style>
