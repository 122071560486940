<template>
  <div id="insights" class="px-4 sm:px-6 lg:px-8">
    <!-- Notification Popup -->
    <AppNotification ref="notification" :type="notificationType" :title="notificationTitle" :text="notificationText" />

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Ready -->
    <div v-else>
      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 flex-wrap dark:border-neutral-700">
          <!-- Tab Header -->
          <h3 class="primary_header">Insights</h3>
          <div class="flex items-center flex-wrap gap-4"></div>
        </div>
      </div>

      <!-- Content -->
      <div class="flex flex-col gap-5 flex-wrap">
        <!-- Stats Highlevel -->
        <ContributionInsightsWidget :partner_id="partnerStore.partner.id" />

        <div class="flex gap-5 flex-wrap">
          <ContributionsLineChart :partner_id="partnerStore.partner.id" />

          <!-- Dropoff Donut Chart -->
          <InvestorProgressPieChart :partner_id="partnerStore.partner.id" />
        </div>

        <!-- Averages Stat Highlights -->
        <ContributionsAvgStats :partner_id="partnerStore.partner.id" />

        <div class="flex gap-5">
          <!-- Largest Contributers Bar Chart -->
          <LargestContributersChart :partner_id="partnerStore.partner.id" />

          <!-- Investment Locations Mapped -->
          <InvestmentsMapped :partner_id="partnerStore.partner.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";

// Stores
import { usePartnerStore } from "@/stores/partner";
const partnerStore = usePartnerStore();

// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import AppNotification from "@/components/applicationui/AppNotification.vue";
import ContributionInsightsWidget from "@/components/partners/ContributionInsightsWidget.vue";
import ContributionsLineChart from "@/components/partners/ContributionsLineChart.vue";
import InvestorProgressPieChart from "@/components/partners/InvestorProgressPieChart.vue";
import LargestContributersChart from "@/components/partners/LargestContributersChart.vue";
import InvestmentsMapped from "@/components/partners/InvestmentsMapped.vue";
import ContributionsAvgStats from "@/components/partners/ContributionsAvgStats.vue";

// Toasts START
const notification = ref(null);
const notificationType = ref("success");
const notificationTitle = ref("Success");
const notificationText = ref("");
// const notify = (type, title, text) => {
//   notificationType.value = type
//   notificationTitle.value = title
//   notificationText.value = text
//   notification.value.show()
// }
// Toasts END

// Mounted START
const loading = ref(true);
onMounted(async () => {
  // Ready
  loading.value = false;
});
// Mounted END
</script>
