<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <NotificationPopup
      ref="notification"
      :type="notificationType"
      :title="notificationTitle"
      :text="notificationText"
    />

    <!-- Offers Table -->
    <div>
      <!-- Loading -->
      <div
        v-if="loading"
        class="flex flex-grow items-center justify-center h-[70vh]"
      >
        <div class="w-32 h-32 -mt-10">
          <SpinnerJump />
        </div>
      </div>

      <!-- Empty State -->
      <div v-else-if="offerings.length <= 0">
        <div class="text-center">
          <CubeTransparentIcon
            class="h-12 w-12 mx-auto text-gray-400 dark:text-neutral-400"
          />
          <h3
            class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300"
          >
            No Offerings
          </h3>
          <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">
            Get started by creating a new offering.<br />
          </p>
          <div class="mt-6">
            <button
              type="button"
              class="button_primary"
              @click="showEditOfferingPopup"
            >
              New Offering
              <PlusIcon class="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <!-- Offerings -->
      <div v-else>
        <!-- Header -->
        <div>
          <div
            class="border-b border-gray-200 pb-5 mb-5 flex items-center flex-wrap justify-between gap-5 dark:border-neutral-700"
          >
            <!-- Tab Header -->
            <h3 class="primary_header">Offerings</h3>

            <div class="flex items-center gap-4 flex-wrap">
              <!-- Search -->
              <SearchInput v-model="query" />
              <!-- Create New Offering Button -->
              <ButtonPrimary
                v-if="
                  !(
                    partnerStore.partner.partners_protected.partner_type ==
                      'dib' && offerings.length > 0
                  )
                "
                text="New Offering"
                :icon="PlusIcon"
                size="lg"
                @click="showEditOfferingPopup"
              />
            </div>
          </div>
          <div class="flex">
            <TableOrCard v-model="isTable" />
          </div>
        </div>

        <!-- Offering Table -->
        <!-- <TablePrimary v-if="isTable" :rows="filteredOfferings" :columns="columns" :isClickable="true" @click-primary="showEditOfferingPopup" class="-mt-5" export_title="offerings" /> -->
        <TableSticky
          v-if="isTable"
          v-model="columns"
          :loading="loading_offerings"
          :loading_all="loading_all_offerings"
          :rows="filteredOfferings"
          :is-clickable="true"
          class="-mt-8"
          export_title="offerings"
          @click-primary="showEditOfferingPopup"
        />

        <!-- Offering Cards -->
        <div v-else class="flex flex-wrap gap-10 mt-4">
          <!-- Cards -->
          <div
            v-for="o in filteredOfferings"
            :key="o.id"
            class="relative h-fit w-80 box-border flex justify-between flex-col background_secondary shadow-opera dark:shadow-none rounded-lg dark:text-neutral-300"
          >
            <OfferingCard
              :offering="o"
              :partner="partnerStore.partner"
              :allow_edit="true"
              @button-clicked="showEditOfferingPopup"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from "vue";
import { useUserStore } from "@/stores/user";
import { usePartnerStore } from "@/stores/partner";
import { useOfferingsStore } from "@/stores/offerings";
import { useRouter } from "vue-router";
const router = useRouter();

// Components
import NotificationPopup from "@/components/applicationui/AppNotification.vue";
import SpinnerJump from "@/components/loading/SpinnerJump.vue";
import OfferingCard from "@/components/offerings/OfferingCard.vue";

import ButtonPrimary from "@/components/applicationui/AppButtonPrimary.vue";
import TableSticky from "@/components/applicationui/tables/AppExportableTable.vue";
import TableOrCard from "@/components/applicationui/tables/TableOrCard.vue";
import SearchInput from "@/components/applicationinputs/SearchInput.vue";

// Libraries
import { PlusIcon } from "@heroicons/vue/24/outline";
import { CubeTransparentIcon } from "@heroicons/vue/24/solid";

// Stores
const userStore = useUserStore();
const partnerStore = usePartnerStore();
const offeringsStore = useOfferingsStore();

// Globals
const isTable = ref(false);
if (window.innerWidth < 1024) isTable.value = false;

const loading = ref(true);
const loading_offerings = ref(true);
const loading_all_offerings = ref(true);
const query = ref("");
const offerings = ref([]);

const notification = ref(null);
const notificationType = ref("success");
const notificationTitle = ref("Offering Created Successfully");
const notificationText = ref(
  "Don't forget to add any offering or subscription documents that are needed.",
);

const columns = ref([
  { key: "name", label: "Name", type: "bold", visible: true },
  { key: "tapi_offering_id", label: "ID", type: "copy", visible: false },
  { key: "offering_type", label: "Type", type: "badge", visible: true },
  { key: "market_sector", label: "Sector", type: "bold", visible: true },
  { key: "status", label: "Status", type: "badge", visible: true },
  { key: "visibility", label: "Visibility", type: "badge", visible: true },
  { key: "slogan", label: "Slogan", type: "text", visible: false },
  { key: "created_at", label: "Created", type: "date", visible: true },
]);

// Mounted
onMounted(async () => {
  // Get Offerings
  await setOfferings();
  // Ready
  loading.value = false;
});

// Computed
const filteredOfferings = computed(() => {
  return query.value === ""
    ? offerings.value
    : offerings.value.filter((offering) => {
        if (offering?.name?.toLowerCase().includes(query.value.toLowerCase()))
          return true;
        if (
          offering?.offering_type
            ?.toLowerCase()
            .includes(query.value.toLowerCase())
        )
          return true;
        if (
          offering?.market_sector
            ?.toLowerCase()
            .includes(query.value.toLowerCase())
        )
          return true;
        if (
          offering?.tapi_offering_id
            ?.toLowerCase()
            .includes(query.value.toLowerCase())
        )
          return true;
      });
});

// Functions
async function setOfferings() {
  // Super Admin
  if (userStore.user.profiles_protected.role == "super_admin") {
    offerings.value = await offeringsStore.getAllOfferingsPaginated(0, 49);
    if (!offerings.value) {
      notify(
        "failure",
        "Sorry",
        "We were unable to get your offerings. If the issue persits, please contact tech support.",
      );
      loading_offerings.value = false;
      loading_all_offerings.value = false;
      return;
    }
    loading_offerings.value = false;
    getRestOfOfferings();
    return;
  }
  // Partner Admin
  offerings.value = await offeringsStore.getAllOfferingsForPartnerPaginated(
    partnerStore.partner.id,
    0,
    49,
  );
  if (!offerings.value) {
    notify(
      "failure",
      "Sorry",
      "We were unable to get your offerings. If the issue persits, please contact tech support.",
    );
    loading_offerings.value = false;
    loading_all_offerings.value = false;
    return;
  }
  loading_offerings.value = false;
  getRestOfOfferingsForPartner();
  return;
}

async function getRestOfOfferings() {
  const data = await offeringsStore.getAllOfferingsPaginated(50);
  if (!data) {
    loading_all_offerings.value = false;
    return;
  }
  offerings.value = offerings.value.concat(data);
  loading_all_offerings.value = false;
}

async function getRestOfOfferingsForPartner() {
  const data = await offeringsStore.getAllOfferingsForPartnerPaginated(
    partnerStore.partner.id,
    50,
  );
  if (!data) {
    loading_all_offerings.value = false;
    return;
  }
  offerings.value = offerings.value.concat(data);
  loading_all_offerings.value = false;
}

async function showEditOfferingPopup(offering) {
  router.push({ name: "edit-offering", params: { id: offering?.id } });
}

// Simple Functions
const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};
</script>
