<template>
  <div class="relative">
    <BookmarkIcon
      v-if="props.article.status == 'unpublished'"
      class="size-6 text-yellow-600 absolute -top-1 right-5"
    />
    <div
      class="flex flex-col w-72 ring-1 ring-border dark:ring-neutral-600 rounded-md overflow-hidden"
    >
      <div
        v-if="props.article?.image || props.article?.preview_url"
        ref="images"
        class="h-52 w-72 bg-gray-100 dark:bg-neutral-900 bg-[center_center] bg-no-repeat bg-cover"
        :style="{ backgroundImage: 'url(' + articleUrl + ')' }"
      ></div>
      <div v-else class="h-52 w-72 bg-gray-100 dark:bg-neutral-900"></div>
      <div class="pt-2.5 flex flex-col gap-2.5">
        <div
          class="w-full h-12 px-4 overflow-hidden font-bold text-gray-900 dark:text-white"
        >
          {{ props.article?.title ?? "Title" }}
        </div>
        <div
          class="w-full h-24 px-4 overflow-hidden text-sm text-gray-700 dark:text-neutral-200"
        >
          {{ props.article?.description ?? "Description" }}
        </div>
        <div class="grow"></div>
        <div class="flex justify-end px-4 pb-4 text-center">
          <div v-if="props.disabled" class="button_primary">
            Learn more
            <ArrowRightIcon class="w-4 h-4" />
          </div>
          <div v-else-if="props.edit" class="flex items-center gap-4">
            <router-link
              :to="{ name: 'article', params: { id: props.article.id } }"
              class="button_secondary"
            >
              Preview
              <ArrowTopRightOnSquareIcon class="w-4 h-4" />
            </router-link>
            <div class="button_primary" @click="handleEmit('edit')">
              Edit
              <PencilSquareIcon class="w-4 h-4" />
            </div>
          </div>
          <router-link
            v-else-if="props.preview"
            :to="{ name: 'article', params: { id: props.article.id } }"
            class="button_primary"
          >
            Preview
            <ArrowTopRightOnSquareIcon class="w-4 h-4" />
          </router-link>
          <router-link
            v-else
            :to="{ name: 'article', params: { id: props.article.id } }"
            class="button_primary"
          >
            Learn more
            <ArrowRightIcon class="w-4 h-4" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import {
  ArrowRightIcon,
  PencilSquareIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/vue/24/outline";
import { BookmarkIcon } from "@heroicons/vue/24/solid";
// Props
const props = defineProps({
  article: { type: Object, required: true },
  disabled: { type: Boolean, required: false, default: false },
  edit: { type: Boolean, required: false, default: false },
  preview: { type: Boolean, required: false, default: false },
});
// Computed
const articleUrl = computed(() => {
  if (props.article?.image) return props.article?.image;
  return props.article?.preview_url;
});
// Emits
const emit = defineEmits(["edit", "view"]);
// Handle Edit
const handleEmit = (type) => {
  emit(type);
};
</script>
