<template>
  <Tippy
    :content="props.content"
    :theme="tippyTheme"
    :animation="props.animation"
  >
    <slot></slot>
  </Tippy>
</template>

<script setup>
import { ref, onMounted } from "vue";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/material.css";
import "tippy.js/animations/perspective-subtle.css";
import { Tippy } from "vue-tippy";

const props = defineProps({
  content: { type: String, required: true },
  theme: { type: String, required: false, default: null },
  animation: { type: String, default: "perspective-subtle" },
});

const tippyTheme = ref("light");

onMounted(() => {
  if (props.theme) {
    tippyTheme.value = props.theme;
    return;
  }
  const rootElement = document.documentElement;
  if (rootElement.classList.contains("dark")) tippyTheme.value = "material";
});
</script>
