<template>
  <div
    class="relative bg-white rounded-lg shadow-md ring-1 ring-gray-100 h-fit w-full max-w-6xl dark:bg-neutral-800 dark:ring-neutral-700"
    @mouseenter="animation_ref.play()"
    @mouseleave="animation_ref.stop()"
  >
    <div
      class="flex justify-between items-center px-4 py-4 bg-white w-full max-w-6xl rounded-t-lg border-b border-gray-200 border-dashed dark:bg-neutral-800 dark:border-neutral-700"
    >
      <div class="flex items-center gap-x-2">
        <Vue3Lottie
          ref="animation_ref"
          animation-link="https://lottie.host/3f8e3b21-b067-4648-b151-866b7b7184d2/CRle3uzMwO.json"
          :height="40"
          :width="40"
          :loop="false"
          :auto-play="true"
          class="-m-1 -mt-2"
        />
        <h2
          class="max-w-6xl text-base font-semibold text-gray-900 dark:text-neutral-100"
        >
          Current Holdings
        </h2>
      </div>
      <div
        class="flex items-center gap-x-1 underline text-operacolor hover:text-operacolorlight text-sm cursor-pointer"
        @click="emit('show-all')"
      >
        View all positions
        <ArrowTopRightOnSquareIcon class="size-4" />
      </div>
    </div>
    <div class="overflow-y-scroll max-h-96 h-fit w-full max-w-6xl">
      <div class="">
        <div class="mx-4">
          <table class="w-full text-left">
            <tbody>
              <template v-for="position in props.positions" :key="position.id">
                <tr
                  class="border-t border-gray-100 first:border-t-0 dark:border-neutral-600"
                >
                  <td class="relative py-5 pr-6">
                    <div class="flex gap-x-6">
                      <component
                        :is="getPositionIcon(position)"
                        class="h-5 w-5 mt-0.5 flex-none text-green-600 block"
                        aria-hidden="true"
                      />
                      <div class="flex-auto">
                        <div class="flex items-start gap-x-3">
                          <div
                            class="text-sm/6 font-medium text-gray-900 dark:text-neutral-100"
                          >
                            {{ formatMoney(position.total_value) }} USD
                          </div>
                        </div>
                        <div
                          v-if="position.units == 'dollars'"
                          class="mt-1 text-xs/5 text-gray-500 dark:text-neutral-400"
                        >
                          {{ position.units }}
                        </div>
                        <div
                          v-else
                          class="mt-1 text-xs/5 text-gray-500 dark:text-neutral-400"
                        >
                          {{ position.total_units }} {{ position.units }}
                          <i class="text-[10px]"
                            >({{
                              formatMoney(position.price_per_unit)
                            }}
                            each)</i
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-5 pr-6 table-cell">
                    <div
                      class="text-sm/6 text-gray-900 font-semibold dark:text-neutral-100"
                    >
                      {{ position.offerings.name }}
                    </div>
                    <div
                      v-if="position.type == 'buy'"
                      class="mt-1 text-xs/5 flex items-center gap-x-1"
                    >
                      <span
                        class="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400"
                        >Holding
                        <CubeIcon class="size-4 text-blue-600 ml-1" />
                      </span>
                    </div>
                    <div
                      v-if="position.type == 'distribution'"
                      class="mt-1 text-xs/5 flex items-center gap-x-1"
                    >
                      <span
                        class="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400"
                        >Distribution
                        <ArrowUturnUpIcon class="size-4 text-green-600 ml-1" />
                      </span>
                    </div>
                  </td>
                  <td class="py-5 text-right">
                    <div
                      class="flex justify-end items-center gap-x-1 text-operacolor hover:text-operacolorlight"
                      @click="emit('position-details', position)"
                    >
                      <div class="text-sm/6 font-medium cursor-pointer">
                        View<span class="inline"> position</span>
                      </div>
                      <ArrowTopRightOnSquareIcon class="size-4" />
                    </div>
                    <div
                      class="mt-1 text-xs/5 text-gray-500 dark:text-neutral-400"
                    >
                      {{ formatDateMonthYear(position.latest_date) }}
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref } from "vue";

// Icons
const animation_ref = ref(null);
import { Vue3Lottie } from "vue3-lottie";
import { WalletIcon, BanknotesIcon } from "@heroicons/vue/20/solid";
import {
  ArrowTopRightOnSquareIcon,
  ArrowUturnUpIcon,
  CubeIcon,
} from "@heroicons/vue/24/outline";
// Libraries
import { formatMoney, formatDateMonthYear } from "@/helper/helper";

// Emits
const emit = defineEmits(["show-all", "position-details"]);

// Props
const props = defineProps({
  positions: { type: Array, required: true, default: null },
});

// Position Icons
const getPositionIcon = (position) => {
  if (position.type == "distribution" && position.units == "dollars")
    return BanknotesIcon;
  return WalletIcon;
};
</script>
