<template>
  <main class="relative min-h-screen min-w-screen isolate">
    <img
      src="https://images.unsplash.com/photo-1491982883790-ead7c97a047e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2188&q=80"
      alt=""
      class="absolute inset-0 object-cover object-top w-screen h-screen -z-10"
    />
    <div class="px-6 py-32 mx-auto text-center max-w-7xl sm:py-40 lg:px-8">
      <p class="text-base font-semibold leading-8 text-black">Oops</p>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-black sm:text-5xl">
        Portal not found
      </h1>
      <p class="mt-4 text-base text-black/70 sm:mt-6">
        Sorry, we couldn't find the investor protal you're looking for.<br />
        Please double check you url or contact tech support.
      </p>
    </div>
  </main>
</template>
