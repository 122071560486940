<template>
  <div
    class="flex-col items-center justify-center w-1/2 min-w-96 gap-2 bg-white rounded-lg shadow-md py-2.5 px-2.5 ring-1 ring-gray-100 dark:bg-neutral-800 dark:ring-neutral-700 dark:shadow-none"
  >
    <div class="font-semibold border-b border-dashed border-gray-200 mx-5 pb-2 mb-1 text-center dark:border-neutral-600 dark:text-neutral-100">
      Investors Locations
    </div>
    <div ref="mapContainer" class="px-2 pb-10 mt-4 w-full min-w-96 h-[400px] rounded-lg"></div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted, onUnmounted } from "vue";

// Stores
import { useTransactionsStore } from "@/stores/transactions";
const transactionsStore = useTransactionsStore();

// Props
const props = defineProps({
  partner_id: { type: String, required: true },
});

// Mapbox Imports
import "../../../node_modules/mapbox-gl/dist/mapbox-gl.css"; // If this component is moved to a different location, this import path will need to be updated
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken = "pk.eyJ1IjoiYmhhcmRlbiIsImEiOiJjbTZoMjBxMmQwN2UyMmpvb2pmcGJ0dzU1In0.ISwj2YmBNsgkMZRXkeg5Yg";
// Map Theme
const theme = localStorage.theme;
let url = `mapbox://styles/mapbox/light-v11`;
if (theme === "dark") url = `mapbox://styles/mapbox/dark-v11`;



// Get Addresses START
const investor_addresses = ref([]);
const getInvestorAddresses = async () => {
  // Get all transactions
  const settled_transactions = await transactionsStore.getAllSettledTransactionsForPartnerFillAccountDataFillPartyDate(props.partner_id);
  // Get all unique accounts
  const unique_account_ids = [...new Set(settled_transactions.map((transaction) => transaction.account_id))];
  const last_transactions = unique_account_ids.map((account_id) => {
    const transactions = settled_transactions.filter((transaction) => transaction.account_id === account_id);
    const totalAmount = transactions.reduce((sum, transaction) => sum + (transaction.amount * transaction.price_per_unit), 0);
    const latestTransaction = transactions.reduce((latest, transaction) => {
      return new Date(transaction.date) > new Date(latest.date) ? transaction : latest;
    });
    latestTransaction.total_invested = totalAmount;
    return latestTransaction;
  });
  // Get all unique accounts with their transactions

  // Get all investor addresses
  investor_addresses.value = last_transactions.map((transaction) => {
    const party = transaction.accounts.parties
    return {
      address: `${party.address}, ${party.city}, ${party.state}, ${party.zip}`,
      amount: transaction.total_invested,
    };
  });
};
// Get Addresses END

// Add Map Markers START
async function createMap() {
  const map = new mapboxgl.Map({
    container: mapContainer.value,
    style: url, // Replace with your preferred map style
    center: [-97, 38],
    zoom: 3,
    projection: "mercator",
    attributionControl: false, // Disables the Mapbox branding
    // logoPosition: false // Removes the Mapbox logo
  });
  mapRef.value = map;

  const addressMap = new Map();
  for (const address of investor_addresses.value) {
    if (addressMap.has(address.address)) {
      addressMap.set(address.address, addressMap.get(address.address) + address.amount);
    } else {
      addressMap.set(address.address, address.amount);
    }
  }
  const unique_addresses = Array.from(addressMap.entries());

  for (const address of unique_addresses) {
    const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address[0])}.json?access_token=pk.eyJ1IjoiYmhhcmRlbiIsImEiOiJjbTZoMjBxMmQwN2UyMmpvb2pmcGJ0dzU1In0.ISwj2YmBNsgkMZRXkeg5Yg`);
    const data = await response.json();
    const [lng, lat] = data.features[0].center;

    const marker_radius = Math.min(15, Math.max(4, Math.log2(address[1] / 1000)));

    map.addLayer({
      id: `marker-${address[0]}`,
      type: "circle",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: { type: "Point", coordinates: [lng, lat] }
        } 
      },
      paint: {
        "circle-radius": marker_radius, // Adjust size as needed
        "circle-color": "rgba(12,167,120 ,0.2)", // Fill color with 40% opacity
        "circle-stroke-width": 1, // Border thickness
        "circle-stroke-color": "rgba(12,167,120, 0.7)" // Solid border color
      }
    });
  }
}
// Add Map Markers END

const mapContainer = ref(null);
const mapRef = ref(null);
onMounted( async () => {
  await getInvestorAddresses();
  await createMap();
});

onUnmounted(() => {
  mapRef.value.remove();
  mapRef.value = null;
});
</script>
