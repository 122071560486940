<template>
  <fieldset>
    <legend
      class="flex gap-1 items-center text-sm font-semibold leading-6 text-gray-900 dark:text-white"
    >
      {{ props.label }}
      <InfoCircle v-if="props.info" :info="props.info" />
    </legend>
    <p
      v-if="props.subLabel"
      class="mt-1 mb-4 text-sm leading-6 text-gray-600 dark:text-neutral-400"
    >
      {{ props.subLabel }}
    </p>
    <div class="mt-2 flex items-center gap-6">
      <div v-for="item in items" :key="item.value" class="flex items-center">
        <input
          :key="item.value"
          v-model="selected"
          type="radio"
          :value="item.value"
          class="h-4 w-4 border-gray-300 dark:border-neutral-700 dark:bg-neutral-700 dark:checked:bg-operacolor text-operacolor focus:ring-operacolor cursor-pointer"
        />
        <label
          :for="item.value"
          class="ml-3 block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-200"
          >{{ capitalizeFirstLetter(item.label) }}</label
        >
      </div>
    </div>
  </fieldset>
</template>

<script setup>
// Components
import InfoCircle from "@/components/applicationui/InfoCircle.vue";
// Libraries
import { capitalizeFirstLetter } from "@/helper/helper";
// Model
const selected = defineModel({ type: Boolean });
// Props
const props = defineProps({
  label: { type: String, required: true },
  subLabel: { type: String, required: false, default: null },
  info: { type: String, required: false, default: null },
  items: { type: Array, required: true },
});
</script>
