<template>
  <div
    class="w-screen h-screen flex flex-col items-center justify-center gap-10"
  >
    <div v-if="loading" class="h-52 w-52 -mt-20">
      <SpinnerJump />
    </div>

    <div
      v-else
      class="w-screen h-screen flex flex-col items-center justify-center gap-10 bg-operacolor text-center"
    >
      <div
        class="background flex flex-col gap-10 md:p-10 p-5 items-center max-w-[80vw]"
      >
        <img
          src="https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/ExpiredTimer.png"
          class="h-52"
          alt="Expired Icon"
        />
        <div class="text_primary font-bold text-2xl text-center">
          Expired Login Link
        </div>
        <div class="text_color_tertiary">
          It looks like your login link expired. <br />Don't worry, just login
          again and use the new link sent to your email
        </div>
        <button class="button_primary w-fit" @click="goToLogin">
          Login
          <ArrowRightIcon class="w-4 h-4" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { usePartnerStore } from "@/stores/partner";
// Components
import SpinnerJump from "@/components/loading/SpinnerJump.vue";
// Librairies
import { ArrowRightIcon } from "@heroicons/vue/24/outline";
// Stores
const partnerStore = usePartnerStore();
// Globals
const router = useRouter();
const loading = ref(true);
// Mounted
onMounted(async () => {
  const partner_id = localStorage.getItem("partner_id");
  await partnerStore.setPartnerById(partner_id);
  loading.value = false;
});
// Funcitons
const goToLogin = () => {
  router.push(`/login?partner=${partnerStore.partner.meta_name}`);
};
</script>
