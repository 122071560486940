<template>
  <Slideover
    v-model="offering.show_checkout"
    header="Offering Checkout Customization"
  >
    <div
      class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full"
    >
      <div class="flex flex-col gap-5 w-full">
        <!-- Checkout Form Colors -->
        <div class="flex flex-wrap gap-10 w-full pb-2">
          <!-- Checkout Form Gradient Start Color -->
          <div class="flex gap-3">
            <div class="w-72">
              <label
                for="start_color"
                class="!flex items-center gap-2 input_label"
              >
                Checkout Form Gradient Start Color
                <Tooltip
                  content="The checkout form has a gradient background starting with one color and fading to another. We often recommend using your partner primary color for this value"
                >
                  <QuestionMarkCircleIcon
                    class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight"
                  />
                </Tooltip>
              </label>
              <div class="input_wrapper">
                <input
                  id="start_color"
                  v-model="offering.gradient_start_color"
                  type="color"
                  name="start_color"
                  class="p-1 h-10 w-14 dark:bg-transparent dark:border-neutral-700 bg-white border border-gray-200 cursor-pointer rounded-lg"
                  placeholder="#00000"
                />
              </div>
            </div>
            <!-- Checkout Form Gradient End Color -->
            <div class="w-72">
              <label
                for="end_color"
                class="!flex items-center gap-2 input_label"
              >
                Checkout Form Gradient End Color
                <Tooltip
                  content="The checkout form has a gradient background starting with one color and fading to another. We often recommend using your partner light primary color for this value"
                >
                  <QuestionMarkCircleIcon
                    class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight"
                  />
                </Tooltip>
              </label>
              <div class="input_wrapper">
                <input
                  id="end_color"
                  v-model="offering.gradient_end_color"
                  type="color"
                  name="end_color"
                  class="p-1 h-10 w-14 dark:bg-transparent dark:border-neutral-700 bg-white border border-gray-200 cursor-pointer rounded-lg"
                  placeholder="#FFFFFF"
                />
              </div>
            </div>
          </div>

          <div>
            <ButtonSecondary
              text="Restore Default"
              :icon="EyeDropperIcon"
              size="lg"
              class="mb-10"
              @click="restoreDefault()"
            />

            <div class="select_menu_label">Preview</div>
            <div
              class="w-80 h-52 flex flex-col rounded-lg p-4"
              :style="`background: linear-gradient(to right, ${offering.gradient_start_color}, ${offering.gradient_end_color})`"
            >
              <div class="bg-white opacity-25 h-3 w-16 rounded-md mb-2"></div>
              <div class="bg-white opacity-25 h-5 w-full rounded-md mb-6"></div>
              <div class="bg-white opacity-25 h-3 w-16 rounded-md mb-2"></div>
              <div class="bg-white opacity-25 h-5 w-full rounded-md mb-6"></div>
              <div
                class="border-t-[1px] border-white w-full border-dashed border-opacity-40"
              ></div>
              <div
                class="bg-white opacity-25 h-8 w-full rounded-md mt-auto"
              ></div>
            </div>
          </div>
        </div>

        <!-- Attestations Section -->
        <div
          class="border-t border-dashed border-gray-300 dark:border-neutral-700 pt-5"
        >
          <div class="select_menu_label !flex gap-1 items-center">
            <div class="text-lg font-semibold">Custom Attestations</div>
            <Tooltip
              content="Add custom attestations to the offering. Investors cannot complete their investment without checking all your custom attestation checkboxes."
            >
              <QuestionMarkCircleIcon
                class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight"
              />
            </Tooltip>
          </div>

          <div
            v-if="offering.attestations?.length > 0"
            class="flex flex-col gap-5 w-full"
          >
            <div
              v-for="(a, index) in offering.attestations"
              :key="index"
              class="flex gap-5 items-start w-full bg-white p-4 rounded-xl shadow-sm dark:shadow-none dark:bg-neutral-900"
            >
              <div class="flex-1">
                <AppInput
                  id="attestation-text"
                  v-model="offering.attestations[index]"
                  label="Attestation"
                />

                <form
                  class="py-5 flex items-end gap-5"
                  @submit.prevent="
                    handleAddLinkToAttestation(
                      index,
                      $event.target['attestation-link-label'].value,
                      $event.target['attestation-link-url'].value,
                    );
                    $event.target['attestation-link-label'].value = '';
                    $event.target['attestation-link-url'].value = '';
                  "
                >
                  <AppInput
                    id="attestation-link-label"
                    label="Link Label"
                    placeholder="Apple"
                    class="w-1/4"
                  />
                  <AppInput
                    id="attestation-link-url"
                    label="Link URL"
                    placeholder="https://apple.com"
                    class="flex-1"
                  />
                  <ButtonPrimary
                    size="lg"
                    text="Add Link"
                    :icon="BarsArrowUpIcon"
                    class="w-fit"
                    type="submit"
                  />
                </form>
              </div>

              <div class="flex-shrink">
                <TrashIcon
                  class="size-5 cursor-pointer hover:text-red-700 dark:hover:text-red-600 dark:text-neutral-200 transition-all ease-in-out duration-300"
                  @click="offering.attestations.splice(index, 1)"
                />
              </div>
            </div>

            <ButtonPrimary
              size="lg"
              text="New Attestation"
              :icon="PlusIcon"
              class="w-fit self-center"
              @click="offering.attestations.push('')"
            />

            <div v-if="offering.attestations.length > 0" class="mt-5">
              <div class="select_menu_label">Preview Attestations</div>
              <div
                class="bg-white dark:bg-neutral-900 shadow-sm rounded-xl p-5"
              >
                <div
                  v-for="(attestation, index) in offering.attestations"
                  :key="index"
                  class="flex flex-col gap-3"
                >
                  <AppCheckbox :label="attestation" />
                </div>
              </div>
            </div>
          </div>

          <div v-else class="flex flex-col items-center fle-wrap">
            <ShieldCheckIcon
              class="mx-auto size-12 text-gray-400 dark:text-neutral-500"
            />
            <h3
              class="mt-2 text-sm font-semibold text-gray-900 dark:text-white"
            >
              No Attestations
            </h3>
            <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">
              Get started by creating a new attestation for your investors
            </p>
            <div class="mt-6">
              <ButtonPrimary
                size="lg"
                text="New Attestation"
                :icon="PlusIcon"
                class="w-fit self-center"
                @click="offering.attestations.push('')"
              />
            </div>
          </div>
        </div>
      </div>

      <ButtonSecondary
        text="Close"
        size="lg"
        class="w-full mt-10"
        @click="offering.show_checkout = false"
      />
    </div>
  </Slideover>
</template>

<script setup>
// Components
import Slideover from "@/components/applicationui/AppSlideover.vue";
import AppInput from "@/components/applicationinputs/AppInput.vue";
import AppCheckbox from "@/components/applicationinputs/AppCheckbox.vue";
import ButtonPrimary from "@/components/applicationui/AppButtonPrimary.vue";
import ButtonSecondary from "@/components/applicationui/AppButtonSecondary.vue";
import Tooltip from "@/components/applicationui/AppTooltip.vue";

// Libraries
import { PlusIcon } from "@heroicons/vue/20/solid";
import {
  EyeDropperIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
  BarsArrowUpIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/24/outline";

// Stores
import { usePartnerStore } from "@/stores/partner";
const partnerStore = usePartnerStore();

// Model
const offering = defineModel({ type: Object });

// Checkout Form Colors
const restoreDefault = () => {
  offering.value.gradient_start_color = partnerStore.partner.primary_color;
  offering.value.gradient_end_color = partnerStore.partner.primary_color_light;
};

// Attestation Links
const handleAddLinkToAttestation = (index, label, url) => {
  if (!label || !url) return;
  if (offering.value.attestations[index].slice(-1) === " ") {
    offering.value.attestations[index] +=
      `<a href="${url}" class="link" target="_blank">${label}</a>`;
  } else {
    offering.value.attestations[index] +=
      ` <a href="${url}" class="link" target="_blank">${label}</a>`;
  }
  label = "";
  url = "";
};
</script>
