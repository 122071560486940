<template>
  <div class="sentry-unmask">
    <div
      v-if="props.size == 'large'"
      class="flex items-center h-16 gap-2 shrink-0 sentry-unmask"
    >
      <img class="w-auto h-10 sentry-unmask" :src="getLogoUrl" />
      <div class="text-xl font-semibold partner-title sentry-unmask">
        {{ props.partner?.logo_text }}
      </div>
    </div>
    <div
      v-else-if="props.size == 'logo-only'"
      class="flex items-center h-fit gap-1 shrink-0 sentry-unmask"
    >
      <img class="w-auto h-6 sentry-unmask" :src="getLogoUrl" />
    </div>
    <div v-else class="flex items-center h-fit gap-1 shrink-0">
      <img class="w-auto h-6 sentry-unmask" :src="getLogoUrl" />
      <div class="font-semibold partner-title sentry-unmask">
        {{ props.partner?.logo_text }}
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { computed } from "vue";
import { supabase } from "@/lib/supabaseClient";
// Props
const props = defineProps({
  size: { type: String, default: "small" },
  partner: { type: Object, required: true, default: null },
});
// Functions
const getLogoUrl = computed(() => {
  const { data } = supabase.storage
    .from("partners")
    .getPublicUrl(`${props.partner?.meta_name}/logo`);
  return data.publicUrl;
});
</script>

<style>
.partner-title {
  color: v-bind("props?.partner?.title_color");
}

.dark .partner-title {
  color: v-bind("props?.partner?.title_color_dark") !important;
}
</style>
