<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Popups -->
    <NotificationPopup
      ref="notification"
      :type="notificationType"
      :title="notificationTitle"
      :text="notificationText"
    />

    <!-- Create Party Popup -->
    <AlphaFlow
      v-if="showFlow"
      v-model="showFlow"
      need="create-party-for-other"
      @completed="handleAlphaFlowComplete"
      @closed="showFlow = false"
    />
    <!-- Popups -->

    <!-- Slideovers -->
    <PartySlideover
      v-if="showPartySlideover"
      :show="showPartySlideover"
      :party="selectedParty"
      :for_self="false"
      :partner="partnerStore.partner"
      @closed="showPartySlideover = false"
      @error="
        notify(
          'failure',
          'Sorry',
          `We could not update your client's profile. Please try again later or contact Tech Support`,
        )
      "
      @upload-failure="
        notify(
          'failure',
          'Sorry',
          `We could not upload your client's new profile image. Please try again later or contact Tech Support`,
        )
      "
      @upload-success="
        notify(
          'success',
          'Avatar Updated',
          `Your client's profile image has been updated successfully`,
        );
        userStore.setUser();
      "
      @success="
        notify(
          'success',
          'Profile Updated',
          `Your client's profile has been updated successfully`,
        )
      "
    />
    <!-- Slideovers -->

    <!-- Loading -->
    <div
      v-if="loading"
      class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center"
    >
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Empty State -->
    <div
      v-else-if="
        !partiesStore?.managedParties ||
        partiesStore?.managedParties?.length <= 0
      "
      class="text-center"
    >
      <UsersIcon class="w-12 h-12 mx-auto text-gray-400" />
      <h3
        class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300"
      >
        No Clients
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Get started by creating a new client.<br /><span
          v-if="userStore.user?.party_id"
          >You must fill out your profile information first.</span
        >
      </p>
      <div class="mt-6">
        <button
          v-if="userStore.user?.party_id"
          type="button"
          class="button_primary"
          @click="showFlow = true"
        >
          Create Client
          <PlusIcon class="w-5 h-5" aria-hidden="true" />
        </button>
        <router-link v-else to="/dashboard/settings" class="button_primary">
          Add Profile
          <ArrowLongRightIcon class="w-5 h-5" aria-hidden="true" />
        </router-link>
      </div>
    </div>

    <!-- Content -->
    <div v-else class="flex flex-col">
      <!-- Header -->
      <div>
        <div
          class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700"
        >
          <!-- Tab Header -->
          <h3 class="primary_header">Clients</h3>

          <div class="flex items-center gap-4">
            <!-- Search -->
            <div class="flex items-center gap-2">
              <div>
                <div class="relative rounded-md">
                  <div
                    class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                  >
                    <MagnifyingGlassIcon
                      class="w-4 h-4 text-gray-400 dark:text-neutral-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    v-model="query"
                    type="text"
                    name="search"
                    class="pl-8 input"
                    placeholder="Quick Search"
                  />
                </div>
              </div>
            </div>
            <!-- Create Client -->
            <button
              type="button"
              class="button_primary"
              @click="showFlow = true"
            >
              Create Client
              <UserPlusIcon class="w-4 h-4" />
            </button>
          </div>
        </div>
        <div class="flex">
          <SwitchGroup as="div" class="flex items-center justify-between w-fit">
            <Switch
              v-model="isTable"
              class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-operacolor focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-0 dark:focus:ring-operacolor dark:focus:ring-offset-0"
            >
              <span class="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                class="pointer-events-none absolute h-full w-full rounded-md bg-gray-50 dark:bg-neutral-950"
              ></span>
              <span
                aria-hidden="true"
                :class="[
                  isTable ? 'bg-operacolor' : 'bg-gray-200 dark:bg-neutral-700',
                  'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                ]"
              ></span>
              <span
                aria-hidden="true"
                :class="[
                  isTable ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                ]"
              ></span>
            </Switch>
            <SwitchLabel as="span" class="ml-3 text-sm">
              <span class="font-medium text_color_primary">Table View</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
      </div>

      <!-- Investors Table -->
      <TablePrimary
        v-if="isTable"
        :rows="filteredParties"
        :columns="columns"
        :is-clickable="true"
        class="-mt-5"
        export_title="clients"
        @click-primary="handlePartyClicked"
      />

      <!-- Investor Cards -->
      <div v-else role="list" class="flex gap-6 flex-wrap mt-4">
        <div
          v-for="p in filteredParties"
          :key="p.id"
          class="divide-y divide-gray-200 rounded-lg bg-white shadow-opera dark:shadow-none cursor-pointer border w-80 border-white hover:border-gray-300 hover:border dark:bg-neutral-800 dark:border-neutral-800 dark:hover:border-neutral-600"
          @click="handlePartyClicked(p)"
        >
          <InvestorCard :investor="p" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from "vue";

// Components
import AlphaFlow from "@/components/flows/AlphaFlow.vue";
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import InvestorCard from "@/components/investors/InvestorCard.vue";
import TablePrimary from "@/components/applicationui/tables/LegacyTablePrimary.vue";
import PartySlideover from "@/components/investors/PartySlideover.vue";
import NotificationPopup from "@/components/applicationui/AppNotification.vue";

// Libraries
import { UsersIcon, ArrowLongRightIcon } from "@heroicons/vue/24/outline";
import {
  MagnifyingGlassIcon,
  UserPlusIcon,
  PlusIcon,
} from "@heroicons/vue/20/solid";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

// Stores
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();
import { usePartiesStore } from "@/stores/parties";
const partiesStore = usePartiesStore();
import { usePartnerStore } from "@/stores/partner";
const partnerStore = usePartnerStore();

// Globals
const selectedParty = ref(null);
const loading = ref(true);
const showFlow = ref(false);
const isTable = ref(true);
const showPartySlideover = ref(false);
const query = ref("");
const notification = ref(null);
const notificationType = ref("success");
const notificationText = ref("Success");
const notificationTitle = ref("");
const columns = [
  { key: "first_name", label: "Name", type: "double", key2: "last_name" },
  { key: "contact_email", label: "Email" },
  { key: "phone", label: "Phone" },
  { key: "created_at", label: "Created", type: "date" },
];

// Mounted
onMounted(async () => {
  // Check that we have a user
  if (!userStore.user?.party_id) {
    loading.value = false;
    return;
  }

  // Get Managed Parties
  if (!partiesStore.managedParties) await partiesStore.setManagedParties();

  // Ready
  loading.value = false;
});

// Computed
const filteredParties = computed(() => {
  return query.value === ""
    ? partiesStore.managedParties
    : partiesStore.managedParties.filter((party) => {
        if (
          party.contact_email.toLowerCase().includes(query.value.toLowerCase())
        )
          return true;
        if (party.first_name.toLowerCase().includes(query.value.toLowerCase()))
          return true;
        if (party.last_name.toLowerCase().includes(query.value.toLowerCase()))
          return true;
        if (party.id.toLowerCase().includes(query.value.toLowerCase()))
          return true;
        if (party.phone.toLowerCase().includes(query.value.toLowerCase()))
          return true;
        if (party.zip.toLowerCase().includes(query.value.toLowerCase()))
          return true;
      });
});

// Simple Functions
const handleAlphaFlowComplete = () => {
  showFlow.value = false;
  partiesStore.setManagedParties();
};

const handlePartyClicked = (party) => {
  selectedParty.value = party;
  showPartySlideover.value = true;
};

const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};
</script>
