<template>
  <div class="relative">
    <!-- Popups -->
    <NotificationPopup
      ref="notification"
      :type="notificationType"
      :title="notificationTitle"
      :text="notificationText"
    />
    <!-- Popups -->

    <!-- Sign Here Popup -->
    <TransitionRoot as="template" :show="showDocusignPopup">
      <Dialog as="div" class="relative z-50" @close="showDocusignPopup = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex items-end justify-center w-full h-full p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative w-5/6 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-900 h-5/6"
              >
                <div class="w-full h-full">
                  <!-- Loading Docusign -->
                  <div
                    v-if="loadingDocusign"
                    class="w-full h-full flex justify-center items-center"
                  >
                    <div class="w-10 h-10">
                      <SpinnerBounce />
                    </div>
                  </div>
                  <!-- Docusign iframe -->
                  <iframe
                    :src="docusignSrc"
                    class="w-full h-full"
                    frameborder="0"
                    style="border: 0"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Table Loading -->
    <div
      v-if="loading"
      class="rounded-b-lg w-full h-full flex justify-center items-center"
    >
      <div class="w-10 h-10">
        <SpinnerBounce />
      </div>
    </div>

    <!-- Subsciption Documents Table -->
    <div v-else>
      <div class="secondary_header mb-4">Subscription Documents</div>
      <TablePrimary
        :rows="documents"
        :columns="columns"
        :is-clickable="false"
        :allow_export="false"
        empty_message="No subscription documents"
        @click-primary="signHere"
        @click-secondary="resendSubscriptionDocuments"
      />
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";
import { useTransactionsStore } from "@/stores/transactions";
// Components
import NotificationPopup from "@/components/applicationui/AppNotification.vue";
import SpinnerBounce from "@/components/loading/SpinnerBounce.vue";
import TablePrimary from "@/components/applicationui/tables/LegacyTablePrimary.vue";
// Libraries
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
// Props
const props = defineProps({
  account_id: {
    type: String,
    required: true,
    default: "",
  },
  tapi_account_id: {
    type: String,
    required: true,
    default: "",
  },
});
// Stores
const transactionsStore = useTransactionsStore();
// Globals
const showDocusignPopup = ref(false);
const loadingDocusign = ref(false);
const docusignSrc = ref("");
const loading = ref(true);
const notification = ref(null);
const notificationType = ref("success");
const notificationTitle = ref("File Uploaded");
const notificationText = ref(
  "Your file was uploaded successfully, refresh this page to access the new file",
);
const documents = ref([]);
const columns = [
  { key: "field1", label: "Offering" },
  { key: "esignStatus", label: "Status", type: "badge" },
  { key: "createdDate", label: "Date", type: "date" },
  { key: "esignStatus", label: "", type: "custom-esign" },
];

// Mounted
onMounted(async () => {
  await setDocuments(props.account_id, props.tapi_account_id);
  loading.value = false;
});

// Setters
async function setDocuments(account_id, tapi_account_id) {
  documents.value = await getUnsignedDocs(account_id, tapi_account_id);
}

async function setDocusignSrc(trade, account_id) {
  docusignSrc.value = await getDocusignUrl(trade, account_id);
}

// Getters
async function getUnsignedDocs(account_id, tapi_account_id) {
  // Get trades
  const data = await transactionsStore.getTradesFromTapi(
    account_id,
    tapi_account_id,
  );

  // Check if data is available
  if (!data) {
    notify(
      "failure",
      "Sorry",
      "We were unable to retrieve your subscription documents",
    );
    return;
  }
  if (!data?.partyDetails) return [];

  // Add loading key value pair to each trade in partyDetails
  data.partyDetails.forEach((trade) => (trade.loading = false));

  // Sort trades by created date
  data.partyDetails.sort((a, b) => {
    return new Date(b.createdDate) - new Date(a.createdDate);
  });

  // Return ordered trades
  return data.partyDetails;
}

async function getDocusignUrl(trade, account_id) {
  const data = await transactionsStore.getSubscriptionDocument(
    account_id,
    trade.accountId,
    trade.offeringId,
    trade.orderId,
  );
  if (!data) {
    notify(
      "failure",
      "Sorry",
      "We were unable to get the docusign url for your subscription documents. Please try again, if the issue persists contact support",
    );
    return;
  }
  return data.document_details.url;
}

// Actions
async function resendSubscriptionDocuments(trade) {
  trade.loading = true;
  const successful = await transactionsStore.emailSubscriptionDocument(
    trade.orderId,
    props.account_id,
    trade.accountId,
    trade.offeringId,
  );
  if (!successful) {
    notify(
      "failure",
      "Sorry",
      "We were unable to resend your subscription documents. Please try again later, if the issue persists contact support",
    );
    return;
  }
  notify(
    "success",
    "Success",
    "Your subscription documents were resent, please check you inbox",
  );
  trade.loading = false;
}

async function signHere(trade) {
  loadingDocusign.value = true;
  showDocusignPopup.value = true;
  await setDocusignSrc(trade, props.account_d);
  createDocusignListener(props.account_id, props.tapi_account_id);
  loadingDocusign.value = false;
}

// Helpers
const createDocusignListener = (account_id, tapi_account_id) => {
  window.addEventListener("message", (event) => {
    if (!event.origin.includes("norcapsecurities.com")) return;
    if (event?.data == "signing_complete") {
      showDocusignPopup.value = false;
      notify("success", "Success", "Thank you for signing");
      setDocuments(account_id, tapi_account_id);
    }
  });
};

const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};
</script>
