<template>
  <div>
    <!-- Loading -->
    <div
      v-if="loading"
      class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[50vh] w-[90vw]"
    >
      <div class="h-28 w-28 -mt-8">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Content -->
    <div v-else class="sm:w-[500px] w-[90vw] pt-2">
      <div class="flex items-start mt-4">
        <div
          class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 dark:bg-blue-950 rounded-full sm:mx-0 sm:h-10 sm:w-10"
        >
          <CheckBadgeIcon class="w-6 h-6 text-blue-500" aria-hidden="true" />
        </div>
        <div class="ml-4 text-left w-[390px]">
          <DialogTitle
            as="h3"
            class="text-base font-semibold leading-6 dark:text-white"
            >Investment Verification</DialogTitle
          >
          <p class="text-sm text-gray-500 dark:text-neutral-300 mt-1">
            This investment is provides an investment cap based on each
            investors income and networth
          </p>

          <!-- Previous RegCF Investments (last 12 months) -->
          <div class="w-full">
            <p
              class="text-gray-600 dark:text-neutral-500 text-sm mt-5 mb-1 italic"
            >
              Enter below the amount if any that you have invested in RegCF
              offerings in the last 12 months
            </p>
            <label for="regcf-investment" class="input_label"
              >Recent RegCF Investments</label
            >
            <!-- <p>You may leave this blank if you have not invested in a RegCF offering in the last 12 months</p> -->
            <div class="relative mt-2 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <span class="text-gray-500 dark:text-neutral-500 sm:text-sm"
                  >$</span
                >
              </div>
              <input
                id="regcf-investment"
                v-model="recent_regcf_investments"
                class="input pl-6 pr-12"
                type="text"
                name="regcf-investment"
                placeholder="0.00"
                aria-describedby="price-currency"
                aria-required
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
              >
                <span class="text-gray-500 dark:text-neutral-400 sm:text-sm"
                  >USD</span
                >
              </div>
            </div>
          </div>

          <div class="bg-white dark:bg-neutral-800 rounded-lg mb-4 mt-8 shadow">
            <!-- Offering Logo -->
            <div class="flex gap-3 items-center px-8 pb-4 pt-6 rounded-t-lg">
              <img :src="offeringLogo" alt="Offering Logo" class="size-8" />
              <div class="secondary_header">{{ props?.offering?.name }}</div>
            </div>

            <div class="w-full relative flex justify-between items-center">
              <div
                class="size-8 z-10 bg-gray-50 dark:bg-neutral-950 rounded-full -ml-5 border-r-[1px] border-gray-200 dark:border-0"
              ></div>
              <div
                class="flex-1 border-b-[1px] border-dashed border-gray-200 dark:border-neutral-600"
              ></div>
              <div
                class="size-8 bg-gray-50 dark:bg-neutral-950 rounded-full -mr-5 border-l-[1px] border-gray-200 dark:border-0"
              ></div>
            </div>
            <!-- Trade Details -->
            <div class="px-8 py-2 rounded-b-lg flex flex-col">
              <div class="flex w-full justify-between items-center pb-1 pt-4">
                <div class="text_color_tertiary text-sm">
                  Your Investment Cap
                </div>
                <div class="text-gray-600 dark:text-neutral-300">
                  {{ formatMoney(investmentCap) }}
                </div>
              </div>
              <div
                class="flex w-full justify-between items-center py-1 pb-4 border-b-[1px] border-dashed border-gray-200 dark:border-neutral-600"
              >
                <div class="text_color_tertiary text-sm">Investment Total</div>
                <div class="text-gray-600 dark:text-neutral-300">
                  {{ formatMoney(props?.trade?.investmentTotal) }}
                </div>
              </div>
              <div
                v-if="investmentCap >= myTotal"
                class="flex w-full justify-end items-center py-4 gap-2"
              >
                <div class="text_color_secondary font-semibold">
                  Investment Amount Verified
                </div>
                <div class="bg-green-600 rounded-full p-1">
                  <CheckIcon class="size-4 text-white" />
                </div>
              </div>
              <div
                v-else
                class="flex w-full justify-end items-center py-4 gap-2"
              >
                <div class="text_color_secondary font-semibold">
                  Investment Amount Exceeds Cap
                </div>
                <div class="bg-yellow-500 rounded-full p-1">
                  <ExclamationTriangleIcon class="size-5 text-white" />
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-start mt-6">
            <div class="flex items-center h-6">
              <input
                id="attested"
                v-model="attested"
                aria-describedby="comments-description"
                name="attested"
                type="checkbox"
                class="w-4 h-4 border-gray-300 rounded text-operacolor focus:ring-operacolor"
              />
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="attested" class="font-medium text_color_secondary"
                >I have read the
                <AppLink
                  :to="educationalMaterials"
                  class="text-blue-600 underline cursor-pointer"
                  >education materials</AppLink
                >
                available
              </label>
            </div>
          </div>

          <div class="gap-4 mt-8 mb-1 flex justify-between flex-row-reverse">
            <button
              type="button"
              class="button_primary"
              :disabled="isDisabled"
              @click="handleClick('next')"
            >
              Confirm
              <CheckCircleIcon class="w-4 h-4" />
            </button>
            <button
              type="button"
              class="button_secondary"
              @click="handleClick('previous')"
            >
              Previous
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { onMounted, ref, computed } from "vue";
// Stores
import { useOfferingsStore } from "@/stores/offerings";
import { usePartnerStore } from "@/stores/partner";
import { usePartiesStore } from "@/stores/parties";
const partnerStore = usePartnerStore();
const offeringsStore = useOfferingsStore();
const partiesStore = usePartiesStore();

// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import AppLink from "@/components/applicationui/AppLink.vue";
// Libraries
import { formatMoney } from "@/helper/helper";
import {
  CheckBadgeIcon,
  CheckCircleIcon,
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/vue/24/outline";
import { DialogTitle } from "@headlessui/vue";

// Props
const props = defineProps({
  offering: { type: Object, required: true, default: null },
  trade: { type: Object, required: true, default: null },
  party_id: { type: String, required: false, default: null },
});

// Globals
const loading = ref(true);
const offeringLogo = ref(
  "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/receiptIcon.png?t=2024-06-18T17%3A11%3A57.627Z",
);
const attested = ref(false);
const recent_regcf_investments = ref("");
const party = ref();

// Mounted
onMounted(async () => {
  offeringLogo.value = await offeringsStore.getOfferingFile(
    props.offering?.id,
    props.offering?.tapi_offering_id,
    "logo",
  );
  party.value = await partiesStore.getPartyById(props.party_id);
  loading.value = false;
});

// Computed
const recent_investment_total = computed(() => {
  if (!recent_regcf_investments.value) return 0;
  return parseFloat(recent_regcf_investments.value.replace(/,/g, ""));
});

const educationalMaterials = computed(() => {
  if (
    partnerStore.partner.use_additional_signin_content &&
    partnerStore.partner.additional_signin_content
  )
    return partnerStore.partner.additional_signin_content;
  return "/dashboard/education";
});

const investmentCap = computed(() => {
  const income = parseFloat(
    party.value?.current_household_income?.replace(/,/g, ""),
  );
  const networth = parseFloat(party.value?.networth?.replace(/,/g, ""));

  let cap = 0;
  if (income >= 107000 && networth >= 107000) {
    if (income > networth) cap = income * 0.1;
    cap = networth * 0.1;
  } else {
    if (income > networth) cap = income * 0.05;
    else cap = networth * 0.05;
    if (2200 > cap) cap = 2200;
  }

  return cap - recent_investment_total.value;
});

const myTotal = computed(() => {
  if (!props?.trade?.investmentTotal) return 0;
  return parseFloat(props?.trade?.investmentTotal);
});

const isDisabled = computed(() => {
  if (!attested.value) return true;
  if (investmentCap.value < myTotal.value) return true;
  return false;
});

// Emits
const emit = defineEmits(["next", "previous"]);

// Functions
const handleClick = (emitted) => {
  emit(emitted);
};
</script>
