<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex justify-center min-h-full p-4 text-center items-center"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-900 my-8 w-fit max-w-lg p-6"
            >
              <div class="absolute top-0 right-0 pt-4 pr-4 block">
                <button
                  type="button"
                  class="text-gray-400 rounded-md hover:text-gray-500 dark:hover:text-neutral-300 dark:text-neutral-400 focus:outline-none"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Content -->
              <div class="flex items-start min-w-fit">
                <div
                  class="flex items-center justify-center flex-shrink-0 mx-auto bg-blue-100 dark:bg-blue-950 rounded-full h-10 w-10"
                >
                  <FolderIcon
                    class="w-6 h-6 text-blue-500 dark:text-blue-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="flex-shrink mt-3 mr-8 ml-4 text-left">
                  <DialogTitle
                    as="h3"
                    class="text-base font-semibold leading-6 dark:text-white mr-10"
                    >Update Account Status</DialogTitle
                  >

                  <div class="my-5 max-w-52">
                    <!-- Approval Status -->
                    <div class="select_menu_label">Status</div>
                    <SelectMenu v-model="status" :items="statuses" />
                  </div>

                  <div class="flex flex-col gap-2 my-5">
                    <p class="text-sm text-gray-500 dark:text-neutral-400">
                      <span class="text-gray-600 dark:text-neutral-300 mr-1"
                        >Account Nickname:</span
                      >
                      {{ props.account.name }}
                    </p>
                    <p class="text-sm text-gray-500 dark:text-neutral-400">
                      <span class="text-gray-600 dark:text-neutral-300 mr-1"
                        >Account #:</span
                      >
                      {{ props.account.tapi_account_id }}
                    </p>
                    <p class="text-sm text-gray-500 dark:text-neutral-400">
                      <span class="text-gray-600 dark:text-neutral-300 mr-1"
                        >Created Date:</span
                      >
                      {{ formatDate(props.account.created_at) }}
                    </p>
                    <div class="text-sm text-gray-500 dark:text-neutral-400">
                      <span class="text-gray-600 dark:text-neutral-300 mr-2"
                        >Account Type:</span
                      >
                      <div :class="getBadgeClass(props.account.type)">
                        {{ capitalizeFirstLetter(props.account.type) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex gap-4 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <div
                  class="button_primary"
                  @click="handleSavedChanges(props.account, status)"
                >
                  Save
                  <LoadGifButton v-if="updating" />
                </div>
                <div class="button_secondary" @click="open = false">Cancel</div>
              </div>
              <!-- Content -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref } from "vue";
import { useAccountsStore } from "@/stores/accounts";
const accountsStore = useAccountsStore();

// Components
import LoadGifButton from "@/components/loading/LoadGifButton.vue";
import SelectMenu from "@/components/applicationinputs/SelectMenu.vue";

// Libraries
import {
  formatDate,
  capitalizeFirstLetter,
  getBadgeClass,
} from "@/helper/helper";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { FolderIcon, XMarkIcon } from "@heroicons/vue/20/solid";

// Model
const open = defineModel({ type:  Boolean });

// Props
const props = defineProps({
  account: { type: Object, required: true, default: null },
});

// Emits
const emit = defineEmits(["updated"]);

// Globals
const status = ref(props.account.accounts_protected.approval_status);
const statuses = ["pending", "approved", "disapproved"];
const updating = ref(false);

// Functions
async function handleSavedChanges(account, status) {
  updating.value = true;
  await accountsStore.updateApprovalStatus(account.id, status);
  updating.value = false;
  emit("updated");
  open.value = false;
}
</script>
