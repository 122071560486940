<template>
  <div class="w-screen h-screen flex items-center justify-center">
    <div class="size-24 -mt-10">
      <SpinnerBounce />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { usePartnerStore } from "@/stores/partner";
import { useUserStore } from "@/stores/user";
import { useAccountsStore } from "@/stores/accounts";

import SpinnerBounce from "@/components/loading/SpinnerBounce.vue";

const router = useRouter();
const partnerStore = usePartnerStore();
const userStore = useUserStore();
const accountsStore = useAccountsStore();

onMounted(async () => {
  await partnerStore.setPartner();
  await userStore.setUser();
  // No need to wait for these
  accountsStore.setHoldingAccount();
  accountsStore.setAccounts();
  if (userStore.user.profiles_protected.role === "super_admin")
    router.push("/dashboard/partners");
  else if (userStore.user.profiles_protected.role === "partner_admin")
    router.push("/dashboard/investors");
  else if (userStore.user.profiles_protected.role === "investor")
    router.push(partnerStore.partner.redirect_url);
  else router.push("/dashboard/settings");
});
</script>
