import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
import { logErrorMessage } from '@/schemas/AdvancedErrorHandler.js'
import { ref } from 'vue'


export const usePartiesStore = defineStore('parties', () => {
  // State (refs)
  const parties = ref(null) // All parties visible to the user (based on permissions)
  const managedParties = ref(null) // All parties created by the user that are not the user's party

  // Getters, (computed properties)

  // Actions, (functions)
  async function setParties() {
    parties.value = await getParties()
  }
  async function setManagedParties() {
    const user_id = await getUserID()
    if (!user_id) return
    const party_id = await getPartyID(user_id)
    if (!party_id) return
    managedParties.value = await getManagedParties(user_id, party_id)
  }

  async function getParties() {
    const { data, error } = await supabase.from('parties').select('*, parties_protected(*)').order('created_at', { ascending: false })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }
  async function getManagedParties(user_id, user_party_id) {
    const { data, error } = await supabase.from('parties').select(`*, parties_protected(*)`).eq('user_id', user_id).neq('id', user_party_id).order('created_at', { ascending: false })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }
  async function updateTapiParty(party) { // Expects a party object
    var body = {
      id: party.id,
      partyId: party.tapi_party_id,
      primAddress1: party.address,
      primAddress2: party.unit,
      primCity: party.city,
      primState: party.state,
      primCountry: party.country,
      primZip: party.zip,
      phone: party.phone,
      emailAddress: party.contact_email,
      empStatus: party.employment_status,
      occupation: party.occupation,
      empName: party.empolyer_name,
      empAddress1: party.employer_address,
      empAddress2: party.employer_unit,
      empCity: party.employer_city,
      empState: party.employer_state,
      empZip: party.employer_zip,
      empCountry: party.emplyer_counter,
      currentAnnIncome: party.current_annual_income,
      currentHouseholdIncome: party.current_household_income,
      avgAnnIncome: party.average_annual_income,
      avgHouseholdIncome: party.average_household_income,
      householdNetworth: party.networth,
    };
    const { error } = await supabase.functions.invoke('update-party', { body: body })
    if (error) { console.log(await logErrorMessage(error)); return }
  }
  async function getPartyById(id) {
    const { data, error } = await supabase.from('parties').select('*').eq('id', id).limit(1)
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0]
  }
  async function updatePartySupabase(party) { // Expects a party object
    const { error } = await supabase.from('parties').update(party).eq('id', (party.id))
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function getAllPartiesWithAccountData() {
    const { data, error } = await supabase.from('parties').select('*, parties_protected(*), partners(*, partners_protected(*)), accounts!accounts_primary_party_id_fkey(*, accounts_protected!inner(*))').eq('accounts.accounts_protected.is_holding_account', true).order('created_at', { ascending: false });
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }
  async function getAllPartiesFullPaginated(start = 0, finish = 10000000) {
    const { data, error } = await supabase
      .from("parties")
      .select(
        "*, parties_protected(*), partners(*, partners_protected(*)), accounts!accounts_primary_party_id_fkey(*, accounts_protected!inner(*), transactions(*, offerings(*)))",
      )
      .eq("accounts.accounts_protected.is_holding_account", true)
      .neq("accounts.transactions.status", "archived")
      .order("created_at", { ascending: false })
      .range(start, finish);
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }

  async function getInvestorObject(id) {
    const { data, error } = await supabase
      .from("parties")
      .select(
        "*, parties_protected(*), partners(*, partners_protected(*)), accounts!accounts_primary_party_id_fkey(*, accounts_protected!inner(*), transactions(*, offerings(*)), positions(*, offerings(*)))",
      )
      .eq("id", id)
      .neq("accounts.transactions.status", "archived")
      .limit(1);
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0]
  }

  async function updateNotes(note, id) {
    const { error } = await supabase.from('parties_protected').update({ notes: note }).eq('id', id)
    if (error) { console.log(await logErrorMessage(error)); return } // TODO add error show
    return true
  }
  async function updateKycAndAmlStatus(id, kyc, aml) {
    const { error } = await supabase.from('parties_protected').update({ kyc: kyc, aml: aml }).eq('id', id);
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function getPartyDocuments(user_id, party_id, tapi_party_id) {
    let docs = []
    let docs2 = []
    docs = await getInvestorDocuments(`${user_id}/investor/${party_id}`)
    docs.forEach(doc => { doc.userFolder = user_id; doc.partyFolder = party_id })
    docs2 = await getInvestorDocuments(`${user_id}/investor/${tapi_party_id}`)
    docs2.forEach(doc => { doc.userFolder = user_id; doc.partyFolder = tapi_party_id })
    const allDocs = docs.concat(docs2)
    return allDocs
  }
  async function getEntityDocuments(user_id, party_id) {
    let docs = []
    let docs2 = []
    docs = await getInvestorDocuments(`${user_id}/entity/${party_id}`)
    docs.forEach(doc => { doc.userFolder = user_id; doc.partyFolder = party_id })
    const allDocs = docs.concat(docs2)
    return allDocs
  }
  async function uploadPartyDocumentToTapi(party_id, tapi_party_id, newFile, managed_kyc_aml = true) { //TODO add this to parties edge-function
    const formData = new FormData();
    formData.append("file", newFile, newFile.name)
    formData.append("partyId", tapi_party_id)
    formData.append("id", party_id)
    const { error } = await supabase.functions.invoke('upload-verification-documents', { body: formData });
    if (error) { console.log(await logErrorMessage(error)); return }
    // TODO run requestKycAml if manual review partner
    if (managed_kyc_aml) {
      const success = await requestManualKycAmlReview(party_id, tapi_party_id)
      if (!success) { console.log('failed to request manual kyc/aml review'); return }
    }
    return true
  }
  async function requestManualKycAmlReview(party_id, tapi_party_id) {
    const { error } = await supabase.functions.invoke('request-manual-kyc-aml-review', { body: { id: party_id, investorId: tapi_party_id } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function uploadInvestorDocumentToSupabase(user_id, party_id, newFile) {
    const { error } = await supabase.storage.from('investor_documents').upload(`${user_id}/investor/${party_id}/${newFile.name}`, newFile, { cacheControl: '3600', upsert: true });
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function uploadEntityDocumentToSupabase(user_id, party_id, newFile) {
    const { error } = await supabase.storage.from('investor_documents').upload(`${user_id}/entity/${party_id}/${newFile.name}`, newFile, { cacheControl: '3600', upsert: true });
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function downloadInvestorFile(filePath) {
    const { data, error } = await supabase.storage.from('investor_documents').createSignedUrl(filePath, 3600);
    if (error) { console.log(await logErrorMessage(error)); return }
    return data?.signedUrl
  }
  async function archiveInvestorFile(oldFolder, newFolder) {
    const { error } = await supabase.storage.from('investor_documents').move(oldFolder, newFolder)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function performKycAml(party_id, tapi_party_id) {
    const { error } = await supabase.functions.invoke('run-kyc-aml', { body: { id: party_id, partyId: tapi_party_id } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function performAml(id, tapi_party_id) {
    const { error } = await supabase.functions.invoke('run-aml', { body: { partyId: tapi_party_id, id: id } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function createPartyInTapi(party) {
    var usCit = 'non-resident';
    if (party.isUSCitizen) usCit = 'U.S. resident';
    //Format Date String
    let dateParts = party.date_of_birth.split("-");
    let reformattedDate = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
    var body = {
      'firstName': party.first_name,
      'middleInitial': party.initial,
      'lastName': party.last_name,
      'dob': reformattedDate,
      'socialSecurityNumber': party.social,
      'domicile': usCit,
      'primAddress1': party.address,
      'primAddress2': party.unit,
      'primCity': party.city,
      'primState': party.state,
      'primCountry': party.country,
      'primZip': party.zip,
      'phone': party.phone,
      'emailAddress': party.contact_email,
      'empStatus': party.employment_status,
      'occupation': party.occupation,
      'empName': party.employer_name,
      'empAddress1': party.employer_address,
      'empAddress2': party.employer_unit,
      'empCity': party.employer_city,
      'empState': party.employer_state,
      'empZip': party.employer_zip,
      'empCountry': party.employer_country,
      'associatedPerson': party.is_associated_bd,
      'currentAnnIncome': party.current_annual_income,
      'avgAnnIncome': party.average_annual_income,
      'currentHouseholdIncome': party.current_household_income,
      'avgHouseholdIncome': party.average_household_income,
      'householdNetworth': party.networth,
      'invest_to': 0
    }
    const { data, error } = await supabase.functions.invoke('create-party?', { body })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data.partyDetails[1][0].partyId
  }
  async function createParty(party) {
    const partyWithoutTaxId = { ...party }
    delete partyWithoutTaxId.tax_id
    const { data, error } = await supabase.from('parties').insert(partyWithoutTaxId).select()
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0].id
  }
  async function checkInvestorVerification(party_id) {
    const { data, error } = await supabase.from('parties_protected').select('kyc, aml').eq('id', party_id).limit(1)
    if (error) { console.log(await logErrorMessage(error)); return }
    if (data[0].kyc == 'approved' && data[0].aml == 'approved') return true
    return false
  }

  // Not Callable Functions
  async function getUserID() {
    const auth = await supabase.auth.getUser();
    return auth.data.user?.id
  }
  async function getPartyID(user_id) {
    const { data, error } = await supabase.from('profiles').select('party_id').eq('id', user_id).limit(1)
    if (error) { console.log(await logErrorMessage(error)); return }
    return data[0]?.party_id
  }
  async function getInvestorDocuments(folder) {
    const { data, error } = await supabase.storage.from('investor_documents').list(folder, { limit: 100, offset: 0, sortBy: { column: 'name', order: 'asc' } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }

  return {
    parties,
    managedParties,
    setParties,
    setManagedParties,
    getParties,
    getManagedParties,
    getPartyById,
    updateTapiParty,
    updatePartySupabase,
    getAllPartiesFullPaginated,
    getAllPartiesWithAccountData,
    updateNotes,
    updateKycAndAmlStatus,
    getPartyDocuments,
    getEntityDocuments,
    uploadPartyDocumentToTapi,
    uploadInvestorDocumentToSupabase,
    uploadEntityDocumentToSupabase,
    downloadInvestorFile,
    performKycAml,
    performAml,
    archiveInvestorFile,
    createPartyInTapi,
    createParty,
    checkInvestorVerification,
    requestManualKycAmlReview,
    getInvestorObject
  }
})