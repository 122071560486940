<template>
  <div v-if="offering.status == 'pending'" class="absolute -top-1 right-2">
    <BookmarkIcon class="w-6 h-6 text-yellow-400" />
  </div>

  <!-- Card Banner -->
  <img
    class="flex items-center object-cover h-20 gap-2 text-lg bg-gray-200 rounded-t-lg"
    :src="bannerUrl"
  />
  <!-- Card Logo -->
  <div
    class="mx-5 -mt-5 overflow-hidden bg-white rounded-full ring-1 ring-border w-fit"
  >
    <img :src="logoUrl" class="w-10 h-10 object-fit" />
  </div>

  <div class="h-[160px]">
    <!-- Card Name -->
    <div class="px-5 py-4 overflow-hidden font-semibold max-h-[80px]">
      {{ offering.name }}
    </div>
    <!-- Card Description -->
    <div class="pb-2 px-5 max-h-[80px] text-sm grow">
      <div>{{ truncateText(offering.slogan) }}</div>
    </div>
  </div>

  <!-- Card Info -->
  <div
    class="flex flex-col px-5 py-2 border-t divide-y divide-dashed divide-border dark:divide-neutral-500 border-border dark:border-neutral-600"
  >
    <div class="flex justify-between py-4 text-sm">
      <span>Market Sector</span
      ><span>{{ capitalizeFirstLetter(offering.market_sector) }}</span>
    </div>
    <div
      v-if="props.partner.partners_protected.show_scores && offering.score"
      class="flex justify-between py-4 text-sm"
    >
      <span>Opera Score</span>

      <div
        v-if="offering.score && offering.score != 'Not yet available'"
        class="flex items-center gap-1"
      >
        <span class="mr-1 text-xs text-gray-400">({{ offering.score }})</span>
        <OperaScore :score="offering.score" />
      </div>
      <span v-else class="text-operacolor">{{ offering.score }}</span>
    </div>
  </div>

  <!-- Buttons -->
  <div v-if="props.allow_edit" class="flex px-5 pb-5 gap-5">
    <div
      class="justify-center w-full cursor-pointer inline-flex items-center gap-x-1.5 rounded-md font-medium bg-white dark:bg-operacolor dark:hover:bg-white dark:hover:text-operacolor dark:text-white px-2.5 py-1.5 text-sm text-operacolor shadow-sm ring-1 ring-inset ring-operacolor dark:ring-0 hover:bg-operacolor hover:text-white"
      @click="handleOfferingClick"
    >
      Edit Offering
      <PencilSquareIcon class="w-4 h-4" />
    </div>
  </div>
  <div v-else class="flex px-5 pb-5">
    <div
      class="justify-center w-full cursor-pointer inline-flex items-center gap-x-1.5 rounded-md font-medium bg-white dark:bg-operacolor dark:hover:bg-white dark:hover:text-operacolor dark:text-white px-2.5 py-1.5 text-sm text-operacolor shadow-sm ring-1 ring-inset ring-operacolor dark:ring-0 hover:bg-operacolor hover:text-white"
      @click="handleOfferingClick"
    >
      Learn More
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";
import { useOfferingsStore } from "@/stores/offerings";
// Libraries
import { capitalizeFirstLetter } from "@/helper/helper";
import { PencilSquareIcon } from "@heroicons/vue/24/outline";
import { BookmarkIcon } from "@heroicons/vue/24/solid";
// Components
import OperaScore from "@/components/offerings/OperaScore.vue";
// Props
const props = defineProps({
  offering: {
    type: Object,
    required: true,
    default: null,
  },
  partner: {
    type: Object,
    required: true,
    default: null,
  },
  allow_edit: {
    type: Boolean,
    required: false,
    default: false,
  },
});
// Emit
const emit = defineEmits(["button-clicked"]);
// Stores
const offeringsStore = useOfferingsStore();
// Globals
const logoUrl = ref("");
const bannerUrl = ref("");
// Mounted
onMounted(async () => {
  await setPublicUrlLogo();
  await setPublicUrlBanner();
});
// Functions
async function setPublicUrlLogo() {
  logoUrl.value = await offeringsStore.getOfferingFile(
    props.offering.id,
    props.offering.tapi_offering_id,
    "logo",
  );
}

async function setPublicUrlBanner() {
  bannerUrl.value = await offeringsStore.getOfferingFile(
    props.offering.id,
    props.offering.tapi_offering_id,
    "banner",
  );
}

// Simple Fucntions
const handleOfferingClick = () => {
  emit("button-clicked", props.offering);
};

const truncateText = (t) => {
  if (t && t.length > 100) return t.slice(0, 100) + "...";
  else return t;
};
</script>
