<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
        ></div>
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex justify-center min-h-full p-4 text-center items-center"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              :class="[
                props.size == 'lg'
                  ? 'w-[90vw] h-[90vh] overflow-scroll'
                  : 'max-w-lg w-fit overflow-hidden px-4 pt-5 pb-4 my-8 p-6',
                'relative text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-900',
              ]"
            >
              <!-- X Mark -->
              <div
                v-if="size != 'lg'"
                class="absolute top-0 right-0 pt-4 pr-4 block"
              >
                <button
                  type="button"
                  class="text-gray-400 rounded-md hover:text-gray-500 dark:hover:text-neutral-300 dark:text-neutral-400 focus:outline-none"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                </button>
              </div>

              <!-- Content -->
              <div class="flex flex-col">
                <slot></slot>
              </div>
              <!-- Content -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/24/solid";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
const open = defineModel({
  type: Boolean,
  required: true,
});
const props = defineProps({
  size: { type: String, required: false, default: "sm" },
});
</script>
