import { z } from 'zod'

const OfferingTypeEnumSchema = z.enum(['equity', 'debt', 'hybrid', 'fund'], {
  errorMap: () => ({ message: "Offering type must be one of: 'Equity', 'Debt', 'Hybrid' or 'Fund'." }),
});
const OfferingStatusEnumSchema = z.enum(['approved', 'pending', 'disapproved'], {
  errorMap: () => ({ message: "Offering status must be one of: 'Approved', 'Pending', or 'Disapproved'." }),
});
const OfferingVisibilityEnumSchema = z.enum(['public', 'private', 'accredited', 'advisor', 'custom'], {
  errorMap: () => ({ message: "Offering visibility must be one of: 'Public', 'Private', 'Accredited', 'Advisor, or 'Custom'." }),
});
const OfferingContentTypesEnumSchema = z.enum(['image', 'content', 'links', 'key_values'], {
  errorMap: () => ({ message: "Offering Content types must be one of: 'Image', 'Content', 'Links', or 'Key Values'." }),
});
const OfferingUnitTypeEnumSchema = z.enum(['shares', 'dollars', 'units'], {
  errorMap: () => ({ message: "Offering units type must be 'Shares', 'Dollars' or 'Units'." }),
});
const OfferingLinkTypesEnumSchema = z.enum(['website', 'facebook', 'linkedin', 'twitter'], {
  errorMap: () => ({ message: "Link type must be one of: 'Website', 'Facebook', 'Linkedin', or 'Twitter'." }),
});

// Color validation regex (hex color code)
const colorSchema = z.string({ message: "Offering color values must be a string" }).refine((value) => {
  const hexColorRegex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
  return hexColorRegex.test(value);
}, {
  message: "Invalid HEX color. Expected format: #RRGGBB or #RGB.",
});

export const OfferingSchema = z.object({
  // IDs
  id: z.string({ message: "Offering ID must be a string" }).uuid('Offering ID must be a valid UUID').optional(),
  partner_id: z.string({ message: "Offering partner ID must be a string" }).uuid('Offering partner ID must be a valid UUID'),
  tapi_offering_id: z.string({ message: "Offering TAPI ID must be a string" }).optional(),
  // Strings
  name: z.string({ message: "Offering name must be a string" }).min(1),
  slogan: z.string({ message: "Offering slogan must be a string" }).min(1),
  description: z.string({ message: "Offering description must be a string" }).min(1),
  market_sector: z.string({ message: "Offering market sector must be a string" }).min(1),
  score: z.string({ message: "Offering score must be a string" }).nullable().optional(),
  aum: z.string({ message: "Offering AUM must be a string" }).nullable().optional(),
  aum_explanation: z.string({ message: "Offering AUM explanation must be a string" }).nullable().optional(),
  liquidity: z.string({ message: "Offering liquidity score must be a string" }).nullable().optional(),
  liquidity_explanation: z.string({ message: "Offering liquidity explanation must be a string" }).nullable().optional(),
  management: z.string({ message: "Offering management score must be a string" }).nullable().optional(),
  management_explanation: z.string({ message: "Offering management explanation must be a string" }).nullable().optional(),
  longevity: z.string({ message: "Offering longevity score must be a string" }).nullable().optional(),
  longevity_explanation: z.string({ message: "Offering longevity explanation must be a string" }).nullable().optional(),
  documents_score: z.string({ message: "Offering documents score must be a string" }).nullable().optional(),
  documents_explanation: z.string({ message: "Offering documents explanation must be a string" }).nullable().optional(),
  financials: z.string({ message: "Offering financials score must be a string" }).nullable().optional(),
  financials_explanation: z.string({ message: "Offering financials explanation must be a string" }).nullable().optional(),
  fee_score: z.string({ message: "Offering fee score must be a string" }).nullable().optional(),
  fee_explanation: z.string({ message: "Offering fee explanation must be a string" }).nullable().optional(),
  fedex_instructions: z.string({ message: "Offering FedEx instructions must be a string" }).nullable().optional(),
  mail_instructions: z.string({ message: "Offering mail instructions must be a string" }).nullable().optional(),
  bank_instructions: z.string({ message: "Offering bank instructions must be a string" }).nullable().optional(),
  account_number_instructions: z.string({ message: "Offering account number instructions must be a string" }).nullable().optional(),
  routing_number_instructions: z.string({ message: "Offering routing number instructions must be a string" }).nullable().optional(),
  reference_instructions: z.string({ message: "Offering reference instructions must be a string" }).nullable().optional(),
  address_instructions: z.string({ message: "Offering address instructions must be a string" }).nullable().optional(),
  custody_fedex_instructions: z.string({ message: "Offering custody FedEx instructions must be a string" }).nullable().optional(),
  custody_mail_instructions: z.string({ message: "Offering custody mail instructions must be a string" }).nullable().optional(),
  custody_bank_instructions: z.string({ message: "Offering custody bank instructions must be a string" }).nullable().optional(),
  custody_account_number_instructions: z.string({ message: "Offering custody account number instructions must be a string" }).nullable().optional(),
  custody_routing_number_instructions: z.string({ message: "Offering custody routing number instructions must be a string" }).nullable().optional(),
  custody_reference_instructions: z.string({ message: "Offering custody reference instructions must be a string" }).nullable().optional(),
  custody_address_instructions: z.string({ message: "Offering custody address instructions must be a string" }).nullable().optional(),
  // Colors
  gradient_end_color: colorSchema,
  gradient_start_color: colorSchema,
  // Booleans
  archived: z.boolean({ message: "Offering archived must be a boolean" }),
  show_score: z.boolean({ message: "Offering show score must be a boolean" }),
  require_accreditation: z.boolean({ message: "Offering require accreditation must be a boolean" }),
  show_factright_report: z.boolean({ message: "Offering show FactRight reports must be a boolean" }),
  show_forum: z.boolean({ message: "Offering show forum must be a boolean" }),
  accept_ach: z.boolean({ message: "Offering accept ACH must be a boolean" }),
  accept_cc: z.boolean({ message: "Offering accept credit card must be a boolean" }),
  accept_wire: z.boolean({ message: "Offering accept wire must be a boolean" }),
  accept_check: z.boolean({ message: "Offering accept check must be a boolean" }),
  is_custody_only: z.boolean({ message: "Offering is custody only must be a boolean" }),
  use_regcf_protocols: z.boolean({ message: "Offering use RegCF protocols must be a boolean" }),
  // Dates
  start_date: z.string().date(),
  end_date: z.string().date(),
  // Arrays
  attestations: z.array(z.string({ message: "Each attestation must be a string" })).nullable().optional(),
  allowed_user_groups: z.array(z.string({ message: "Each allowed user group must be a string" })).nullable().optional(),
  allowed_account_types: z.array(
    z.enum(['individual', 'joint', 'entity'], { message: "Each allowed account type must be one of 'Individual', 'Joint', or 'Entity'" })
  ),
  // Floats
  minimum: z.number({ message: "Offering minimum must be a positive number" }).positive(),
  maximum: z.number({ message: "Offering maximum must be a positive number" }).positive(),
  unit_price: z.number({ message: "Offering unit price must be a positive number" }).positive(),
  target_amount: z.number({ message: "Offering target amount must be a positive number" }).positive(),
  // Arrays of Objects
  content: z.array(
    z.object({
      id: z.string(),
      type: OfferingContentTypesEnumSchema,
      name: z.string().optional(),
      header: z.string().optional(),
      text: z.string().optional(),
      url: z.string().optional(),
    })
  ).nullable(),
  links: z.array(z.object({
    id: z.string(), 
    type: OfferingLinkTypesEnumSchema,
    name: z.string().optional(),
    url: z.string().optional(),
  })),
  key_values: z.array(z.object({
    id: z.string().optional(),
    name: z.string(),
    value: z.string().optional(),
    type: z.string().optional(),
  })),
  // Enums
  offering_type: OfferingTypeEnumSchema,
  type: OfferingUnitTypeEnumSchema.optional().nullable(),
  status: OfferingStatusEnumSchema,
  visibility: OfferingVisibilityEnumSchema,

})
  .refine(data => { // Validate 'score' is set when 'show_score' is true
    return !data.show_score || data.score;
  }, {
    path: ['score_content'],
    message: 'Score info is required when Show Score is toggled on.',
  })
  .refine((data) => { // Validate that the end date is after the start date
    console.log(data.start_date);
    console.log(data.end_date);
    const start = new Date(data.start_date);
    const end = new Date(data.end_date);
    console.log(start);
    console.log(end);
    return end > start;
  }, {
    message: "End date must be after start date.",
    path: ['end_date'],
  })
  .refine((data) => data.minimum < data.maximum, { // Validate that the minimum is less than the maximum
    message: "Minimum must be smaller than Maximum.",
    path: ['minimum'],
  }).refine((data) => data.unit_price <= data.maximum, { // Validate that the unit price is less than or equal to the maximum
    message: "Unit price must not exceed Maximum.",
    path: ['unit_price'],
  }).refine((data) => data.target_amount <= data.maximum, { // Validate that the target amount is less than or equal to the maximum
    message: "Target amount must not exceed Maximum.",
    path: ['target_amount'],
  })
  .refine((data) => { // Validate that at least one payment method is true
    return data.accept_ach || data.accept_cc || data.accept_wire || data.accept_check;
  }, {
    message: "At least one payment method must be true.",
    path: ['accept_ach'],
  })
  .refine(data => { // Validate all check instructions are set when 'accept_check' is true
    return !data.accept_check || (data.fedex_instructions && data.mail_instructions);
  }, {
    path: ['fedex_instructions'],
    message: 'If accepting checks, FedEx & Regular mail instructions are required.',
  })
  .refine(data => { // Validate all wire instructions are set when 'accept_wire' is true
    return !data.accept_wire || (data.bank_instructions && data.account_number_instructions && data.routing_number_instructions && data.reference_instructions && data.address_instructions);
  }, {
    path: ['bank_instructions'],
    message: 'If accepting wires, all wire instructions fields are required.',
  })
  .refine((data) => {
    if (data.is_custody_only) {
      return !data.accept_ach && !data.accept_cc && !data.accept_wire && !data.accept_check;
    }
    return true; // No restriction if is_custody_only is false
  }, {
    message: "If Only Accept Custody Payments is turned on, all other payment methods must be turned off.",
    path: ['is_custody_only'], // Attach the error to the is_custody_only field
  })
  .refine((data) => { // Validate all custody instructions are set when 'is_custody_only' is true
    if (data.is_custody_only) {
      return (
        data.custody_fedex_instructions &&
        data.custody_mail_instructions &&
        data.custody_bank_instructions &&
        data.custody_account_number_instructions &&
        data.custody_routing_number_instructions &&
        data.custody_reference_instructions &&
        data.custody_address_instructions
      );
    }
    return true; // If is_custody_only is false, no validation on instructions
  }, {
    message: "All custody instructions are required if 'Only Accept Custody Payments' is turned on.",
    path: ['is_custody_only'],
  })
  .refine((data) => { // Validate that the 'visibility' is 'public' and 'show_forum' is true if 'use_regcf_protocols' is true
    if (data.use_regcf_protocols) {
      return data.show_forum === true && data.visibility === 'public';
    }
    return true; // No validation if use_regcf_protocols is false
  }, {
    message: "If Use RegCF Protocols is turned on, Show Forum must be turned on and Offering Visibility must be 'public'.",
    path: ['use_regcf_protocols'], // Attach the error to use_regcf_protocols
  })