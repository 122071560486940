<template>
  <Slideover v-model="offering.show_overview" header="Description & Overview">
    <!-- Content -->
    <div
      class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full"
    >
      <div class="flex-1">
        <!-- Description -->
        <SafeTextarea
          id="description"
          ref="safe_description"
          v-model="offering.description"
          label="Description"
          :rows="3"
        />

        <!-- Overview -->
        <div
          v-if="offering?.key_values?.length > 0"
          class="border-t border-dashed border-gray-300 dark:border-neutral-700 mt-6 pt-5"
        >
          <div class="select_menu_label">Overview Section</div>
          <div class="flex flex-col gap-5 w-full">
            <div class="flex flex-col gap-5">
              <div
                v-for="(key_value, i) in offering?.key_values"
                :key="i"
                class="flex gap-5 bg-white dark:bg-neutral-900 rounded-lg p-4 shadow"
              >
                <!-- Key Value Name -->
                <AppInput
                  id="keyName"
                  v-model="key_value.name"
                  label="Name"
                  placeholder="ex. Ticker"
                  :disabled="key_value.type == 'curated'"
                  class="flex-1 w-52"
                />

                <!-- Key Value Value -->
                <AppInput
                  id="keyValue"
                  v-model="key_value.value"
                  label="Value"
                  placeholder="ex. APPL"
                  :disabled="key_value.type == 'curated'"
                  class="flex-1 w-52"
                />

                <!-- Key Value Type -->
                <SelectMenu
                  v-if="key_value.type != 'curated'"
                  v-model="key_value.type"
                  label="Type"
                  class="w-40"
                  :items="[
                    'text',
                    'badge blue',
                    'badge red',
                    'badge yellow',
                    'badge green',
                    'badge purple',
                    'badge pink',
                    'badge teal',
                    'badge indigo',
                    'badge orange',
                    'badge gray',
                  ]"
                />

                <!-- Key Value Delete -->
                <TrashIcon
                  class="size-5 mb-3 cursor-pointer hover:text-red-700 dark:text-neutral-300 transition-all ease-in-out duration-300 self-end"
                  @click="offering?.key_values?.splice(i, 1)"
                />
              </div>
            </div>

            <div class="flex flex-wrap items-center justify-center gap-5">
              <!-- Add Custom Info -->
              <ButtonPrimary
                text="Add Custom Info"
                :icon="TagIcon"
                size="lg"
                @click="
                  offering?.key_values?.push({
                    name: '',
                    value: '',
                    type: 'text',
                  })
                "
              />

              <div>- or -</div>

              <!-- Add Curated Info -->
              <div class="w-52">
                <AppDropdown
                  title="Add From Curated List"
                  :items="[
                    'security type',
                    'market sector',
                    'price per share',
                    'raise goal',
                    'ticker',
                    'start date',
                    'end date',
                    'funded amount',
                    'pending amount',
                    'gross fees',
                    'net fees',
                    'minimum investment',
                    'maximum investment',
                  ]"
                  @selected="handleCuratedInfoSelection"
                />
              </div>
            </div>

            <!-- Preview Overview Key Info -->
            <div v-if="offering?.key_values?.length > 0" class="mt-4">
              <div class="select_menu_label">Preview of Overview Section</div>
              <div
                class="bg-white dark:bg-neutral-900 shadow-opera dark:shadow-none !rounded-xl w-full"
              >
                <dl class="divide-y divide-gray-100 dark:divide-neutral-800">
                  <div
                    v-for="key_value in offering?.key_values"
                    :key="key_value"
                    class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  >
                    <dt class="tertiary_subheader dark:text-white">
                      {{ key_value.name }}
                    </dt>
                    <dd
                      :class="[
                        key_value.type.includes('badge')
                          ? getBadgeClass(key_value.type) + ' w-fit'
                          : 'mt-1 text sm:col-span-2 sm:mt-0',
                      ]"
                      class=""
                    >
                      {{ key_value.value }}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div
          v-else
          class="flex flex-col items-center border-t border-dashed border-gray-300 dark:border-neutral-700 mt-6 pt-5"
        >
          <SwatchIcon
            class="mx-auto size-12 text-gray-400 dark:text-neutral-500"
          />
          <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-white">
            No Overview Info
          </h3>
          <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">
            Get started by creating a new overview info pair.
          </p>

          <div class="mt-6 flex items-center flex-wrap gap-4">
            <!-- Add Custom Info -->
            <ButtonPrimary
              text="Add Custom Info"
              :icon="TagIcon"
              size="lg"
              @click="
                offering?.key_values?.push({
                  name: '',
                  value: '',
                  type: 'text',
                })
              "
            />

            <div>- or -</div>
            <!-- Add Curated Info -->
            <div class="w-52">
              <AppDropdown
                title="Add From Curated List"
                :items="[
                  'security type',
                  'market sector',
                  'price per share',
                  'raise goal',
                  'ticker',
                  'start date',
                  'end date',
                  'funded amount',
                  'pending amount',
                  'gross fees',
                  'net fees',
                  'minimum investment',
                  'maximum investment',
                ]"
                @selected="handleCuratedInfoSelection"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <ButtonSecondary
        text="Close"
        size="lg"
        class="w-full mt-10"
        @click="offering.show_overview = false"
      />
    </div>
    <!-- Content -->
  </Slideover>
</template>

<script setup>
// Essentials
import { ref, watch } from "vue";

// Components
import Slideover from "@/components/applicationui/AppSlideover.vue";
import SelectMenu from "@/components/applicationinputs/SelectMenu.vue";
import AppDropdown from "@/components/applicationinputs/AppDropdown.vue";
import AppInput from "@/components/applicationinputs/AppInput.vue";
import SafeTextarea from "@/components/applicationinputs/safeTextarea.vue";
import ButtonSecondary from "@/components/applicationui/AppButtonSecondary.vue";
import ButtonPrimary from "@/components/applicationui/AppButtonPrimary.vue";

// Libraries
import { getBadgeClass } from "@/helper/helper";
import { TrashIcon, TagIcon, SwatchIcon } from "@heroicons/vue/24/outline";

// Model
const offering = defineModel({ type: Object });

// Handle Curated Info Selection
const handleCuratedInfoSelection = (selection) => {
  if (selection == "start date") {
    offering.value.key_values.push({
      id: "start-date",
      name: "Raise Start Date",
      value: "(Generated by Opera)",
      type: "curated",
    });
    return;
  }
  if (selection == "end date") {
    offering.value.key_values.push({
      id: "end-date",
      name: "Raise End Date",
      value: "(Generated by Opera)",
      type: "curated",
    });
    return;
  }
  if (selection == "funded amount") {
    offering.value.key_values.push({
      id: "funded-amount",
      name: "Funded Amount",
      value: "(Generated by Opera)",
      type: "curated",
    });
    return;
  }
  if (selection == "pending amount") {
    offering.value.key_values.push({
      id: "pending-amount",
      name: "Pending Amount",
      value: "(Generated by Opera)",
      type: "curated",
    });
    return;
  }
  if (selection == "minimum investment") {
    offering.value.key_values.push({
      id: "minimum-investment",
      name: "Minimum Investment",
      value: "(Generated by Opera)",
      type: "curated",
    });
    return;
  }
  if (selection == "maximum investment") {
    offering.value.key_values.push({
      id: "maximum-investment",
      name: "Maximum Investment",
      value: "(Generated by Opera)",
      type: "curated",
    });
    return;
  }
  if (selection == "raise goal") {
    offering.value.key_values.push({
      id: "raise-goal",
      name: "Raise Goal",
      value: "(Generated by Opera)",
      type: "curated",
    });
    return;
  }
  if (selection == "security type") {
    offering.value.key_values.push({
      id: "security-type",
      name: "Security Type",
      value: "(Generated by Opera)",
      type: "curated",
    });
    return;
  }
  if (selection == "price per share") {
    offering.value.key_values.push({
      id: "unit-price",
      name: "Price per Share",
      value: "(Generated by Opera)",
      type: "curated",
    });
    return;
  }

  if (selection == "gross fees") {
    offering.value.key_values.push({
      name: "Gross Fees",
      value: "",
      type: "text",
    });
    return;
  }
  if (selection == "net fees") {
    offering.value.key_values.push({
      name: "Net Fees",
      value: "",
      type: "text",
    });
    return;
  }
  if (selection == "ticker") {
    offering.value.key_values.push({ name: "Ticker", value: "", type: "text" });
    return;
  }
  if (selection == "market sector") {
    offering.value.key_values.push({
      name: "Market Sector",
      value: "",
      type: "text",
    });
    return;
  }
};

// Safe START
const safe_description = ref(null);
const setErrorMessage = async (message = "This field is required") => {
  safe_description.value.setErrorMessage(message);
};
defineExpose({ setErrorMessage });
// Safe END

// Watchers
watch(
  () => offering.value.key_values,
  (newKeyValue) => {
    if (!newKeyValue) return;
    if (newKeyValue?.length > 0) {
      // If the key_values array is not empty and a key_values object already exists in the additional content array do nothing
      if (offering.value.content.some((obj) => obj.type === "key_values"))
        return;
      // If the links array is not empty and a links object does not exist in the additional content array add it
      offering.value.content.push({
        id: "key_values",
        name: "Overview Key Info Section",
        type: "key_values",
      });
    }
    // If the links array is empty remove the links object from the additional content array
    if (newKeyValue?.length === 0)
      offering.value.content = offering.value.content.filter(
        (obj) => obj.type !== "key_values",
      );
  },
  { deep: true },
);
</script>
