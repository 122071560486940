<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Notification Popup -->
    <NotificationPopup
      ref="notification"
      :type="notificationType"
      :title="notificationTitle"
      :text="notificationText"
    />

    <!-- User Slideover -->
    <Slideover
      v-model="openUserSlideover"
      :partner="selectedUser?.profiles_protected?.partners"
      header="User Information"
    >
      <UserSlideover
        :user="selectedUser"
        @close="openUserSlideover = false"
        @save="
          notify(
            'success',
            'User Updated',
            `The user was updated successfully`,
          );
          setUsers();
        "
      />
    </Slideover>

    <!-- Invite User -->
    <Popup v-model="openInvitePopup">
      <InviteUser
        :current-user-role="userStore.user.profiles_protected.role"
        :current-user-partner="partnerStore.partner"
        @close="openInvitePopup = false"
        @failure="
          notify(
            'failure',
            'Sorry',
            'There was an error sending the invitation, please try again. If the issue persists contact tech support',
          )
        "
        @emails-dont-match="
          notify(
            'failure',
            'Emails must matchEmails do not match',
            'Please make sure the emails match before sending the invitation',
          )
        "
        @success="
          notify(
            'success',
            'Email Sent',
            'The invitation email has been sent. The user will need to click the link in the email to complete the registration process',
          )
        "
      />
    </Popup>

    <!-- Loading -->
    <div
      v-if="loading"
      class="flex flex-grow items-center justify-center h-[70vh]"
    >
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Ready -->
    <div v-else>
      <div>
        <!-- Empty State -->
        <div v-if="users && users.length <= 0">
          <div class="text-center">
            <UserCircleIcon
              class="w-12 h-12 mx-auto text-gray-400 dark:text-neutral-400"
            />
            <h3
              class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300"
            >
              No Users
            </h3>
            <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">
              You have no users yet.<br />
              Once a user has signed up you will see their information here.
            </p>
          </div>
        </div>

        <!-- Has Accounts -->
        <div v-else>
          <!-- Header -->
          <div>
            <div
              class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 flex-wrap dark:border-neutral-700"
            >
              <!-- Tab Header -->
              <h3 class="primary_header">Users</h3>

              <div class="flex items-center flex-wrap gap-4">
                <!-- Search -->
                <SearchInput v-model="query" />
                <!-- Invite User -->
                <Tooltip
                  :content="
                    partnerStore.partner.disable_signup
                      ? 'You need to enable user sign up in Site Settings in order to send and user invite.'
                      : ''
                  "
                >
                  <ButtonPrimary
                    text="Invite User"
                    size="lg"
                    :disabled="partnerStore.partner.disable_signup"
                    :icon="EnvelopeIcon"
                    @click="openInvitePopup = true"
                  />
                </Tooltip>
              </div>
            </div>
            <div class="flex">
              <TableOrCard v-model="isTable" />
            </div>
          </div>

          <!-- Users Table -->
          <TableSticky
            v-if="isTable"
            v-model="columns"
            :loading="loading_users"
            :loading_all="loading_all_users"
            :rows="filteredUsers"
            :is-clickable="true"
            class="-mt-8"
            export_title="users"
            @click-primary="handleUserClick"
          />

          <!-- User Cards -->
          <div v-else role="list" class="flex gap-6 flex-wrap mt-4">
            <div
              v-for="u in filteredUsers"
              :key="u.id"
              class="divide-y divide-gray-200 rounded-lg bg-white shadow-opera cursor-pointer border w-80 border-white hover:border-gray-300 hover:border dark:bg-neutral-800 dark:border-neutral-800 dark:hover:border-neutral-600"
              @click="handleUserClick(u)"
            >
              <UserCard :user="u" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from "vue";
import { useUserStore } from "@/stores/user";
import { usePartnerStore } from "@/stores/partner";
import { useProfilesStore } from "@/stores/profiles";
// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import NotificationPopup from "@/components/applicationui/AppNotification.vue";
import TableSticky from "@/components/applicationui/tables/AppExportableTable.vue";
import UserCard from "@/components/users/UserCard.vue";
import InviteUser from "@/components/users/InviteUser.vue";
import Popup from "@/components/applicationui/AppPopup.vue";
import Slideover from "@/components/applicationui/AppSlideover.vue";
import UserSlideover from "@/components/users/UserSlideover.vue";
import Tooltip from "@/components/applicationui/AppTooltip.vue";
import ButtonPrimary from "@/components/applicationui/AppButtonPrimary.vue";
import TableOrCard from "@/components/applicationui/tables/TableOrCard.vue";
import SearchInput from "@/components/applicationinputs/SearchInput.vue";
// Libraries
import { UserCircleIcon } from "@heroicons/vue/24/outline";
import { EnvelopeIcon } from "@heroicons/vue/24/solid";
// Stores
const userStore = useUserStore();
const partnerStore = usePartnerStore();
const profilesStore = useProfilesStore();

// Globals
const isTable = ref(true);
if (window.innerWidth < 1024) isTable.value = false;
const loading = ref(true);
const loading_users = ref(true);
const loading_all_users = ref(true);

const users = ref([]);
const selectedUser = ref({});
const query = ref("");
const openInvitePopup = ref(false);
const openUserSlideover = ref(false);
const newUser = ref({ role: "investor", email: "", verify_email: "" });
const notification = ref(null);
const notificationType = ref("success");
const notificationTitle = ref("Success");
const notificationText = ref("");
const columns = ref([
  { key: "email", label: "Email", type: "bold", visible: true },
  {
    key: "parties.first_name",
    label: "Name",
    key2: "parties.last_name",
    type: "two-strings",
    visible: true,
  },
  {
    key: "profiles_protected.role",
    label: "Role",
    type: "badge",
    visible: true,
  },
  { key: "parties.phone", label: "Phone", type: "default-fill", visible: true },
  {
    key: "profiles_protected.groups",
    label: "User Groups",
    type: "badge-array",
    visible: true,
  },
  {
    key: "created_at",
    label: "Date Created",
    type: "date",
    sorter: true,
    visible: true,
  },
]);

// Mounted
onMounted(async () => {
  // Get Users
  await setUsers();

  // Setup
  newUser.value.partner = partnerStore.partner;

  // Add Partner Column
  if (userStore.user.profiles_protected.role == "super_admin") {
    columns.value.splice(columns.value.length - 1, 0, {
      key: "profiles_protected.partners",
      label: "Partner",
      type: "partner-logo",
      visible: true,
    });
  }
  // Ready
  loading.value = false;
});

// Computed
const filteredUsers = computed(() => {
  return query.value === ""
    ? users.value
    : users.value.filter((user) => {
        if (user?.parties?.first_name)
          if (
            user?.parties?.first_name
              ?.toLowerCase()
              .includes(query.value.toLowerCase())
          )
            return true;
        if (user?.parties?.last_name)
          if (
            user?.parties?.last_name
              ?.toLowerCase()
              .includes(query.value.toLowerCase())
          )
            return true;
        if (user?.email)
          if (user?.email?.toLowerCase().includes(query.value.toLowerCase()))
            return true;
        if (user?.parties?.phone)
          if (user?.parties?.phone?.includes(query.value)) return true;
        if (user?.profiles_protected.role)
          if (
            user?.profiles_protected?.role
              ?.toLowerCase()
              ?.includes(query.value.toLowerCase())
          )
            return true;
        if (user?.profiles_protected?.partners?.title)
          if (
            user?.profiles_protected?.partners?.title
              ?.toLowerCase()
              ?.includes(query.value.toLowerCase())
          )
            return true;
        if (user?.profiles_protected?.partners?.logo_text)
          if (
            user?.profiles_protected?.partners?.logo_text
              ?.toLowerCase()
              ?.includes(query.value.toLowerCase())
          )
            return true;
        if (user?.profiles_protected?.partners?.meta_name)
          if (
            user?.profiles_protected?.partners?.meta_name
              ?.toLowerCase()
              ?.includes(query.value.toLowerCase())
          )
            return true;
      });
});

// Functions
async function setUsers() {
  if (userStore.user.profiles_protected.role == "super_admin") {
    users.value = await profilesStore.getAllProfilesPaginated(0, 49);
    loading_users.value = false;
    getRestOfUsers();
  } else if (userStore.user.profiles_protected.role == "partner_admin") {
    users.value = await profilesStore.getAllProfilesForPartnerPaginated(
      partnerStore.partner.id,
      0,
      49,
    );
    loading_users.value = false;
    getRestOfUsersForPartner();
  }
}

async function getRestOfUsers() {
  if (users.value.length < 49) {
    loading_all_users.value = false;
    return;
  }
  const rest_of_users = await profilesStore.getAllProfilesPaginated(50);
  users.value = users.value.concat(rest_of_users);
  loading_all_users.value = false;
}

async function getRestOfUsersForPartner() {
  if (users.value.length < 49) {
    loading_all_users.value = false;
    return;
  }
  const rest_of_users = await profilesStore.getAllProfilesForPartnerPaginated(
    partnerStore.partner.id,
    50,
  );
  users.value = users.value.concat(rest_of_users);
  loading_all_users.value = false;
}

// Simple Functions
const handleUserClick = (user) => {
  selectedUser.value = user;
  openUserSlideover.value = true;
};

const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};
</script>
