<template>
  <div>
    <div
      v-if="props.label"
      class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300 mb-2"
    >
      {{ props.label }}
    </div>

    <Listbox v-model="selectedModel" as="div" :disabled="props.disabled">
      <div class="relative">
        <ListboxButton
          :class="[
            props.disabled
              ? 'bg-gray-100 opacity-75 cursor-not-allowed '
              : 'bg-white dark:bg-neutral-800 cursor-pointer ',
            bad_input
              ? 'ring-2 ring-red-600 dark:ring-2 dark:ring-red-600'
              : 'ring-1 ring-gray-300 dark:ring-0',
            'relative w-full rounded-md dark:text-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-inset focus:outline-none focus:ring-2 focus:ring-operacolor sm:text-sm sm:leading-6',
          ]"
        >
          <span class="block truncate capitalize">{{
            getSelectedString(selectedModel)
          }}</span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <ChevronUpDownIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-neutral-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            :class="[props.inverted ? '-top-64' : '']"
          >
            <ListboxOption
              v-for="item in props.items"
              :key="item"
              v-slot="{ active, selected }"
              as="template"
              :value="item"
            >
              <li
                :class="[
                  active
                    ? 'bg-operacolor text-white'
                    : 'text-gray-900 dark:text-neutral-200',
                  'relative cursor-pointer select-none py-2 pl-3 pr-9',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate capitalize',
                  ]"
                  >{{ getItemString(item) }}</span
                >

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-operacolor',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script setup>
// Essentials
import { onMounted, ref } from "vue";
// Libraries
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
// Props
const props = defineProps({
  items: { type: Array, required: true, default: null }, //This component expects that the array will have objects with the following structure: { id: number, label: string }
  type: { type: String, required: false, default: "text" },
  label: { type: String, required: false, default: null },
  disabled: { type: Boolean, required: false, default: false },
  inverted: { type: Boolean, required: false, default: false },
});
// Models
const selectedModel = defineModel({
  type: [String, Object],
  default: null,
});

// Get Labels
const getSelectedString = (val) => {
  if (props.type === "redirect_urls") return val.label;
  if (props.type === "partner") return val.title;
  return val;
};
const getItemString = (item) => {
  if (props.type === "redirect_urls") return item.label;
  if (props.type === "partner") return item.title;
  return item;
};

// Set error message
const bad_input = ref(false);
const showError = (message = "This field is required") => {
  bad_input.value = true;
  setTimeout(() => {
    bad_input.value = false;
  }, 3500);
  console.error(message);
};
defineExpose({ showError });

// Mounted
onMounted(() => {
  if (props?.items && props?.items.length > 0 && !selectedModel.value)
    selectedModel.value = props.items[0];
});
</script>
