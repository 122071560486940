<template>
  <nav aria-label="Progress" class="bg-white">
    <ol role="list" class="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
      <li v-for="(step, stepIdx) in props.steps" :key="step.name" class="relative md:flex md:flex-1">
        <div v-if="step.status === 'complete'" class="group flex w-full items-center">
          <span class="flex items-center pr-6 pl-3 py-4 text-xs font-medium">
            <span class="flex size-8 shrink-0 items-center justify-center rounded-full bg-green-600 group-hover:bg-green-700">
              <CheckIcon class="size-5 text-white" aria-hidden="true" />
            </span>
            <span class="ml-4 text-xs font-medium text-gray-900">{{ step.name }}</span>
          </span>
        </div>
        <div v-else-if="step.status === 'current'" class="flex items-center pr-6 pl-3 py-4 text-xs font-medium" aria-current="step">
          <span class="flex size-8 shrink-0 items-center justify-center rounded-full border-2 border-green-600">
            <span class="text-green-600">{{ step.id }}</span>
          </span>
          <span class="ml-4 text-xs font-medium text-green-600">{{ step.name }}</span>
        </div>
        <div v-else class="group flex items-center">
          <span class="flex items-center pr-6 pl-3 py-4 text-xs font-medium">
            <span class="flex size-8 shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
              <span class="text-gray-500 group-hover:text-gray-900">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-xs font-medium text-gray-500 group-hover:text-gray-900">{{ step.name }}</span>
          </span>
        </div>
        <template v-if="stepIdx !== steps.length - 1">
          <!-- Arrow separator for lg screens and up -->
          <div class="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
            <svg class="size-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
            </svg>
          </div>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { CheckIcon } from '@heroicons/vue/24/solid'

// Props
const props = defineProps({
  steps: {
    type: Array,
    required: true,
  },
})

// const steps = [
//   { id: '1', name: 'Transaction Created', href: '#', status: 'complete' },
//   { id: '2', name: 'Wire Funds', href: '#', status: 'current' },
//   { id: '3', name: 'Funds Recieved', href: '#', status: 'upcoming' },
//   { id: '4', name: 'Transaction Settled', href: '#', status: 'upcoming' },
// ]
</script>