<template>
  <!-- Contributions & Distributions Line Chart -->
  <div
    class="flex-1 flex-col items-center justify-center min-w-96 gap-2 bg-white rounded-lg shadow-md py-2.5 ring-1 ring-gray-100 dark:bg-neutral-800 dark:ring-neutral-700 dark:shadow-none"
  >
    <div class="font-semibold border-b border-dashed border-gray-200 mx-5 pb-2 mb-1 text-center dark:border-neutral-600 dark:text-neutral-100">
      Total Contributions
    </div>
    <!-- Loading -->
    <div v-if="loading" class="px-2 pb-10 pt-2 w-full min-w-96 h-[500px]">
      <div class="flex flex-grow items-center justify-center h-full">
        <div class="w-32 h-32 -mt-10">
          <SpinnerFlip />
        </div>
      </div>
    </div>
    <div v-else-if="noTransactions">
      <div class="text-center mt-20 flex flex-col items-center justify-center h-full">
        <CurrencyDollarIcon class="mx-auto size-12 text-gray-400" />
        <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-white">No Contributions Yet</h3>
        <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">Once you start receiving contributions,<br/> they will be displayed here.</p>
      </div>
    </div>
    <div v-else class="px-2 pb-10 pt-2 w-full min-w-96 h-[500px]">
      <Line
        ref="contributions_distributions_chart"
        :data="contributions_distributions_chart_data"
        :options="contributions_distributions_chart_options"
      />
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted, nextTick } from "vue";

// Stores
import { useTransactionsStore } from "@/stores/transactions";
const transactionsStore = useTransactionsStore();

// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";

// Libraries
import { CurrencyDollarIcon } from "@heroicons/vue/24/outline";

// ChartJS
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale } from "chart.js";
import { Line } from "vue-chartjs";
ChartJS.register(CategoryScale, LinearScale, Title, Tooltip, Legend);

// Props
const props = defineProps({
  partner_id: { type: String, required: true },
});

// Contributions Over Time (Line Chart) START
const contributions_distributions_chart_data = ref();
const contributions_distributions_chart_options = ref({
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    intersect: false,
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          // Format the dataset value as dollars
          const value = tooltipItem.raw;
          return ` $${value.toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
        },
      },
    },
    legend: {
      display: true,
    },
  },
  scales: {
    y: {
      display: true,
      suggestedMin: 0,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value) {
          return `$${value.toLocaleString("en-US")}`;
        },
      },
    },
  },
});
const cyan = { hex: "#1db1e6", rgba: "rgba(29, 177, 230, ", start: "0.8)", end: "0.2)" };
const pink = { hex: "#EC4899", rgba: "rgba(236, 72, 153", start: ", 0.8)", end: ", 0.2)" };
const violet = { hex: "#8B5CF6", rgba: "rgba(139, 92, 246", start: ", 0.8)", end: ", 0.2)" };
const emerald = { hex: "#10B981", rgba: "rgba(16, 185, 129", start: ", 0.8)", end: ", 0.2)" };
const amber = { hex: "#F59E0B", rgba: "rgba(245, 158, 11", start: ", 0.8)", end: ", 0.2)" };
const orange = { hex: "#F97316", rgba: "rgba(249, 115, 22", start: ", 0.8)", end: ", 0.2)" };
const red = { hex: "#EF4444", rgba: "rgba(239, 68, 68", start: ", 0.8)", end: ", 0.2)" };
const colors = ref([cyan, pink, violet, emerald, amber, orange, red]);

const buildDistributionsChart = (total_contributions_to_each_offering_by_month) => {
  // Set Labels
  const labels = total_contributions_to_each_offering_by_month[0].last_12_months;

  // Get colors array
  const operaColor = getComputedStyle(document.documentElement).getPropertyValue("--operacolor");
  let r = 0,
    g = 0,
    b = 0;
  if (operaColor.length == 4) {
    r = parseInt(operaColor[1] + operaColor[1], 16);
    g = parseInt(operaColor[2] + operaColor[2], 16);
    b = parseInt(operaColor[3] + operaColor[3], 16);
  } else if (operaColor.length == 7) {
    r = parseInt(operaColor[1] + operaColor[2], 16);
    g = parseInt(operaColor[3] + operaColor[4], 16);
    b = parseInt(operaColor[5] + operaColor[6], 16);
  }
  const operacolor = { hex: operaColor, rgba: `rgba(${r},${g},${b}, `, start: "0.8)", end: "0.2)" };
  colors.value.unshift(operacolor);

  // Set datasets
  const datasets = [];
  total_contributions_to_each_offering_by_month.forEach((total_contributions_for_offering, i) => {
    let borderColor = colors.value[i].hex;
    let backgroundColor = colors.value[i].rgba + "0.3)";
    if (i >= colors.value.length) {
      let color = colors.value[i % colors.value.length];
      borderColor = color.hex;
      backgroundColor = color.rgba + "0.3)";
    }
    let dataset = {
      label: total_contributions_for_offering.offering.name,
      data: total_contributions_for_offering.contributions_totals_by_month,
      fill: true,
      borderColor,
      backgroundColor,
      tension: 0.1,
      pointStyle: "circle",
      pointRadius: 8,
      pointHoverRadius: 10,
    };
    datasets.push(dataset);
  });

  contributions_distributions_chart_data.value = {
    labels,
    datasets,
  };
};

const contributions_distributions_chart = ref(null);
const setChartLineGradient = () => {
  if (!contributions_distributions_chart.value) return;
  const chart = contributions_distributions_chart.value.chart;
  const { ctx, chartArea } = chart;

  chart.data.datasets.forEach((dataset, i) => {
    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
    let bgColor = colors.value[i];
    if (i >= colors.value.length) bgColor = colors.value[i % colors.value.length];
    gradient.addColorStop(0, bgColor.rgba + bgColor.start);
    gradient.addColorStop(1, bgColor.rgba + bgColor.end);
    dataset.borderColor = bgColor.hex;
    dataset.backgroundColor = gradient;
  });

  chart.update();
};
//  Contributions Over Time (Line Chart) END

// Set Contributions By Offering START
const getFirstDayOfEachMonth = (start_date) => {
  const dates = [];
  const currentDate = new Date();
  dates.push(new Date(currentDate)); // Include today's date

  // Set to the first day of the current month
  currentDate.setDate(1);

  // Loop until we reach the start date
  while (currentDate >= new Date(start_date)) {
    dates.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  return dates.reverse();
};
function addTotalsRow(data) {
  if (!data.length) return data; // Return original data if it's empty

  // Get the last_12_months array from the first item (assuming it's the same for all)
  const last_12_months = data[0].last_12_months;

  // Initialize an array with zeros to hold the sum of contributions
  const contributions_totals_by_month = new Array(12).fill(0);

  // Sum up contributions at each index
  data.forEach((item) => {
    item.contributions_totals_by_month.forEach((value, index) => {
      if (typeof value === "number") {
        contributions_totals_by_month[index] += value;
      }
    });
  });

  // Create the totals row
  const totalsRow = {
    last_12_months,
    offering: { name: "Total Contributions" },
    contributions_totals_by_month,
  };

  // return the totals array
  return totalsRow;
}

const noTransactions = ref(false);
const setContributionsByOffering = async () => {
  const all_transactions = await transactionsStore.getAllSettledTransactionsForPartnerExcludeDistributions(props.partner_id);

  // Group transactions by offering_id
  const transactionsGroupedByOffering = Object.values(
    all_transactions.reduce((acc, transaction) => {
      acc[transaction.offering_id] = acc[transaction.offering_id] || [];
      acc[transaction.offering_id].push(transaction);
      return acc;
    }, {}),
  );

  // If there are no transactions then return and set flag
  if (transactionsGroupedByOffering.length <= 0) {
    noTransactions.value = true;
    return;
  }

  // Get the earliest offering create date
  let earliest_offering_create_at = null;
  transactionsGroupedByOffering.forEach((all_transactions_for_offering) => {
    const offering_created_at = all_transactions_for_offering[0].offerings.created_at;
    if (!earliest_offering_create_at || offering_created_at < earliest_offering_create_at) earliest_offering_create_at = offering_created_at;
  });

  const first_days_of_each_month = getFirstDayOfEachMonth(earliest_offering_create_at);

  const total_contributions_to_each_offering_by_month = [];
  transactionsGroupedByOffering.forEach((all_transactions_for_offering) => {
    const last_12_months = [];
    const contributions_totals_by_month = [];
    first_days_of_each_month.forEach((first_of_the_month) => {
      const sum = all_transactions_for_offering.reduce((acc, transaction) => {
        if (transaction.settled_at < first_of_the_month.toISOString()) acc += transaction.amount * transaction.price_per_unit;
        return acc;
      }, 0);
      contributions_totals_by_month.push(sum);
      let date_string = `${first_of_the_month.toLocaleString("default", { month: "short" })}, ${first_of_the_month.getFullYear()}`;
      if (first_of_the_month.getDate() !== 1) date_string = "Today";
      last_12_months.push(date_string);
    });
    total_contributions_to_each_offering_by_month.push({
      offering: all_transactions_for_offering[0].offerings,
      last_12_months,
      contributions_totals_by_month,
    });
  });

  if (total_contributions_to_each_offering_by_month.length > 1) {
    total_contributions_to_each_offering_by_month.unshift(addTotalsRow(total_contributions_to_each_offering_by_month));
  } else {
    if (total_contributions_to_each_offering_by_month[0]) {
      total_contributions_to_each_offering_by_month[0].offering.name = "Total Contributions";
    }
  }

  buildDistributionsChart(total_contributions_to_each_offering_by_month);
};

// Set Contributions By Offering END

// Mounted START
const loading = ref(true);
onMounted(async () => {
  await setContributionsByOffering();

  // Ready
  loading.value = false;

  // Set chart line gradient
  await nextTick();
  setChartLineGradient();
});
// Mounted END
</script>
