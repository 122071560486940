<template>
  <div class="w-full">
    <!-- Back Button -->
    <div class="button_secondary m-5" @click="goBack()">
      <ArrowLeftIcon class="w-4 h-4" />
      Back
    </div>

    <!-- Article -->
    <div class="flex justify-center w-full p-5">
      <!-- Leave this outer div empty -->
      <div class="flex flex-col w-fit max-w-[1000px]">
        <img class="w-full rounded-lg" :src="props.article?.preview_url" />
        <div class="flex flex-wrap items-end justify-between mt-10 gap-y-5">
          <div class="text-3xl font-bold">{{ props.article?.title }}</div>
          <div class="text-sm">by: {{ props.article?.author }}</div>
        </div>

        <div class="flex flex-col gap-5 my-10 w-full max-w-[1000px]">
          <!-- Paragraph 1 -->
          <div v-for="content in props.article?.content" :key="content.id">
            <!-- Heading -->
            <div v-if="content?.type == 'header'" class="text-xl font-semibold">
              {{ content?.text }}
            </div>
            <!-- Paragraph -->
            <div
              v-else-if="content?.type == 'paragraph'"
              class="whitespace-pre-line"
            >
              {{ content?.text }}
            </div>
            <!-- Image -->
            <img
              v-else-if="content?.type == 'image'"
              class="w-full rounded-lg"
              :src="`${supabaseUrl}/storage/v1/object/public/articles/${props.article?.id}/${content?.name}`"
            />
            <!-- Link -->
            <a
              v-else-if="content?.type == 'link'"
              :href="content?.url"
              class="text-blue-600 hover:underline"
              >{{ content?.label }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ArrowLeftIcon } from "@heroicons/vue/24/outline";
const props = defineProps({
  article: { type: Object, required: true, default: null },
});
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const router = useRouter();
const goBack = () => {
  router.go(-1);
};
</script>
