<template>
  <Slideover v-model="offering.show_order" header="Offering Content Order">
    <div
      class="relative mt-6 flex-1 flex flex-col justify-between px-4 sm:px-6 h-full"
    >
      <SlickDrag v-model="offering.content" />

      <ButtonSecondary
        text="Close"
        size="lg"
        class="w-full mt-5"
        @click="offering.show_order = false"
      />
    </div>
  </Slideover>
</template>

<script setup>
// Components
import SlickDrag from "@/components/applicationui/AppSlickDrag.vue";
import Slideover from "@/components/applicationui/AppSlideover.vue";
import ButtonSecondary from "@/components/applicationui/AppButtonSecondary.vue";

// Model
const offering = defineModel({ type: Object });
</script>
