import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
import { logErrorMessage } from '@/schemas/AdvancedErrorHandler.js'
// import { ref } from 'vue'


export const useProfilesStore = defineStore('profiles', () => {
  // State (refs)

  // Getters, (computed properties)

  // Actions, (functions)
  async function getAllProfilesForPartnerPaginated(partner_id, start = 0, finish = 10000000) {
    const { data, error } = await supabase.from('profiles').select('*, profiles_protected(*, partners(*)), parties!party_id(*), parties_protected!party_id(*)')
      .eq('profiles_protected.partner_id', partner_id)
      .order('created_at', { ascending: false })
      .range(start, finish);
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }
  async function getAllProfilesPaginated(start = 0, finish = 10000000) {
    const { data, error } = await supabase.from('profiles').select('*, profiles_protected(*, partners(*)), parties!party_id(*), parties_protected!party_id(*)').order('created_at', { ascending: false }).range(start, finish);
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }
  async function getAllProfilesForPartnerFillPartyAccount(partner_id) {
    const { data, error } = await supabase.from('profiles').select('*, profiles_protected(*), parties!party_id(*, accounts!primary_party_id(*, accounts_protected(*))), parties_protected!party_id(*)')
      .eq('profiles_protected.partner_id', partner_id)
      .order('created_at', { ascending: false });
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }
  async function getAllProfilesFillPartyAccountTransactionOfferingPaginated(start = 0, finish = 10000000) {
    const { data, error } = await supabase
      .from("profiles")
      .select(
        "*, profiles_protected(*), parties!party_id(*, parties_protected(*), partners(*, partners_protected(*)), accounts!accounts_primary_party_id_fkey(*, accounts_protected(*), transactions(*, offerings(*))))",
      )
      .neq("parties.accounts.transactions.status", "archived")
      .order("created_at", { ascending: false })
      .range(start, finish);
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function updateRole(id, role) {
    const { error } = await supabase.from('profiles_protected').update({ role: role }).eq('id', id);
    if (error) { console.log(await logErrorMessage(error)); return }
  }
  async function updateProfileParty(id, party_id) {
    const { error } = await supabase.from('profiles').update({ party_id: party_id }).eq('id', id);
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function addEmailToPendingPartnerAdmins(email) {
    const { error } = await supabase.from('pending_partner_admins').insert({ email: email });
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function updateUsersGroup(id, groups) {
    const { error } = await supabase.from('profiles_protected').update({ groups }).eq('id', id);
    if (error) { console.log(await logErrorMessage(error)); return }
  }
  async function deleteUserAllTheWay(id, user_to_delete_id) {   
    console.log('deleteUserAllTheWay', id, user_to_delete_id)
    const { error } = await supabase.functions.invoke('delete-user', { body: { id, user_to_delete_id } });
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }

  // Not Callable Functions

  return {
    getAllProfilesForPartnerPaginated,
    getAllProfilesPaginated,
    getAllProfilesForPartnerFillPartyAccount,
    updateRole,
    updateProfileParty,
    updateUsersGroup,
    addEmailToPendingPartnerAdmins,
    deleteUserAllTheWay,
    getAllProfilesFillPartyAccountTransactionOfferingPaginated,
  };
})