<template>
  <div>
    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center">
      <div class="w-12 h-12">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Content -->
    <div v-else class="flex gap-5 flex-wrap">
      <div class="flex gap-5 flex-wrap flex-1">
        <!-- Contributing Investors -->
        <AppTooltip
          content="The completion rate is the percent of all investors who login that complete the investment process. The change value is based on a rolling 30 day average."
          class="flex-1"
        >
          <AppStatHighlightCard
            title="Avg. Completion Rate"
            :value="avg_completion_rate"
            :change="avg_completion_rate_change"
            :type="avg_completion_rate_change_type"
            :icon="PercentBadgeIcon"
            class="flex-1"
          />
        </AppTooltip>

        <!-- Contributing Entities -->
        <AppTooltip
          content="The average contribution is the average amount each of you contributors invests. Reinvestments are included in this average. The change value is based on a rolling 30 day average."
          class="flex-1"
        >
          <AppStatHighlightCard
            title="Avg. Contribution"
            :value="avg_contribution"
            :change="avg_contribution_change"
            :type="avg_contribution_change_type"
            :icon="BanknotesIcon"
            class="flex-1"
          />
        </AppTooltip>
      </div>

      <div class="flex gap-5 flex-wrap flex-1">
        <!-- Total Contributions -->
        <AppTooltip
          content="The reinvestment rate is the percent of your contributors who make an additional contribution after their initial investment. The change value is based on a rolling 30 day average."
          class="flex-1"
        >
          <AppStatHighlightCard
            title="Reinvestmnet Rate"
            :value="avg_reinvestment_rate"
            :change="avg_reinvestment_rate_change"
            :type="avg_reinvestment_rate_change_type"
            :icon="ArrowPathRoundedSquareIcon"
            class="flex-1"
          />
        </AppTooltip>

        <!-- Total Distributions -->
        <AppTooltip
          content='The "Interest" value is the number of unique visitors your site has had, visitors must login to be tracked. The change value is based on a rolling 30 day average.'
          class="flex-1"
        >
          <AppStatHighlightCard
            title="Interest (Unique Visitors)"
            :value="unique_visitors"
            :change="unique_visitors_change"
            :type="unique_visitors_change_type"
            :icon="CursorArrowRaysIcon"
            class="flex-1"
          />
        </AppTooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { onMounted, ref } from "vue";

// Stores
import { useTransactionsStore } from "@/stores/transactions";
const transactionsStore = useTransactionsStore();
import { useProfilesStore } from "@/stores/profiles";
const profilesStore = useProfilesStore();

// Libraries
import { PercentBadgeIcon, BanknotesIcon, ArrowPathRoundedSquareIcon, CursorArrowRaysIcon } from "@heroicons/vue/24/solid";

// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import AppTooltip from "@/components/applicationui/AppTooltip.vue";
import AppStatHighlightCard from "@/components/applicationui/AppStatHighlightCard.vue";

// Props
const props = defineProps({
  partner_id: { type: String, required: true },
});

// Get Stats START
const pastDate = new Date();
pastDate.setDate(pastDate.getDate() - 30);
const pastDateTimestampFormat = pastDate.toISOString();
const getStats = async () => {
  // Get all users for partner
  const all_users_for_partner = await profilesStore.getAllProfilesForPartnerFillPartyAccount(props.partner_id);

  // Get all unique account_ids for settled transactions (Gets an array of invested users)
  const settled_transactions = await transactionsStore.getAllSettledTransactionsForPartnerFillAccountData(props.partner_id);
  const unique_account_ids = [...new Set(settled_transactions.map((transaction) => transaction.account_id))];

  getAvgCompletionRate(all_users_for_partner, settled_transactions, unique_account_ids);

  getAvgContribution(settled_transactions);

  getAvgReinvestmentRate(settled_transactions, unique_account_ids);

  getUniqueVisitors(all_users_for_partner);
};
// Get Stats END

// Unique Visitors START
const unique_visitors = ref("0");
const unique_visitors_change = ref("0");
const unique_visitors_change_type = ref("increase");
const getUniqueVisitors = (all_users_for_partner) => {
  // Get unique visitors
  const unique_visitors_num = all_users_for_partner.length;
  unique_visitors.value = unique_visitors_num.toString();

  // Get all users for partner from last month
  const all_users_for_partner_last_month = all_users_for_partner.filter((user) => user.created_at < pastDateTimestampFormat);
  const unique_visitors_last_month = all_users_for_partner_last_month.length;
  const unique_visitors_change_value = unique_visitors_num - unique_visitors_last_month;
  if (unique_visitors_change_value > 0) unique_visitors_change_type.value = "increase";
  else if (unique_visitors_change_value < 0) unique_visitors_change_type.value = "decrease";
  else unique_visitors_change_type.value = "no-change";
  unique_visitors_change.value = unique_visitors_change_value.toString();
};

// Average Contribution START
const avg_contribution = ref("$0");
const avg_contribution_change = ref("0%");
const avg_contribution_change_type = ref("increase");
const getAvgContribution = (settled_transactions) => {
  // Get all settled transactions from before 30 days ago
  const settled_transactions_before_30_days = settled_transactions.filter((transaction) => transaction.settled_at < pastDateTimestampFormat);
  // Get total amount of settled transactions from before 30 days ago
  const total_amount_last_month = settled_transactions_before_30_days.reduce(
    (acc, transaction) => acc + transaction.amount * transaction.price_per_unit,
    0,
  );
  // Get total amount of settled transactions
  const total_amount = settled_transactions.reduce((acc, transaction) => acc + transaction.amount * transaction.price_per_unit, 0);
  // Get average contribution
  const avg_contribution_value = total_amount / settled_transactions.length;
  if (avg_contribution_value > 999) avg_contribution.value = "$" + avg_contribution_value.toFixed(0);
  else if (avg_contribution_value) avg_contribution.value = "$" + avg_contribution_value.toFixed(2);

  // Get average contribution from last month
  const avg_contribution_last_month = total_amount_last_month / settled_transactions_before_30_days.length;
  const avg_contribution_change_value = avg_contribution_value - avg_contribution_last_month;
  if (avg_contribution_change_value > 0) avg_contribution_change_type.value = "increase";
  else if (avg_contribution_change_value < 0) avg_contribution_change_type.value = "decrease";
  else avg_contribution_change_type.value = "no-change";
  if (avg_contribution_change_value) avg_contribution_change.value = avg_contribution_change_value.toFixed(2) + "%";
};
// Average Contribution END

// Average Reinvestment Rate START
const avg_reinvestment_rate = ref("0%");
const avg_reinvestment_rate_change = ref("0%");
const avg_reinvestment_rate_change_type = ref("increase");
const getAvgReinvestmentRate = (settled_transactions, unique_account_ids) => {
  // Filter out transactions with type "distribution"
  const filtered_transactions = settled_transactions.filter((transaction) => transaction.type != "distribution");

  // Get all unique account_ids for transactions, with the number or repeats
  const account_id_counts = filtered_transactions.reduce((acc, transaction) => {
    acc[transaction.account_id] = (acc[transaction.account_id] || 0) + 1;
    return acc;
  }, {});
  // get all account_ids with more than 1 transaction
  const reinvestors = Object.keys(account_id_counts).filter((account_id) => account_id_counts[account_id] > 1);
  const reinvestment_rate = (reinvestors.length / unique_account_ids.length) * 100;
  if (reinvestment_rate) avg_reinvestment_rate.value = reinvestment_rate.toFixed(2) + "%";

  // Get all settled transactions from before 30 days ago
  const settled_transactions_before_30_days = filtered_transactions.filter((transaction) => transaction.settled_at < pastDateTimestampFormat);
  // Get unique account_ids for settled transactions from before 30 days ago
  const unique_account_ids_last_month = [...new Set(settled_transactions_before_30_days.map((transaction) => transaction.account_id))];
  const reinvestors_last_month = unique_account_ids_last_month.filter((account_id) => reinvestors.includes(account_id));
  const reinvestment_rate_last_month = (reinvestors_last_month.length / unique_account_ids_last_month.length) * 100;
  const reinvestment_rate_change = reinvestment_rate - reinvestment_rate_last_month;
  if (reinvestment_rate_change > 0) avg_reinvestment_rate_change_type.value = "increase";
  else if (reinvestment_rate_change < 0) avg_reinvestment_rate_change_type.value = "decrease";
  else avg_reinvestment_rate_change_type.value = "no-change";
  avg_reinvestment_rate_change.value = reinvestment_rate_change.toFixed(2) + "%";
};

// Average Completion Rate START
const avg_completion_rate = ref("0%");
const avg_completion_rate_change = ref("0%");
const avg_completion_rate_change_type = ref("increase");
const getAvgCompletionRate = (all_users_for_partner, settled_transactions, unique_account_ids) => {
  // Get investor percentage completion rate
  const current_avg_completion_rate = (unique_account_ids.length / all_users_for_partner.length) * 100;
  avg_completion_rate.value = current_avg_completion_rate.toFixed(2) + "%";

  // Get all settled transactions from before 30 days ago

  const settled_transactions_before_30_days = settled_transactions.filter((transaction) => transaction.settled_at < pastDateTimestampFormat);
  // Get unique account_ids for settled transactions from before 30 days ago
  const unique_account_ids_last_month = [...new Set(settled_transactions_before_30_days.map((transaction) => transaction.account_id))];
  // Get all_users_for_partner from last month
  const all_users_for_partner_last_month = all_users_for_partner.filter((user) => user.created_at < pastDateTimestampFormat);
  // Get investor percentage completion rate from last month
  const avg_completion_rate_last_month = (unique_account_ids_last_month.length / all_users_for_partner_last_month.length) * 100;
  const completion_rate_change = current_avg_completion_rate - avg_completion_rate_last_month;
  if (completion_rate_change > 0) avg_completion_rate_change_type.value = "increase";
  else if (completion_rate_change < 0) avg_completion_rate_change_type.value = "decrease";
  else avg_completion_rate_change_type.value = "no-change";
  avg_completion_rate_change.value = completion_rate_change.toFixed(2) + "%";
};
// Average Completion Rate END

// Mounted
const loading = ref(true);
onMounted(async () => {
  await getStats();
  loading.value = false;
});
</script>
