<template>
  <div
    class="flex-col items-center justify-center w-1/2 min-w-96 gap-2 bg-white rounded-lg shadow-md py-2.5 ring-1 ring-gray-100 dark:bg-neutral-800 dark:ring-neutral-700 dark:shadow-none"
  >
    <div
      class="font-semibold border-b border-dashed border-gray-200 mx-5 pb-2 mb-1 text-center dark:border-neutral-600 dark:text-neutral-100"
    >
      Largest Contributors
    </div>
    <div
      v-if="loading"
      class="flex flex-grow items-center justify-center h-[400px]"
    >
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>
    <div v-else-if="noTransactions">
      <div class="text-center mt-[20%] flex flex-col items-center justify-center h-full">
        <UserGroupIcon class="mx-auto size-12 text-gray-400" />
        <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-white">No Contributions Yet</h3>
        <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">Once you start receiving contributions,<br/> they will be displayed here.</p>
      </div>
    </div>
    <div v-else class="px-2 pb-10 pt-2 w-full min-w-96 h-[400px]">
      <Bar
        ref="top_contributors_chart"
        :data="top_contributors_chart_data"
        :options="top_contributors_chart_options"
      />
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";

// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";

// Stores
import { useTransactionsStore } from "@/stores/transactions";
const transactionsStore = useTransactionsStore();

// Libraries
import { UserGroupIcon } from "@heroicons/vue/24/outline";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar } from "vue-chartjs";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const props = defineProps({
  partner_id: { type: String, required: true, default: null },
});

const top_contributors_chart_data = ref();
const top_contributors_chart_options = ref({
  maintainAspectRatio: false,
  responsive: true,
  indexAxis: "y",
  // interaction: {
  //   intersect: false,
  // },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          // Format the dataset value as dollars
          const value = tooltipItem.raw;
          return ` $${value.toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
        },
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: true,
      suggestedMin: 0,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value) {
          return `$${value.toLocaleString("en-US")}`;
        },
      },
    },
  },
});
const buildTopContributorsChart = () => {
  const labels = contributors_names.value
  const data = contributors_contributions.value;

  // Get the operacolor
  const operacolor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue("--operacolor");
  const operacolor_rgba = hexToRgba(operacolor, 0.6);

  top_contributors_chart_data.value = {
    labels,
    datasets: [
      {
        label: "Contributions",
        data,
        fill: true,
        borderColor: operacolor,
        backgroundColor: operacolor_rgba,
        tension: 0.1,
        pointStyle: "circle",
        pointRadius: 8,
        pointHoverRadius: 10,
      },
    ],
  };
};

const hexToRgba = (hex, opacity) => {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${opacity})`;
};

// Get top contributors START
const noTransactions = ref(false);
const contributors_names = ref([]);
const contributors_contributions = ref([]);
const getTopContributors = async () => {
  // Get top contributors
  // Get all unique account_ids for settled transactions (Gets an array of invested users)
  const settled_transactions = await transactionsStore.getAllSettledTransactionsForPartnerFillAccountDataFillPartyDate(props.partner_id);

  if (settled_transactions.length <= 0) {
    noTransactions.value = true;
    return;
  }

  // Get the account with their total contributions
  const account_contributions = settled_transactions.reduce((acc, transaction) => {
    if (transaction.type == "buy") {
      if (!acc[transaction.account_id]) {
        let name = transaction.accounts.name;
        if(transaction.accounts.type == 'individual') {
          name = `${transaction.accounts.parties.first_name} ${transaction.accounts.parties.last_name}`;
        }
        acc[transaction.account_id] = { name: name, contribution: 0 };
      }
      acc[transaction.account_id].contribution += transaction.amount * transaction.price_per_unit;
    }
    return acc;
  }, {});
  const sorted_contributors = Object.entries(account_contributions)
    .sort((a, b) => b[1].contribution - a[1].contribution)
    .slice(0, 5);
  contributors_names.value = sorted_contributors.map((contributor) => contributor[1].name);
  contributors_contributions.value = sorted_contributors.map((contributor) => contributor[1].contribution);
};
// Get top contributors END

const loading = ref(true);
onMounted(async () => {
  await getTopContributors();
  buildTopContributorsChart();
  loading.value = false;
});
</script>
