<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Popups -->
    <NotificationPopup ref="notification" :type="notificationType" :title="notificationTitle" :text="notificationText" />
    <!-- End Popups -->

    <!-- Slideovers -->
    <PositionSlideover v-model="show_position_slideover" :position="position" />
    <TransactionSlideover v-model="show_transaction_slideover" :transaction="transaction" />
    <!-- End Slideovers -->

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Ready -->
    <div v-else>
      <div>
        <!-- Empty State -->
        <div v-if="holding_positions.length <= 0 && holding_transactions.length <= 0">
          <div class="text-center">
            <WalletIcon class="w-12 h-12 mx-auto text-gray-400" />
            <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Holdings</h3>
            <p class="mt-1 text-sm text-gray-500">
              Get started by investing with the invest tab.<br />You must fill out your profile information first.
            </p>
            <div class="mt-6">
              <RouterLink v-if="userStore.user?.party_id" to="/dashboard/investing">
                <ButtonPrimary text="Invest now" size="xl" :icon="ArrowLongRightIcon" />
              </RouterLink>
              <RouterLink v-else to="/dashboard/settings">
                <ButtonPrimary text="Add Profile" size="xl" :icon="ArrowLongRightIcon" />
              </RouterLink>
            </div>
          </div>
        </div>

        <!-- Positions Table Sub Tab -->
        <div v-else-if="show_all_positions">
          <div>
            <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
              <!-- Tab Header -->
              <h3 class="primary_header flex items-center gap-1">
                <span class="cursor-pointer hover:underline" @click="show_all_positions = false">Holdings</span>
                <span class="inline">
                  <ChevronRightIcon class="size-5 shrink-0 text-gray-500" aria-hidden="true" />
                </span>
                Positions
              </h3>
            </div>
          </div>

          <div class="mt-10">
            <!-- Positions Table -->
            <div class="secondary_header">Positions</div>
            <TableSticky
              v-model="positionsColumns"
              :rows="combined_holding_positions"
              :loading="loading_positions"
              :loading_all="loading_all_positions"
              export_title="positions"
              class="-mt-8"
            />
          </div>
        </div>

        <!-- Transactions Table Sub Tab -->
        <div v-else-if="show_all_transactions">
          <div>
            <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
              <!-- Tab Header -->
              <h3 class="primary_header flex items-center gap-1">
                <span class="cursor-pointer hover:underline" @click="show_all_transactions = false">Holdings</span>
                <span class="inline">
                  <ChevronRightIcon class="size-5 shrink-0 text-gray-500" aria-hidden="true" />
                </span>
                Transactions
              </h3>
            </div>
          </div>

          <!-- Transactions Table -->
          <div class="mt-10">
            <div class="secondary_header">Transactions</div>
            <TableSticky
              v-model="transactionColumns"
              :rows="holding_transactions"
              :loading="loading_positions"
              :loading_all="loading_all_positions"
              export_title="transactions"
              class="-mt-8"
            />
          </div>
        </div>

        <!-- Distributions Table Sub Tab -->
        <div v-else-if="show_all_distributions">
          <div>
            <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
              <!-- Tab Header -->
              <h3 class="primary_header flex items-center gap-1">
                <span class="cursor-pointer hover:underline" @click="show_all_distributions = false">Holdings</span>
                <span class="inline">
                  <ChevronRightIcon class="size-5 shrink-0 text-gray-500" aria-hidden="true" />
                </span>
                Distributions
              </h3>
            </div>
          </div>

          <!-- Distributions Table -->
          <div class="mt-10">
            <div class="secondary_header">Distributions</div>
            <TableSticky
              v-model="positionsColumns"
              :rows="distributions"
              :loading="loading_distributions"
              :loading_all="loading_distributions"
              export_title="distributions_summed"
              class="-mt-8"
            />
          </div>
        </div>

        <!-- Paperwork Table Sub Tab -->
        <div v-else-if="show_all_paperwork">
          <div>
            <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
              <!-- Tab Header -->
              <h3 class="primary_header flex items-center gap-1">
                <span class="cursor-pointer hover:underline" @click="show_all_paperwork = false">Holdings</span>
                <span class="inline">
                  <ChevronRightIcon class="size-5 shrink-0 text-gray-500" aria-hidden="true" />
                </span>
                Subscription Documents
              </h3>
            </div>
          </div>

          <!-- Paperwork Table -->
          <div class="mt-10">
            <SubscriptionDocuments
              :account_id="accountsStore?.holdingAccount?.id"
              :tapi_account_id="accountsStore?.holdingAccount?.tapi_account_id"
            />
          </div>
        </div>

        <!-- Content -->
        <div v-else>
          <!-- Header -->
          <div>
            <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
              <!-- Tab Header -->
              <h3 class="primary_header">Holdings</h3>
            </div>
          </div>

          <div class="flex flex-col gap-10">
            <!-- Holdings Table -->
            <div>
              <div v-if="holding_positions.length > 0" class="flex flex-wrap gap-5 h-fit w-full mb-10">
                <!-- Pie Chart for Positions -->
                <div
                  v-if="combined_holding_positions.length > 1"
                  class="w-fit h-96 flex flex-col items-center gap-2 bg-white rounded-lg shadow-md py-2.5 ring-1 ring-gray-100 dark:bg-neutral-800 dark:ring-neutral-700 dark:shadow-none"
                >
                  <div
                    class="font-semibold border-b border-dashed border-gray-200 w-[90%] pb-2 text-center dark:border-neutral-600 dark:text-neutral-100"
                  >
                    Positions
                  </div>
                  <div class="size-80">
                    <Pie ref="positionsPieChart" :data="pie_chart_data" :options="options" />
                  </div>
                </div>

                <!-- Line Chart for Positions -->
                <div
                  class="flex-1 flex-col items-center justify-center min-w-96 h-96 gap-2 bg-white rounded-lg shadow-md py-2.5 ring-1 ring-gray-100 dark:bg-neutral-800 dark:ring-neutral-700 dark:shadow-none"
                >
                  <div
                    class="font-semibold border-b border-dashed border-gray-200 mx-5 pb-2 mb-1 text-center dark:border-neutral-600 dark:text-neutral-100"
                  >
                    Account Value
                  </div>
                  <div class="px-2 pb-10 pt-2 w-full min-w-96 h-full">
                    <Line ref="positionsLineChart" :data="line_chart_data" :options="line_chart_options" />
                  </div>
                </div>
              </div>

              <!-- Widgets -->
              <div class="flex justify-start flex-wrap gap-10">
                <!-- Current Holdings and Sub Docs -->
                <div v-if="holding_positions.length > 0 || distributions?.length > 0" ref="holdingsContainer" class="flex flex-col justify-start gap-10 flex-1 h-fit">
                  <div v-if="holding_positions.length > 0" class="h-fit sm:min-w-[430px]">
                    <PositionsWidget
                      :positions="combined_holding_positions"
                      @show-all="show_all_positions = true"
                      @position-details="showPositionDetails"
                    />
                  </div>

                  <div v-if="distributions?.length > 0" class="h-fit sm:min-w-[430px]">
                    <DistributionsWidget
                      :distributions="distributions"
                      @show-all="show_all_distributions = true"
                      @distribution-details="showPositionDetails"
                    />
                  </div>
                </div>

                <div class="sm:min-w-[550px] min-w-96 flex-1" :style="{ maxHeight: `${holdingsHeight}px` }">
                  <TransactionsWidget
                    :transactions="holding_transactions"
                    @show-all="show_all_transactions = true"
                    @transaction-details="showTransactionDetails"
                  />
                </div>
              </div>
              <div class="max-h-2xl h-fit mt-10">
                <SubDocsWidget
                  :account_id="accountsStore?.holdingAccount?.id"
                  :tapi_account_id="accountsStore?.holdingAccount?.tapi_account_id"
                  @show-all="show_all_paperwork = true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted, nextTick } from "vue";

// Stores START
import { useUserStore } from "@/stores/user";
import { useAccountsStore } from "@/stores/accounts";
import { useTransactionsStore } from "@/stores/transactions";
const userStore = useUserStore();
const accountsStore = useAccountsStore();
const transactionsStore = useTransactionsStore();
// Stores END

// Components
import SubscriptionDocuments from "@/components/transactions/SubscriptionDocuments.vue";
import NotificationPopup from "@/components/applicationui/AppNotification.vue";
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import TableSticky from "@/components/applicationui/tables/AppExportableTable.vue";
import PositionsWidget from "@/components/transactions/PositionsWidget.vue";
import DistributionsWidget from "@/components/distributions/DistributionsWidget.vue";
import TransactionsWidget from "@/components/transactions/TransactionsWidget.vue";
import SubDocsWidget from "@/components/transactions/SubDocsWidget.vue";
import ButtonPrimary from "@/components/applicationui/AppButtonPrimary.vue";

// Libraries
import { formatDateMonthYear } from "@/helper/helper";
import { ArrowLongRightIcon, WalletIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";

// ChartJS Pie START
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale } from "chart.js";
import { Pie, Line } from "vue-chartjs";

ChartJS.register(CategoryScale, LinearScale, Title, Tooltip, Legend);
const pie_chart_data = ref();
const options = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          // Format the dataset value as dollars
          const value = tooltipItem.raw;
          return ` $${value.toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
        },
      },
    },
  },
};
const buildPositionsPieChart = async () => {
  const labels = combined_holding_positions.value.map((p) => p.offerings.name);
  const data = combined_holding_positions.value.map((p) => p.total_value);
  // Get the operacolor
  const operacolor = getComputedStyle(document.documentElement).getPropertyValue("--operacolor");

  // Set the background colors
  const backgroundColor = combined_holding_positions.value.map((p, i) => {
    const colors = [
      operacolor,
      "#EC4899", // pink 600
      "#16a34a", // green 600
      "#06B6D4", // cyan 600
      "rgb(75, 192, 192)",
      "rgb(153, 102, 255)",
      "rgb(255, 159, 64)",
    ];
    return colors[i % colors.length];
  });
  const theme = localStorage.theme;
  let border_color = "#ffffff";
  if (theme === "dark") border_color = "#404040";
  const datasets = [
    {
      clip: 0,
      data,
      label: "Portfolio",
      backgroundColor,
      borderColor: border_color,
      borderWidth: 2,
      hoverOffset: 4,
    },
  ];
  ChartJS.defaults.plugins.legend.position = "top";
  pie_chart_data.value = { labels, datasets };
};
const positionsPieChart = ref(null);
// ChartJS Pie END

// ChartJS Line START
const line_chart_data = ref();
const line_chart_options = ref({
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    intersect: false,
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          // Format the dataset value as dollars
          const value = tooltipItem.raw;
          return ` $${value.toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
        },
      },
    },
    legend: {
      display: true,
    },
  },
  scales: {
    y: {
      display: true,
      suggestedMin: 0,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value) {
          return `$${value.toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
        },
      },
    },
  },
});
const positionsLineChart = ref(null);
const buildLineChart = async () => {
  if (holding_positions.value.length <= 0) return;

  // Create the labels and data
  const labels = holding_positions.value.map((t) => formatDateMonthYear(t.created_at));
  let holding_total = 0;
  let distributions_total = 0;
  const distributions_data = [];
  const holdings_data = [];
  holding_positions.value.forEach((t) => {
    let distribution_value = distributions_total;
    let holding_value = holding_total;
    if (t.type === "distribution" && t.units != "shares") {
      distributions_total = distributions_total + t.amount * t.price_per_unit;
      distribution_value = distributions_total;
    } else {
      holding_total = holding_total + t.amount * t.price_per_unit;
      holding_value = holding_total;
    }
    distributions_data.push(distribution_value);
    holdings_data.push(holding_value);
  });

  // If there is only one data point in holdings_data, add a second data point to make the line visible
  if (holdings_data.length === 1) {
    holdings_data.unshift(0);
    labels.unshift("");
  }

  // Get the operacolor
  const operacolor = getComputedStyle(document.documentElement).getPropertyValue("--operacolor");
  const operacolor_rgba = hexToRgba(operacolor, 0.3);

  const operacolorlight = getComputedStyle(document.documentElement).getPropertyValue("--operacolorlight");
  const operacolorlight_rgba = hexToRgba(operacolorlight, 0.3);

  const datasets = [
    {
      label: "Account Value",
      data: holdings_data,
      fill: true,
      borderColor: operacolor,
      backgroundColor: operacolor_rgba,
      tension: 0.01,
      pointStyle: "circle",
      pointRadius: 8,
      pointHoverRadius: 10,
    },
  ];
  if (distributions_total > 0) {
    datasets.unshift({
      label: "Total Distributions",
      data: distributions_data,
      fill: true,
      borderColor: operacolorlight,
      backgroundColor: operacolorlight_rgba,
      tension: 0.01,
      pointStyle: "circle",
      pointRadius: 8,
      pointHoverRadius: 10,
    });
  }

  line_chart_data.value = {
    labels,
    datasets,
  };

  const largest_total = Math.max(holding_total, distributions_total);
  line_chart_options.value.scales.y.suggestedMax = largest_total * 1.1;
};
const setChartLineGradient = () => {
  if (!positionsLineChart.value) return;
  const chart = positionsLineChart.value.chart;
  const ctx = chart.ctx;

  const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
  const gradient2 = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);

  const operacolor = getComputedStyle(document.documentElement).getPropertyValue("--operacolor");

  const rgbaColor = hexToRgba(operacolor, 0.8);
  const rgbaColorSoft = hexToRgba(operacolor, 0.2);
  const rgbaColorLight = hexToRgba("#1db1e6", 0.8);
  const rgbaColorLightSoft = hexToRgba("#1db1e6", 0.2);

  gradient.addColorStop(0, rgbaColor);
  gradient.addColorStop(0, rgbaColor);
  gradient.addColorStop(1, rgbaColorSoft);

  gradient2.addColorStop(0, rgbaColorLight);
  gradient2.addColorStop(0, rgbaColorLight);
  gradient2.addColorStop(1, rgbaColorLightSoft);

  chart.data.datasets[0].backgroundColor = gradient2;
  chart.data.datasets[0].borderColor = "#1db1e6";
  chart.data.datasets[1].backgroundColor = gradient;

  chart.update();
};
const hexToRgba = (hex, opacity) => {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${opacity})`;
};
// ChartJS Line END

// Position Slideover START
import PositionSlideover from "@/components/transactions/PositionSlideover.vue";
const show_position_slideover = ref(false);
const position = ref(null);
const showPositionDetails = (passed_position) => {
  position.value = passed_position;
  show_position_slideover.value = true;
};
// Position Slideover END

// Transactions Slideover START
import TransactionSlideover from "@/components/transactions/TransactionSlideover.vue";
const show_transaction_slideover = ref(false);
const transaction = ref(null);
const showTransactionDetails = (passed_transaction) => {
  transaction.value = passed_transaction;
  show_transaction_slideover.value = true;
};
// Transactions Slideover End

// Set Positions START
const positionsColumns = ref([
  { key: "offerings.name", label: "Name", type: "bold", visible: true },
  { key: "total_value", label: "Total Value", type: "money", visible: true },
  { key: "units", label: "Unit Type", type: "badge", visible: true },
  { key: "price_per_unit", label: "Unit Price", type: "money", visible: true },
  { key: "settled_at", label: "Date", type: "date", visible: true },
]);
const holding_positions = ref([]);
const distributions = ref([]);
const loading_distributions = ref(true);
const combined_holding_positions = ref([]);
const loading_positions = ref(true);
const loading_all_positions = ref(true);
const setPositions = async () => {
  loading_positions.value = true;
  loading_all_positions.value = true;
  // Get holding account positions
  const positions = await transactionsStore.getAllSettledTransactionsByAccountId(accountsStore?.holdingAccount?.id);
  if (!positions) {
    notify(
      "failure",
      "Sorry",
      "We were unable to get your positions, please refresh the page and try again. If the issue persists, contact support.",
    );
    return;
  }
  // Set Holding Positions
  holding_positions.value = positions;

  // Combined Distributions
  const distributions_all = positions.filter((p) => p.type === "distribution" && p.transaction_method === "distribution" && p.units != "shares");
  const combinedDistributions = distributions_all.reduce((acc, position) => {
    position.total_value = position.amount * position.price_per_unit;
    position.total_units = position.amount;
    position.earliest_date = position.created_at;
    position.latest_date = position.settled_at;
    if (position.type === "distribution") position.offerings.name = `${position.offerings.name} Earnings`;
    if (position.offering_id) {
      const existing = acc.find((p) => p.offering_id === position.offering_id && p.type === position.type);
      if (existing) {
        existing.total_value += position.total_value;
        existing.total_units += position.amount;
        if (existing.earliest_date > position.created_at) existing.earliest_date = position.created_at;
        if (existing.latest_date < position.settled_at) existing.latest_date = position.settled_at;
        if (existing.units != position.units) existing.units = "dollars";
        if (!existing.settled_at || existing.settled_at > position.settled_at) existing.settled_at = position.settled_at;
      } else acc.push({ ...position });
    } else acc.push({ ...position });
    return acc;
  }, []);
  distributions.value = combinedDistributions;
  loading_distributions.value = false;

  // Combine positions
  const combinedPositions = positions.reduce((acc, position) => {
    position.total_value = position.amount * position.price_per_unit;
    position.total_units = position.amount;
    position.earliest_date = position.created_at;
    position.latest_date = position.settled_at;
    if (position.type == "distribution" && position.transaction_method == "distribution" && position.units != "shares") return acc;
    if (position.offering_id) {
      const existing = acc.find((p) => p.offering_id === position.offering_id);
      if (existing) {
        existing.total_value += position.total_value;
        existing.total_units += position.amount;
        if (existing.earliest_date > position.created_at) existing.earliest_date = position.created_at;
        if (existing.latest_date < position.settled_at) existing.latest_date = position.settled_at;
        if (existing.units != position.units) existing.units = "dollars";
        if (!existing.settled_at || existing.settled_at > position.settled_at) existing.settled_at = position.settled_at;
      } else acc.push({ ...position });
    } else acc.push({ ...position });
    return acc;
  }, []);
  combined_holding_positions.value = combinedPositions;

  await buildPositionsPieChart();
  await buildLineChart();

  loading_positions.value = false;
  loading_all_positions.value = false;
};
// Set Positions END

// Set Transactions START
const holding_transactions = ref([]);
const loading_transactions = ref(true);
const loading_all_transactions = ref(true);
const transactionColumns = ref([
  { key: "offerings.name", label: "Name", type: "bold", visible: true },
  {
    key: "amount",
    key2: "price_per_unit",
    label: "Total Value",
    type: "multiply",
    visible: true,
  },
  {
    key: "transaction_method",
    label: "Transaction Type",
    type: "badge",
    visible: true,
  },
  { key: "units", label: "Unit Type", type: "badge", visible: true },
  { key: "price_per_unit", label: "Unit Price", type: "money", visible: true },
  { key: "status", label: "Status", type: "badge", visible: true },
  { key: "created_at", label: "Date", type: "date", visible: true },
]);
const setTransactions = async () => {
  loading_transactions.value = true;
  loading_all_transactions.value = true;
  holding_transactions.value = await transactionsStore.getAllTransactionByAccountId(accountsStore?.holdingAccount?.id);
  loading_transactions.value = false;
  loading_all_transactions.value = false;
};
// Set Transactions END

// Set Container Height START
const holdingsContainer = ref(null);
const holdingsHeight = ref("");
const setContainerHeight = () => {
  if (!holdingsContainer.value) return
  if (holdingsContainer.value.offsetHeight > 0) holdingsHeight.value = holdingsContainer.value.offsetHeight;
};
// Set Container Height END

// Toasts START
const notification = ref(null);
const notificationType = ref("success");
const notificationTitle = ref("Success");
const notificationText = ref("");
const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};
// Toasts END

// Visibility Toggles START
const show_all_positions = ref(false);
const show_all_transactions = ref(false);
const show_all_distributions = ref(false);
const show_all_paperwork = ref(false);
// Visibility Toggles END

// Mounted START

const loading = ref(true);
onMounted(async () => {
  // Check that profile has an associated party
  if (!userStore?.user?.party_id) {
    loading.value = false;
    return;
  }

  // Get holding account positions
  await setPositions();

  // Get holding account transactions
  await setTransactions();

  // Ready
  loading.value = false;

  // Wait for DOM to update then set the chart line gradient
  await nextTick();
  setChartLineGradient();
  setContainerHeight()
});
// Mounted END
</script>
