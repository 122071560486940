<template>
  <div class="bg-white">
    <!-- Cookiess Banner -->
    <div v-if="showCookieBanner" class="fixed bottom-0 w-full p-5 z-50">
      <div
        class="shadow-md bg-white ring-1 ring-gray-200 py-3 px-4 w-full rounded-md flex justify-between items-center gap-x-5 gap-y-1 md:flex-nowrap flex-wrap"
      >
        <div class="text-sm">
          Opera uses essential cookies and related technologies on its websites.
          By clicking "Accept", or continuing to use Opera's websites, you agree
          to the use of cookies, as well as to Opera's
          <a
            class="text-blue-600 hover:text-blue-500"
            target="_blank"
            href="https://app.hubspot.com/documents/4042879/view/473022400?accessId=2a9568"
            >Privacy Policy and Notice at Collection</a
          >
          and
          <a
            href="https://hubs.ly/Q01yYmG_0"
            target="_blank"
            class="text-blue-600 hover:text-blue-500"
            >Terms of Use</a
          >.
        </div>
        <div class="flex gap-3 justify-end w-fit ml-auto">
          <div
            class="button_secondary whitespace-nowrap"
            @click="openLearnMore = true"
          >
            Learn More
          </div>
          <div class="button_primary_nc" @click="setCookieAcceptValue()">
            Accept
          </div>
        </div>
      </div>
    </div>

    <!-- Cookies Popup -->
    <TransitionRoot as="template" :show="openLearnMore">
      <Dialog as="div" class="relative z-50" @close="openLearnMore = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
              >
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    @click="openLearnMore = false"
                  >
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <LockClosedIcon
                      class="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      class="text-base font-semibold leading-6 text-gray-900"
                      >Essential Cookies</DialogTitle
                    >
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        These cookies enable core functionality such as
                        security, verification of identity and network
                        management. These cookies can't be disabled.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    class="button_primary_nc w-full justify-center sm:ml-3 sm:w-auto sm:text-sm"
                    @click="setCookieAcceptValue()"
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    class="button_secondary w-full justify-center sm:ml-3 sm:w-auto sm:text-sm mt-3 sm:mt-0"
                    @click="openLearnMore = false"
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Tech Support Popup -->
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="open = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex items-start justify-center min-h-full p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative w-full max-w-sm overflow-hidden text-left transition-all transform bg-white border border-gray-300 rounded-md shadow-md sm:my-14 sm:mx-5"
              >
                <div class="w-full sm:flex sm:items-start">
                  <div class="flex-col items-start w-full">
                    <div class="w-full min-w-0 p-4 border-b border-border">
                      <!-- Textarea -->
                      <form
                        class="relative flex flex-col w-full"
                        @submit.prevent="sendSupportEmail()"
                      >
                        <label for="email" class="input_label">Email</label>
                        <div class="mb-4 input_wrapper">
                          <input
                            id="email"
                            v-model="email"
                            type="email"
                            autocomplete="email"
                            name="email"
                            class="input"
                            placeholder="example@gmail.com"
                            required="true"
                          />
                        </div>
                        <div
                          class="w-full overflow-hidden rounded-lg ring-1 ring-inset ring-gray-300"
                        >
                          <label for="comment" class="sr-only"
                            >Add your comment</label
                          >
                          <textarea
                            id="comment"
                            v-model="supportStr"
                            rows="5"
                            name="comment"
                            required="true"
                            class="block w-full resize-y border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Add your comment..."
                          />
                        </div>
                        <!-- Buttons -->
                        <div class="flex justify-between mt-4">
                          <button
                            type="button"
                            class="button_secondary"
                            @click="open = false"
                          >
                            Cancel
                          </button>
                          <button type="submit" class="button_primary_nc">
                            Send Email
                          </button>
                        </div>
                      </form>
                    </div>
                    <!-- Support Text -->
                    <div class="px-4 py-4 text-xs text-gray-500">
                      Prefer direct email?
                      <a
                        href="mailto:support@northcapital.com"
                        target="_blank"
                        class="text-blue-800 cursor-pointer"
                        >techsupport@northcapital.com</a
                      >
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <HubspotForm
      v-if="showHubspotForm"
      :show="showHubspotForm"
      @submitted="contactSubmitted()"
      @closed="showHubspotForm = false"
    />
    <NotificationPopup
      ref="notification"
      title="Thank you!"
      text="Your request has been sent to our team. We will be reaching out very soon."
    />

    <!-- Nav Header -->
    <header class="inset-x-0 top-0 z-50 flex justify-center">
      <div class="w-[1000px]">
        <nav
          class="flex items-center justify-between py-6 pr-4"
          aria-label="Global"
        >
          <div class="flex lg:flex-1">
            <div class="-m-1.5 lg:p-1.5 lg:pl-0 pl-5">
              <span class="sr-only">OPERA</span>
              <div class="flex items-center gap-2.5">
                <img class="w-auto h-12" :src="logoUrl" alt="" />
                <div class="text-2xl font-semibold text-[#005599]">OPERA</div>
              </div>
            </div>
          </div>
          <div class="flex lg:hidden">
            <button
              type="button"
              class="inline-flex items-center justify-center text-gray-700 rounded-md"
              @click="mobileMenuOpen = true"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon class="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
          <div class="hidden lg:flex lg:gap-x-12">
            <a
              v-for="item in navigation"
              :key="item.name"
              class="text-sm leading-6 text-gray-900 cursor-pointer"
              @click="navigate(item)"
              >{{ item.name }}</a
            >
          </div>
          <!-- <div class="hidden gap-2 lg:flex lg:flex-1 lg:justify-end">
            <RouterLink to="/login?partner=opera" class="text-[#016cb2] px-3.5 py-1.5 font-medium hover:ring-1 hover:ring-[rgba(1,108,178,0.2)] rounded-md">Sign Up</RouterLink>
            <RouterLink to="/login" class="text-[#016cb2] px-3.5 py-1.5 font-medium ring-1 ring-[#016cb2] rounded-md hover:ring-0 hover:bg-[rgba(1,108,178,0.1)]">Sign in</RouterLink>
          </div> -->
        </nav>
        <!-- Mobile Menu Landing -->
        <Dialog
          as="div"
          class="lg:hidden"
          :open="mobileMenuOpen"
          @close="mobileMenuOpen = false"
        >
          <div class="fixed inset-0 z-50" />
          <DialogPanel
            class="fixed inset-y-0 right-0 z-50 w-full px-6 py-6 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
          >
            <div class="flex items-center justify-between">
              <a href="#" class="-m-1.5 p-1.5">
                <span class="sr-only">OPERA</span>
                <img class="w-auto h-8" :src="logoUrl" alt="Logo" />
              </a>
              <button
                type="button"
                class="-m-2.5 rounded-md p-2.5 text-gray-700"
                @click="mobileMenuOpen = false"
              >
                <span class="sr-only">Close menu</span>
                <XMarkIcon class="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flow-root mt-6">
              <div class="-my-6 divide-y divide-gray-500/10">
                <div class="py-6 space-y-2">
                  <a
                    v-for="item in navigation"
                    :key="item.name"
                    class="block px-3 py-2 -mx-3 text-base leading-7 text-gray-900 rounded-lg cursor-pointer hover:bg-gray-50"
                    @click="navigate(item)"
                  >
                    {{ item.name }}
                  </a>
                </div>
                <!-- <div class="py-6">
                  <RouterLink to="/login" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Sign in</RouterLink>
                </div> -->
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </div>
    </header>

    <div
      class="flex flex-col items-center justify-center gap-12 mx-5 mt-32 md:mx-10 lg:mx-20 lg:gap-28 pb-28"
    >
      <!-- Hero Section Start -->
      <div
        class="flex items-center justify-center lg:justify-between max-w-[1000px] w-full flex-wrap-reverse"
      >
        <div class="flex flex-col py-20">
          <div
            class="text-4xl font-bold text-gray-900 mb-[16px] lg:w-[450px] max-w-[450px] w-full"
          >
            How do we make a difference?
          </div>
          <div
            class="text-lg lg:w-[450px] max-w-[450px] w-full mb-[48px] text-gray-900"
          >
            Empowering investors, advisors, brokers, and issuers by providing
            access and transparency to private securities and other alternative
            assets.
          </div>
          <div class="flex items-center gap-5">
            <RouterLink to="/signup?partner=opera" class="button_primary_nc"
              >Get Started</RouterLink
            >
            <div
              class="flex items-center gap-2 cursor-pointer text-[#016cb2] px-3.5 py-1.5 font-medium hover:ring-1 hover:ring-[rgba(1,108,178,0.2)] rounded-md"
              @click="showHubspotForm = true"
            >
              <div>Schedule Demo</div>
              <ArrowUpRightIcon class="w-4 h-4" />
            </div>
          </div>
        </div>
        <div class="relative h-96 w-96">
          <img :src="logoGif" alt="Opera Logo Spinning" />
          <div
            class="absolute top-0 left-0 h-full w-full bg-[rgba(0,171,231,0.7)] blur-[100px] opacity-[0.48] rounded-[400px]"
          ></div>
        </div>
      </div>

      <!-- Advisors Section -->
      <div
        ref="advisor"
        class="flex items-center lg:justify-between justify-center max-w-[1000px] w-full lg:gap-y-16 rounded-xl flex-wrap bg-[#EBF9FD] overflow-hidden"
      >
        <div class="relative h-96 w-96">
          <img
            class="absolute lg:-left-20 lg:-bottom-32"
            :src="pieChartGif"
            alt="A Pie Chart Gif"
          />
        </div>
        <div class="flex flex-col px-5 py-16 lg:px-unset lg:pr-16">
          <div
            class="bg-[rgba(0,85,153,0.10)] text-[#005599] py-1 px-2 w-fit rounded-md mb-[16px] text-sm font-semibold"
          >
            ADVISORS
          </div>
          <div
            class="text-4xl font-bold text-gray-900 mb-[16px] lg:w-[450px] max-w-[450px] w-full"
          >
            Manage your client's alternative assets in one place
          </div>
          <div
            class="text-lg lg:w-[530px] max-w-[530px] w-full mb-[35px] text-gray-900"
          >
            Access to a broad spectrum of alternative assets for client
            portfolios while facilitating compliance with the Custody Rule.
            Monitor client accounts, statements and tax documents from one
            dashboard. Review risk rankings and investment due diligence reports
            prior to making allocation decisions.
          </div>
          <div
            class="ring-inset w-fit cursor-pointer inline-flex font-medium items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-[#005599] shadow-sm hover:ring-1 ring-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
            @click="showHubspotForm = true"
          >
            Learn More
            <ArrowUpRightIcon class="w-4 h-4" />
          </div>
        </div>
      </div>

      <!-- Investors Section -->
      <div
        ref="investors"
        class="flex items-center lg:justify-between justify-center max-w-[1000px] w-full flex-wrap rounded-xl bg-[linear-gradient(290deg,#00AAE7_0%,#016CB2_100%)] overflow-hidden"
      >
        <div class="flex flex-col px-5 py-10 lg:py-16 lg:pl-24">
          <div
            class="bg-[rgba(255,255,255,0.10)] text-white py-1 px-2 w-fit rounded-md mb-[16px] text-sm font-semibold"
          >
            INVESTORS
          </div>
          <div
            class="text-4xl font-bold text-white mb-[16px] lg:w-[480px] max-w-[480px] w-full"
          >
            Make educated decisions in the alternative assets market
          </div>
          <div
            class="text-lg text-gray-200 lg:w-[450px] max-w-[450px] w-full mb-[35px]"
          >
            Access, filter and review alternative investment opportunities.
            Utilize tax-efficient, qualified accounts to build a portfolio of
            alternative assets. Access and review due diligence documentation
            prior to making investment decisions.
          </div>
          <div
            class="w-fit cursor-pointer inline-flex font-medium items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-[#005599] shadow-sm hover:ring-1 ring-gray-200 ring-inset focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
            @click="showHubspotForm = true"
          >
            Learn More
            <ArrowUpRightIcon class="w-4 h-4" />
          </div>
        </div>
        <div class="h-96 w-96">
          <img class="" :src="blockGif" alt="A Block Gif" />
        </div>
      </div>

      <!-- Opera Section -->
      <div
        ref="opera"
        class="max-w-[1000px] w-full flex-wrap rounded-xl overflow-hidden"
      >
        <div class="flex flex-col items-center justify-center gap-5">
          <div class="flex text-4xl font-bold text-center text-gray-900">
            What is OPERA?
          </div>
          <div class="sm:w-[500px] w-full text-center text-gray-900">
            OPERA is a custody and agency brokerage platform built for private
            funds, exempt offerings, and other alternative assets.
          </div>
        </div>
        <div class="bg-[#EBF2F7] rounded-xl overflow-hidden mt-10">
          <div class="overflow-hidden max-h-[550px]">
            <img
              class=""
              :src="friendsLaptops"
              alt="A picture of friends on thier laptops working"
            />
          </div>
          <div
            class="flex flex-col gap-8 px-8 py-8 lg:px-24 lg:py-12 text-gray-900"
          >
            <div>
              Advisors and investors alike can gain access to alternative assets
              that are offered and then held in custody by North Capital Private
              Securities Corporation (“North Capital”) and other approved
              custodians. North Capital is a registered Broker Dealer and a
              Qualified Custodian for qualified accounts such IRAs and ROTH
              IRAs, providing investors with tax-efficient asset location
              options for their allocations to alternative assets.
            </div>
            <div
              class="w-fit cursor-pointer inline-flex font-medium items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-[#005599] shadow-sm hover:ring-1 ring-gray-200 ring-inset focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
              @click="showHubspotForm = true"
            >
              Want to Learn More
              <ArrowUpRightIcon class="w-4 h-4" />
            </div>
          </div>
        </div>
      </div>

      <!-- Issuers Section -->
      <div
        ref="issuers"
        class="flex items-center lg:justify-between justify-center max-w-[1000px] w-full flex-wrap rounded-xl bg-[#EBF9FD] overflow-hidden"
      >
        <div class="p-5 h-96 w-96">
          <img class="rotate-90" :src="gridGif" alt="A grid Gif" />
        </div>
        <div class="flex flex-col px-5 py-16 lg:pr-10">
          <div
            class="bg-[rgba(0,85,153,0.10)] text-[#005599] py-1 px-2 w-fit rounded-md mb-[16px] text-sm font-semibold"
          >
            Issuers
          </div>
          <div
            class="text-4xl font-bold text-gray-900 mb-[16px] lg:w-[450px] max-x-[450px]"
          >
            Gain exposure to your target investors
          </div>
          <div
            class="text-lg lg:w-[530px] max-w-[530px] w-full mb-[35px] text-gray-900"
          >
            Access registered investment advisors focused on alternative
            strategies. Enable online transaction processing to streamline
            subscriptions and payment processing. Seamlessly facilitate
            reporting, distributions and tax documentation to your investor
            clientele.
          </div>
          <div
            class="ring-inset w-fit cursor-pointer inline-flex font-medium items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-[#005599] shadow-sm hover:ring-1 ring-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
            @click="showHubspotForm = true"
          >
            Learn More
            <ArrowUpRightIcon class="w-4 h-4" />
          </div>
        </div>
      </div>

      <!-- Get Started Section -->
      <div
        ref="start"
        class="max-w-[1000px] w-full flex-wrap rounded-xl overflow-hidden"
      >
        <div class="flex flex-col items-center justify-center gap-5">
          <div class="flex text-4xl font-bold text-center text-gray-900">
            Ready to get started?
          </div>
          <div
            class="lg:w-[520px] w-full max-w-[520px] text-center text-gray-900"
          >
            Explore the product further or create an account now. Contact us if
            you would like to see a demo or discuss our services.
          </div>
          <div class="flex flex-wrap items-center gap-5">
            <RouterLink to="/signup?partner=opera" class="button_primary_nc"
              >Get Started</RouterLink
            >
            <div
              class="flex items-center gap-2 cursor-pointer text-[#016cb2] px-3.5 py-1.5 font-medium hover:ring-1 hover:ring-[rgba(1,108,178,0.2)] rounded-md"
              @click="showHubspotForm = true"
            >
              <div>Schedule Demo</div>
              <ArrowUpRightIcon class="w-4 h-4" />
            </div>
          </div>
        </div>
        <div class="bg-[#EBF2F7] rounded-xl overflow-hidden mt-10">
          <div class="overflow-hidden md:h-[500px] h-[300px]">
            <img
              class=""
              :src="advisorWalking"
              alt="A picture of friends on thier laptops working"
            />
          </div>
          <div
            class="flex flex-col gap-8 px-8 py-8 lg:px-24 lg:py-12 text-gray-900"
          >
            <div>
              Investments in alternative assets are speculative and involve
              substantial risks. Consider the risks outlined in the formal
              offering documents, including the Private Placement Memorandum,
              before investing. Risks include, but are not limited to,
              illiquidity, lack of diversification, complete loss of capital,
              default risk, and capital call risks. Investments may not achieve
              their objectives.
            </div>
            <div class="text-[#016cb2] max-w-[450px]">
              Tax efficient accounts may not be available for all investors.
              Subject to income limitations and other restrictions
            </div>
          </div>
        </div>
      </div>

      <!-- Tech Support Section -->
      <div class="max-w-[1000px] w-full flex-wrap rounded-xl overflow-hidden">
        <div
          class="flex items-center justify-center w-full bg-center bg-no-repeat bg-cover sm:justify-start h-96"
          :style="{ backgroundImage: 'url(' + techSupport + ')' }"
        >
          <div
            class="flex flex-col gap-4 p-8 m-5 bg-white text-gray-900 rounded-lg sm:m-0 sm:ml-16 w-80"
          >
            <div class="text-2xl font-bold text-gray-900">
              Technical Support
            </div>
            <div>
              Check out our help pages and knowledge based articles for all
              things Opera
            </div>
            <div
              class="text-[#016cb2] px-3.5 py-1.5 font-medium ring-1 ring-[#016cb2] rounded-md hover:ring-0 hover:bg-[rgba(1,108,178,0.1)] flex gap-2 items-center w-fit cursor-pointer"
              @click="open = true"
            >
              <div>Contact Tech Support</div>
              <ArrowUpRightIcon class="w-4 h-4" />
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="max-w-[1000px] w-full flex-wrap">
        <FooterView @contact="showHubspotForm = true" />
      </div>
    </div>
  </div>
</template>

<script setup>
import FooterView from "@/components/landing/FooterView.vue";
import HubspotForm from "@/components/landing/HubspotForm.vue";
import NotificationPopup from "@/components/applicationui/AppNotification.vue";

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowUpRightIcon,
  LockClosedIcon,
} from "@heroicons/vue/24/outline";
</script>

<script>
import { supabase } from "@/lib/supabaseClient";
export default {
  name: "LandingPage",
  data() {
    return {
      navigation: [
        { name: "Advisors" },
        { name: "Investors" },
        { name: "Opera" },
        { name: "Issuers" },
        { name: "Start" },
      ],
      mobileMenuOpen: false,
      show: false,
      showHubspotForm: false,
      open: false,
      openLearnMore: false,
      email: "",
      supportStr: "",
      showCookieBanner: false,
      // Images
      logoUrl:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/logo.png",
      advisorWalking:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/advisorWalking.png",
      blockGif:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/blockGif",
      blockStatic:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/blockStatic.png",
      friendsLaptops:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/friendsLaptops.png",
      gridGif:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/gridGif",
      gridStatic:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/gridStatic.png",
      logoGif:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/logoGif",
      logoStatic:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/logoStatic.png",
      operaHouse1:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/operaHouse1.png",
      operaHouse2:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/operaHouse2.png",
      pieChartGif:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/pieChartGif",
      pieChartStatic:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/pieChartStatic.png",
      techSupport:
        "https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/techSupport.png",
    };
  },
  mounted() {
    this.getCookieAcceptValue();
  },
  methods: {
    async navigate(link) {
      if (link.name == "Advisors") {
        this.mobileMenuOpen = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.$refs.advisor.scrollIntoView({ behavior: "smooth" });
      } else if (link.name == "Investors") {
        this.mobileMenuOpen = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.$refs.investors.scrollIntoView({ behavior: "smooth" });
      } else if (link.name == "Opera") {
        this.mobileMenuOpen = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.$refs.opera.scrollIntoView({ behavior: "smooth" });
      } else if (link.name == "Issuers") {
        this.mobileMenuOpen = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.$refs.issuers.scrollIntoView({ behavior: "smooth" });
      } else if (link.name == "Start") {
        this.mobileMenuOpen = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.$refs.start.scrollIntoView({ behavior: "smooth" });
      }
    },
    async contactSubmitted() {
      this.showHubspotForm = false;
      this.$refs.notification.show();
    },
    async sendSupportEmail() {
      this.open = false;
      try {
        var body = {
          email: this.email,
          support: this.supportStr,
        };
        const { data, error } = await supabase.functions.invoke(
          "send-support-email",
          { body: body },
        );
        if (error) {
          console.error("Error fetching data:", error);
        } else {
          console.log(data);
        }
      } catch (e) {
        console.error("Error:", e);
      }
    },
    getCookieAcceptValue() {
      const storedValue = localStorage.getItem("operaCookie");
      if (storedValue) this.showCookieBanner = false;
      else this.showCookieBanner = true;
    },
    setCookieAcceptValue() {
      localStorage.setItem("operaCookie", true);
      this.showCookieBanner = false;
      this.openLearnMore = false;
    },
  },
};
</script>

<style></style>
