<template>
  <div class="flex items-center gap-1">
    <div
      v-for="i in splitNumberIntoParts(props.score)"
      :key="i"
      class="single-chart"
    >
      <svg viewBox="0 0 36 36" class="circular-chart stroke-operacolor">
        <!-- Background Ring -->
        <path
          class="circle-bg"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <!-- Ring is full -->
        <path
          v-if="i == 1"
          class="circle"
          stroke-dasharray="100, 100"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <!-- Ring is partial -->
        <path
          v-else-if="i != 0"
          class="circle"
          :stroke-dasharray="i + ', 100'"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <!-- Ring is empty -->
      </svg>
    </div>
  </div>
</template>

<script setup>
// Essentials
// Props
const props = defineProps({
  score: {
    type: String,
    default: "0.00",
  },
});
// Functions
function splitNumberIntoParts(number) {
  number = parseFloat(number);

  const wholeParts = Math.floor(number);
  const fractionalPart = Math.round((number - wholeParts) * 100); // Convert fractional part to an integer

  const result = Array(5).fill(0);

  for (let i = 0; i < 5; i++) {
    if (i < wholeParts) {
      result[i] = 1;
    } else if (i === wholeParts) {
      result[i] = fractionalPart;
    }
  }
  return result;
}
</script>

<style>
.single-chart {
  width: 16px;
  justify-content: space-around;
}

.circular-chart {
  display: block;
  max-width: 100%;
  overflow: visible;
}

.circle-bg {
  fill: none;
  stroke-width: 7;
  @apply stroke-gray-200 dark:stroke-neutral-700;
}

.circle {
  fill: none;
  stroke-width: 7;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}
</style>
