<template>
  <div class="flex flex-col gap-10 mt-4">
    <div class="flex gap-10">
      <ArticleCard :article="article" :disabled="true" />

      <div class="flex flex-col gap-8">
        <!-- Title & Author -->
        <div class="flex flex-wrap w-full gap-10 items-center">
          <!-- Article Title -->
          <div class="w-full sm:w-96 sm:flex-1">
            <label for="title" class="!flex items-center gap-2 input_label">
              Article Title
            </label>
            <div class="input_wrapper">
              <input
                id="title"
                v-model="article.title"
                type="text"
                name="title"
                class="input"
                placeholder="Article Title"
                required
              />
            </div>
          </div>
          <!-- Article Title -->
          <div class="w-full sm:w-96 sm:flex-1 sm:max-w-96">
            <label for="author" class="!flex items-center gap-2 input_label">
              Author
            </label>
            <div class="input_wrapper">
              <input
                id="author"
                v-model="article.author"
                type="text"
                name="author"
                class="input"
                placeholder="Benjamin Franklin"
              />
            </div>
          </div>
        </div>

        <!-- Description -->
        <div class="w-full">
          <label for="description" class="!flex items-center gap-2 input_label"
            >Description</label
          >
          <div class="input_wrapper">
            <textarea
              id="description"
              v-model="article.description"
              name="description"
              class="input"
              placeholder="Description"
              required
            ></textarea>
          </div>
        </div>

        <div class="flex items-center gap-10">
          <!-- Article Preview Title -->
          <div class="flex-shrink">
            <label for="preview" class="!flex items-center gap-2 input_label">
              Preview Image
            </label>
            <div class="flex items-center mt-2 gap-x-3">
              <div v-if="!article.image">
                <PhotoIcon class="w-12 h-12 text-gray-300" aria-hidden="true" />
              </div>
              <img
                v-else
                :src="article.image"
                alt="Preview Image"
                class="w-24 h-12 rounded-lg object-fit"
              />
              <label for="preview" type="button" class="button_secondary"
                >Change</label
              >
              <input
                id="preview"
                name="preview"
                type="file"
                class="sr-only"
                accept="image/*"
                @change="previewImageChanged"
              />
            </div>
          </div>

          <!-- Set Status -->
          <div class="flex-1 max-w-52">
            <div class="select_menu_label">Status</div>
            <SelectMenu
              v-model="article.status"
              :items="['published', 'unpublished']"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Drag & Drop Content Creation -->
    <div
      class="bg-white shadow dark:shadow-none dark:bg-black w-full rounded-xl flex flex-col gap-5"
    >
      <div
        v-if="!article.content || article.content.length <= 0"
        class="self-center my-10 text-center"
      >
        <NewspaperIcon class="w-12 h-12 mx-auto text-gray-400" />
        <h3
          class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300"
        >
          No Content
        </h3>
        <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">
          Get started by adding content
        </p>
        <div class="mt-6">
          <button type="button" class="button_primary" @click="handleHeader">
            Add Header
            <PlusIcon class="w-5 h-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div v-else class="w-full flex flex-col">
        <OptionsButton
          class="m-2 mb-4 self-end"
          @header="handleHeader"
          @image="handleImage"
          @paragraph="handleParagraph"
          @link="handleLink"
        />

        <SlickList
          v-model:list="article.content"
          axis="y"
          use-drag-handle
          class="flex flex-col gap-3 max-h-[600px] min-h-52 resize-y pb-5 overflow-scroll scrollbar-visible"
        >
          <SlickItem
            v-for="(content, i) in article.content"
            :key="content"
            :index="i"
          >
            <div
              class="mx-5 pl-2 pr-4 py-4 bg-white dark:bg-neutral-900 rounded-lg flex gap-1 items-center shadow-opera dark:shadow-none"
            >
              <DragHandle>
                <div class="flex cursor-grab dark:text-neutral-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-grip-vertical"
                  >
                    <circle cx="9" cy="12" r="1" />
                    <circle cx="9" cy="5" r="1" />
                    <circle cx="9" cy="19" r="1" />
                    <circle cx="15" cy="12" r="1" />
                    <circle cx="15" cy="5" r="1" />
                    <circle cx="15" cy="19" r="1" />
                  </svg>
                </div>
              </DragHandle>
              <div class="w-full">
                <!-- Header -->
                <div
                  v-if="content.type === 'header'"
                  class="w-full flex items-center gap-5"
                >
                  <div class="w-full">
                    <label
                      for="header"
                      class="!flex justify-between items-end input_label"
                    >
                      Header
                    </label>
                    <div class="input_wrapper">
                      <input
                        id="header"
                        v-model="content.text"
                        type="text"
                        name="header"
                        class="input"
                        placeholder="Header for  Paragraph"
                        required
                      />
                    </div>
                  </div>
                  <TrashIcon
                    class="w-5 h-5 text-red-600 cursor-pointer"
                    @click="article.content.splice(i, 1)"
                  />
                </div>
                <!-- Paragraph -->
                <div
                  v-else-if="content.type === 'paragraph'"
                  class="w-full flex items-center gap-5"
                >
                  <div class="w-full">
                    <label
                      for="paragraph"
                      class="!flex items-center gap-2 input_label"
                    >
                      Paragraph
                    </label>
                    <div class="input_wrapper">
                      <textarea
                        id="paragraph"
                        v-model="content.text"
                        name="paragraph"
                        rows="5"
                        class="input"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <TrashIcon
                    class="w-5 h-5 text-red-600 cursor-pointer"
                    @click="article.content.splice(i, 1)"
                  />
                </div>
                <!-- Link -->
                <div
                  v-else-if="content.type === 'link'"
                  class="w-full flex items-center gap-5"
                >
                  <div class="max-w-96 min-w-52">
                    <label
                      for="link"
                      class="!flex items-center gap-2 input_label"
                    >
                      Link Label
                    </label>
                    <div class="input_wrapper">
                      <input
                        id="link"
                        v-model="content.label"
                        type="text"
                        name="link"
                        class="input"
                        placeholder="More Information"
                        required
                      />
                    </div>
                  </div>
                  <div class="flex-1">
                    <label
                      for="url"
                      class="!flex items-center gap-2 input_label"
                    >
                      Link URL
                    </label>
                    <div class="input_wrapper">
                      <input
                        id="url"
                        v-model="content.url"
                        type="text"
                        name="url"
                        class="input"
                        placeholder="https://www.example.com/link"
                        required
                      />
                    </div>
                  </div>
                  <TrashIcon
                    class="w-5 h-5 text-red-600 cursor-pointer"
                    @click="article.content.splice(i, 1)"
                  />
                </div>
                <!-- Image -->
                <div
                  v-else-if="content.type === 'image'"
                  class="w-full flex items-center gap-5"
                >
                  <div class="w-full">
                    <label class="!flex items-center gap-2 input_label">
                      Image
                      <InfoCircle
                        info="The smaller this file is the faster it will load when displayed to investors, we recommend a file size of 1 MB or less."
                      />
                    </label>
                    <div class="flex items-center mt-2 gap-x-3">
                      <img
                        v-if="content.url"
                        :src="content.url"
                        alt="Uploaded Image"
                        class="w-24 h-12 rounded-lg object-fit"
                      />
                      <PhotoIcon
                        v-else
                        class="w-12 h-12 text-gray-300"
                        aria-hidden="true"
                      />
                      <label
                        :for="`image-upload-edit-${content.id}`"
                        type="button"
                        class="button_secondary"
                        >Change</label
                      >
                      <input
                        :id="`image-upload-edit-${content.id}`"
                        :name="`image-upload-edit-${content.id}`"
                        type="file"
                        class="sr-only"
                        accept="image/*"
                        @change="handleImageChanged($event, content.id)"
                      />
                    </div>
                  </div>
                  <TrashIcon
                    class="w-5 h-5 text-red-600 cursor-pointer"
                    @click="article.content.splice(i, 1)"
                  />
                </div>
              </div>
            </div>
          </SlickItem>
        </SlickList>
      </div>
    </div>
  </div>
</template>

<script setup>
// Imports
import { v4 as uuidv4 } from "uuid";
import { SlickList, SlickItem, DragHandle } from "vue-slicksort";
import {
  PhotoIcon,
  TrashIcon,
  NewspaperIcon,
  PlusIcon,
} from "@heroicons/vue/24/outline";

// Components
import InfoCircle from "@/components/applicationui/InfoCircle.vue";
import OptionsButton from "@/components/offerings/ContentOptionsMenu.vue";
import ArticleCard from "@/components/articles/ArticleCard.vue";
import SelectMenu from "@/components/applicationinputs/SelectMenu.vue";

// Props
const article = defineModel({ type: Object });

// Preview Image
const previewImageChanged = (event) => {
  article.value.image = URL.createObjectURL(event.target.files[0]);
  article.value.previewFile = event.target.files[0];
};

// Content
const handleHeader = () => {
  article.value.content.push({ id: uuidv4(), type: "header", text: "" });
};
const handleParagraph = () => {
  article.value.content.push({ id: uuidv4(), type: "paragraph", text: "" });
};
const handleLink = () => {
  article.value.content.push({
    id: uuidv4(),
    type: "link",
    label: "",
    url: "",
  });
};
const handleImage = () => {
  article.value.content.push({
    id: uuidv4(),
    type: "image",
    name: "",
    alt: "",
    url: "",
  });
};

// Content Images
const handleImageChanged = (event, id) => {
  const file = event.target.files[0];
  const articleContent = article.value.content.find((c) => c.id === id);
  articleContent.file = file;
  articleContent.url = URL.createObjectURL(file);
  articleContent.name = file.name;
  articleContent.alt = "Article Image";
};
</script>
