<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Popups -->
    <!-- Investor Flow Popup -->
    <AlphaFlow
      v-if="showFlow"
      v-model="showFlow"
      need="create-party-for-self"
      @completed="showFlow = false"
      @closed="showFlow = false"
    />
    <!-- Notifactions -->
    <NotificationPopup
      ref="notification"
      :type="notificationType"
      :title="notificationTitle"
      :text="notificationText"
    />
    <!-- Unlock Advisor Access Popup -->
    <RequestAdvisorAccess
      v-if="showRequestAdvisorAccess"
      v-model="showRequestAdvisorAccess"
      :party_id="userStore.user.party_id"
      :name="`${userStore.user.parties.first_name} ${userStore.user.parties.last_name}`"
    />
    <!-- Popups -->

    <!-- Slideovers -->
    <!-- Party Slideover -->
    <PartySlideover
      v-if="showPartySlideover"
      :show="showPartySlideover"
      :party="userStore.user.parties"
      :partner="partnerStore.partner"
      :for_self="true"
      :avatar_url="userStore.user.avatar"
      @closed="showPartySlideover = false"
      @error="
        notify(
          'failure',
          'Sorry',
          'We could not update your profile. Please try again later or contact Tech Support',
        )
      "
      @upload-failure="
        notify(
          'failure',
          'Sorry',
          'We could not upload your new profile image. Please try again later or contact Tech Support',
        )
      "
      @upload-success="
        notify(
          'success',
          'Avatar Updated',
          'Your profile image has been updated successfully',
        );
        userStore.setUser();
      "
      @success="
        notify(
          'success',
          'Profile Updated',
          'Your profile has been updated successfully',
        );
        userStore.setUser();
      "
    />
    <!-- Slideovers -->

    <div>
      <!-- Loading -->
      <div
        v-if="loading"
        class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center"
      >
        <div class="w-32 h-32 -mt-10">
          <SpinnerFlip />
        </div>
      </div>

      <div v-else class="box-border flex flex-col w-full h-full gap-10">
        <!-- User Avatar, Name, Role -->
        <div class="flex items-center gap-8">
          <UserAvatar
            :avatar="userStore.user.avatar"
            :user_id="userStore.user.id"
            :allow_edit="true"
            :first_name="userStore.user?.parties?.first_name"
            :last_name="userStore.user?.parties?.last_name"
            @failure="
              notify(
                'failure',
                'Sorry',
                'We could not upload your new profile image. Please try again later or contact Tech Support',
              )
            "
            @success="
              notify(
                'success',
                'Avatar Updated',
                'Your profile image has been updated successfully',
              );
              userStore.setUser();
            "
          />
          <div class="flex flex-col gap-3">
            <div class="text-2xl dark:text-neutral-300">
              {{ userStore.user.parties?.first_name }}
              {{ userStore.user.parties?.last_name }}
            </div>
            <div class="w-fit" :class="userStore.user.profiles_protected.role">
              {{
                capitalizeFirstLetter(userStore.user.profiles_protected.role)
              }}
            </div>
          </div>
        </div>

        <!-- Personal Info Section -->
        <div class="flex flex-col flex-wrap gap-8">
          <!-- Get Started -->
          <div
            v-if="!userStore.user?.party_id"
            class="flex gap-2.5 items-center"
          >
            <div class="button_primary w-fit" @click="showFlow = true">
              Add Profile
            </div>
            <InfoCircle
              info="More information is required before you can begin investing"
            />
          </div>

          <!-- Personal Info Widget -->
          <div v-else class="w-fit">
            <div class="mb-2 dark:text-white">Personal Info</div>
            <div
              class="flex flex-wrap justify-between sm:gap-16 gap-8 gap-y-4 p-5 background_secondary rounded-lg shadow-opera dark:shadow-none"
            >
              <div
                class="flex flex-col gap-1 w-fit items-center justify-center"
              >
                <div class="overflow-hidden">
                  <div>
                    {{
                      userStore.user.parties.first_name +
                      " " +
                      userStore.user.parties.last_name
                    }}
                  </div>
                  <div
                    class="text-sm font-medium text-gray-500 dark:text-neutral-500"
                  >
                    {{ userStore.user.email }}
                  </div>
                </div>
              </div>
              <div class="flex flex-col justify-center">
                <div
                  class="button_primary h-fit"
                  @click="showPartySlideover = true"
                >
                  View Profile
                </div>
              </div>
            </div>
          </div>

          <!-- Security Section -->
          <div class="flex flex-col w-fit">
            <div class="mb-2 dark:text-white">Security</div>
            <div
              v-if="securityLoading"
              class="h-[98px] w-[465px] skeleton rounded-lg"
            ></div>
            <div
              v-else
              class="flex flex-wrap flex-col sm:gap-2.5 gap-4 p-5 background_secondary rounded-lg shadow-opera dark:shadow-none"
            >
              <div class="flex items-center flex-wrap gap-2.5 gap-y-1">
                <div class="sm:w-52 w-fit">Last Login:</div>
                <div class="">{{ formatDateWithTime(lastSignIn) }}</div>
              </div>
              <div class="flex items-center flex-wrap gap-2.5">
                <div class="sm:w-52 w-fit">Two Factor Authentication:</div>
                <div class="badge_blue">Enforced</div>
              </div>
            </div>
          </div>

          <!-- Request Advisor Access -->
          <div
            v-if="
              userStore.user.profiles_protected.role == 'investor' &&
              partnerStore.partner.allow_advisors
            "
            class="button_secondary w-fit"
            @click="showRequestAdvisorAccess = true"
          >
            Unlock Advisor Access
            <LockClosedIcon class="w-4 h-4" />
          </div>

          <!-- Themes Dropdown -->
          <div class="w-52">
            <div class="input_label">Theme</div>
            <SelectMenu v-model="selectedTheme" :items="themes" />
          </div>

          <!-- Logout -->
          <div
            class="button_primary w-fit"
            @click="userStore.signOut(partnerStore.partner.meta_name)"
          >
            Sign out
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted, watch } from "vue";
import { supabase } from "@/lib/supabaseClient";

// Components
import PartySlideover from "@/components/investors/PartySlideover.vue";
import AlphaFlow from "@/components/flows/AlphaFlow.vue";
import UserAvatar from "@/components/users/UserAvatar.vue";
import InfoCircle from "@/components/applicationui/InfoCircle.vue";
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import NotificationPopup from "@/components/applicationui/AppNotification.vue";
import SelectMenu from "@/components/applicationinputs/SelectMenu.vue";
import RequestAdvisorAccess from "@/components/custody/RequestAdvisorAccess.vue";

// Libaries
import { capitalizeFirstLetter, formatDateWithTime } from "@/helper/helper";
import { LockClosedIcon } from "@heroicons/vue/24/outline";

// Stores
import { usePartnerStore } from "@/stores/partner";
const partnerStore = usePartnerStore();
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();
// Globals
const showRequestAdvisorAccess = ref(false);
const showPartySlideover = ref(false);
const showFlow = ref(false);
const loading = ref(true);
const securityLoading = ref(true);
const lastSignIn = ref("");
const notification = ref(null);
const notificationType = ref("success");
const notificationText = ref("Success");
const notificationTitle = ref("");
const selectedTheme = ref({ id: 1, label: "Light", value: "light" });
const themes = ref([
  { id: 1, label: "Light", value: "light" },
  { id: 2, label: "Dark", value: "dark" },
  { id: 3, label: "System", value: "system" },
]);
// Mounted
onMounted(async () => {
  // Set theme
  setUserTheme(userStore.user.theme);

  // Set last login
  setLastLoginDate();

  // Set dark mode
  loading.value = false;
});

// Simple Functions
const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};

const setLastLoginDate = async () => {
  lastSignIn.value = await userStore.getLastLoginDate();
  securityLoading.value = false;
};

const setUserTheme = (theme) => {
  if (theme == "system") selectedTheme.value = themes.value[2];
  else if (theme == "dark") selectedTheme.value = themes.value[1];
  else if (theme == "light") selectedTheme.value = themes.value[0];
};

const updateUserTheme = async (theme) => {
  // Set the theme on the site
  if (theme == "dark") {
    document.documentElement.classList.add("dark");
    localStorage.theme = "dark";
  } else if (theme == "light") {
    document.documentElement.classList.remove("dark");
    localStorage.theme = "light";
  } else if (theme == "system") {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.documentElement.classList.add("dark");
      localStorage.theme = "dark";
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.theme = "light";
    }
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", ({ matches }) => {
        if (matches) {
          document.documentElement.classList.add("dark");
          localStorage.theme = "dark";
        } else {
          document.documentElement.classList.remove("dark");
          localStorage.theme = "light";
        }
      });
  }

  // Update the user theme in the database
  const { error } = await supabase
    .from("profiles")
    .update({ theme: theme })
    .eq("id", userStore.user.id);
  if (error) {
    console.log("Failed to update user theme", error);
  }
};

// Watchers
watch(selectedTheme, async (newVal) => {
  updateUserTheme(newVal.value);
});
</script>
