<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
        />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-900 sm:my-8 sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  class="rounded-md text-gray-400 dark:text-neutral-300 hover:text-gray-500 hover:dark:text-neutral-400 outline-none"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Content -->
              <div class="p-5 text-gray-900 dark:text-white">
                <!-- Loading -->
                <div v-if="loading">
                  <div class="flex gap-52">
                    <div
                      class="w-64 rounded-lg shadow-lg h-52 bg-gray-50 skeleton"
                    ></div>
                    <div
                      class="w-64 rounded-lg shadow-lg h-52 bg-gray-50 skeleton"
                    ></div>
                  </div>
                </div>
                <!-- Ready -->
                <div v-else>
                  <form
                    class="flex justify-center gap-5"
                    @submit.prevent="
                      confirmDeposit(
                        props.account.id,
                        props.account.tapi_account_id,
                        amount,
                        partnerStore.partner.id,
                        props.account.name,
                      )
                    "
                  >
                    <div class="flex flex-col w-1/3 gap-5">
                      <div
                        class="rounded-md flex p-5 flex-col gap-2.5 bg-gray-50 dark:bg-neutral-800 shadow-opera w-full"
                      >
                        <div class="text-lg font-medium">From</div>
                        <div class="flex items-center gap-2.5">
                          <div
                            :class="bankLogo"
                            class="w-10 h-10 bg-cover bg-[center_center] bg-no-repeat"
                          ></div>
                          <div class="font-bold">
                            {{ exAccount.AccountNickName }}
                          </div>
                        </div>
                        <div
                          class="pl-2.5 flex flex-col justify-between w-full"
                        >
                          <div class="flex items-center justify-between">
                            Account #:
                            <span class="text-gray-600 dark:text-neutral-400"
                              >*{{ exAccount.AccountNumber }}</span
                            >
                          </div>
                          <div class="flex items-center justify-between">
                            Routing #:
                            <span class="text-gray-600 dark:text-neutral-400">{{
                              exAccount.AccountRoutingNumber
                            }}</span>
                          </div>
                          <div class="flex items-center justify-between">
                            Type:
                            <span class="text-gray-600 dark:text-neutral-400">{{
                              exAccount.accountType
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="flex flex-col items-center self-center w-1/3 gap-5 mt-6"
                    >
                      <!-- Amount -->
                      <div class="w-52">
                        <label
                          for="amount"
                          class="block text-sm font-medium leading-6"
                          >Amount</label
                        >
                        <div class="relative mt-2 rounded-md shadow-sm">
                          <div
                            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                          >
                            <span class="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            id="amount"
                            v-model="amount"
                            type="text"
                            name="amount"
                            required=""
                            class="pr-12 pl-7 input"
                            placeholder="0.00"
                            aria-describedby="price-currency"
                          />
                          <div
                            class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                          >
                            <span
                              class="text-gray-500 sm:text-sm dark:text-neutral-500"
                              >USD</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="flex justify-between w-full px-3">
                        <div
                          class="button_secondary w-fit"
                          @click="closePopup()"
                        >
                          Close
                        </div>
                        <button type="submit" class="button_primary w-fit">
                          Confirm
                          <LoadGifButton v-if="isThinking" />
                        </button>
                      </div>
                    </div>

                    <div class="flex flex-col w-1/3 gap-5">
                      <div
                        class="rounded-md flex p-5 flex-col gap-2.5 bg-gray-50 dark:bg-neutral-800 shadow-opera"
                      >
                        <div class="text-lg font-medium">To</div>
                        <div class="flex items-center gap-2.5">
                          <img
                            :src="partnerStore.getLogo"
                            class="object-cover w-10 h-10"
                          />
                          <div class="font-bold">{{ account.name }}</div>
                        </div>
                        <div class="pl-2.5 flex flex-col">
                          <div class="flex items-center justify-between">
                            Account:
                            <span class="text-gray-600 dark:text-neutral-400">{{
                              account.name
                            }}</span>
                          </div>
                          <div class="flex items-center justify-between">
                            Cash:
                            <span class="text-gray-600 dark:text-neutral-400">{{
                              formatMoney(availableCash)
                            }}</span>
                          </div>
                          <div class="flex items-center justify-between">
                            Type:
                            <span class="text-gray-600 dark:text-neutral-400">{{
                              getAccountType(account.type)
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <!-- Content -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, watch, onMounted } from "vue";
import { usePartnerStore } from "@/stores/partner";
import { useEmailsStore } from "@/stores/emails";
import { useTransactionsStore } from "@/stores/transactions";
// Components
import LoadGifButton from "@/components/loading/LoadGifButton.vue";
// Libraries
import {
  getAccountType,
  formatMoneyString,
  formatMoney,
  getBank,
} from "@/helper/helper";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
// Props
const props = defineProps({
  show: {
    type: Boolean,
    required: true,
    default: false,
  },
  account: {
    type: Object,
    required: true,
    default: null,
  },
  exAccount: {
    type: Object,
    required: true,
    default: null,
  },
});
// Emits
const emit = defineEmits(["closed", "success", "failure", "zero"]);
// Stores
const emailsStore = useEmailsStore();
const partnerStore = usePartnerStore();
const transactionsStore = useTransactionsStore();
// Globals
const open = ref(false);
const availableCash = ref(0);
const amount = ref("");
const bankLogo = ref("default");
const loading = ref(true);
const isThinking = ref(false);
// Mounted
onMounted(async () => {
  open.value = props.show;
  // Set available cash
  await setAvailableCash(props.account.id);

  // Set bank logo
  if (props.exAccount)
    bankLogo.value = getBank(props.exAccount.bankName.toLowerCase());

  // Ready
  loading.value = false;
});

// Functions
async function setAvailableCash(account_id) {
  availableCash.value = await transactionsStore.getCashBalance(account_id);
  if (!availableCash.value) availableCash.value = 0;
}

async function confirmDeposit(
  account_id,
  tapi_account_id,
  depositAmount,
  partner_id,
  name,
) {
  isThinking.value = true;

  // Check if amount is not zero
  if (!depositAmount) {
    amount.value = "";
    open.value = false;
    emit("zero");
    return;
  }

  // Send custody team an email
  const successful = await emailsStore.sendDepositRequestEmail(
    account_id,
    tapi_account_id,
    depositAmount,
    name,
  );
  if (!successful) {
    open.value = false;
    emit("failure");
    return;
  }

  // Create transaction in supabase
  const transaction_id = await transactionsStore.createTransactionInSupabase(
    null,
    account_id,
    null,
    "cash",
    1,
    partner_id,
    parseFloat(depositAmount.replace(/\D/g, "")),
    "deposit",
    "custody",
  );
  if (!transaction_id) {
    open.value = false;
    emit("failure");
    return;
  }

  open.value = false;
  emit("success");

  isThinking.value = false;
}

// Watchers
watch(open, (value) => {
  if (!value) {
    setTimeout(() => {
      emit("closed");
    }, 400);
  }
});
watch(amount, (value) => {
  if (value) {
    amount.value = formatMoneyString(value);
  }
});
</script>

<style scoped>
.default {
  background-image: url("@/assets/icons/bank.svg");
}

.chase {
  background-image: url("@/assets/icons/chase.png");
}

.wellsfargo {
  background-image: url("@/assets/icons/wellsfargo.svg");
}

.boa {
  background-image: url("@/assets/icons/boa.png");
}

.citi {
  background-image: url("@/assets/icons/citi.png");
}

.gs {
  background-image: url("@/assets/icons/gs.png");
}

.truist {
  background-image: url("@/assets/icons/truist.png");
}

.schwab {
  background-image: url("@/assets/icons/schwab.png");
}

.fidelity {
  background-image: url("@/assets/icons/fidelity.png");
}

.cf {
  background-image: url("@/assets/icons/cf.png");
}
</style>
