<template>
  <div class="flex items-end gap-5">
    <div class="w-96">
      <label for="user_group" class="!flex items-center gap-2 input_label"
        >Create User Group
        <InfoCircle
          info="Navigate to the the Users tab to easily add users to one of you custom groups"
        />
      </label>
      <div class="input_wrapper">
        <input
          v-model="new_user_group"
          type="text"
          name="user_group"
          class="input"
          placeholder="ex. Self Accredited"
        />
      </div>
    </div>
    <ButtonPrimary
      text="Create Group"
      size="lg"
      :loading="loading"
      @click="createUserGroup"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";

import InfoCircle from "@/components/applicationui/InfoCircle.vue";
import ButtonPrimary from "@/components/applicationui/AppButtonPrimary.vue";

import { usePartnerStore } from "@/stores/partner";
const partnerStore = usePartnerStore();

const props = defineProps({
  user_groups: { type: Array, required: false, default: null },
  partner_id: { type: String, required: true },
});

const new_user_group = ref("");
const loading = ref(false);

const createUserGroup = async () => {
  if (loading.value) return;
  loading.value = true;

  if (!new_user_group.value) {
    loading.value = false;
    return;
  }
  let new_user_groups = props.user_groups;
  if (!Array.isArray(new_user_groups)) new_user_groups = [];
  if (new_user_groups.includes(new_user_group.value)) {
    loading.value = false;
    return;
  }

  new_user_groups.push(new_user_group.value);
  await partnerStore.updateUserGroups(props.partner_id, new_user_groups);
  new_user_group.value = "";
  loading.value = false;
};
</script>
