<template>
  <div class="px-4 dark:text-neutral-300 sm:px-6 lg:px-8">
    <div class="flex flex-col w-full border-box gap-7">
      <div class="flex flex-col w-full gap-10 border-box">
        <div class="flex flex-col flex-wrap gap-4">
          <div class="text-3xl">Insights & Education</div>
          <div class="pb-4 border-b-2 border-neutral-300 w-fit">
            Keeping you at the forefront of modern investing
          </div>
        </div>

        <div class="flex flex-wrap gap-10">
          <div v-for="article in articles" :key="article.id">
            <ArticleCard :article="article" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";
import { supabase } from "@/lib/supabaseClient";

// Components
import ArticleCard from "@/components/articles/ArticleCard.vue";

// Stores
import { usePartnerStore } from "@/stores/partner";
const partnerStore = usePartnerStore();

// Get Partner Articles
const articles = ref([]);
const getArticles = async () => {
  const { data, error } = await supabase
    .from("articles")
    .select("*")
    .eq("partner_id", partnerStore.partner.id)
    .neq("status", "unpublished")
    .order("created_at", { ascending: false });
  if (error) {
    console.log(error);
    // TODO Show error notification
    return;
  }
  articles.value.push(...data);
};
// Get Opera Articles
const getOperaArticles = async () => {
  const { data, error } = await supabase
    .from("opera_articles")
    .select("*")
    .in("id", partnerStore.partner.opera_articles)
    .order("created_at", { ascending: false });
  if (error) {
    console.log(error);
    // TODO Show error notification
    return;
  }
  articles.value.push(...data);
};

// Mounted
onMounted(async () => {
  await Promise.all([getOperaArticles(), getArticles()]);
});
</script>
