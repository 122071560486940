<template>
  <div class="">
    <input
      id="user_profile_input"
      ref="myFile"
      type="file"
      class="hidden"
      accept="image/png, image/jpeg"
      @change="updateAvatar()"
    />
    <div v-if="props.size == 'xs' && props.avatar">
      <img
        class="object-cover w-8 h-8 rounded-full bg-gray-100"
        :src="
          props.avatar ??
          'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/defaultAvatar.png'
        "
        alt="Avatar"
      />
    </div>
    <div
      v-else-if="props.size == 'xs' && props.first_name && props.last_name"
      class="user_intials_xs"
    >
      <div class="font-semibold text-white text-sm tracking-wide">
        {{ `${props.first_name.charAt(0)}${props.last_name.charAt(0)}` }}
      </div>
    </div>
    <div v-else-if="props.size == 'xs'">
      <img
        class="object-cover w-8 h-8 rounded-full bg-gray-100"
        :src="'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/defaultAvatar.png'"
        alt="Avatar"
      />
    </div>
    <div
      v-else-if="props.avatar"
      :class="[props.allow_edit ? 'cursor-pointer' : 'cursor-default']"
      class="user_profile"
      :style="{ backgroundImage: 'url(' + props.avatar + ')' }"
      @click="openBrowse()"
    >
      <div
        :class="[
          props.allow_edit
            ? 'user_profile_image_edit'
            : 'user_profile_image_not_edit',
        ]"
      >
        Edit
      </div>
    </div>
    <div
      v-else-if="props.first_name && props.last_name"
      :class="[props.allow_edit ? 'cursor-pointer' : 'cursor-default']"
      class="user_intials"
      @click="openBrowse()"
    >
      <div class="font-bold text-white text-4xl tracking-tighter">
        {{ `${props.first_name.charAt(0)} ${props.last_name.charAt(0)}` }}
      </div>
      <div
        :class="[
          props.allow_edit
            ? 'user_profile_image_edit'
            : 'user_profile_image_not_edit',
        ]"
      >
        Edit
      </div>
    </div>
    <div
      v-else
      :class="[props.allow_edit ? 'cursor-pointer' : 'cursor-default']"
      class="user_profile"
      :style="{
        backgroundImage:
          'url(https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/defaultAvatar.png)',
      }"
      @click="openBrowse()"
    >
      <div
        :class="[
          props.allow_edit
            ? 'user_profile_image_edit'
            : 'user_profile_image_not_edit',
        ]"
      >
        Edit
      </div>
    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref } from "vue";
import { supabase } from "@/lib/supabaseClient";
// Props
const props = defineProps({
  avatar: { type: String, default: null },
  user_id: { type: String, default: null },
  first_name: { type: String, required: false, default: null },
  last_name: { type: String, required: false, default: null },
  allow_edit: { type: Boolean, default: false },
  size: { type: String, required: false, default: "md" },
});
// Globals
const myFile = ref(null);
// Emits
const emit = defineEmits(["success", "failure"]);
// Functions
const openBrowse = () => {
  if (!props.allow_edit) return;
  myFile.value.click();
};

const updateAvatar = async () => {
  if (!props.user_id || !myFile.value || myFile.value <= 0) {
    return;
  }
  let file = myFile.value.files[0];
  let filePath = props.user_id + "/profile";
  const { error } = await supabase.storage
    .from("avatars")
    .upload(filePath, file, { cacheControl: "36000", upsert: true });
  if (error) {
    emit("failure");
    console.log(error);
  } else await updateHasAvatar();
};

const updateHasAvatar = async () => {
  const { error } = await supabase
    .from("profiles")
    .update({ has_avatar: true })
    .eq("id", props.user_id);
  if (error) {
    emit("failure");
    console.log(error);
  } else emit("success");
};
</script>

<style scoped>
.user_profile {
  @apply relative z-10 h-24 w-24 bg-no-repeat bg-cover bg-center flex flex-col-reverse items-center overflow-hidden rounded-full;
}
.user_intials {
  @apply relative z-10 h-24 w-24 bg-operacolor flex items-center justify-center overflow-hidden rounded-full;
}
.user_intials_xs {
  @apply relative z-10 h-8 w-8 bg-operacolor flex items-center justify-center overflow-hidden rounded-full;
}

.user_profile_image_edit {
  @apply absolute bottom-0 w-0 h-0 box-border flex items-center justify-center invisible text-[#cbcbcb] text-xs bg-black/60 transition-[height] duration-[0.2s];
}
.user_profile_image_not_edit {
  @apply hidden;
}

.user_profile:hover .user_profile_image_edit {
  @apply w-full h-5 visible;
}

.user_intials:hover .user_profile_image_edit {
  @apply w-full h-5 visible;
}
</style>
