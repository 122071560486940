<template>
  <div>
    <!-- Loading -->
    <div
      v-if="loading"
      class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[90vh] w-[90vw]"
    >
      <div class="h-20 w-20">
        <SpinnerBounce />
      </div>
    </div>

    <!-- Sign -->
    <div
      v-else
      class="flex flex-col items-center justify-center gap-5 p-5 w-[90vw] h-[90vh]"
    >
      <iframe
        :src="docusignSrc"
        class="w-full h-full"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";

// Store
import { useTransactionsStore } from "@/stores/transactions";
const transactionsStore = useTransactionsStore();

// Components
import SpinnerBounce from "@/components/loading/SpinnerBounce.vue";

// Props
const props = defineProps({
  account: { type: Object, required: true, default: null },
  tapi_trade_id: { type: String, required: true, default: null },
  offering: { type: Object, required: true, default: null },
  signing_method: { type: String, required: true, default: "sign-now" },
});

//Emits
const emit = defineEmits(["next"]);

// Globals
const loading = ref(true);
const docusignSrc = ref("");

// Mounted
onMounted(async () => {
  if (props.signing_method == "email") {
    // Send subscription document to accounts email, (we don't need to wait for this to finish)
    transactionsStore.emailSubscriptionDocument(
      props.tapi_trade_id,
      props.account.id,
      props.account.tapi_account_id,
      props.offering.tapi_offering_id,
    );
    emit("next");
    return;
  }
  await getDocusignEnvelope(props.account, props.tapi_trade_id, props.offering);
  loading.value = false;
});

// Functions
async function getDocusignEnvelope(
  { id, tapi_account_id },
  tapi_trade_id,
  { tapi_offering_id },
) {
  // Get docusign envelope
  const data = await transactionsStore.getSubscriptionDocument(
    id,
    tapi_account_id,
    tapi_offering_id,
    tapi_trade_id,
  );
  if (!data) {
    transactionsStore.emailSubscriptionDocument(
      props.tapi_trade_id,
      props.account.id,
      props.account.tapi_account_id,
      props.offering.tapi_offering_id,
    );
    emit("next");
    return;
  }

  // If we have the data, set the docusign src and change page to sign
  docusignSrc.value = data.document_details.url;
  window.addEventListener("message", (event) => {
    if (!event.origin.includes("norcapsecurities.com")) return;
    if (!event) return;
    if (!event.data) return;
    if (event.type != "message") return;
    if (event.data == "signing_complete") {
      emit("next");
    }
  });
}
</script>
