<template>
  <div>
    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center">
      <div class="w-12 h-12">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Content -->
    <div v-else class="flex gap-5 flex-wrap">
      <div class="flex gap-5 flex-wrap flex-1">
        <!-- Contributing Investors -->
        <AppTooltip
          content="This is the total number of individuals who have contributed. The change value is based on a rolling 30 day average."
          class="flex-1"
        >
          <AppStatHighlightCard
            title="Contributing Investors"
            :value="contributing_investors.length.toString()"
            :change="recent_contributing_investors.length.toString()"
            :type="contributing_investors_change_type"
            :icon="UsersIcon"
            class="flex-1"
          />
        </AppTooltip>

        <!-- Contributing Entities -->
        <AppTooltip
          content="This is the total number of entities that have contributed. The change value is based on a rolling 30 day average."
          class="flex-1"
        >
          <AppStatHighlightCard
            title="Contributing Entities"
            :value="contributing_entities.length.toString()"
            :change="recent_contributing_entities.length.toString()"
            :type="contributing_entities_change_type"
            :icon="BuildingOffice2Icon"
            class="flex-1"
          />
        </AppTooltip>
      </div>

      <div class="flex gap-5 flex-wrap flex-1">
        <!-- Total Contributions -->
        <AppTooltip
          content="This is the total dollar amount contributed. The change value is based on a rolling 30 day average."
          class="flex-1"
        >
          <AppStatHighlightCard
            title="Total Contributions"
            :value="total_contributions"
            :change="total_contributions_change"
            :type="total_contributions_change_type"
            :icon="ShieldCheckIcon"
            class="flex-1"
          />
        </AppTooltip>

        <!-- Total Distributions -->
        <AppTooltip
          content="This is the total dollar amount that has been distributed to your contributors. The change value is based on a rolling 30 day average."
          class="flex-1"
        >
          <AppStatHighlightCard
            title="Total Distributions"
            :value="total_distributions"
            :change="total_distributions_change"
            :type="total_distributions_change_type"
            :icon="ReceiptRefundIcon"
            class="flex-1"
          />
        </AppTooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { onMounted, ref } from "vue";

// Stores
import { useTransactionsStore } from "@/stores/transactions";
const transactionsStore = useTransactionsStore();

// Libraries
import { formatMoney } from "@/helper/helper";
import { ShieldCheckIcon, BuildingOffice2Icon, UsersIcon, ReceiptRefundIcon } from "@heroicons/vue/24/solid";

// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import AppTooltip from "@/components/applicationui/AppTooltip.vue";
import AppStatHighlightCard from "@/components/applicationui/AppStatHighlightCard.vue";

// Props
const props = defineProps({
  partner_id: { type: String, required: true },
});

// Get Date 30 days ago START
const pastDate = new Date();
pastDate.setDate(pastDate.getDate() - 30);
const pastDateTimestampFormat = pastDate.toISOString();
// Get Date 30 days ago END

// Set contributing investors START
const contributing_investors = ref([]);
const recent_contributing_investors = ref([]);
const contributing_investors_change_type = ref("no-change");
const setContributingInvestors = async (unique_contributors) => {
  // Set contributing investors
  contributing_investors.value = unique_contributors.filter((contributor) => contributor.accounts.type != "entity");

  // Set contributing investors change from last month
  recent_contributing_investors.value = contributing_investors.value.filter((contributor) => contributor.settled_at > pastDateTimestampFormat);

  // Set contributing investors change type
  if (recent_contributing_investors.value.length > 0) contributing_investors_change_type.value = "increase";
  else contributing_investors_change_type.value = "no-change";
};
// Set contributing investors END

// Set contributing entities START
const contributing_entities = ref([]);
const recent_contributing_entities = ref([]);
const contributing_entities_change_type = ref("no-change");
const setContributingEntities = async (unique_contributors) => {
  // Set contributing entities
  contributing_entities.value = unique_contributors.filter((contributor) => contributor.accounts.type === "entity");

  // Set contributing entities change from last month
  recent_contributing_entities.value = contributing_entities.value.filter((contributor) => contributor.settled_at > pastDateTimestampFormat);

  // Set contributing entities change type
  if (recent_contributing_entities.value.length > 0) contributing_entities_change_type.value = "increase";
};

// Set Total Contributions Start
const total_contributions = ref("$0.00");
const total_contributions_change = ref("$0.00");
const total_contributions_change_type = ref("no-change");
const setTotalContributions = async (all_settled_transactions) => {
  const sum_contributions = all_settled_transactions.reduce((acc, transaction) => {
    if (transaction.type != "distribution") return acc + transaction.amount * transaction.price_per_unit;
    return acc;
  }, 0);
  total_contributions.value = formatMoney(sum_contributions);
  if (sum_contributions > 1000) total_contributions.value = total_contributions.value.slice(0, -3);

  // Set total contributions change value
  const sum_recent_contributions = all_settled_transactions.reduce((acc, transaction) => {
    if (transaction.type != "distribution" && transaction.settled_at > pastDateTimestampFormat)
      return acc + transaction.amount * transaction.price_per_unit;
    return acc;
  }, 0);
  total_contributions_change.value = formatMoney(sum_recent_contributions);
  if (sum_recent_contributions > 1000) total_contributions_change.value = total_contributions_change.value.slice(0, -3);

  // Set total distributions change type
  if (sum_recent_contributions > 0) total_contributions_change_type.value = "increase";
};
// Set Total Contributions END

// Set Total Distributions Start
const total_distributions = ref("$0.00");
const total_distributions_change = ref("$0.00");
const total_distributions_change_type = ref("no-change");
const setTotalDistribution = async (all_settled_transactions) => {
  // Set total distributions
  const sum_distributions = all_settled_transactions.reduce((acc, transaction) => {
    if (transaction.type == "distribution") return acc + transaction.amount * transaction.price_per_unit;
    return acc;
  }, 0);
  total_distributions.value = formatMoney(sum_distributions);
  if (sum_distributions > 1000) total_distributions.value = total_distributions.value.slice(0, -3);

  // Set total distributions change value
  const sum_recent_distributions = all_settled_transactions.reduce((acc, transaction) => {
    if (transaction.type == "distribution" && transaction.settled_at > pastDateTimestampFormat)
      return acc + transaction.amount * transaction.price_per_unit;
    return acc;
  }, 0);
  total_distributions_change.value = formatMoney(sum_recent_distributions);
  if (sum_recent_distributions > 1000) total_distributions_change.value = total_distributions_change.value.slice(0, -3);

  // Set total distributions change type
  if (sum_recent_distributions > 0) total_distributions_change_type.value = "increase";
};
// Set Total Distributions END

// Get all contributors START
const setContributors = async () => {
  const all_settled_transactions = await transactionsStore.getAllSettledTransactionsForPartnerFillAccountData(props.partner_id);

  // Filter out duplicate accounts
  const unique_contributors = Object.values(
    all_settled_transactions.reduce((acc, contributor) => {
      acc[contributor.account_id] = contributor; // Overwrites duplicate IDs, keeping the last occurrence
      return acc;
    }, {}),
  );

  // Set contributing entities
  setContributingEntities(unique_contributors);

  // Set contributing investors
  setContributingInvestors(unique_contributors);

  // Set total contributions
  setTotalContributions(all_settled_transactions);

  // Set total distributions
  setTotalDistribution(all_settled_transactions);
};
// Get all contributors END

// Mounted START
const loading = ref(true);
onMounted(async () => {
  await setContributors();

  // Ready
  loading.value = false;
});
// Mounted END
</script>
