<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <NotificationPopup ref="notification" :type="notificationType" :title="notificationTitle" :text="notificationText" />

    <!-- UpdateTrade Popup -->
    <UpdateTrade
      v-if="openTransactionStatusEditor"
      v-model="transaction"
      :show="openTransactionStatusEditor"
      @closed="openTransactionStatusEditor = false"
      @updated="
        notify('success', 'Success', 'Transaction updated successfully');
        setAllTransactions();
      "
      @error="notify('failure', 'Sorry', 'Something unexpected happened, please try again, or contact tech support')"
    />
    <!-- Popups -->

    <!-- Investor Slideover -->
    <Slideover v-model="showInvestorSlideover" :partner="investor.partners">
      <InvestorSlideover
        v-model="investor"
        :partner="investor.partners"
        @closed="showInvestorSlideover = false"
        @updated="
          notify('success', 'Success', 'Investor updated successfully');
          setAllTransactions();
        "
      />
    </Slideover>

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerBounce />
      </div>
    </div>

    <!-- Transactions Table -->
    <div v-else>
      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
          <!-- Tab Header -->
          <h3 class="primary_header">Transactions</h3>
          <!-- Search -->
          <SearchInput v-model="query" />
        </div>
        <div class="flex">
          <TableOrCard v-model="isTable" />
        </div>
      </div>

      <!-- Transactions Table -->
      <TableSticky
        v-if="isTable"
        v-model="columns"
        :loading="loading_transactions"
        :loading_all="loading_all_transactions"
        :rows="filteredTransactions"
        :is-clickable="clickable"
        class="-mt-8"
        export_title="transactions"
        @click-primary="handleTransactionClicked"
        @click-secondary="handleShowInvestorSlideover"
      />

      <!-- Transaction Card -->
      <div v-else>
        <ul role="list" class="flex gap-10 flex-wrap mt-4">
          <div v-for="t in filteredTransactions" :key="t.id" class="w-96 mt-2" @click="handleTransactionClicked(t)">
            <TransactionCard :transaction="t" :clickable="clickable" />
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from "vue";

// Stores
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();
import { usePartnerStore } from "@/stores/partner";
const partnerStore = usePartnerStore();
import { useTransactionsStore } from "@/stores/transactions";
const transactionsStore = useTransactionsStore();
import { usePartiesStore } from "@/stores/parties";
const partiesStore = usePartiesStore();

// Components
import UpdateTrade from "@/components/transactions/UpdateTrade.vue";
import NotificationPopup from "@/components/applicationui/AppNotification.vue";
import SpinnerBounce from "@/components/loading/SpinnerBounce.vue";
import TableSticky from "@/components/applicationui/tables/AppExportableTable.vue";
import TransactionCard from "@/components/transactions/TransactionCard.vue";
import TableOrCard from "@/components/applicationui/tables/TableOrCard.vue";
import SearchInput from "@/components/applicationinputs/SearchInput.vue";
import InvestorSlideover from "@/components/investors/InvestorSlideover.vue";
import Slideover from "@/components/applicationui/AppSlideover.vue";
// Libraries

// Table Display
const loading = ref(true);
const isTable = ref(true);
if (window.innerWidth < 1024) isTable.value = false;
const loading_transactions = ref(true);
const loading_all_transactions = ref(true);
const clickable = ref(false);
const columns = ref([
  { key: "offerings.name", label: "Offering", type: "bold", visible: true },
  { key: "tapi_trade_id", label: "Trade #", type: "copy", visible: false },
  { key: "status", label: "Status", type: "badge", visible: true },
  { key: "type", label: "Type", type: "badge", visible: true },
  { key: "accounts.name", label: "Account", type: "link", visible: true },
  { key: "transaction_method", label: "Method", type: "badge", visible: true },
  {
    key: "payment_status",
    label: "Payment Status",
    type: "badge",
    visible: true,
  },
  { key: "amount", label: "# of Units", type: "defualt-fill", visible: false },
  { key: "price_per_unit", label: "Price", type: "money", visible: false },
  {
    key: "amount",
    key2: "price_per_unit",
    label: "Total Value",
    type: "multiply",
    visible: true,
  },
  { key: "units", label: "Units", type: "badge", visible: false },
  { key: "updated_at", label: "Last Updated", type: "date", visible: false },
  { key: "created_at", label: "Created Date", type: "date", visible: true },
]);

// Search
const query = ref("");
const filteredTransactions = computed(() => {
  return query.value === ""
    ? transactions.value
    : transactions.value.filter((transaction) => {
        if (transaction?.id?.toLowerCase().includes(query.value.toLowerCase())) return true;
        if (transaction?.offerings?.name?.toLowerCase().includes(query.value.toLowerCase())) return true;
        if (transaction?.status?.toLowerCase().includes(query.value.toLowerCase())) return true;
        if (transaction?.type?.toLowerCase().includes(query.value.toLowerCase())) return true;
        if (transaction?.tapi_trade_id?.includes(query.value)) return true;
        if (transaction?.account_id?.includes(query.value.toLowerCase())) return true;
        if (transaction?.accounts?.name?.toLowerCase().includes(query.value.toLowerCase())) return true;
        if (transaction?.units?.toLowerCase().includes(query.value.toLowerCase())) return true;
      });
});

// Transactions
const transactions = ref([]);
const transaction = ref({});
const openTransactionStatusEditor = ref(false);

async function setAllTransactions() {
  const data = await transactionsStore.getAllTransactionsPaginated(0, 49);
  loading_transactions.value = false;
  if (!data) {
    notify("failure", "Sorry", "Failed to get transactions, please try again later, or contact tech support");
    loading_all_transactions.value = false;
    return;
  }
  transactions.value = data;
  getRestOfTransactions();
}

async function getRestOfTransactions() {
  const data = await transactionsStore.getAllTransactionsPaginated(50);
  if (!data) {
    loading_all_transactions.value = false;
    return;
  }
  transactions.value = transactions.value.concat(data);
  loading_all_transactions.value = false;
}

const handleTransactionClicked = (t) => {
  transaction.value = t;
  if (userStore.user.profiles_protected.role == "super_admin" || partnerStore.partner.partners_protected.trade_status_admin)
    openTransactionStatusEditor.value = true;
};

const investor = ref({});
const showInvestorSlideover = ref(false);
const handleShowInvestorSlideover = async (t) => {
  investor.value = await partiesStore.getInvestorObject(t.accounts?.parties?.id);
  if (!investor.value) {
    notify("failure", "Sorry", "We were unable to get your investor, please try again later, or contact tech support");
    return;
  }
  showInvestorSlideover.value = true;
};

// Payment Status Column
import { useOfferingsStore } from "@/stores/offerings";
const offeringsStore = useOfferingsStore();
const makePaymentStatusColumnVisibleIfNeeded = async () => {
  if (userStore.user.profiles_protected.role == "super_admin") return;
  const offerings = await offeringsStore.getAllOfferingsForPartnerPaginated(userStore.user.profiles_protected.partner_id);
  if (!offerings || offerings.length < 1) return;

  let hasNoNeedForPaymentStatusColumn = true;
  for (const offering of offerings) {
    if (offering.accept_ach || offering.accept_cc) {
      hasNoNeedForPaymentStatusColumn = false;
      break;
    }
  }

  if (hasNoNeedForPaymentStatusColumn) {
    const paymentStatusColumnIndex = columns.value.findIndex(column => column.key === "payment_status");
    columns.value.splice(paymentStatusColumnIndex, 1, {
      key: "payment_status",
      label: "Payment Status",
      type: "badge",
      visible: false,
    });
  }
};

// Notifications
const notification = ref(null);
const notificationType = ref("success");
const notificationTitle = ref("Success");
const notificationText = ref("Transaction updated successfully");

const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};

// Mounted
onMounted(async () => {
  // Setup
  if (userStore.user.profiles_protected.role == "super_admin" || partnerStore.partner.partners_protected.trade_status_admin) clickable.value = true;
  if (userStore.user.profiles_protected.role == "super_admin")
    columns.value.splice(columns.value.length - 2, 0, {
      key: "partners",
      label: "Partner",
      type: "partner-logo",
      visible: true,
    });
  // Get Transactions
  await setAllTransactions();

  await makePaymentStatusColumnVisibleIfNeeded();
  // Ready
  loading.value = false;
});
</script>
