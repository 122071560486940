<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 dark:bg-neutral-500 dark:bg-opacity-25 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col overflow-y-scroll bg-gray-50 dark:bg-neutral-950 py-6 shadow-xl">
                  <!-- Header -->
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-base font-semibold leading-6 text-gray-900 dark:text-neutral-100">
                        <h3 class="text-xl font-bold text-gray-900 sm:text-2xl dark:text-white">Transaction Details</h3>
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          class="relative rounded-md text-gray-400 hover:text-gray-500 dark:text-neutral-400 dark:hover:text-neutral-300 focus:outline-none"
                          @click="open = false"
                        >
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Header -->

                  <!-- Loading -->
                  <div v-if="loading || !props.transaction" class="flex flex-grow items-center justify-center h-[70vh]">
                    <div class="w-32 h-32 -mt-20">
                      <SpinnerFlip />
                    </div>
                  </div>

                  <!-- Content -->
                  <div v-else>
                    <!-- Banner Image -->
                    <div class="relative h-40 mt-5">
                      <img class="absolute h-full w-full object-cover" :src="offering_banner_url" alt="" />
                    </div>

                    <!-- Logo Image -->
                    <div class="relative ml-16 -mt-10 z-10">
                      <img class="w-auto z-10 rounded-full h-20 shadow-md ring-1 ring-gray-500 sentry-unmask" :src="offering_logo_url" />
                    </div>

                    <!-- Offering Details -->
                    <div class="relative mt-6 flex-1 flex flex-col px-4 sm:px-6">
                      <div class="flex gap-5 justify-between">
                        <div class="flex flex-col gap-3">
                          <!-- Offering Name -->
                          <div class="font-semibold dark:text-white">
                            {{ props.transaction.offerings.name }}
                          </div>
                          <!-- Offering Slogan -->
                          <div class="text-sm text-gray-600 dark:text-neutral-300">
                            {{ props.transaction.offerings.slogan }}
                          </div>
                        </div>

                        <div class="w-40 mt-7">
                          <div class="badge_gray w-fit">
                            {{ props.transaction.offerings.market_sector }}
                          </div>
                        </div>
                      </div>

                      <div v-if="props.transaction.offerings.key_values" class="mt-5">
                        <div class="bg-white dark:bg-neutral-900 shadow dark:shadow-none !rounded-xl w-full">
                          <dl class="divide-y divide-gray-100 dark:divide-neutral-800">
                            <div v-for="key_value in props.transaction.offerings.key_values" :key="key_value">
                              <!-- Dynamic Key Values -->
                              <div v-if="key_value.type == 'curated'" class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <!-- Curated Cases -->
                                <dt
                                  v-if="key_value.id == 'start-date' && props.transaction.offerings.offering_type == 'Fund'"
                                  class="tertiary_subheader dark:text-white"
                                >
                                  Inception Date
                                </dt>
                                <dt v-else class="tertiary_subheader dark:text-white">
                                  {{ key_value.name }}
                                </dt>

                                <dd
                                  v-if="key_value.id == 'security-type'"
                                  :class="[getBadgeClass(props.transaction.offerings.offering_type), 'w-fit mt-1 text sm:col-span-2 sm:mt-0']"
                                >
                                  {{ capitalizeFirstLetter(props.transaction.offerings.offering_type) }}
                                </dd>
                                <dd v-else-if="key_value.id == 'minimum-investment'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">
                                  {{ formatMoney(props.transaction.offerings?.minimum) }}
                                </dd>
                                <dd v-else-if="key_value.id == 'maximum-investment'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">
                                  {{ formatMoney(props.transaction.offerings?.maximum) }}
                                </dd>
                                <dd v-else-if="key_value.id == 'raise-goal'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">
                                  {{ formatMoney(props.transaction.offerings?.target_amount) }}
                                </dd>
                                <dd v-else-if="key_value.id == 'unit-price'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">
                                  {{ formatMoney(props.transaction.offerings?.unit_price) }}
                                </dd>
                                <dd v-else-if="key_value.id == 'pending-amount'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">
                                  {{ formatMoney(offering_details?.pendingAmount) ?? "Loading..." }}
                                </dd>
                                <dd v-else-if="key_value.id == 'funded-amount'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">
                                  {{ formatMoney(offering_details?.fundedAmount) ?? "Loading..." }}
                                </dd>
                                <dd v-else-if="key_value.id == 'start-date'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">
                                  {{ formatDate(props.transaction.offerings?.start_date) }}
                                </dd>
                                <dd v-else-if="key_value.id == 'end-date'" class="w-fit mt-1 text sm:col-span-2 sm:mt-0">
                                  {{ formatDate(props.transaction.offerings?.end_date) }}
                                </dd>
                                <dd v-else class="w-fit mt-1 text sm:col-span-2 sm:mt-0">Not Available</dd>
                              </div>
                              <!-- Normal Cases -->
                              <div v-else class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="tertiary_subheader dark:text-white">
                                  {{ key_value.name }}
                                </dt>
                                <dd
                                  :class="[
                                    key_value.type.includes('badge') ? getBadgeClass(key_value.type) + ' w-fit' : 'mt-1 text sm:col-span-2 sm:mt-0',
                                  ]"
                                >
                                  {{ key_value.value }}
                                </dd>
                              </div>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>

                    <!-- Check Instructions -->
                    <div v-if="props.transaction.status == 'pending' && props.transaction.transaction_method == 'check'" class="px-4 sm:px-6 mt-5">
                      <div class="flex flex-col justify-center h-fit w-full">
                        <div class="text-gray-900 dark:text-neutral-200 font-semibold mb-4">Check instructions</div>
                        <div
                          class="relative flex flex-col gap-5 flex-wrap p-5 bg-white dark:bg-neutral-900 rounded-lg shadow-opera dark:shadow-none dark:ring-1 dark:ring-neutral-800"
                        >
                          <!-- Copy Button -->
                          <div class="absolute w-full flex justify-end items-start top-1 right-1">
                            <div class="button_secondary" @click="copyToClipboard(check_instructions)">
                              Copy
                              <CheckIcon v-if="copied" class="w-4 h-4 text-green-600" />
                              <ClipboardIcon v-else class="w-4 h-4" />
                            </div>
                          </div>

                          <!-- Fedex or UPS instructions -->
                          <div>
                            <dt class="text-sm font-medium text_color_primary">Fedex or UPS instructions</dt>
                            <dd class="mt-1 text-sm text_color_tertiary">
                              {{ props.transaction.offerings.fedex_instructions }}
                            </dd>
                          </div>

                          <!-- Regular mail instructions -->
                          <div>
                            <dt class="text-sm font-medium text_color_primary">Regular mail instructions</dt>
                            <dd class="mt-1 text-sm text_color_tertiary">
                              {{ props.transaction.offerings.mail_instructions }}
                            </dd>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Wire Instructions -->
                    <div
                      v-else-if="props.transaction.status == 'pending' && props.transaction.transaction_method == 'wire'"
                      class="px-4 sm:px-6 mt-5"
                    >
                      <div class="flex flex-col justify-center h-fit w-full">
                        <div class="text-gray-900 dark:text-neutral-200 font-semibold mb-4">Wire instructions</div>
                        <div
                          class="relative flex flex-col gap-5 flex-wrap p-5 bg-white dark:bg-neutral-900 rounded-lg shadow-opera dark:shadow-none dark:ring-1 dark:ring-neutral-800"
                        >
                          <!-- Copy Button -->
                          <div class="absolute w-full flex justify-end items-start top-1 right-1">
                            <div class="button_secondary" @click="copyToClipboard(wire_instructions)">
                              Copy
                              <CheckIcon v-if="copied" class="w-4 h-4 text-green-600" />
                              <ClipboardIcon v-else class="w-4 h-4" />
                            </div>
                          </div>

                          <div class="flex gap-10 items-center flex-wrap">
                            <!-- Account Number -->
                            <div>
                              <dt class="text-sm font-medium text_color_primary">Account Number</dt>
                              <dd class="mt-1 text-sm text_color_tertiary">
                                {{ props.transaction.offerings?.account_number_instructions }}
                              </dd>
                            </div>

                            <!-- Routing Number -->
                            <div>
                              <dt class="text-sm font-medium text_color_primary">Routing Number</dt>
                              <dd class="mt-1 text-sm text_color_tertiary">
                                {{ props.transaction.offerings?.routing_number_instructions }}
                              </dd>
                            </div>
                          </div>

                          <div class="flex gap-10 items-center flex-wrap">
                            <!-- Receiving Bank -->
                            <div>
                              <dt class="text-sm font-medium text_color_primary">Receiving Bank</dt>
                              <dd class="mt-1 text-sm text_color_tertiary">
                                {{ props.transaction.offerings?.bank_instructions }}
                              </dd>
                            </div>

                            <!-- Reference -->
                            <div>
                              <dt class="text-sm font-medium text_color_primary">Reference</dt>
                              <dd class="mt-1 text-sm text_color_tertiary">
                                {{ props.transaction.offerings?.reference_instructions }}
                              </dd>
                            </div>
                          </div>

                          <!-- Address -->
                          <div>
                            <dt class="text-sm font-medium text_color_primary">Address</dt>
                            <dd class="mt-1 text-sm text_color_tertiary">
                              {{ props.transaction.offerings?.address_instructions }}
                            </dd>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Transactions Progress -->
                    <div v-if="isActiveTransaction" class="px-4 sm:px-6 mt-5">
                      <TransactionStatusProgress :steps="progress_steps" />
                    </div>

                    <!-- Transaction Details -->
                    <div class="relative mt-6 flex-1 flex flex-col px-4 sm:px-6">
                      <dl
                        class="space-y-6 pb-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500 dark:text-neutral-400 dark:border-neutral-700"
                      >
                        <div class="flex justify-between">
                          <dt>Transaction #</dt>
                          <dd class="text-gray-900 dark:text-neutral-100 flex gap-x-1 items-center">
                            <span class="max-w-24 truncate">{{ props.transaction.tapi_trade_id ?? props.transaction.id }}</span>
                            <AppCopy :text="props.transaction.tapi_trade_id ?? props.transaction.id" class="mb-0.5" />
                          </dd>
                        </div>

                        <div class="flex justify-between">
                          <dt>Transaction Type</dt>
                          <dd class="text-gray-900 dark:text-neutral-100 capitalize">
                            {{ props.transaction.type }}
                          </dd>
                        </div>

                        <div
                          v-if="props.transaction.type == 'buy' || props.transaction.type == 'distribution'"
                          class="flex justify-between items-center"
                        >
                          <dt v-if="props.transaction.transaction_method == 'distribution'">Distribution Method</dt>
                          <dt v-else>Purchase Method</dt>
                          <dd
                            v-if="props.transaction.transaction_method == 'distribution' && props.transaction.units != 'shares'"
                            class="text-gray-900 dark:text-neutral-100"
                          >
                            Cash
                          </dd>
                          <dd
                            v-if="props.transaction.transaction_method == 'distribution' && props.transaction.units == 'shares'"
                            class="text-gray-900 dark:text-neutral-100"
                          >
                            Shares
                          </dd>
                          <dt v-else-if="props.transaction.transaction_method == 'check'" class="badge_gray">
                            Check
                            <TicketIcon class="size-5 text-teal-500 ml-1" />
                          </dt>
                          <dt v-else-if="props.transaction.transaction_method == 'creditcard'" class="badge_gray">
                            Credit Card
                            <CreditCardIcon class="size-5 text-indigo-500 ml-1" />
                          </dt>
                          <dt v-else-if="props.transaction.transaction_method == 'ach'" class="badge_gray">
                            ACH
                            <BuildingLibraryIcon class="size-5 text-purple-500 ml-1" />
                          </dt>
                          <dt v-else-if="props.transaction.transaction_method == 'wire'" class="badge_gray">
                            Wire
                            <ChevronDoubleRightIcon class="size-5 text-pink-500 ml-1" />
                          </dt>
                          <dt v-else-if="props.transaction.transaction_method == 'custody'" class="badge_gray">
                            Transfer
                            <ArrowsRightLeftIcon class="size-5 text-pink-500 ml-1" />
                          </dt>
                          <dt v-else-if="props.transaction.transaction_method == 'tbd'" class="badge_gray">
                            Transfer
                            <ClockIcon class="size-5 text-pink-yellow ml-1" />
                          </dt>
                        </div>

                        <div class="flex justify-between items-center">
                          <dt class="capitalize">Transaction Status</dt>
                          <dd :class="getBadgeClass(props.transaction.status)">
                            {{ capitalizeFirstLetter(props.transaction.status) }}
                          </dd>
                        </div>

                        <div v-if="props.transaction.payment_status" class="flex justify-between items-center">
                          <dt class="capitalize">Payment Status</dt>
                          <dd :class="getBadgeClass(props.transaction.payment_status)">
                            {{ capitalizeFirstLetter(props.transaction.payment_status) }}
                          </dd>
                        </div>

                        <div class="flex justify-between">
                          <dt class="capitalize">Created</dt>
                          <dd class="text-gray-900 dark:text-neutral-100">
                            {{ formatDate(props.transaction.created_at) }}
                          </dd>
                        </div>

                        <div v-if="props.transaction.status == 'settled'" class="flex justify-between">
                          <dt class="capitalize">Settled</dt>
                          <dd class="text-gray-900 dark:text-neutral-100">
                            {{ formatDate(props.transaction.settled_at) }}
                          </dd>
                        </div>

                        <div v-if="props.transaction.status == 'refunded'" class="flex justify-between">
                          <dt class="capitalize">Refunded</dt>
                          <dd class="text-gray-900 dark:text-neutral-100">
                            {{ formatDate(props.transaction.refunded_at) }}
                          </dd>
                        </div>

                        <div class="flex justify-between">
                          <dt class="capitalize">Last Updated</dt>
                          <dd class="text-gray-900 dark:text-neutral-100">
                            {{ formatDate(props.transaction.updated_at) }}
                          </dd>
                        </div>
                      </dl>
                      <dl
                        class="space-y-6 border-t border-gray-200 border-dashed pt-6 text-sm font-medium text-gray-500 dark:text-neutral-400 dark:border-neutral-700"
                      >
                        <div class="flex justify-between">
                          <dt>Units</dt>
                          <dd class="text-gray-900 dark:text-neutral-100 capitalize">
                            {{ props.transaction.units }}
                          </dd>
                        </div>

                        <div class="flex justify-between">
                          <dt class="capitalize">
                            {{ `Total ${props.transaction.units}` }}
                          </dt>
                          <dd class="text-gray-900 dark:text-neutral-100">
                            {{ props.transaction.amount }}
                          </dd>
                        </div>

                        <div class="flex justify-between">
                          <dt class="capitalize">
                            {{ `Price Per ${props.transaction.units.slice(0, -1)}` }}
                          </dt>
                          <dd class="text-gray-900 dark:text-neutral-100">
                            {{ formatMoney(props.transaction.price_per_unit) }}
                            USD
                          </dd>
                        </div>

                        <div class="flex items-center justify-between border-t border-gray-200 pt-6 dark:text-neutral-100">
                          <dt class="text-base">Total</dt>
                          <dd class="text-base">
                            {{ formatMoney(props.transaction.amount * props.transaction.price_per_unit) }}
                            USD
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, watch, computed } from "vue";

// Stores
import { useOfferingsStore } from "@/stores/offerings";
const offeringsStore = useOfferingsStore();

// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import AppCopy from "@/components/applicationui/AppCopy.vue";
import TransactionStatusProgress from "@/components/transactions/TransactionStatusProgress.vue";

// Libraries
import { formatMoney, formatDate, getBadgeClass, capitalizeFirstLetter } from "@/helper/helper";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { TicketIcon, CreditCardIcon, ClockIcon } from "@heroicons/vue/24/solid";
import { BuildingLibraryIcon, ChevronDoubleRightIcon, ArrowsRightLeftIcon, ClipboardIcon, CheckIcon } from "@heroicons/vue/24/outline";

// Model
const open = defineModel({ type: Boolean });

// Props
const props = defineProps({
  transaction: { type: Object, required: false, default: null },
});

// Progress Steps Start
const isActiveTransaction = computed(() => {
  if (!props.transaction) return false;
  return ["pending", "refund_pending", "funded"].includes(props.transaction.status);
});
const progress_steps = computed(() => {
  if (!props.transaction) return [];
  if (!isActiveTransaction.value) return [];

  if (props.transaction.transaction_method == "wire") {
    // Wire
    if (props.transaction.payment_status == "funded" || props.transaction.status == "funded") {
      // Wire Funded
      return [
        { id: "1", name: "Transaction Created", status: "complete" },
        { id: "2", name: "Wire Sent", status: "complete" },
        { id: "3", name: "Wire Recieved", status: "complete" },
        { id: "4", name: "Transaction Settled", status: "current" },
      ];
    } else {
      // Wire Pending
      return [
        { id: "1", name: "Transaction Created", status: "complete" },
        { id: "2", name: "Wire Funds", status: "current" },
        { id: "3", name: "Funds Recieved", status: "upcoming" },
        { id: "4", name: "Transaction Settled", status: "upcoming" },
      ];
    }
  }
  if (props.transaction.transaction_method == "check") {
    // Check
    if (props.transaction.payment_status == "funded" || props.transaction.status == "funded") {
      // Check Funded
      return [
        { id: "1", name: "Transaction Created", status: "complete" },
        { id: "2", name: "Mail Check", status: "complete" },
        { id: "3", name: "Check Recieved", status: "complete" },
        { id: "4", name: "Transaction Settled", status: "current" },
      ];
    } else {
      // Check Pending
      return [
        { id: "1", name: "Transaction Created", status: "complete" },
        { id: "2", name: "Mail Check", status: "current" },
        { id: "3", name: "Check Recieved", status: "upcoming" },
        { id: "4", name: "Transaction Settled", status: "upcoming" },
      ];
    }
  }

  if (props.transaction.payment_status == "funded" || props.transaction.status == "funded") {
    // CC or ACH Funded
    return [
      { id: "1", name: "Transaction Created", status: "complete" },
      { id: "2", name: "Funds Sent", status: "complete" },
      { id: "3", name: "Funds Recieved", status: "complete" },
      { id: "4", name: "Transaction Settled", status: "current" },
    ];
  }
  // CC or ACH Pending
  return [
    { id: "1", name: "Transaction Created", status: "complete" },
    { id: "2", name: "Funds Sent", status: "complete" },
    { id: "3", name: "Funds Recieved", status: "current" },
    { id: "4", name: "Transaction Settled", status: "upcoming" },
  ];
});

// Instructions START
const wire_instructions = computed(() => {
  if (!props.transaction.offerings) return;
  return `Account Number: ${props.transaction?.offerings?.account_number_instructions ?? ""}\nRouting Number: ${props.transaction?.offerings?.routing_number_instructions ?? ""}\nBank: ${props.transaction?.offerings?.bank_instructions ?? ""}\nReference: ${props.transaction?.offerings?.reference_instructions ?? ""}\nAddress: ${props.transaction?.offerings?.address_instructions ?? ""}`;
});

const check_instructions = computed(() => {
  if (!props.transaction.offerings) return;
  return `Fedex or UPS instructions: ${props.transaction?.offerings?.fedex_instructions ?? ""}\nRegular mail instructions: ${props.transaction?.offerings?.mail_instructions ?? ""}`;
});
// Instructions END

const copied = ref(false);
const copyToClipboard = async (text) => {
  copied.value = true;
  navigator.clipboard.writeText(text);
  await new Promise((resolve) => setTimeout(resolve, 1000));
  copied.value = false;
  copied.value = false;
};

// Offering Images START
const offering_logo_url = ref(null);
const offering_banner_url = ref(null);
const setOfferingImages = async () => {
  if (!props.transaction.offerings || !props.transaction.offerings.id) return;
  // Get Logo
  offering_logo_url.value = await offeringsStore.getOfferingFile(props.transaction.offering_id, props.transaction.offerings.tapi_offering_id, "logo");
  // Get Banner
  offering_banner_url.value = await offeringsStore.getOfferingFile(
    props.transaction.offering_id,
    props.transaction.offerings.tapi_offering_id,
    "banner",
  );
};
// Offering Images END

// Get Offering Details START
const loading_offering_details = ref(false);
const offering_details = ref(null);
async function setOfferingDetails() {
  if (!props.transaction.offerings.tapi_offering_id) return;
  loading_offering_details.value = true;
  offering_details.value = await offeringsStore.getOfferingDetails(props.transaction.offerings.tapi_offering_id);
  loading_offering_details.value = false;
}
// Get Offering Details END

// Setup
const loading = ref(true);
const setup = async () => {
  if (!props.transaction) return;

  // Get the transaction's offering images if they exist
  await setOfferingImages();

  // Get the offering details if they exist
  setOfferingDetails();

  // Ready
  loading.value = false;
};

watch(open, (newVal) => {
  if (newVal) setup();
});
</script>
