<template>
  <div class="shadow ring-1 ring-black ring-opacity-5 background relative">
    <!-- Popups -->
    <NotificationPopup
      ref="notification"
      :type="notificationType"
      :title="notificationTitle"
      :text="notificationText"
    />
    <!-- Popups -->

    <div
      class="text-base font-semibold leading-6 text_color_primary p-4 border-gray-300 border-b-[1px] dark:border-b-[1px] dark:border-neutral-700 bg-gray-100 dark:bg-neutral-800 rounded-t-lg"
    >
      <!-- Header -->
      <div class="flex items-center justify-between flex-wrap gap-y-3">
        <span class="flex items-center flex-wrap gap-2">
          <div>Entity Documents</div>
        </span>

        <!-- Upload Button -->
        <label for="entity-doc" type="button" class="button_primary">
          Upload
          <LoadGifButton v-if="uploading == true" />
          <CloudArrowUpIcon v-else class="size-5" />
        </label>

        <!-- Hidden input field -->
        <input
          id="entity-doc"
          name="entity-doc"
          type="file"
          class="sr-only"
          accept="*"
          @change="
            handleEntityUpload(
              $event,
              props.user_id,
              props.party?.user_id,
              props.party?.id,
            )
          "
        />
      </div>
    </div>

    <!-- Loading -->
    <div
      v-if="loading"
      class="h-20 rounded-b-lg w-full flex justify-center items-center dark:bg-neutral-900"
    >
      <div class="w-10 h-10">
        <SpinnerBounce />
      </div>
    </div>

    <!-- Documents list -->
    <ul
      v-else-if="documents.length > 0"
      role="list"
      class="divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900 max-h-72 overflow-y-scroll rounded-b-lg bg-white"
    >
      <li
        v-for="doc in documents"
        :key="doc.id"
        class="flex justify-between sm:gap-x-6 py-3 mx-3 first:pt-3 last:pb-3"
      >
        <div class="flex min-w-0 gap-x-4 items-center">
          <div
            class="bg-white ring-1 ring-gray-300 p-1.5 rounded-lg text-xs dark:bg-neutral-800 dark:ring-neutral-700"
          >
            <DocumentTextIcon class="text-operacolor w-5 h-5" />
          </div>
          <div class="min-w-0 flex items-center w-40">
            <p
              class="text-sm font-semibold leading-6 text-gray-900 dark:text-neutral-300 truncate"
            >
              {{ doc.name }}
            </p>
          </div>
        </div>
        <div class="shrink-0 flex flex-col items-start justify-center w-40">
          <div class="text-sm text-gray-500 dark:text-neutral-400">
            {{ formatDate(doc.created_at) }}
          </div>
        </div>
        <div class="flex gap-5 items-center">
          <!-- Download Button -->
          <div
            class="dark:text-[rgb(102,183,237)] text-[rgba(1,108,178)] px-3.5 py-1.5 rounded-md bg-[rgba(1,108,178,0.1)] flex items-center gap-2 cursor-pointer hover:bg-[rgba(1,108,178)] hover:text-white transition ease-in-out duration-700"
            @click="
              downloadFile(
                `${doc.userFolder}/entity/${doc.partyFolder}/${doc.name}`,
              )
            "
          >
            Download
            <ArrowDownTrayIcon class="w-4 h-4" />
          </div>

          <!-- Archive Icon Button -->
          <div class="mr-2">
            <TrashIcon
              class="w-6 h-6 text-neutral-900 dark:text-neutral-400 hover:text-red-600 dark:hover:text-red-600 transition ease-in-out duration-500 cursor-pointer"
              @click="
                archiveFile(
                  doc,
                  props.party?.id,
                  props.party?.user_id,
                  props.user_id,
                  props.party?.tapi_party_id,
                )
              "
            />
          </div>
        </div>
      </li>
    </ul>
    <div
      v-else
      class="p-4 flex justify-center secondary_header rounded-b-lg dark:bg-neutral-900"
    >
      No entity documents
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";
import { usePartiesStore } from "@/stores/parties";
// Components
import LoadGifButton from "@/components/loading/LoadGifButton.vue";
import NotificationPopup from "@/components/applicationui/AppNotification.vue";
// Libraries
import { formatDate } from "@/helper/helper";
import {
  ArrowDownTrayIcon,
  TrashIcon,
  CloudArrowUpIcon,
} from "@heroicons/vue/24/outline";
import { DocumentTextIcon } from "@heroicons/vue/24/solid";
import SpinnerBounce from "@/components/loading/SpinnerBounce.vue";
// Props
const props = defineProps({
  party: { type: Object, required: true, default: null }, //Expects a party object
  user_id: { type: String, required: true, default: null }, //Expects the current user id
});
// Emits
// Stores
const partiesStore = usePartiesStore();
// Globals
const loading = ref(true);
const uploading = ref(false);

const notification = ref(null);
const notificationType = ref("success");
const notificationTitle = ref("File Uploaded");
const notificationText = ref(
  "Your file was uploaded successfully, refresh this page to access the new file",
);

const documents = ref([]);

// Mounted
onMounted(async () => {
  // Set documents array
  await setEntityDocuments(
    props.party?.user_id,
    props?.user_id,
    props.party?.id,
  );
  // Ready
  loading.value = false;
});

// Functions
async function setEntityDocuments(party_user_id, current_user_id, party_id) {
  // Set default values
  let docs = [];
  let docs2 = [];
  // Get all accredited documents
  docs = await partiesStore.getEntityDocuments(current_user_id, party_id);
  if (party_user_id != current_user_id)
    docs2 = await partiesStore.getEntityDocuments(party_user_id, party_id);
  if (!docs && !docs2) {
    notify(
      "failure",
      "Sorry",
      "We were unable to load your files. Please try again later, if the issue persists please contact tech support",
    );
    return;
  }
  // Set documents
  documents.value = docs.concat(docs2);
}

async function handleEntityUpload(
  event,
  current_user_id,
  party_user_id,
  party_id,
) {
  console.log("Uploading file...");
  uploading.value = true;
  const newFile = event.target.files[0];

  // Upload the file to Supabase
  const successful = await partiesStore.uploadEntityDocumentToSupabase(
    current_user_id,
    party_id,
    newFile,
  );
  // Set loading to false
  uploading.value = false;
  // If unsuccessful notify the user
  if (!successful) {
    notify(
      "failure",
      "Sorry",
      "We were unable to upload your file. Please try again later, if the issue persists please contact tech support",
    );
    return;
  }
  // Notify the user of the successful upload
  notify(
    "success",
    "File Uploaded",
    "Your file was uploaded successfully, you may need to refresh this page to view the new file.",
  );
  // Reset all files (to include newly uploaded file)
  setEntityDocuments(party_user_id, current_user_id, party_id);
}

async function downloadFile(filePath) {
  const url = await partiesStore.downloadInvestorFile(filePath);
  window.open(url, "_blank");
}

async function archiveFile(doc, party_id, party_user_id, current_user_id) {
  const timestamp = Date.now();
  const successful = await partiesStore.archiveInvestorFile(
    `${doc.userFolder}/entity/${party_id}/${doc.name}`,
    `${doc.userFolder}/archived/${party_id}/${timestamp}_${doc.name}`,
  );
  if (!successful) {
    notify(
      "failure",
      "Sorry",
      "We were unable to archive your file. Please try again later, if the issue persists please contact tech support",
    );
    return;
  }
  notify("success", "File Archived", "Your file was archived successfully");
  setEntityDocuments(party_user_id, current_user_id, party_id);
}

// Simple Functions
const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};
</script>
