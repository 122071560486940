<template>
  <div
    class="relative bg-white rounded-lg shadow-md ring-1 ring-gray-100 h-full w-full dark:bg-neutral-800 dark:ring-neutral-700"
    @mouseenter="animation_ref.play()"
    @mouseleave="animation_ref.stop()"
  >
    <div
      class="flex justify-between items-center px-4 py-4 bg-white w-full rounded-t-lg border-b border-gray-200 border-dashed dark:bg-neutral-800 dark:border-neutral-700"
    >
      <div class="flex items-center gap-x-3">
        <Vue3Lottie
          ref="animation_ref"
          animation-link="https://lottie.host/40b2486b-2ec9-44fc-ab0f-fe74765a65b7/eFoMtzHmZA.json"
          :height="35"
          :width="35"
          :loop="false"
          :auto-play="true"
          class="-m-1"
        />
        <h2 class="max-w-3xl text-base font-semibold text-gray-900 dark:text-neutral-100">Transaction History</h2>
      </div>
      <div class="flex items-center gap-x-1 underline text-operacolor hover:text-operacolorlight text-sm cursor-pointer" @click="emit('show-all')">
        View all transactions
        <ArrowTopRightOnSquareIcon class="size-4" />
      </div>
    </div>
    <div class="overflow-y-scroll max-h-[calc(100%-68px)] w-full">
      <div class="">
        <div class="mx-4">
          <table class="w-full text-left">
            <tbody>
              <template v-for="transaction in props.transactions" :key="transaction.id">
                <tr class="border-t border-gray-100 first:border-t-0 dark:border-neutral-600">
                  <td class="relative py-5 pr-6">
                    <div class="flex gap-x-6">
                      <component
                        :is="getTransactionIcon(transaction)"
                        class="h-5 w-5 mt-0.5 flex-none block"
                        :class="getTransactionStyles(transaction)"
                        aria-hidden="true"
                      />
                      <div class="flex-auto">
                        <div class="flex items-start gap-x-3">
                          <div class="text-sm/6 font-medium text-gray-900 dark:text-neutral-100">
                            {{ formatMoney(transaction.amount * transaction.price_per_unit) }}
                            USD
                          </div>
                          <div :class="getBadgeClass(transaction.status)" class="capitalize">
                            {{ capitalizeFirstLetter(transaction.status) }}
                          </div>
                        </div>
                        <div
                          v-if="transaction.units == 'dollars' && transaction.type == 'distribution'"
                          class="mt-1 text-xs/5 text-gray-500 dark:text-neutral-400"
                        >
                          Earnings
                        </div>
                        <div v-else-if="transaction.units == 'dollars'" class="mt-1 text-xs/5 text-gray-500 dark:text-neutral-400">
                          {{ transaction.units }}
                        </div>
                        <div v-else class="mt-1 text-xs/5 text-gray-500 dark:text-neutral-400">
                          {{ transaction.amount }} {{ transaction.units }}
                          <i class="text-[10px]">({{ formatMoney(transaction.price_per_unit) }} each)</i>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-5 pr-6 table-cell">
                    <div class="text-sm/6 text-gray-900 font-semibold dark:text-neutral-100">
                      {{ transaction.offerings.name }}
                    </div>
                    <div v-if="transaction.transaction_method == 'tbd'" class="mt-1 text-xs/5 flex items-center gap-x-1">
                      <span>{{ paidByText(transaction) }}</span>
                      <span
                        class="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400"
                        >{{ capitalizeFirstLetter(transaction.transaction_method) }}
                        <ClockIcon class="size-4 text-yellow-500 ml-1" />
                      </span>
                    </div>
                    <div v-else-if="transaction.transaction_method == 'custody'" class="mt-1 text-xs/5 flex items-center gap-x-1">
                      <span>paid by</span>
                      <span
                        class="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400"
                        >Exchange
                        <ArrowsRightLeftIcon class="size-4 text-pink-500 ml-1" />
                      </span>
                    </div>
                    <div v-else-if="transaction.transaction_method == 'wire'" class="mt-1 text-xs/5 flex items-center gap-x-1">
                      <span>{{ paidByText(transaction) }}</span>
                      <span
                        class="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400"
                        >{{ capitalizeFirstLetter(transaction.transaction_method) }}
                        <ChevronDoubleRightIcon class="size-4 text-pink-500 ml-1" />
                      </span>
                    </div>
                    <div v-else-if="transaction.transaction_method == 'ach'" class="mt-1 text-xs/5 flex items-center gap-x-1">
                      <span>paid by</span>
                      <span
                        class="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400"
                        >{{ capitalizeFirstLetter(transaction.transaction_method) }}
                        <BuildingLibraryIcon class="size-4 text-purple-600 ml-1" />
                      </span>
                    </div>
                    <div v-else-if="transaction.transaction_method == 'creditcard'" class="mt-1 text-xs/5 flex items-center gap-x-1">
                      <span>paid by</span>
                      <span
                        class="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400"
                        >{{ capitalizeFirstLetter(transaction.transaction_method) }}
                        <CreditCardIcon class="size-4 text-blue-600 ml-1" />
                      </span>
                    </div>
                    <div v-else-if="transaction.transaction_method == 'check'" class="mt-1 text-xs/5 flex items-center gap-x-1">
                      <span>{{ paidByText(transaction) }}</span>
                      <span
                        class="capitalize inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400"
                        >{{ transaction.transaction_method }}
                        <TicketIcon class="size-4 text-teal-500 ml-1" />
                      </span>
                    </div>
                    <div v-else-if="transaction.transaction_method == 'distribution'" class="mt-1 text-xs/5 flex items-center gap-x-1">
                      <span
                        class="capitalize inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400"
                        >{{ transaction.transaction_method }}
                        <ArrowUturnUpIcon class="size-3.5 text-green-600 ml-1" />
                      </span>
                    </div>
                  </td>
                  <td class="py-5 text-right">
                    <div
                      class="flex justify-end items-center gap-x-1 text-operacolor hover:text-operacolorlight"
                      @click="emit('transaction-details', transaction)"
                    >
                      <a class="text-sm/6 font-medium cursor-pointer">View<span class="inline"> transaction</span></a>
                      <ArrowTopRightOnSquareIcon class="size-4" />
                    </div>
                    <div class="mt-1 text-xs/5 text-gray-500 dark:text-neutral-400">
                      {{ formatDateMonthYear(transaction.created_at) }}
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref } from "vue";

// Icons
const animation_ref = ref(null);
import { Vue3Lottie } from "vue3-lottie";
import {
  WalletIcon,
  BanknotesIcon,
  ArrowUpCircleIcon,
  XCircleIcon,
  StopCircleIcon,
  ArrowPathIcon,
  MinusCircleIcon,
} from "@heroicons/vue/20/solid";
import { TicketIcon, CreditCardIcon, ClockIcon } from "@heroicons/vue/24/solid";
import {
  ArrowTopRightOnSquareIcon,
  ArrowUturnUpIcon,
  BuildingLibraryIcon,
  ChevronDoubleRightIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/vue/24/outline";

// Libraries
import {
  formatMoney,
  formatDateMonthYear,
  capitalizeFirstLetter,
  getBadgeClass,
} from "@/helper/helper";

// Emits
const emit = defineEmits(["show-all", "transaction-details"]);

// Props
const props = defineProps({
  transactions: { type: Array, required: true, default: null },
});

// Transaction Icons
const getTransactionIcon = (transaction) => {
  if (transaction.status == "pending") return ClockIcon;
  if (transaction.status == "refund_pending") return ArrowPathIcon;
  if (transaction.status == "refunded") return ArrowPathIcon;
  if (transaction.status == "canceled") return StopCircleIcon;
  if (transaction.status == "disapproved") return XCircleIcon;
  if (transaction.status == "funded") return CreditCardIcon;
  if (transaction.status == "settled") {
    if (transaction.type == "distribution" && transaction.units == "dollars")
      return BanknotesIcon;
    if (transaction.type == "distribution" && transaction.units != "dollars")
      return ArrowUpCircleIcon;
    return WalletIcon;
  }
  return MinusCircleIcon;
};

// Transaction Styles
const getTransactionStyles = (transaction) => {
  if (transaction.status == "pending") return "text-yellow-500";
  if (transaction.status == "refund_pending") return "text-yellow-600";
  if (transaction.status == "refunded") return "text-purple-600";
  if (transaction.status == "canceled") return "text-red-600";
  if (transaction.status == "disapproved") return "text-red-600";
  if (transaction.status == "funded") return "text-blue-600";
  if (transaction.status == "settled") {
    if (transaction.type == "distribution" && transaction.units == "dollars")
      return "text-green-600";
    if (transaction.type == "distribution" && transaction.units != "dollars")
      return "text-green-600";
    return "text-green-600";
  }
  return "text-gray-600";
};

// Transaction Paid By or Pay by text
const paidByText = (transaction) => {
  if (transaction.status != 'pending') return "paid by";
  if (transaction.payment_status != 'pending') return "paid by";
  if (transaction.transaction_method == 'wire' || transaction.transaction_method == 'check' || transaction.transaction_method == 'tbd') {
    return "pay by";
  }
  return "paid by";
};
</script>
