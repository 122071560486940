<template>
  <div>
    <!-- Loading -->
    <div
      v-if="loading"
      class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[50vh] w-[90vw]"
    >
      <div class="h-40 w-40 -mt-20">
        <SpinnerJump />
      </div>
    </div>

    <!-- Content -->
    <div v-else class="p-5 sm:w-[500px] w-[90vw] dark:text-neutral-300">
      <div class="mb-5 dark:text-white">{{ getKycLabel }}</div>

      <div class="flex gap-1 mb-1 text-sm md:text-base">
        <div>1.</div>
        Driver's license.
      </div>
      <div class="flex gap-1 mb-1 text-sm md:text-base">
        <div>2.</div>
        Passport.
      </div>
      <div class="flex gap-1 mb-1 text-sm md:text-base">
        <div>3.</div>
        Government issued photo ID.
      </div>

      <div class="my-5 mt-5">
        <DragDrop @changed-files="updateDataTransferObj" />
      </div>

      <div class="flex flex-row-reverse items-center justify-between">
        <button class="self-end button_primary" @click="handleKycClick()">
          {{ nextButtonText }}
          <ArrowRightIcon class="w-4 h-4" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed } from "vue";
// Stores
import { useUserStore } from "@/stores/user";
import { usePartiesStore } from "@/stores/parties";
import { usePartnerStore } from "@/stores/partner";
const userStore = useUserStore();
const partnerStore = usePartnerStore();
const partiesStore = usePartiesStore();

// Libraries
import { ArrowRightIcon } from "@heroicons/vue/24/outline";

// Components
import DragDrop from "@/components/applicationinputs/documents/DragDrop.vue";
import SpinnerJump from "@/components/loading/SpinnerJump.vue";

// Props
const props = defineProps({
  isEntity: { type: Boolean, required: false, default: false },
  isForOther: { type: Boolean, required: false, default: false },
  isJoint: { type: Boolean, required: false, default: false },
  party: { type: Object, required: true, default: null },
});

// Emits
const emit = defineEmits(["next"]);

// Handle Submit
const loading = ref(false);
async function handleKycClick() {
  // If we don't have any documents, skip
  if (noDocuments.value) {
    emit("next");
    return;
  }

  loading.value = true;

  // Upload kyc documents
  await uploadKycAmlDocs(
    props.party?.id,
    props.party?.tapi_party_id,
    userStore.user.id,
    dt.value,
  );
  emit("next");
}

async function uploadKycAmlDocs(party_id, tapi_party_id, user_id, dtArray) {
  const promises = [];

  for (let newFile of dtArray.files) {
    // Push upload to tapi promise to array
    const promise = partiesStore.uploadPartyDocumentToTapi(
      party_id,
      tapi_party_id,
      newFile,
      partnerStore.partner.partners_protected.managed_kyc_aml,
    );
    promises.push(promise);
    // Push upload to supabase promise to array
    const promise2 = partiesStore.uploadInvestorDocumentToSupabase(
      user_id,
      party_id,
      newFile,
    );
    promises.push(promise2);
  }

  // Wait for all promises to resolve
  await Promise.all(promises);
}

// Documents
const noDocuments = ref(true);
const dt = ref(new DataTransfer());
const updateDataTransferObj = (dtObj) => {
  dt.value = dtObj;
  if (dt.value.files.length > 0) noDocuments.value = false;
  else noDocuments.value = true;
};

// Button Text
const nextButtonText = computed(() => {
  if (noDocuments.value) return "Do This Later";
  return "Next";
});

const getKycLabel = computed(() => {
  if (props.isEntity)
    return "Please upload one of the following to verify the agent's identity:";
  if (props.isForOther)
    return "Please upload one of the following to verify your client's identity:";
  if (props.isJoint)
    return "Please upload one of the following for each account owner to verify their identity:";
  return "Please upload one of the following to verify your identity:";
});
</script>
