<template>
  <div>
    <!-- Small Switch -->
    <!-- This is only still used on the investView get rid of it so we can remove this code -->
    <SwitchGroup
      v-if="props.size == 'small'"
      as="div"
      class="flex items-center justify-between w-fit"
    >
      <Switch
        v-model="bool"
        class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-operacolor focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-0 dark:focus:ring-operacolor dark:focus:ring-offset-0"
      >
        <span class="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          class="pointer-events-none absolute h-full w-full rounded-md bg-gray-50 dark:bg-neutral-950"
        ></span>
        <span
          aria-hidden="true"
          :class="[
            bool ? 'bg-operacolor' : 'bg-gray-200 dark:bg-neutral-700',
            'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
          ]"
        ></span>
        <span
          aria-hidden="true"
          :class="[
            bool ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
          ]"
        ></span>
      </Switch>
      <SwitchLabel as="span" class="ml-3 text-sm">
        <span class="font-medium text_color_primary">{{ props.label }}</span>
      </SwitchLabel>
    </SwitchGroup>

    <!-- Normal Switch -->
    <SwitchGroup
      v-else
      as="div"
      class="flex items-center justify-between flex-wrap sm:flex-nowrap gap-y-3 gap-x-2"
    >
      <span class="flex flex-grow flex-col">
        <SwitchLabel
          as="span"
          class="text-sm font-medium leading-6 text-gray-900 dark:text-neutral-100"
          passive
          >{{ props.label }}</SwitchLabel
        >
        <SwitchDescription
          as="span"
          class="text-sm text-gray-500 dark:text-neutral-400"
          >{{ props.description }}</SwitchDescription
        >
      </span>
      <Switch
        v-model="bool"
        :disabled="props.disabled"
        :class="[
          switchStyles,
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-2 dark:focus:ring-neutral-400 dark:focus:ring-offset-0',
        ]"
      >
        <span
          aria-hidden="true"
          :class="[
            bool ? 'translate-x-5' : 'translate-x-0 !bg-white',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            { '!bg-gray-300': props.disabled },
          ]"
        ></span>
      </Switch>
    </SwitchGroup>
  </div>
</template>

<script setup>
// Essentials
import { computed } from "vue";
// Libraris
import {
  Switch,
  SwitchGroup,
  SwitchLabel,
  SwitchDescription,
} from "@headlessui/vue";
// Props
const props = defineProps({
  label: { type: String, required: false, default: "" },
  description: { type: String, required: false, default: "" },
  size: { type: String, required: false, default: "normal" },
  disabled: { type: Boolean, required: false, default: false },
  color: { type: String, required: false, default: null },
});
// Models
const bool = defineModel({ type: Boolean, default: true });

// Computed
const switchStyles = computed(() => {
  if (!bool.value && !props.disabled)
    return "bg-gray-200 dark:bg-neutral-700 focus:ring-operacolor";
  if (!bool.value && props.disabled) return "!cursor-not-allowed opacity-50";

  if (bool.value && props.disabled)
    return "!cursor-not-allowed bg-gray-700 opacity-50";
  if (bool.value && !props.disabled && !props.color)
    return "bg-operacolor focus:ring-operacolor";
  if (bool.value && !props.disabled && props.color == "red")
    return "bg-red-600 focus:ring-red-600";
  if (bool.value && !props.disabled && props.color == "green")
    return "bg-green-600 focus:ring-green-600";
  if (bool.value && !props.disabled && props.color == "yellow")
    return "bg-yellow-500 focus:ring-yellow-500";
  // Add more colors as needed
  return "bg-operacolor focus:ring-operacolor";
});
</script>
