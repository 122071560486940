<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="fixed inset-0 z-[9999] flex items-end px-4 py-6 pointer-events-none sm:items-start sm:p-6"
  >
    <div class="flex flex-col items-center w-full space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transition duration-300 ease-out transform"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="open"
          class="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto dark:bg-neutral-900 ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  v-if="props.type == 'success'"
                  class="w-6 h-6 text-green-400"
                  aria-hidden="true"
                />
                <ExclamationTriangleIcon
                  v-else-if="props.type == 'failure'"
                  class="w-6 h-6 text-amber-500"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium dark:text-neutral-300">
                  {{ props.title }}
                </p>
                <p class="mt-1 text-sm text-gray-500 dark:text-neutral-500">
                  {{ props.text }}
                </p>
              </div>
              <div class="flex flex-shrink-0 ml-4">
                <button
                  type="button"
                  class="inline-flex text-gray-400 dark:text-neutral-400 rounded-md hover:text-gray-500 dark:hover:text-neutral-500 outline-none"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref } from "vue";
// Libraries
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";
// Props
const props = defineProps({
  type: { type: String, default: "success" },
  title: { type: String, default: "Success" },
  text: { type: String, default: "" },
});
// Globals
// TODO make this a model
const open = ref(false);
// Functions
function show() {
  open.value = true;
  setTimeout(() => {
    open.value = false;
  }, 5000);
}
function hide() {
  open.value = false;
}
// Expose
defineExpose({
  show,
  hide,
});
</script>
