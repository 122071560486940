import { defineStore } from "pinia";
import { ref } from "vue";
import { getAccountType, capitalizeFirstLetter } from "@/helper/helper";
import { logErrorMessage } from "@/schemas/AdvancedErrorHandler.js";
import { supabase } from "../lib/supabaseClient";

export const useAccountsStore = defineStore("accounts", () => {
  // State (refs)
  const holdingAccount = ref(null); // Holding account for user's party
  const accounts = ref(null); // All accounts
  const myAccounts = ref(null); // Accounts where primary party is user's party and is not holding account
  const managedAccounts = ref(null); // Accounts created by user but user is not primary party
  const custodyAccounts = ref(null); // Accounts where the user_id is the same as the current user id and is not a holding account

  // Getters, (computed properties)

  // Actions, (functions)
  async function setHoldingAccount() {
    const user_id = await getUserID();
    if (!user_id) return;
    const party_id = await getPartyID(user_id);
    if (!party_id) return;
    holdingAccount.value = await getHoldingAccount(party_id);
  }
  async function setAccounts() {
    const user_id = await getUserID();
    if (!user_id) return;
    accounts.value = getAllAccounts(user_id);
  }
  async function setMyAccounts() {
    const user_id = await getUserID();
    if (!user_id) return;
    const party_id = await getPartyID(user_id);
    if (!party_id) return;
    myAccounts.value = await getMyAccounts(party_id);
  }
  async function setManagedAccounts() {
    const user_id = await getUserID();
    if (!user_id) return;
    const party_id = await getPartyID(user_id);
    if (!party_id) return;
    managedAccounts.value = await getManagedAccounts(user_id, party_id);
  }
  async function setCustodyAccounts() {
    custodyAccounts.value = await getCustodyAccounts();
  }

  async function getHoldingAccount(party_id) {
    const { data, error } = await supabase
      .from("accounts")
      .select("*, accounts_protected(*)")
      .eq("primary_party_id", party_id)
      .eq("accounts_protected.is_holding_account", true)
      .limit(1);
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data[0];
  }
  async function getAllAccounts(user_id) {
    const { data, error } = await supabase
      .from("accounts")
      .select(`*, accounts_protected(*)`)
      .eq("user_id", user_id)
      .order("created_at", { ascending: false });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function getCustodyAccounts() {
    const { data, error } = await supabase
      .from("accounts")
      .select(`*, accounts_protected!inner(*)`)
      .neq("accounts_protected.is_holding_account", true)
      .order("created_at", { ascending: false });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function getMyAccounts(party_id) {
    const { data, error } = await supabase
      .from("accounts")
      .select(`*, accounts_protected!inner(*), parties!primary_party_id(*), transactions(*), positions(*, offerings(*))`)
      .eq("primary_party_id", party_id)
      .neq("accounts_protected.is_holding_account", true)
      .neq("transactions.status", 'archived')
      .order("group", { ascending: true })
      .order("created_at", { ascending: false });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function getManagedAccounts(user_id, party_id) {
    const { data, error } = await supabase
      .from("accounts")
      .select(`*, accounts_protected!inner(*), parties!primary_party_id(*), transactions(*), positions(*)`)
      .eq("user_id", user_id)
      .neq("primary_party_id", party_id)
      .neq("accounts_protected.is_holding_account", true)
      .neq("transactions.status", "archived")
      .order("group", { ascending: true })
      .order("created_at", { ascending: false });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function getAccreidationDocuments(user_id, account_id, tapi_account_id) {
    let docs = [];
    let docs2 = [];
    docs = await getInvestorDocuments(`${user_id}/accredited/${account_id}`);
    docs.forEach((doc) => {
      doc.userFolder = user_id;
      doc.accountFolder = account_id;
    });
    docs2 = await getInvestorDocuments(`${user_id}/accredited/${tapi_account_id}`);
    docs2.forEach((doc) => {
      doc.userFolder = user_id;
      doc.accountFolder = tapi_account_id;
    });
    const allDocs = docs.concat(docs2);
    return allDocs;
  }
  async function getAccreditationLetter(account_id, tapi_account_id) {
    const { data, error } = await supabase.functions.invoke("get-accreditation-letter", { body: { id: account_id, accountId: tapi_account_id } });
    if (error || !data?.document_details[0]?.documentUrl) {
      console.log(await logErrorMessage(error ?? "No documents found"));
      return;
    }
    return data.document_details[data.document_details.length - 1]?.documentUrl;
  }
  async function requestAiVerification(account_id, tapi_account_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
      aiMethod: "Upload",
    };
    const { error } = await supabase.functions.invoke("request-ai-verification", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return true;
  }
  async function updateAiRequest(account_id, ai_request_id) {
    var body = {
      id: account_id,
      airequestId: ai_request_id,
      aiRequestStatus: "New Info Added",
    };
    const { error } = await supabase.functions.invoke("update-ai-verification", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return true;
  }
  async function uploadAccreditationDocumentToTapi(account_id, tapi_account_id, newFile) {
    // Create a new FormData object
    const formData = new FormData();
    formData.append("id", account_id);
    formData.append("accountId", tapi_account_id);
    formData.append("file", newFile, newFile.name);
    // Call the function
    const { error } = await supabase.functions.invoke("upload-accreditation-documents", { body: formData });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return true;
  }
  async function uploadAccreditationDocumentToSupabase(user_id, account_id, newFile) {
    const { error } = await supabase.storage
      .from("investor_documents")
      .upload(`${user_id}/accredited/${account_id}/${newFile.name}`, newFile, { cacheControl: "3600", upsert: true });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return true;
  }
  async function downloadAccreditationFile(filePath) {
    const { data, error } = await supabase.storage.from("investor_documents").createSignedUrl(filePath, 3600);
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data?.signedUrl;
  }
  async function archiveAccreditationFile(oldFolder, newFolder) {
    const { error } = await supabase.storage.from("investor_documents").move(oldFolder, newFolder);
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return true;
  }
  async function createAccountInTapi(party, registration, secondary_party_id) {
    var domestic = "international_account";
    if (party.isUSCitizen) domestic = "domestic_account";
    var body = {
      accountRegistration: party?.first_name + " " + party?.last_name + " " + getAccountType(registration),
      type: getAccountType(registration),
      domesticYN: domestic,
      streetAddress1: party.address,
      streetAddress2: party.unit,
      city: party.city,
      state: party.state,
      zip: party.zip,
      country: party.country,
      primary_party_id: party?.id,
      secondary_party_id: secondary_party_id,
      AccreditedStatus: "Not Accredited",
    };
    const { data, error } = await supabase.functions.invoke("create-account", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data.accountDetails[0].accountId;
  }
  async function createHoldingAccountInTapi(party_id, party, type = "individual", registration, secondary_party_id = null) {
    let domestic = "international_account";
    if (party.isUSCitizen) domestic = "domestic_account";
    let taxId = party.social;
    if (type == "entity" && party.tax_id) taxId = party.tax_id;
    var body = {
      accountRegistration: registration,
      type: capitalizeFirstLetter(type),
      domesticYN: domestic,
      streetAddress1: party.address,
      streetAddress2: party.unit,
      city: party.city,
      state: party.state,
      zip: party.zip,
      country: party.country,
      primary_party_id: party_id,
      secondary_party_id: secondary_party_id,
      AccreditedStatus: "Not Accredited",
      taxID: taxId,
    };
    const { data, error } = await supabase.functions.invoke("create-account", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data.accountDetails[0].accountId;
  }
  async function createHoldingAccount(account) {
    const { data, error } = await supabase.from("accounts").insert(account).select("*");
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data[0];
  }

  async function createAccountInSupabase(tapi_account_id, partner_id, party_id, registration, name, secondary_party_id, isSubjectToWithholding) {
    let body = {
      tapi_account_id: tapi_account_id,
      partner_id: partner_id,
      primary_party_id: party_id,
      secondary_party_id: secondary_party_id,
      type: registration,
      name: name,
      is_subject_to_withholding: isSubjectToWithholding,
    };
    const { data, error } = await supabase.from("accounts").insert(body).select("*");
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    if (myAccounts.value) myAccounts.value.push(data[0]);
    else myAccounts.value = data;
    return data[0];
  }

  async function createCustodyAccountBarc(tapi_account_id, name) {
    let body = {
      accountId: tapi_account_id,
      nickname: name,
    };
    const { error } = await supabase.functions.invoke("create-custody-account", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return true;
  }

  async function createLinkInTapi(tapi_account_id, tapi_party_id, account_id, linkType = "owner", primary_value = 1) {
    var body = {
      id: account_id,
      firstEntryType: "Account",
      firstEntry: tapi_account_id,
      relatedEntryType: "IndivACParty",
      relatedEntry: tapi_party_id,
      linkType: linkType,
      primary_value: primary_value,
    };
    const { error } = await supabase.functions.invoke("create-link", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return true;
  }

  async function requestCustodialAccount(account_id, tapi_account_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
      createdIpAddress: "23.30.55.109",
    };
    const response = await supabase.functions.invoke("request-custodial-account", { body });
    if (response.error) {
      console.log(await logErrorMessage(response.error));
      return;
    }
    return true;
  }

  async function getStripe(account_id, tapi_account_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
    };
    const { data, error } = await supabase.functions.invoke("stripe", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function updateStripe(account_id, tapi_account_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
    };
    const { data, error } = await supabase.functions.invoke("update-stripe", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }

  async function getPlaid(account_id, tapi_account_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
    };
    const { data, error } = await supabase.functions.invoke("plaid", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function updatePlaid(account_id, tapi_account_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
    };
    const { data, error } = await supabase.functions.invoke("update-plaid", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function deleteExternalAccount(account_id, tapi_account_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
      types: "Account",
    };
    const { data, error } = await supabase.functions.invoke("delete-external-account", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function getCreditCard(account_id, tapi_account_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
    };
    const { data, error } = await supabase.functions.invoke("get-linked-creditcard", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function getAchAccount(account_id, tapi_account_id) {
    var body = {
      id: account_id,
      accountId: tapi_account_id,
      types: "Account",
    };
    const { data, error } = await supabase.functions.invoke("get-ach-account", { body });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }
  async function getExistingAccountGroups(user_id) {
    const { data, error } = await supabase.from("accounts").select("group").eq("user_id", user_id);
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    // Get unique groups
    const groups = [...new Set(data.map((group) => group.group))];
    // Remove null values and return
    return groups.filter((group) => group !== null);
  }
  async function updateGroup(group, account_id) {
    const { error } = await supabase.from("accounts").update({ group: group }).eq("id", account_id);
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return true;
  }
  async function updateAccreditationStatus(id, accredited_status) {
    const { error } = await supabase.from("accounts_protected").update({ accredited_status: accredited_status }).eq("id", id);
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return true;
  }
  async function updateApprovalStatus(id, approval_status) {
    const { error } = await supabase.from("accounts_protected").update({ approval_status: approval_status }).eq("id", id);
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return true;
  }
  async function updateAccountTrustedContact(id, trusted_contact) {
    const { error } = await supabase.from("accounts").update(trusted_contact).eq("id", id);
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
  }

  // Not Callable Functions
  async function getUserID() {
    const auth = await supabase.auth.getUser();
    return auth.data.user?.id;
  }
  async function getPartyID(user_id) {
    const { data, error } = await supabase.from("profiles").select("party_id").eq("id", user_id).limit(1);
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data[0]?.party_id;
  }
  async function getInvestorDocuments(folder) {
    const { data, error } = await supabase.storage
      .from("investor_documents")
      .list(folder, { limit: 100, offset: 0, sortBy: { column: "name", order: "asc" } });
    if (error) {
      console.log(await logErrorMessage(error));
      return;
    }
    return data;
  }

  return {
    holdingAccount,
    accounts,
    myAccounts,
    managedAccounts,
    custodyAccounts,
    setHoldingAccount,
    setAccounts,
    setMyAccounts,
    setManagedAccounts,
    getHoldingAccount,
    setCustodyAccounts,
    getAllAccounts,
    getMyAccounts,
    getManagedAccounts,
    getCustodyAccounts,
    getAccreidationDocuments,
    getAccreditationLetter,
    requestAiVerification,
    updateAiRequest,
    uploadAccreditationDocumentToTapi,
    uploadAccreditationDocumentToSupabase,
    downloadAccreditationFile,
    archiveAccreditationFile,
    createAccountInTapi,
    createHoldingAccountInTapi,
    createHoldingAccount,
    createAccountInSupabase,
    createLinkInTapi,
    createCustodyAccountBarc,
    getStripe,
    updateStripe,
    getPlaid,
    updatePlaid,
    deleteExternalAccount,
    getCreditCard,
    getAchAccount,
    requestCustodialAccount,
    getExistingAccountGroups,
    updateGroup,
    updateAccreditationStatus,
    updateApprovalStatus,
    updateAccountTrustedContact,
  };
});
