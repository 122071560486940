<template>
  <div>
    <label v-if="props.label" :for="props.id" class="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">
      {{ props.label }}
      <Tooltip v-if="props.tooltip" :content="props.tooltip">
        <QuestionMarkCircleIcon class="size-4 text-gray-600 cursor-pointer dark:text-neutral-400 hover:text-operacolorlight" />
      </Tooltip>
    </label>
    <div class="mt-2">
      <textarea :id="props.id" ref="input_ref" v-model="paragraph" :name="props.id" :rows="props.rows" :disabled="props.disabled" :placeholder="props.placeholder" class="textarea_custom" @input="resetCustomValidity"></textarea>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Tooltip from '@/components/applicationui/AppTooltip.vue'
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'

const paragraph = defineModel({
  type: String,
  default: ''
})

const props = defineProps({
  id: { type: String, required: true, default: null },
  label: { type: String, required: false, default: null },
  tooltip: { type: String, required: false, default: null },
  rows: { type: Number, required: false, default: 2 },
  disabled: { type: Boolean, required: false, default: false },
  placeholder: { type: String, required: false, default: '' }
})

// Set error message
const input_ref = ref(null)
const resetCustomValidity = () => { input_ref.value.setCustomValidity("") }
const setErrorMessage = async (message = 'This field is required') => {
  input_ref.value.setCustomValidity(message)
  await new Promise(resolve => setTimeout(resolve, 300))
  input_ref.value.reportValidity()
}
defineExpose({ setErrorMessage })
</script>

<style scoped>
.textarea_custom {
  @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 text-sm leading-6 dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-800 dark:placeholder:text-neutral-600 focus:ring-2 focus:ring-inset focus:ring-operacolor dark:focus:ring-operacolor disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 disabled:ring-gray-200 dark:disabled:text-neutral-400 dark:disabled:ring-0
}
</style>
