<template>
  <!-- Table Component -->
  <div class="flow-root">
    <div>
      <div class="mb-2 text-right flex justify-end gap-6 items-center">
        <div
          v-if="props.allowExport && selectedRows.length > 0"
          class="text-sm text-operacolor flex items-center gap-2 -mr-3"
        >
          <ButtonSecondary
            text=""
            size="sm"
            :icon="XMarkIcon"
            @click.stop="deselectAll"
          />
          {{ selectedRows.length }} rows selected
        </div>

        <div v-if="props.allowExport && selectedRows.length > 0">
          <ButtonPrimary
            text="Export to CSV"
            size="lg"
            :loading="loadingExport"
            :icon="ArrowDownTrayIcon"
            :icon-first="true"
            @click.stop="handleExport"
          />
        </div>

        <ColumnSelector v-model="columns" />
      </div>

      <div class="w-full inline-block align-middle">
        <div
          class="w-full rounded-lg shadow dark:shadow-none ring-1 ring-black ring-opacity-5"
        >
          <!-- Header -->
          <div
            class="w-full bg-gray-100 dark:bg-neutral-800 rounded-t-lg bg-opacity-75 backdrop-blur backdrop-filter border-b border-gray-300 dark:border-neutral-700"
          >
            <div class="flex overflow-hidden">
              <AppCheckbox v-model="selectAll" class="pl-4 sm:pl-6 py-3.5" />
              <div
                v-for="column in visibleColumns"
                :key="column.key"
                class="px-3 py-3.5 text-sm font-semibold text-gray-900 dark:text-neutral-300 last:pr-5 last:text-right whitespace-nowrap flex-1 flex last:justify-end"
              >
                <span v-if="column?.label" class="flex items-center gap-1">
                  {{ column.label }}
                  <ArrowsUpDownIcon
                    class="w-4 h-4 mt-0.5 hover:text-operacolor cursor-pointer"
                    :class="[
                      sorter.key == column.key
                        ? 'text-operacolor'
                        : 'text-gray-400 dark:text-neutral-500',
                    ]"
                    @click="sortByColumn(column)"
                  />
                </span>
              </div>
            </div>
          </div>

          <!-- Body -->
          <div
            class="bg-white dark:bg-neutral-900 max-h-[60vh] overflow-y-scroll custom-srollbar"
          >
            <div
              v-for="row in visibleRows"
              :key="row"
              :class="[
                props.isClickable
                  ? 'cursor-pointer hover:bg-gray-50 dark:hover:bg-neutral-950'
                  : '',
                row.selected
                  ? 'bg-sky-50 hover:bg-sky-100 dark:bg-blue-950 dark:hover:bg-blue-900'
                  : '',
                'flex w-full border-b border-gray-200 dark:border-neutral-700 last:border-b-0',
              ]"
              @click="handleClick(row)"
            >
              <AppCheckbox
                v-model="row.selected"
                class="pl-4 sm:pl-6 py-4 flex items-center"
                @click.stop
              />

              <div
                v-for="column in visibleColumns"
                :key="column.key"
                class="py-4 px-3 text-sm text-gray-500 dark:text-neutral-300 truncate whitespace-nowrap last:pr-5 last:text-right last:block flex-1 flex items-center"
              >
                <!-- Badges -->
                <div v-if="column.type == 'badge'">
                  <span
                    v-if="getNestedValue(row, column.key)"
                    :class="[
                      getBadgeClass(getNestedValue(row, column.key), true),
                      'capitalize',
                    ]"
                  >
                    {{
                      getUserFriendlyString(
                        getNestedValue(row, column.key),
                        userStore?.user?.profiles_protected?.role,
                      )
                    }}
                  </span>
                  <span v-else>-</span>
                </div>

                <!-- Badge Array -->
                <div v-else-if="column.type == 'badge-array'">
                  <div v-if="getNestedValue(row, column.key)">
                    <span
                      v-for="badge in getNestedValue(row, column.key)"
                      :key="badge"
                      :class="[getBadgeClass(badge, true), 'capitalize mr-2']"
                    >
                      {{
                        getUserFriendlyString(
                          badge,
                          userStore?.user?.profiles_protected?.role,
                        )
                      }}
                    </span>
                  </div>
                  <span v-else>-</span>
                </div>

                <!-- Date -->
                <span v-else-if="column.type == 'date'">{{
                  formatDateNoTimeZone(getNestedValue(row, column.key)) ?? "-"
                }}</span>

                <!-- Two Strings -->
                <span v-else-if="column.type == 'two-strings'">{{
                  `${getNestedValue(row, column.key) ?? "-"} ${getNestedValue(row, column.key2) ?? ""}`
                }}</span>

                <!-- Bold -->
                <Tooltip
                  v-else-if="column.type == 'bold'"
                  :content="getNestedValue(row, column.key) ?? '-'"
                >
                  <div class="truncate">
                    <span
                      class="font-medium text-gray-900 dark:text-neutral-100"
                      >{{ getNestedValue(row, column.key) ?? "-" }}</span
                    >
                  </div>
                </Tooltip>

                <!-- Small -->
                <!-- <span v-else-if="column.type == 'small'" class="text-xs">{{ getNestedValue(row, column.key) ?? '-' }}</span> -->

                <!-- Copiable -->
                <span
                  v-else-if="column.type == 'copy'"
                  class="flex items-center gap-1"
                >
                  <div>{{ getNestedValue(row, column.key) ?? "-" }}</div>
                  <AppCopy
                    v-if="getNestedValue(row, column.key)"
                    :text="getNestedValue(row, column.key)"
                    @click.stop
                  />
                </span>

                <!-- Capitaize -->
                <span
                  v-else-if="column.type == 'capitalize'"
                  class="capitalize"
                  >{{ getNestedValue(row, column.key) ?? "-" }}</span
                >

                <!-- Money -->
                <span v-else-if="column.type == 'money'">{{
                  formatMoney(getNestedValue(row, column.key)) ?? "-"
                }}</span>

                <!-- Multiply -->
                <span v-else-if="column.type == 'multiply'">
                  {{
                    formatMoney(
                      getNestedValue(row, column.key) *
                        getNestedValue(row, column.key2),
                    ) ?? "-"
                  }}
                </span>

                <!-- Transaction Sum (Special Case) -->
                <span v-else-if="column.type == 'transaction-total'">
                  {{
                    formatMoney(
                      sumTransactionsDollars(getNestedValue(row, column.key)),
                    ) ?? "-"
                  }}
                </span>

                <!-- Transaction Shares (Special Case) -->
                <span v-else-if="column.type == 'transaction-shares'">
                  {{
                    sumTransactionsShares(getNestedValue(row, column.key)) ??
                    "-"
                  }}
                </span>

                <!-- Input First -->
                <span
                  v-else-if="column.type == 'input'"
                  class="flex items-center justify-center gap-1"
                >
                  <div class="w-20">
                    <AppInput
                      id="input_first"
                      v-model="row.percent"
                      type="number"
                      size="sm"
                      @input="handlePrecentChange(row)"
                    />
                  </div>
                  <div class="text-gray-500 dark:text-white">%</div>
                </span>

                <!-- Input Second -->
                <span
                  v-else-if="column.type == 'input-second'"
                  class="flex items-center justify-center gap-1"
                >
                  <div class="text-gray-500 dark:text-white">$</div>
                  <div class="w-20">
                    <AppInput
                      id="input_second"
                      v-model="row.dollar"
                      type="number"
                      size="sm"
                      @input="handleDollarChange(row)"
                    />
                  </div>
                </span>

                <!-- Input Third -->
                <span
                  v-else-if="column.type == 'input-third'"
                  class="min-w-32 flex items-center justify-center gap-1"
                >
                  <div class="text-gray-500 dark:text-white">#</div>
                  <div class="w-20">
                    <AppInput
                      id="input_third"
                      v-model="row.share"
                      type="number"
                      size="sm"
                      @input="handleSharesChange(row)"
                    />
                  </div>
                  <div class="text-xs text-yellow-500 italic">
                    (Rounded from
                    {{
                      (
                        row.percent *
                        (sumTransactionsShares(row.transactions) / 100)
                      ).toFixed(2)
                    }})
                  </div>
                </span>

                <!-- Partner Logo -->
                <span v-else-if="column.type == 'partner-logo'">
                  <PartnerLogo
                    :partner="getNestedValue(row, column.key)"
                    size="small"
                  />
                </span>

                <!-- Button Primary -->
                <span v-else-if="column.type == 'button-primary'">
                  <div
                    class="button_primary"
                    @click.stop="handleButtonPrimaryClicked(row)"
                  >
                    {{ column.key }}
                  </div>
                </span>

                <!-- Button Secondary -->
                <span v-else-if="column.type == 'button-secondary'">
                  <div
                    class="button_secondary"
                    @click.stop="handleButtonSecondaryClicked(row)"
                  >
                    {{ column.key }}
                  </div>
                </span>

                <!-- Link (Secondary) -->
                <Tooltip
                  v-else-if="column.type == 'link'"
                  :content="getNestedValue(row, column.key) ?? '-'"
                >
                  <div class="flex items-center gap-1">
                    <div class="h-6 w-6 flex items-center">
                      <ArrowLeftEndOnRectangleIcon
                        class="size-5 text-blue-600 hover:text-blue-400"
                        @click.stop="handleButtonSecondaryClicked(row)"
                      />
                    </div>

                    <div class="truncate">
                      <span
                        class="font-medium text-gray-900 dark:text-neutral-100"
                        >{{ getNestedValue(row, column.key) ?? "-" }}</span
                      >
                    </div>
                  </div>
                </Tooltip>

                <!-- Esign Status -->
                <!-- <span v-else-if="column.type == 'custom-esign'" class="flex justify-end">
                  <div v-if="getNestedValue(row, column.key) == 'SIGNED'" class="flex w-fit flex-shrink-0 gap-1 items-center">
                    <CheckCircleIcon class="w-6 h-6 text-green-600" />
                    <span class="secondary_header ml-1">Signing Complete</span>
                  </div>
                  <div v-else>
                    <div @click.stop="handleButtonSecondaryClicked(row)" class="button_secondary mr-5">
                      Resend Email
                      <LoadGifButton v-if="row.loading" class="-ml-px -mr-px" />
                      <EnvelopeIcon v-else class="w-4 h-4" />
                    </div>
                    <div @click.stop="handleButtonPrimaryClicked(row)" class="button_primary">
                      Sign
                      <PencilIcon class="w-4 h-4" />
                    </div>
                  </div>
                </span> -->

                <!-- Signed -->
                <!-- <span v-else-if="column.type == 'custom-signed'">
                  <div @click.stop="handleButtonSecondaryClicked(row)" class="button_secondary">{{ column.key }}</div>
                </span> -->

                <!-- Default Fill -->
                <span v-else-if="column.type == 'default-fill'">{{
                  getNestedValue(row, column.key) ?? "-"
                }}</span>

                <!-- Default -->
                <span v-else>{{ getNestedValue(row, column.key) }}</span>
              </div>
            </div>
          </div>

          <div
            v-if="props.rows <= 0"
            class="items-center justify-center w-full bg-white dark:bg-neutral-900"
          >
            <div
              class="flex items-center justify-center w-full py-8 font-semibold dark:bg-neutral-900 dark:text-neutral-300 text-gray-600"
            >
              {{ props.emptyMessage }}
            </div>
          </div>

          <!-- Pagination -->
          <div
            class="flex items-center justify-between border-t border-gray-200 dark:border-neutral-700 px-4 py-3 sm:px-6 dark:bg-neutral-800 bg-gray-100 rounded-b-lg"
          >
            <!-- <div class="flex flex-1 justify-between sm:hidden">
              <div class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</div>
              <div class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 dark:text-neutral-300 hover:bg-gray-50">Next
              </div>
            </div> -->
            <div class="flex flex-1 items-center justify-between">
              <div>
                <div class="text-sm text-gray-700 dark:text-neutral-300">
                  Showing
                  {{ " " }}
                  <span class="font-medium">{{ startResultIndex }}</span>
                  {{ " " }}
                  to
                  {{ " " }}
                  <span class="font-medium">{{ endResultIndex }}</span>
                  {{ " " }}
                  of
                  {{ " " }}
                  <span
                    v-if="props.loadingAll"
                    class="h-[13px] w-6 inline-block"
                  >
                    <SpinnerButton class="h-2 w-2" aria-hidden="true" />
                  </span>
                  <span v-else class="font-medium">{{
                    sortedRows.length
                  }}</span>
                  {{ " " }}
                  results
                </div>
              </div>
              <div>
                <nav
                  class="isolate inline-flex -space-x-px rounded-md shadow dark:shadow-none bg-white dark:bg-neutral-950 cursor-pointer"
                  aria-label="Pagination"
                >
                  <div
                    :class="[
                      currentPage == 1
                        ? 'text-gray-400 dark:text-neutral-500 bg-gray-50 dark:bg-neutral-900'
                        : 'text-gray-600 dark:text-neutral-300 dark:hover:bg-neutral-800 hover:bg-gray-50',
                      'relative inline-flex items-center px-2 py-2 rounded-l-md ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:z-20 focus:outline-offset-0',
                    ]"
                    @click="firstPage"
                  >
                    <span class="sr-only">Previous</span>
                    <ChevronDoubleLeftIcon class="h-5 w-5" aria-hidden="true" />
                  </div>
                  <div
                    :class="[
                      currentPage == 1
                        ? 'text-gray-400 dark:text-neutral-500 bg-gray-50 dark:bg-neutral-900'
                        : 'text-gray-600 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-800',
                      'relative inline-flex items-center px-2 py-2 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:z-20 focus:outline-offset-0',
                    ]"
                    @click="prevPage"
                  >
                    <span class="sr-only">Previous</span>
                    <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                  </div>

                  <span
                    v-if="currentPage > 2 && pages > 4"
                    class="relative inline-flex items-center px-3.5 py-2 text-sm font-semibold text-gray-700 dark:text-neutral-300 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:outline-offset-0 cursor-text"
                  >
                    ...
                  </span>
                  <div
                    v-if="
                      pages - currentPage < 1 && currentPage > 3 && pages > 3
                    "
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20"
                    @click="setSpecificPage(currentPage - 3)"
                  >
                    {{ currentPage - 3 }}
                  </div>
                  <div
                    v-if="
                      pages - currentPage < 2 && currentPage > 2 && pages > 2
                    "
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20"
                    @click="setSpecificPage(currentPage - 2)"
                  >
                    {{ currentPage - 2 }}
                  </div>
                  <div
                    v-if="currentPage > 1"
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20"
                    @click="setSpecificPage(currentPage - 1)"
                  >
                    {{ currentPage - 1 }}
                  </div>
                  <div
                    class="bg-operacolor z-10 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-operacolor relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20"
                  >
                    {{ currentPage }}
                  </div>
                  <div
                    v-if="pages > currentPage"
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20"
                    @click="setSpecificPage(currentPage + 1)"
                  >
                    {{ currentPage + 1 }}
                  </div>
                  <div
                    v-if="
                      pages > currentPage &&
                      currentPage < 3 &&
                      pages >= currentPage + 2
                    "
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20"
                    @click="setSpecificPage(currentPage + 2)"
                  >
                    {{ currentPage + 2 }}
                  </div>
                  <div
                    v-if="
                      pages > currentPage &&
                      currentPage < 2 &&
                      pages >= currentPage + 3
                    "
                    class="text-gray-900 dark:text-neutral-100 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-800 focus:outline-offset-0 relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer focus:z-20"
                    @click="setSpecificPage(currentPage + 3)"
                  >
                    {{ currentPage + 3 }}
                  </div>
                  <span
                    v-if="pages > currentPage + 1 && pages > 4"
                    class="relative inline-flex items-center px-3.5 py-2 text-sm font-semibold text-gray-700 dark:text-neutral-300 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:outline-offset-0 cursor-default"
                  >
                    <Tooltip :content="`${pages} pages`">
                      <div>...</div>
                    </Tooltip>
                  </span>

                  <div
                    :class="[
                      pages == currentPage
                        ? 'text-gray-400 dark:text-neutral-500 bg-gray-50 dark:bg-neutral-900'
                        : 'text-gray-600 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-800',
                      'relative inline-flex items-center px-2 py-2 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:z-20 focus:outline-offset-0',
                    ]"
                    @click="nextPage"
                  >
                    <span class="sr-only">Next</span>
                    <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                  </div>
                  <div
                    :class="[
                      pages == currentPage
                        ? 'text-gray-400 dark:text-neutral-500 bg-gray-50 dark:bg-neutral-900'
                        : 'text-gray-600 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-800',
                      'relative inline-flex items-center px-2 py-2 ring-1 ring-inset rounded-r-md ring-gray-300 dark:ring-neutral-700 focus:z-20 focus:outline-offset-0',
                    ]"
                    @click="lastPage"
                  >
                    <span class="sr-only">Next</span>
                    <ChevronDoubleRightIcon
                      class="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, watch } from "vue";
import {
  formatMoney,
  formatDateNoTimeZone,
  getBadgeClass,
  getUserFriendlyString,
} from "@/helper/helper";

// Stores
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();

// Components
import AppCheckbox from "@/components/applicationinputs/AppCheckbox.vue";
import PartnerLogo from "@/components/partners/PartnerLogo.vue";
import Tooltip from "@/components/applicationui/AppTooltip.vue";
import ButtonPrimary from "@/components/applicationui/AppButtonPrimary.vue";
import ButtonSecondary from "@/components/applicationui/AppButtonSecondary.vue";
import SpinnerButton from "@/components/loading/SpinnerButton.vue";
import ColumnSelector from "@/components/applicationui/tables/ColumnSelector.vue";
import AppCopy from "@/components/applicationui/AppCopy.vue";
import AppInput from "@/components/applicationinputs/AppInput.vue";

// Libraries
import {
  ArrowsUpDownIcon,
  ArrowDownTrayIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import { ArrowLeftEndOnRectangleIcon } from "@heroicons/vue/24/outline";

const columns = defineModel({
  type: Array,
  required: true,
  default: () => [],
});
const visibleColumns = computed(() =>
  columns.value.filter((column) => column.visible),
);

// Props
const props = defineProps({
  //This is an array of objects that contains the data to be displayed in the table
  rows: {
    type: Array,
    required: true,
    default: null,
  },
  // This is an array of objects that contains the columns there should be two values to each object, key and label
  // columns: {
  //   type: Array,
  //   required: true,
  //   default: null
  // },
  // This is a boolean that determines if the row is clickable
  isClickable: {
    type: Boolean,
    required: false,
    default: false,
  },
  allowExport: {
    type: Boolean,
    required: false,
    default: true,
  },
  // This is a boolean that determines if the table is loading (the first 50 results)
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  // This is a boolean that determines if the table has loaded all the data
  loadingAll: {
    type: Boolean,
    required: false,
    default: false,
  },
  // This is a string that determines the title of the exported csv file
  exportTitle: {
    type: String,
    required: false,
    default: "opera",
  },
  emptyMessage: {
    type: String,
    required: false,
    default: "No results found",
  },
  custom_action: {
    type: String,
    required: false,
    default: null,
  }
});
// Emits
const emit = defineEmits(["click-primary", "click-secondary"]);

// Sorting
const sorter = ref({ key: null, order: "asc" });
// Sort the rows passed in props
const sortedRows = computed(() => {
  if (!sorter.value.key) return props.rows;

  const sortedArray = [...props.rows].sort((a, b) => {
    const valueA = getNestedValue(a, sorter.value.key);
    const valueB = getNestedValue(b, sorter.value.key);

    if (valueA == null) return sorter.value.order === "asc" ? -1 : 1;
    if (valueB == null) return sorter.value.order === "asc" ? 1 : -1;
    if (valueA < valueB) return sorter.value.order === "asc" ? -1 : 1;
    if (valueA > valueB) return sorter.value.order === "asc" ? 1 : -1;
    return 0;
  });

  return sortedArray;
});
// Sort the rows when a column is clicked
const sortByColumn = (column) => {
  if (sorter.value.key === column.key) {
    sorter.value.order = sorter.value.order === "asc" ? "desc" : "asc";
  } else {
    sorter.value.key = column.key;
    sorter.value.order = "asc";
  }
};

// Pagination
const startResultIndex = ref(1);

const endResultIndexUncut = ref(50);
const endResultIndex = computed(() => {
  if (sortedRows.value.length < endResultIndexUncut.value)
    return sortedRows.value.length;
  return endResultIndexUncut.value;
});

const pages = computed(() => {
  const pages = Math.ceil(sortedRows.value.length / 50);
  if (pages === 0) return 1;
  return pages;
});

const currentPage = computed(() => {
  return Math.ceil(startResultIndex.value / 50);
});

watch(pages, () => {
  startResultIndex.value = 1;
  endResultIndexUncut.value = 50;
});

const setSpecificPage = (page) => {
  startResultIndex.value = (page - 1) * 50 + 1;
  endResultIndexUncut.value = page * 50;
};

const prevPage = () => {
  if (startResultIndex.value > 1) {
    endResultIndexUncut.value = startResultIndex.value - 1;
    startResultIndex.value = startResultIndex.value - 50;
  }
};

const nextPage = () => {
  if (endResultIndex.value < sortedRows.value.length) {
    startResultIndex.value = endResultIndex.value + 1;
    endResultIndexUncut.value = endResultIndex.value + 50;
  }
};

const firstPage = () => {
  startResultIndex.value = 1;
  endResultIndexUncut.value = 50;
};

const lastPage = () => {
  if (pages.value === 1) return;
  endResultIndexUncut.value = sortedRows.value.length;
  startResultIndex.value =
    Math.floor((sortedRows.value.length - 1) / 50) * 50 + 1;
};

const visibleRows = computed({
  get() {
    return sortedRows.value.slice(
      startResultIndex.value - 1,
      endResultIndex.value,
    );
  },
  set(value) {
    console.log("Setting visible rows", value);
    return value;
  },
});

// Click Handlers (Emit events based on the types of click)
const handleClick = (row) => {
  if (props.isClickable) emit("click-primary", row);
};

const handleButtonPrimaryClicked = (row) => {
  emit("click-primary", row);
};

const handleButtonSecondaryClicked = (row) => {
  emit("click-secondary", row);
};

// Selectiing Rows
const selectAll = ref(false);
const selectAllRows = () => {
  sortedRows.value.forEach((row) => (row.selected = selectAll.value));
};
watch(selectAll, () => selectAllRows());
const deselectAll = () => {
  selectAll.value = false;
  selectAllRows();
};

// Exporting Selected Rows
const loadingExport = ref(false);
const selectedRows = computed(() =>
  sortedRows.value.filter((row) => row.selected),
);
const handleExport = () => {
  loadingExport.value = true;
  const cols = visibleColumns.value;

  let csvContent = cols.map((col) => col.label).join(",") + "\n";

  selectedRows.value.forEach((row) => {
    let rowContent = cols
      .map((col) => {
        if (col.type == "input") return row.percent;
        if (col.type == "input-second") return row.dollar;
        if (col.type == "transaction-total")
          return formatMoney(
            sumTransactionsDollars(getNestedValue(row, col.key)),
          );
        if (col.type == "transaction-shares")
          return sumTransactionsShares(getNestedValue(row, col.key));
        let value = getNestedValue(row, col.key);
        if (value === null || value === undefined) {
          value = "-";
        }
        if (typeof value === "string") {
          value = value.replace(/"/g, '""'); // Escape double quotes
        }
        return `"${value}"`;
      })
      .join(",");
    csvContent += rowContent + "\n";
  });

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${props.exportTitle}_table_data.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  loadingExport.value = false;
};

// This Function returns the sum of all transactions, it takes in an array of transaction objects
const sumTransactionsDollars = (transactions) => {
  if (!transactions) return 0;
  return transactions.reduce(
    (acc, transaction) => acc + transaction.amount * transaction.price_per_unit,
    0,
  );
};

// This Function returns the sum of all transactions, it takes in an array of transaction objects
const sumTransactionsShares = (transactions) => {
  if (!transactions) return 0;
  return transactions.reduce((acc, transaction) => acc + transaction.amount, 0);
};

// Input Special Case
const handlePrecentChange = (row) => {
  if (!row.percent) row.percent = 0;
  row.dollar = (
    row.percent *
    (sumTransactionsDollars(row.transactions) / 100)
  ).toFixed(2);
  row.share = (
    row.percent *
    (sumTransactionsShares(row.transactions) / 100)
  ).toFixed(0);
};
const handleDollarChange = (row) => {
  if (!row.dollar) row.dollar = 0.0;
  row.percent = (
    row.dollar /
    (sumTransactionsDollars(row.transactions) / 100)
  ).toFixed(3);
};
const handleSharesChange = (row) => {
  if (!row.share) row.share = 0;
  row.percent = (
    row.share /
    (sumTransactionsShares(row.transactions) / 100)
  ).toFixed(3);
};

// This function is used to get nested values from an object to display in the columns
const getNestedValue = (obj, path) => {
  // Utility function to get nested values
  if (path === "") return obj;
  return path.split(".").reduce((acc, part) => {
    // Handle array indices in the path
    const match = part.match(/^(.+)\[(\d+)\]$/);
    if (match) {
      const arrayKey = match[1];
      const index = parseInt(match[2], 10);
      return acc && acc[arrayKey] && acc[arrayKey][index];
    }
    return acc && acc[part];
  }, obj);
};

// Callables
const getSelectedRows = () => { return selectedRows.value };

defineExpose({
  getSelectedRows,
});
</script>
