<template>
  <div class="flex items-center">
    <div class="relative rounded-md min-w-52">
      <div
        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
      >
        <MagnifyingGlassIcon
          class="w-4 h-4 text-gray-400 dark:text-neutral-400"
          aria-hidden="true"
        />
      </div>
      <input
        id="search"
        v-model="query"
        type="text"
        name="search"
        class="pl-8 input"
        placeholder="Quick Search"
      />
    </div>
  </div>
</template>

<script setup>
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
const query = defineModel({
  type: String,
  default: "",
});
</script>
