<template>
  <div class="p-5">
    <div class="text-lg dark:text-white font-semibold">Funding Method</div>
    <RadioGroup
      v-model="selected_funding_method"
      label="Payment Method"
      :options="availableFundingOptions"
      class="mt-2 mb-8 max-w-xl"
    />

    <div class="flex flex-row-reverse items-center justify-between">
      <button class="self-end button_primary" @click="handleClick('next')">
        Next
        <ArrowRightIcon class="w-4 h-4" />
      </button>
      <button
        class="self-end button_secondary"
        @click="handleClick('previous')"
      >
        <ArrowLeftIcon class="w-4 h-4" />
        Previous
      </button>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";

// Libraries
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/vue/24/outline";
import RadioGroup from "@/components/applicationinputs/AppRadioGroup.vue";

// Emits
const emit = defineEmits(["next", "previous"]);

// Models
const selected_funding_method = defineModel({
  type: Object,
  default: () => ({}),
});

// Globals
const availableFundingOptions = ref([
  {
    name: "ACH",
    id: "ach",
    description:
      "Connect directly to your bank securely using Plaid (Recommended)",
  },
  {
    name: "Check",
    id: "check",
    description: "Mail a check (The old fashioned way)",
  },
  {
    name: "Wire",
    id: "wire",
    description: "Receive wire instructions and pay directly (Fastest)",
  },
]);

// Mounted
onMounted(() => {
  selected_funding_method.value = availableFundingOptions.value[0];
});

// Functions
const handleClick = (action) => {
  emit(action);
};
</script>
