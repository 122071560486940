<template>
  <form class="flex flex-col gap-5 p-5" @submit.prevent="handleClick('next')">
    <!-- Employment Status -->
    <RadioHorizontal
      v-if="!props.isEntity"
      v-model="party.employment_status"
      :label="getEmploymentStatusLabel"
      :items="employmentOptions"
    />

    <!-- If Employed -->
    <div
      v-if="party.employment_status == 'employed'"
      class="flex flex-col gap-5 my-5"
    >
      <div class="flex flex-col gap-5 md:items-center md:flex-row">
        <!-- Occupation -->
        <div class="grow">
          <label for="occupation" class="input_label">Occupation</label>
          <div class="input_wrapper">
            <input
              id="occupation"
              v-model="party.occupation"
              type="text"
              name="occupation"
              class="input"
              placeholder="Software Engineer"
              required
            />
          </div>
        </div>
        <!-- Employer Name -->
        <div class="grow">
          <label for="employerName" class="input_label">Employer Name</label>
          <div class="input_wrapper">
            <input
              id="employerName"
              v-model="party.employer_name"
              type="text"
              name="employerName"
              class="input"
              placeholder="Apple"
              required
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-5 md:items-center md:flex-row">
        <!-- Employer Address -->
        <div class="grow">
          <label for="employerAddress" class="input_label"
            >Employer Address</label
          >
          <div class="input_wrapper">
            <input
              id="employerAddress"
              v-model="party.employer_address"
              type="text"
              name="employerAddress"
              class="input"
              placeholder="36 Fuller Place"
              required
            />
          </div>
        </div>
        <!-- Employer Apt/Unit -->
        <div class="">
          <label for="employerAptNumber" class="input_label"
            >Employer Apt/Unit</label
          >
          <div class="input_wrapper">
            <input
              id="employerAptNumber"
              v-model="party.employer_unit"
              type="text"
              name="employerAptNumber"
              class="input"
              placeholder="2"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-5 md:items-center md:flex-row">
        <!-- Employer City -->
        <div class="grow">
          <label for="employerCity" class="input_label">Employer City</label>
          <div class="input_wrapper">
            <input
              id="employerCity"
              v-model="party.employer_city"
              type="text"
              name="employerCity"
              class="input"
              placeholder="Brooklyn"
              required
            />
          </div>
        </div>
        <!-- Employer State -->
        <div class="grow">
          <label for="employerState" class="input_label">Employer State</label>
          <div class="input_wrapper">
            <SelectMenu
              v-model="party.employer_state"
              :items="states"
              :inverted="true"
            />
          </div>
        </div>
        <!-- Employer Country  -->
        <div class="grow">
          <label for="party.value.employer_country" class="input_label"
            >Employer Country</label
          >
          <div class="input_wrapper">
            <input
              id="party.value.employer_country"
              v-model="party.employer_country"
              type="text"
              name="party.value.employer_country"
              class="input"
              placeholder="U.S."
              required
            />
          </div>
        </div>
        <!-- Employer Zip  -->
        <div class="grow">
          <label for="employerZip" class="input_label">Employer Zip</label>
          <div class="input_wrapper">
            <input
              id="employerZip"
              v-model="party.employer_zip"
              type="text"
              name="employerZip"
              class="input"
              placeholder="11256"
              required
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Broker Dealer Check -->
    <RadioHorizontal
      v-model="party.is_associated_bd"
      :label="getAssociatedBDLabel"
      sub-label="An associated person is a partner, officer, director, or employee of a registered broker dealer."
      :items="[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]"
    />

    <div class="flex flex-row-reverse items-center justify-between mt-5">
      <button type="submit" class="self-end button_primary">
        Next
        <ArrowRightIcon class="w-4 h-4" />
      </button>
      <button
        class="self-end button_secondary"
        @click="handleClick('previous')"
      >
        <ArrowLeftIcon class="w-4 h-4" />
        Previous
      </button>
    </div>
  </form>
</template>

<script setup>
// Essentials
import { computed, watch } from "vue";
// Libraries
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/vue/24/outline";
import { states } from "@/helper/helper";
// Components
import RadioHorizontal from "@/components/applicationinputs/AppRadioGroupHorizontal.vue";
import SelectMenu from "@/components/applicationinputs/SelectMenu.vue";

// Models
const party = defineModel({
  type: Object,
  required: true,
});

// Props
const props = defineProps({
  isForOther: { type: Boolean, required: false, default: false },
});

// Emits
const emit = defineEmits(["next", "previous"]);

const employmentOptions = [
  { label: "Employed", value: "employed" },
  { label: "Retired", value: "retired" },
  { label: "Student", value: "student" },
  { label: "Unemployed", value: "unemployed" },
];
const getEmploymentStatusLabel = computed(() => {
  if (props.isForOther) return "What is the employment status of the client?";
  return "What is your employment status?";
});
const getAssociatedBDLabel = computed(() => {
  if (props.isForOther) return "Is the client associated with a broker dealer?";
  return "Are you associated with a broker dealer?";
});

const handleClick = (emitted) => {
  emit(emitted);
};

watch(
  () => party.value.employer_country,
  (newValue) => {
    const trimmedValue = newValue.trim().toUpperCase(); // Trim and convert to uppercase

    // Format based on common country codes
    switch (trimmedValue) {
      case "US":
      case "us":
      case "USA":
      case "UNITED STATES":
      case "AMERICA":
        party.value.employer_country = "U.S.A";
        break;
      case "CA":
      case "CAN":
      case "CANADA":
        party.value.employer_country = "Canada";
        break;
      case "UK":
      case "GB":
      case "UNITED KINGDOM":
        party.value.employer_country = "UK";
        break;
      // Add more cases for other countries as needed
      default:
        // Do nothing or handle other cases here
        break;
    }
  },
);
</script>
