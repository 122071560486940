<template>
  <div id="emails" class="px-4 sm:px-6 lg:px-8">
    <!-- Notification Popup -->
    <AppNotification ref="notification" :type="notificationType" :title="notificationTitle" :text="notificationText" />

    <!-- Investor Slideover -->
    <Slideover v-model="showInvestorSlideover" :partner="selectedInvestor.partners">
      <InvestorSlideover
        v-model="selectedInvestor"
        :partner="selectedInvestor.partners"
        @closed="showInvestorSlideover = false"
        @updated="setInvestors()"
      />
    </Slideover>

    <!-- Confirm Action Popup -->
    <AppConfirmAction
      v-model="show_broadcast_confirmation_popup"
      :message="confirmation_message"
      title="Confirm Email Broadcast"
      @confirmed="sendEmailBroadcast"
    />

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Ready -->
    <div v-else>
      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 flex-wrap dark:border-neutral-700">
          <!-- Tab Header -->
          <h3 class="primary_header">Email Broadcast</h3>
          <AppButtonPrimary text="Send Email Broadcast" size="lg" :icon="EnvelopeIcon" :loading="sending" @click="showConfirmPopup" />
          <!-- <div class="flex items-center flex-wrap gap-4"></div> -->
        </div>
      </div>

      <!-- Content -->
      <div class="flex flex-col gap-10">
        <!-- Emails Creation -->
        <div class="flex gap-10 flex-wrap">
          <!-- Email HTML/Text -->
          <div class="flex-1">
            <label for="additional-content" class="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">
              Email Content
            </label>
            <div class="text-sm text-gray-500 dark:text-neutral-400 pb-1">
              This should be in html format or plain text. We recommend using
              <a href="https://tabular.email/" target="_blank" class="text-blue-600 underline cursor-pointer">Tabular</a>
              to create custom emails. If you choose to use Tabular, when your email is looking the way you like in your tabular editor click the 'Use
              Email' button in the top right corner and choose HTML as your export option then copy the 'HTML' code generated and paste it here. Don't
              forget to click 'Save & Publish' at the bottom of the page to save your changes!
            </div>
            <SafeTextarea
              id="welcome_email_content"
              ref="safe_welcome_email_content"
              v-model="email_content"
              :rows="18"
              placeholder="Hello From Opera! Thanks for creating an account!"
            />
            <AppInput
              id="subject"
              ref="email_subject_ref"
              v-model="email_subject"
              label="Email Subject"
              placeholder="Your Email Subject"
              class="mt-5"
            />
          </div>
          <!-- Email Preview -->
          <div class="flex-1">
            <div class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">Email Preview</div>
            <div class="text-sm text-gray-500 dark:text-neutral-400 mb-3">
              If you are getting an error or do not like how your email is looking you can.
              <a class="text-orange-500 underline cursor-pointer font-semibold" @click="setDefaultEmailContent">Use Default Email</a>
            </div>
            <div class="rounded-lg shadow-opera w-full h-[600px] overflow-hidden">
              <iframe class="w-full h-full" :srcdoc="email_content"></iframe>
            </div>
          </div>
        </div>

        <!-- User Selection -->

        <!-- Search -->
        <div>
          <div class="border-b border-gray-200 mb-5 flex items-center justify-between flex-wrap gap-5 dark:border-neutral-700">
            <!-- Tab Header -->
            <div class="flex flex-col">
              <h3 class="primary_header">Select Recipients</h3>
              <div class="text-sm text-gray-500 dark:text-neutral-400 mb-3">
                Select all the users you would like to send your email broadcast to. You can search for users by their email, name, or user ID.<br />
                Once you have selected your users click the "Send Email Broadcast" button to send your email broadcast.
              </div>
            </div>

            <div class="flex items-center flex-wrap gap-4">
              <!-- Search -->
              <SearchInput v-model="query" />
            </div>
          </div>
        </div>

        <AppExportableTable
          ref="table_ref"
          v-model="columns"
          :loading="loading_first_investors"
          :loading_all="loading_all_investors"
          :rows="filteredInvestors"
          :is-clickable="true"
          class="-mt-10"
          @click-primary="handleInvestorClicked"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted, computed } from "vue";

// Stores
import { useProfilesStore } from "@/stores/profiles";
const profilesStore = useProfilesStore();
import { useEmailsStore } from "@/stores/emails";
const emailsStore = useEmailsStore();

// Libraries
import { EnvelopeIcon } from "@heroicons/vue/24/outline";

// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import AppNotification from "@/components/applicationui/AppNotification.vue";
import SafeTextarea from "@/components/applicationinputs/safeTextarea.vue";
import AppExportableTable from "@/components/applicationui/tables/AppExportableTable.vue";
import InvestorSlideover from "@/components/investors/InvestorSlideover.vue";
import Slideover from "@/components/applicationui/AppSlideover.vue";
import SearchInput from "@/components/applicationinputs/SearchInput.vue";
import AppButtonPrimary from "@/components/applicationui/AppButtonPrimary.vue";
import AppConfirmAction from "@/components/applicationui/AppConfirmAction.vue";
import AppInput from "@/components/applicationinputs/AppInput.vue";

const columns = ref([
  {
    key: "parties.first_name",
    key2: "parties.last_name",
    label: "Name",
    type: "two-strings",
    visible: true,
  },
  { key: "email", label: "Email", type: "bold", visible: true },
  { key: "badge", label: "Status", type: "badge", visible: true },
  { key: "parties.phone", label: "Phone", type: "default-fill", visible: false },
  { key: "parties.parties_protected.kyc", label: "KYC", type: "badge", visible: false },
  { key: "parties.parties_protected.aml", label: "AML", type: "badge", visible: false },
  {
    key: "parties.accounts[0].accounts_protected.accredited_status",
    label: "Accreditation",
    type: "badge",
    visible: false,
  },
  { key: "offering_names", label: "Offering", type: "badge-array", visible: false },
  {
    key: "parties.employment_status",
    label: "Employment Status",
    type: "badge",
    visible: false,
  },
  {
    key: "parties.employer_name",
    label: "Employer Name",
    type: "default-fill",
    visible: false,
  },
  {
    key: "parties.occupation",
    label: "Occupation",
    type: "capitalize",
    visible: false,
  },
  {
    key: "parties.employer_address",
    label: "Employer Address",
    type: "capitalize",
    visible: false,
  },
  {
    key: "parties.employer_unit",
    label: "Employer Unit #",
    type: "default-fill",
    visible: false,
  },
  {
    key: "parties.employer_city",
    label: "Employer City",
    type: "capitalize",
    visible: false,
  },
  {
    key: "parties.employer_state",
    label: "Employer State",
    type: "capitalize",
    visible: false,
  },
  {
    key: "parties.employer_zip",
    label: "Employer Zip",
    type: "default-fill",
    visible: false,
  },
  {
    key: "parties.employer_country",
    label: "Employer Country",
    type: "default-fill",
    visible: false,
  },

  { key: "parties.net_worth", label: "Net Worth", type: "money", visible: false },
  {
    key: "parties.average_annual_income",
    label: "Average Annual Income",
    type: "money",
    visible: false,
  },
  {
    key: "parties.average_household_income",
    label: "Average Household Income",
    type: "money",
    visible: false,
  },
  {
    key: "parties.current_annual_income",
    label: "Current Annual Income",
    type: "money",
    visible: false,
  },
  {
    key: "parties.current_household_income",
    label: "Current Household Income",
    type: "money",
    visible: false,
  },

  { key: "parties.address", label: "Address", type: "default-fill", visible: false },
  { key: "parties.zip", label: "Zip", type: "default-fill", visible: false },
  { key: "parties.city", label: "City", type: "capitalize", visible: false },
  { key: "parties.state", label: "State", type: "capitalize", visible: false },
  { key: "parties.country", label: "Country", type: "default-fill", visible: false },
  { key: "created_at", label: "Created", type: "date", visible: true },
]);

// Search START
const query = ref("");
const filteredInvestors = computed(() => {
  return query.value === ""
    ? investors.value
    : investors.value.filter((investor) => {
        // profile
        if (investor.email?.toLowerCase().includes(query.value.toLowerCase())) return true;
        if (investor.id?.toLowerCase().includes(query.value.toLowerCase())) return true;
        // party
        if (investor?.parties) {
          if (investor.parties.contact_email?.toLowerCase().includes(query.value.toLowerCase())) return true;
          if (investor.parties.first_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
          if (investor.parties.last_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
          if (investor.parties.tapi_party_id?.toLowerCase().includes(query.value.toLowerCase())) return true;
          if (investor.parties.phone?.toLowerCase().includes(query.value.toLowerCase())) return true;
          if (investor.parties.zip?.toLowerCase().includes(query.value.toLowerCase())) return true;
        }
        // offering
        if (investor.offering_names) {
          if (investor.offering_names.some((offering) => offering.toLowerCase().includes(query.value.toLowerCase()))) return true;
        }
      });
});
// Search END

// Slideover START
const showInvestorSlideover = ref(false);
const selectedInvestor = ref({});
const handleInvestorClicked = (investor) => {
  if (!investor.parties) return;
  selectedInvestor.value = investor.parties;
  showInvestorSlideover.value = true;
};
// Slideover END

// Set Users START
const investors = ref([]);
const loading_first_investors = ref(true);
const loading_all_investors = ref(true);
async function setInvestors() {
  // get all the parties
  const profiles = await profilesStore.getAllProfilesFillPartyAccountTransactionOfferingPaginated(0, 49);
  if (!profiles) {
    loading_first_investors.value = false;
    loading_all_investors.value = false;
    return;
  }

  // set the party badges based on the partner type
  profiles.forEach((profile) => {
    addBadgeToInvestor(profile);
    setOfferings(profile);
  });

  // set the investors to the returned and formatted parties
  investors.value = profiles;
  loading_first_investors.value = false;

  // get the rest of the parties
  getRestOfInvestors();
}

async function getRestOfInvestors() {
  if (investors.value.length < 49) {
    loading_all_investors.value = false;
    return;
  }
  const rest_of_profiles = await profilesStore.getAllProfilesFillPartyAccountTransactionOfferingPaginated(50);

  // set the party badges based on the partner type
  rest_of_profiles.forEach((profile) => {
    addBadgeToInvestor(profile);
  });

  investors.value = investors.value.concat(rest_of_profiles);
  loading_all_investors.value = false;
}

const addBadgeToInvestor = (investor) => {
  if (!investor.parties) {
    investor.badge = "Incomplete Profile";
    return;
  }
  if (investor?.parties.partners.partners_protected.partner_type == "dab") {
    investor.badge = investor?.parties?.accounts[0]?.accounts_protected?.accredited_status ?? "Not Accredited";
    return;
  } else {
    if (investor?.parties?.accounts[0]?.positions?.length > 0) {
      investor.badge = "Invested";
      return;
    } else if (investor?.parties?.accounts[0]?.transactions?.length > 0) {
      investor.badge = "Pending";
      return;
    } else {
      investor.badge = "Not Invested";
      return;
    }
  }
};

const setOfferings = (investor) => {
  if (!investor.parties) return;
  if (!investor.parties.accounts || investor.parties.accounts.length === 0) return;
  investor.parties.accounts.forEach((account) => {
    if (!account.transactions || account.transactions.length === 0) return;
    account.transactions.forEach((transaction) => {
      if (!transaction.offerings.name) return;
      if (!investor.offering_names) investor.offering_names = [];
      if (!investor.offering_names.includes(transaction.offerings.name)) {
        investor.offering_names.push(transaction.offerings.name);
      }
    });
  });
};
// Set Users END

// Email Content START
const email_content = ref("");
const email_subject = ref("");
const setDefaultEmailContent = () => {
  email_content.value = default_email_content.value;
};
// Email Content END

// Confirm Email Broadcast START
const email_subject_ref = ref(null);
const table_ref = ref(null);
const confirmation_message = ref("Are you sure you want to send this email broadcast to all 0 users selected?");
const show_broadcast_confirmation_popup = ref(false);
const showConfirmPopup = () => {
  if (!email_subject.value) {
    email_subject_ref.value.setErrorMessage("Email Subject is required");
    return;
  }
  const selected_rows = table_ref.value.getSelectedRows();
  if (selected_rows.length === 0) {
    notify("failure", "Oops", "Please select at least one user to send the email to.");
    return;
  }
  confirmation_message.value = `When you confirm this broadcast, your email will be sent to all '${selected_rows.length}' selected users. Are you sure you want to send this email broadcast?
  This action cannot be undone.`;
  show_broadcast_confirmation_popup.value = true;
};
// Confirm Email Broadcast END

// Send Email Broadcast START
const sending = ref(false);
const sendEmailBroadcast = async () => {
  show_broadcast_confirmation_popup.value = false;
  sending.value = true;
  const selected_rows = table_ref.value.getSelectedRows();
  const email_addresses = selected_rows.map((row) => row.email);
  const success = await emailsStore.sendBroadcast(email_subject.value, email_addresses, email_content.value);
  if (!success) {
    notify("failure", "Sorry", "There was an error sending the email broadcast. Please try again, if the issue persists contact support.");
    return;
  }
  sending.value = false;
  notify("success", "Success", "The email broadcast was successfully sent to all selected users.");
};
// Send Email Broadcast END

// Toasts START
const notification = ref(null);
const notificationType = ref("success");
const notificationTitle = ref("Success");
const notificationText = ref("");
const notify = (type, title, text) => {
  notificationType.value = type;
  notificationTitle.value = title;
  notificationText.value = text;
  notification.value.show();
};
// Toasts END

// Mounted START
const loading = ref(true);
onMounted(async () => {
  setDefaultEmailContent();

  // Set the investors
  await setInvestors();

  // Ready
  loading.value = false;
});
// Mounted END

// Example Email Content
const default_email_content = ref(`<!-- You can delete this and replace it with your own custom email content -->

<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
<head>
<title></title>
<meta charset="UTF-8" />
<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
<!--[if !mso]>-->
<meta http-equiv="X-UA-Compatible" content="IE=edge" />
<!--<![endif]-->
<meta name="x-apple-disable-message-reformatting" content="" />
<meta content="target-densitydpi=device-dpi" name="viewport" />
<meta content="true" name="HandheldFriendly" />
<meta content="width=device-width" name="viewport" />
<meta name="format-detection" content="telephone=no, date=no, address=no, email=no, url=no" />
<style type="text/css">
table {
border-collapse: separate;
table-layout: fixed;
mso-table-lspace: 0pt;
mso-table-rspace: 0pt
}
table td {
border-collapse: collapse
}
.ExternalClass {
width: 100%
}
.ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
line-height: 100%
}
body, a, li, p, h1, h2, h3 {
-ms-text-size-adjust: 100%;
-webkit-text-size-adjust: 100%;
}
html {
-webkit-text-size-adjust: none !important
}
body, #innerTable {
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale
}
#innerTable img+div {
display: none;
display: none !important
}
img {
Margin: 0;
padding: 0;
-ms-interpolation-mode: bicubic
}
h1, h2, h3, p, a {
line-height: inherit;
overflow-wrap: normal;
white-space: normal;
word-break: break-word
}
a {
text-decoration: none
}
h1, h2, h3, p {
min-width: 100%!important;
width: 100%!important;
max-width: 100%!important;
display: inline-block!important;
border: 0;
padding: 0;
margin: 0
}
a[x-apple-data-detectors] {
color: inherit !important;
text-decoration: none !important;
font-size: inherit !important;
font-family: inherit !important;
font-weight: inherit !important;
line-height: inherit !important
}
u + #body a {
color: inherit;
text-decoration: none;
font-size: inherit;
font-family: inherit;
font-weight: inherit;
line-height: inherit;
}
a[href^="mailto"],
a[href^="tel"],
a[href^="sms"] {
color: inherit;
text-decoration: none
}
</style>
<style type="text/css">
@media (min-width: 481px) {
.hd { display: none!important }
}
</style>
<style type="text/css">
@media (max-width: 480px) {
.hm { display: none!important }
}
</style>
<style type="text/css">
@media (max-width: 480px) {
.t101,.t102,.t131,.t132,.t42,.t43,.t71,.t72{display:block!important}.t101,.t131,.t168,.t190,.t222,.t42,.t71{text-align:left!important}.t247{padding:18px!important}.t249{width:480px!important}.t108,.t138,.t142,.t147,.t19,.t201,.t206,.t228,.t234,.t240,.t245,.t48,.t78{width:444px!important}.t113,.t24,.t53,.t83{width:0!important;display:none!important}.t100,.t115,.t130,.t26,.t41,.t55,.t70,.t85{vertical-align:top!important;display:inline-block!important}.t115,.t26,.t55,.t85{width:100%!important;max-width:50px!important}.t114,.t25,.t54,.t84{width:50px!important}.t100,.t130,.t41,.t70{width:100%!important;max-width:600px!important}.t212,.t217,.t221{vertical-align:middle!important}.t117,.t28,.t57,.t87{mso-line-height-alt:12px!important;line-height:12px!important;display:block!important}.t120,.t126,.t31,.t37,.t60,.t66,.t90,.t96{width:600px!important}.t152,.t175,.t197{width:384px!important}.t157,.t179,.t210,.t215{display:revert!important}.t212,.t217{width:36px!important}.t221{width:20px!important}.t159,.t167,.t181,.t189{vertical-align:top!important}.t163,.t185{width:354px!important}.t159,.t181{width:30px!important}.t167,.t189{width:600px!important}
}
</style>
<!--[if !mso]>-->
<link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&amp;family=Inter+Tight:wght@500&amp;family=Inter:wght@500&amp;display=swap" rel="stylesheet" type="text/css" />
<!--<![endif]-->
<!--[if mso]>
<xml>
<o:OfficeDocumentSettings>
<o:AllowPNG/>
<o:PixelsPerInch>96</o:PixelsPerInch>
</o:OfficeDocumentSettings>
</xml>
<![endif]-->
</head>
<body id="body" class="t253" style="min-width:100%;Margin:0px;padding:0px;background-color:#F0F0F0;"><div class="t252" style="background-color:#F0F0F0;"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" border="0" align="center"><tr><td class="t251" style="font-size:0;line-height:0;mso-line-height-rule:exactly;background-color:#F0F0F0;" valign="top" align="center">
<!--[if mso]>
<v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false">
<v:fill color="#F0F0F0"/>
</v:background>
<![endif]-->
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" border="0" align="center" id="innerTable"><tr><td align="center">
<table class="t250" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="690" class="t249" style="background-color:#F0F0F0;width:690px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t249" style="background-color:#F0F0F0;width:690px;">
<!--<![endif]-->
<table class="t248" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t247" style="padding:30px 30px 30px 30px;"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;"><tr><td align="left">
<table class="t20" role="presentation" cellpadding="0" cellspacing="0" style="Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t19" style="background-image:url(https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/5de382f2-34ba-4853-8fd9-8408475bc68d.png);background-repeat:no-repeat;background-size:contain;background-position:right top;width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t19" style="background-image:url(https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/5de382f2-34ba-4853-8fd9-8408475bc68d.png);background-repeat:no-repeat;background-size:contain;background-position:right top;width:630px;">
<!--<![endif]-->
<table class="t18" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t17"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;"><tr><td align="left">
<table class="t4" role="presentation" cellpadding="0" cellspacing="0" style="Margin-right:auto;"><tr>
<!--[if mso]>
<td width="68" class="t3" style="width:68px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t3" style="width:68px;">
<!--<![endif]-->
<table class="t2" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t1"><div style="font-size:0px;"><img class="t0" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="68" height="62.125" alt="" src="https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/1c8a55a4-7f9e-4c17-aa1f-a2f9486fd472.png"/></div></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t6" style="mso-line-height-rule:exactly;mso-line-height-alt:100px;line-height:100px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="left">
<table class="t10" role="presentation" cellpadding="0" cellspacing="0" style="Margin-right:auto;"><tr>
<!--[if mso]>
<td width="274" class="t9" style="width:274px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t9" style="width:274px;">
<!--<![endif]-->
<table class="t8" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t7"><h1 class="t5" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:48px;font-weight:700;font-style:normal;font-size:42px;text-decoration:none;text-transform:none;letter-spacing:-1.3px;direction:ltr;color:#212121;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">What’s new at Opera?</h1></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t12" style="mso-line-height-rule:exactly;mso-line-height-alt:12px;line-height:12px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="left">
<table class="t16" role="presentation" cellpadding="0" cellspacing="0" style="Margin-right:auto;"><tr>
<!--[if mso]>
<td width="399" class="t15" style="width:399px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t15" style="width:399px;">
<!--<![endif]-->
<table class="t14" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t13"><p class="t11" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:32px;font-weight:400;font-style:normal;font-size:22px;text-decoration:none;text-transform:none;direction:ltr;color:#666666;text-align:left;mso-line-height-rule:exactly;mso-text-raise:3px;">Monthly newsletter with design content and product announcements</p></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t203" style="mso-line-height-rule:exactly;mso-line-height-alt:55px;line-height:55px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t207" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t206" style="width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t206" style="width:630px;">
<!--<![endif]-->
<table class="t205" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t204"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;"><tr><td align="center">
<table class="t143" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t142" style="background-color:#FFFFFF;width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t142" style="background-color:#FFFFFF;width:630px;">
<!--<![endif]-->
<table class="t141" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t140" style="padding:26px 0 26px 0;"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;"><tr><td align="center">
<table class="t49" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t48" style="width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t48" style="width:630px;">
<!--<![endif]-->
<table class="t47" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t46" style="padding:0 30px 0 30px;"><div class="t45" style="width:100%;text-align:left;"><div class="t44" style="display:inline-block;"><table class="t43" role="presentation" cellpadding="0" cellspacing="0" align="left" valign="top">
<tr class="t42"><td></td><td class="t26" width="154" valign="top">
<div class="t22" style="mso-line-height-rule:exactly;mso-line-height-alt:4px;line-height:4px;font-size:1px;display:block;">&nbsp;&nbsp;</div>
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t25" style="width:154px;"><tr><td class="t23"><div style="font-size:0px;"><img class="t21" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="128" height="96" alt="" src="https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/34be364f-b25f-4242-b9a2-06b05c48768c.png"/></div></td><td class="t24" style="width:26px;" width="26"></td></tr></table>
</td><td class="t41" width="416" valign="top">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t40" style="width:100%;"><tr><td class="t39"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;">
<!--[if !mso]>-->
<tr><td><div class="t28" style="mso-line-height-rule:exactly;font-size:1px;display:none;">&nbsp;&nbsp;</div></td></tr>
<!--<![endif]-->
<tr><td align="center">
<table class="t32" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="416" class="t31" style="width:416px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t31" style="width:416px;">
<!--<![endif]-->
<table class="t30" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t29"><h2 class="t27" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:28px;font-weight:700;font-style:normal;font-size:22px;text-decoration:none;text-transform:none;letter-spacing:-0.6px;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Opera 2.4 release</h2></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t34" style="mso-line-height-rule:exactly;mso-line-height-alt:7px;line-height:7px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t38" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="416" class="t37" style="width:416px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t37" style="width:416px;">
<!--<![endif]-->
<table class="t36" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t35"><p class="t33" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:22px;font-weight:400;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#666666;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.</p></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td>
<td></td></tr>
</table></div></div></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t75" style="mso-line-height-rule:exactly;mso-line-height-alt:22px;line-height:22px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t79" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t78" style="width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t78" style="width:630px;">
<!--<![endif]-->
<table class="t77" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t76" style="padding:0 30px 0 30px;"><div class="t74" style="width:100%;text-align:left;"><div class="t73" style="display:inline-block;"><table class="t72" role="presentation" cellpadding="0" cellspacing="0" align="left" valign="top">
<tr class="t71"><td></td><td class="t55" width="154" valign="top">
<div class="t51" style="mso-line-height-rule:exactly;mso-line-height-alt:4px;line-height:4px;font-size:1px;display:block;">&nbsp;&nbsp;</div>
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t54" style="width:154px;"><tr><td class="t52"><div style="font-size:0px;"><img class="t50" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="128" height="96" alt="" src="https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/fce858b3-853d-4142-8172-e827c1222af9.png"/></div></td><td class="t53" style="width:26px;" width="26"></td></tr></table>
</td><td class="t70" width="416" valign="top">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t69" style="width:100%;"><tr><td class="t68"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;">
<!--[if !mso]>-->
<tr><td><div class="t57" style="mso-line-height-rule:exactly;font-size:1px;display:none;">&nbsp;&nbsp;</div></td></tr>
<!--<![endif]-->
<tr><td align="center">
<table class="t61" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="416" class="t60" style="width:416px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t60" style="width:416px;">
<!--<![endif]-->
<table class="t59" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t58"><h2 class="t56" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:28px;font-weight:700;font-style:normal;font-size:22px;text-decoration:none;text-transform:none;letter-spacing:-0.6px;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Tips and Tricks in Opera</h2></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t63" style="mso-line-height-rule:exactly;mso-line-height-alt:7px;line-height:7px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t67" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="416" class="t66" style="width:416px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t66" style="width:416px;">
<!--<![endif]-->
<table class="t65" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t64"><p class="t62" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:22px;font-weight:400;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#666666;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.</p></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td>
<td></td></tr>
</table></div></div></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t105" style="mso-line-height-rule:exactly;mso-line-height-alt:22px;line-height:22px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t109" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t108" style="width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t108" style="width:630px;">
<!--<![endif]-->
<table class="t107" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t106" style="padding:0 30px 0 30px;"><div class="t104" style="width:100%;text-align:left;"><div class="t103" style="display:inline-block;"><table class="t102" role="presentation" cellpadding="0" cellspacing="0" align="left" valign="top">
<tr class="t101"><td></td><td class="t85" width="154" valign="top">
<div class="t81" style="mso-line-height-rule:exactly;mso-line-height-alt:4px;line-height:4px;font-size:1px;display:block;">&nbsp;&nbsp;</div>
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t84" style="width:154px;"><tr><td class="t82"><div style="font-size:0px;"><img class="t80" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="128" height="96" alt="" src="https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/2a7e2f2a-8d57-4087-9c3f-fe83c3201be3.png"/></div></td><td class="t83" style="width:26px;" width="26"></td></tr></table>
</td><td class="t100" width="416" valign="top">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t99" style="width:100%;"><tr><td class="t98"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;">
<!--[if !mso]>-->
<tr><td><div class="t87" style="mso-line-height-rule:exactly;font-size:1px;display:none;">&nbsp;&nbsp;</div></td></tr>
<!--<![endif]-->
<tr><td align="center">
<table class="t91" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="416" class="t90" style="width:416px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t90" style="width:416px;">
<!--<![endif]-->
<table class="t89" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t88"><h2 class="t86" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:28px;font-weight:700;font-style:normal;font-size:22px;text-decoration:none;text-transform:none;letter-spacing:-0.6px;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Visual Direction in Interaction Design</h2></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t93" style="mso-line-height-rule:exactly;mso-line-height-alt:7px;line-height:7px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t97" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="416" class="t96" style="width:416px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t96" style="width:416px;">
<!--<![endif]-->
<table class="t95" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t94"><p class="t92" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:22px;font-weight:400;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#666666;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu</p></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td>
<td></td></tr>
</table></div></div></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t135" style="mso-line-height-rule:exactly;mso-line-height-alt:22px;line-height:22px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t139" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t138" style="width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t138" style="width:630px;">
<!--<![endif]-->
<table class="t137" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t136" style="padding:0 30px 0 30px;"><div class="t134" style="width:100%;text-align:left;"><div class="t133" style="display:inline-block;"><table class="t132" role="presentation" cellpadding="0" cellspacing="0" align="left" valign="top">
<tr class="t131"><td></td><td class="t115" width="154" valign="top">
<div class="t111" style="mso-line-height-rule:exactly;mso-line-height-alt:4px;line-height:4px;font-size:1px;display:block;">&nbsp;&nbsp;</div>
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t114" style="width:154px;"><tr><td class="t112"><div style="font-size:0px;"><img class="t110" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="128" height="96" alt="" src="https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/3f65516f-6848-4752-a351-120e316fec50.png"/></div></td><td class="t113" style="width:26px;" width="26"></td></tr></table>
</td><td class="t130" width="416" valign="top">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t129" style="width:100%;"><tr><td class="t128"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;">
<!--[if !mso]>-->
<tr><td><div class="t117" style="mso-line-height-rule:exactly;font-size:1px;display:none;">&nbsp;&nbsp;</div></td></tr>
<!--<![endif]-->
<tr><td align="center">
<table class="t121" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="416" class="t120" style="width:416px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t120" style="width:416px;">
<!--<![endif]-->
<table class="t119" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t118"><h2 class="t116" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:28px;font-weight:700;font-style:normal;font-size:22px;text-decoration:none;text-transform:none;letter-spacing:-0.6px;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Opera Product Virtual Conference</h2></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t123" style="mso-line-height-rule:exactly;mso-line-height-alt:7px;line-height:7px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t127" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="416" class="t126" style="width:416px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t126" style="width:416px;">
<!--<![endif]-->
<table class="t125" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t124"><p class="t122" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:22px;font-weight:400;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#666666;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec.</p></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td>
<td></td></tr>
</table></div></div></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td></tr></table>
</td></tr><tr><td align="center">
<table class="t148" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t147" style="width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t147" style="width:630px;">
<!--<![endif]-->
<table class="t146" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t145"><div style="font-size:0px;"><img class="t144" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="630" height="359.78125" alt="" src="https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/6969d4e3-636c-43e2-a27b-95cb9dc11048.webp"/></div></td></tr></table>
</td></tr></table>
</td></tr><tr><td align="center">
<table class="t202" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t201" style="background-color:#FFFFFF;width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t201" style="background-color:#FFFFFF;width:630px;">
<!--<![endif]-->
<table class="t200" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t199" style="padding:22px 30px 22px 30px;"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;"><tr><td align="center">
<table class="t153" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="570" class="t152" style="width:570px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t152" style="width:570px;">
<!--<![endif]-->
<table class="t151" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t150"><h2 class="t149" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:28px;font-weight:700;font-style:normal;font-size:22px;text-decoration:none;text-transform:none;letter-spacing:-0.6px;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Our favorite links this month</h2></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t154" style="mso-line-height-rule:exactly;mso-line-height-alt:10px;line-height:10px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td><div class="t172" style="mso-line-height-rule:exactly;mso-line-height-alt:6px;line-height:6px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t176" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="570" class="t175" style="width:570px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t175" style="width:570px;">
<!--<![endif]-->
<table class="t174" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t173"><div class="t171" style="width:100%;text-align:left;"><div class="t170" style="display:inline-block;"><table class="t169" role="presentation" cellpadding="0" cellspacing="0" align="left" valign="top">
<tr class="t168"><td></td><td class="t159" width="30" valign="top">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t158" style="width:30px;"><tr><td class="t156"><p class="t155" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:22px;font-weight:400;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#666666;text-align:right;mso-line-height-rule:exactly;mso-text-raise:2px;">•</p></td><td class="t157" style="width:10px;" width="10"></td></tr></table>
</td><td class="t167" width="540" valign="top">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t166" style="width:100%;"><tr><td class="t165"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;"><tr><td align="center">
<table class="t164" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="540" class="t163" style="width:540px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t163" style="width:540px;">
<!--<![endif]-->
<table class="t162" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t161"><p class="t160" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:22px;font-weight:400;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#666666;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Put a link here</p></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td>
<td></td></tr>
</table></div></div></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t194" style="mso-line-height-rule:exactly;mso-line-height-alt:6px;line-height:6px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t198" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="570" class="t197" style="width:570px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t197" style="width:570px;">
<!--<![endif]-->
<table class="t196" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t195"><div class="t193" style="width:100%;text-align:left;"><div class="t192" style="display:inline-block;"><table class="t191" role="presentation" cellpadding="0" cellspacing="0" align="left" valign="top">
<tr class="t190"><td></td><td class="t181" width="30" valign="top">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t180" style="width:30px;"><tr><td class="t178"><p class="t177" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:22px;font-weight:400;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#666666;text-align:right;mso-line-height-rule:exactly;mso-text-raise:2px;">•</p></td><td class="t179" style="width:10px;" width="10"></td></tr></table>
</td><td class="t189" width="540" valign="top">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t188" style="width:100%;"><tr><td class="t187"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;"><tr><td align="center">
<table class="t186" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="540" class="t185" style="width:540px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t185" style="width:540px;">
<!--<![endif]-->
<table class="t184" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t183"><p class="t182" style="margin:0;Margin:0;font-family:IBM Plex Sans,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:22px;font-weight:400;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#666666;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Put an even better link here</p></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td>
<td></td></tr>
</table></div></div></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t242" style="mso-line-height-rule:exactly;mso-line-height-alt:55px;line-height:55px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="center">
<table class="t246" role="presentation" cellpadding="0" cellspacing="0" style="Margin-left:auto;Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t245" style="width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t245" style="width:630px;">
<!--<![endif]-->
<table class="t244" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t243"><table role="presentation" width="100%" cellpadding="0" cellspacing="0" style="width:100% !important;"><tr><td align="left">
<table class="t229" role="presentation" cellpadding="0" cellspacing="0" style="Margin-right:auto;"><tr>
<!--[if mso]>
<td width="630" class="t228" style="width:630px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t228" style="width:630px;">
<!--<![endif]-->
<table class="t227" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t226"><div class="t225" style="width:100%;text-align:left;"><div class="t224" style="display:inline-block;"><table class="t223" role="presentation" cellpadding="0" cellspacing="0" align="left" valign="middle">
<tr class="t222"><td></td><td class="t212" width="36" valign="middle">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t211" style="width:36px;"><tr><td class="t209"><div style="font-size:0px;"><img class="t208" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="20" height="20" alt="" src="https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/b8c01e2b-6621-4b1b-8bfb-f6bcfb2cffd3.png"/></div></td><td class="t210" style="width:16px;" width="16"></td></tr></table>
</td><td class="t217" width="36" valign="middle">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t216" style="width:36px;"><tr><td class="t214"><div style="font-size:0px;"><img class="t213" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="20" height="20" alt="" src="https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/fa6cfd4b-9781-4a65-8b00-585f82bb7072.png"/></div></td><td class="t215" style="width:16px;" width="16"></td></tr></table>
</td><td class="t221" width="20" valign="middle">
<table role="presentation" width="100%" cellpadding="0" cellspacing="0" class="t220" style="width:20px;"><tr><td class="t219"><div style="font-size:0px;"><img class="t218" style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width="20" height="20" alt="" src="https://127119e0-68f0-475e-b507-3d20de717b21.b-cdn.net/e/36b6dfa7-1d99-405e-aca8-589d9dee7d88/49d405ea-6a51-4d85-a7ee-b127c5d3ec01.png"/></div></td></tr></table>
</td>
<td></td></tr>
</table></div></div></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t230" style="mso-line-height-rule:exactly;mso-line-height-alt:55px;line-height:55px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="left">
<table class="t235" role="presentation" cellpadding="0" cellspacing="0" style="Margin-right:auto;"><tr>
<!--[if mso]>
<td width="550" class="t234" style="width:550px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t234" style="width:550px;">
<!--<![endif]-->
<table class="t233" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t232"><p class="t231" style="margin:0;Margin:0;font-family:Inter Tight,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:18px;font-weight:500;font-style:normal;font-size:12px;text-decoration:none;text-transform:none;direction:ltr;color:#777777;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">We&#39;re here if you need us! Drop us a line at&nbsp; opera@operaalts.com or simply reply to this email. Need some alone time? We get it! You can always unsubscribe.</p></td></tr></table>
</td></tr></table>
</td></tr><tr><td><div class="t237" style="mso-line-height-rule:exactly;mso-line-height-alt:31px;line-height:31px;font-size:1px;display:block;">&nbsp;&nbsp;</div></td></tr><tr><td align="left">
<table class="t241" role="presentation" cellpadding="0" cellspacing="0" style="Margin-right:auto;"><tr>
<!--[if mso]>
<td width="550" class="t240" style="width:550px;">
<![endif]-->
<!--[if !mso]>-->
<td class="t240" style="width:550px;">
<!--<![endif]-->
<table class="t239" role="presentation" cellpadding="0" cellspacing="0" width="100%" style="width:100%;"><tr><td class="t238"><p class="t236" style="margin:0;Margin:0;font-family:Inter,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;line-height:18px;font-weight:500;font-style:normal;font-size:12px;text-decoration:none;text-transform:none;direction:ltr;color:#777777;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Visit our Privacy Policy to learn your rights and how we use your data.</p></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table>
</td></tr></table>
</td></tr></table></td></tr></table></div><div class="gmail-fix" style="display: none; white-space: nowrap; font: 15px courier; line-height: 0;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div></body>
</html>`);
</script>
