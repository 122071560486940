<template>
  <div class="max-w-3xl">
    <form
      v-if="page == 1"
      class="flex flex-col sm:p-5 gap-7"
      @submit.prevent="showNext()"
    >
      <!-- Top Row Page One -->
      <div>
        <!-- Account Type -->
        <div class="select_menu_label">Account Type</div>
        <SelectMenu
          v-model="registration"
          :items="registrationOptions"
          class="w-40"
        />
        <p class="text-sm text-gray-600 dark:text-neutral-500 max-w-2xl mt-2">
          If you would like to create a joint account but do not see (Joint) as
          an option or if you would like to rollover and existing account please
          contact us at
          <a
            class="text-blue-600 cursor-pointer"
            href="mailto:custody-ops@northcapital.com"
            >custody-ops@northcapital.com</a
          >
        </p>
      </div>

      <div class="grow w-72">
        <label for="name" class="input_label">Account Nickname</label>
        <div class="input_wrapper">
          <input
            id="name"
            v-model="name"
            type="text"
            name="name"
            class="input"
            placeholder="John's Brokerage Account"
            required
          />
        </div>
      </div>

      <!-- Primary Select -->
      <div class="max-w-72">
        <div class="select_menu_label">Primary Account Holder</div>
        <SelectMenu
          v-model="primary"
          :items="partiesStore.parties ?? []"
          :inverted="true"
        />
      </div>

      <!-- Secondary Select -->
      <div v-if="showSecondary" class="max-w-72">
        <div class="select_menu_label">Secondary Account Holder</div>
        <SelectMenu
          v-model="secondary"
          :items="partiesStore.parties ?? []"
          :inverted="true"
        />
      </div>

      <!-- Accredited Check -->
      <div
        class="flex flex-wrap items-center gap-5 text-sm font-medium leading-6 mr-36"
      >
        <RadioHorizontal
          v-model="isAccreditedInvestor"
          label="Are all account holders accredited investors?"
          info="An accredited investor is if an individual with a gross income over $200,000 for the last two years, or joint income $300,000 and a reasonable expectation of the same income level for the current year. A person is also considered an accredited investor if they have a net worth over $1 million, either individually or jointly with their spouse. This amount cannot include a primary residence."
          :items="[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]"
        />
      </div>

      <!-- Tax Withholding Check -->
      <div
        class="flex flex-wrap items-center gap-5 text-sm font-medium leading-6"
      >
        <RadioHorizontal
          v-model="isSubjectToWitholding"
          label="Do you certify that you are not subject to IRS tax withholding?"
          sub-label="Under penalty of perjury, I certify that: (1) I am a U.S. citizen, U.S. resident alien, or other U.S. person, and the Social Security Number or Taxpayer Identification Number provided in this Application is correct (or I am waiting for a number to be issued to me); and (2) I am not subject to backup withholding because: (a) I am exempt from backup withholding; or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of failure to report all interest or dividends; or (c) the IRS has notified me that I am no longer subject to backup withholding"
          :items="[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]"
        />
      </div>

      <!-- Accreditation Section -->
      <div
        v-if="page == isAccreditedInvestor"
        class="max-w-2xl dark:text-neutral-300"
      >
        <AccreditationInfo />

        <div class="sm:m-7 m-1 mt-5">
          <DragDrop @changed-files="updateDTObj" />
        </div>
      </div>

      <!-- Next buttons -->
      <div class="flex flex-row-reverse items-center justify-between">
        <button type="submit" class="button_primary">
          Next
          <ArrowRightIcon class="w-4 h-4" />
        </button>
      </div>
    </form>

    <!-- Attestation PDF -->
    <div v-if="page == 2" class="flex flex-col pt-4 sm:p-5 gap-7">
      <div class="">
        <div class="h-[800px] text_color_secondary overflow-hidden rounded-lg">
          <div>
            By signing below, the account owner states, acknowledges, and agrees
            as follows:
          </div>
          <!-- <div>See in browser: <a class="text-blue-600" :href="attestationUrl" target="_blank">Account_Attestation.pdf</a></div> -->

          <iframe
            src="https://hubs.ly/Q02MZBr30"
            class="mt-2 w-full h-[800px] rounded-lg overflow-scroll"
            frameborder="0"
          ></iframe>

          <!-- <object class="mt-2 w-full h-[800px] rounded-lg overflow-scroll"
            data="https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/attestationVersions/accountAttestation_v5.pdf" type="application/pdf">
            <div>No online PDF viewer installed</div>
          </object> -->
        </div>
      </div>

      <!-- Attestation checkbox -->
      <div class="flex flex-col gap-2">
        <div class="relative flex items-start">
          <div class="flex items-center h-6">
            <input
              id="comments"
              v-model="hasAttested"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              class="w-4 h-4 border-gray-300 rounded text-operacolor focus:ring-operacolor"
            />
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="comments" class="font-medium text_color_secondary"
              >I have read these terms and conditions and accept them</label
            >
          </div>
        </div>

        <div class="relative flex items-start">
          <div class="flex items-center h-6">
            <input
              id="emailAttestation"
              v-model="hasAttestedToEmail"
              aria-describedby="comments-description"
              name="emailAttestation"
              type="checkbox"
              class="w-4 h-4 border-gray-300 rounded text-operacolor focus:ring-operacolor"
            />
          </div>
          <div class="ml-3 text-sm leading-6">
            <label
              for="emailAttestation"
              class="font-medium text_color_secondary"
              >I agree to receive communications regarding my account
              exclusively through email</label
            >
          </div>
        </div>
      </div>

      <div class="flex flex-row-reverse items-center justify-between">
        <button
          class="button_primary self-end"
          :disabled="!hasAttested || !hasAttestedToEmail"
          @click="handleOpenAccount()"
        >
          Open Account
        </button>
        <div class="button_secondary" @click="showPrevious()">Previous</div>
      </div>
    </div>

    <div
      v-else-if="page == 3"
      class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[50vh] w-[90vw]"
    >
      <div class="h-40 w-40 -mt-20">
        <SpinnerJump />
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { usePartiesStore } from "@/stores/parties";
import { useAccountsStore } from "@/stores/accounts";
import { useEmailsStore } from "@/stores/emails";
// Components
import SelectMenu from "@/components/applicationinputs/SelectMenu.vue";
import AccreditationInfo from "@/components/custody/AccreditationInfo.vue";
import DragDrop from "@/components/applicationinputs/documents/DragDrop.vue";
import SpinnerJump from "@/components/loading/SpinnerJump.vue";
import RadioHorizontal from "@/components/applicationinputs/AppRadioGroupHorizontal.vue";
// Libraries
import { ArrowRightIcon } from "@heroicons/vue/24/outline";
// Props
const props = defineProps({
  user: { type: Object, required: true, default: null },
});
// Emits
const emit = defineEmits(["next"]);
// Stores
const userStore = useUserStore();
const partiesStore = usePartiesStore();
const accountsStore = useAccountsStore();
const emailsStore = useEmailsStore();
// Globals
const attestationUrl = 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/attestationVersions/accountAttestation_v5.pdf';
const page = ref(1);
const noDocuments = ref(false);
const dt = ref(new DataTransfer());
const showSecondary = ref(false);
const isAccreditedInvestor = ref(false);
const isSubjectToWitholding = ref(true);
const hasAttested = ref(false);
const hasAttestedToEmail = ref(false);
const name = ref("");
const primary = ref(props.user.parties);
const secondary = ref({});
const registrationOptions = ref([
  { id: 1, label: "Individual", value: "individual" },
  { id: 2, label: "IRA", value: "ira" },
  { id: 3, label: "SEP IRA", value: "sep ira" },
  { id: 4, label: "ROTH IRA", value: "roth ira" },
]);
const registration = ref({ id: 1, label: "Individual", value: "individual" });

// Mounted
onMounted(async () => {
  // Get the users parties
  if (!partiesStore.parties || partiesStore.parties.length <= 0)
    await partiesStore.setParties();

  // If the users has more then one party, show additional account type options
  if (partiesStore.parties.length > 1)
    registrationOptions.value.push(
      { id: 5, label: "Joint", value: "joint" },
      { id: 6, label: "JTWROS", value: "jtwros" },
      { id: 7, label: "Joint TIC", value: "joint tic" },
    );
});

// Functions
const handleOpenAccount = async () => {
  //First Check that the input values are all good.
  if (!hasAttested.value) return;
  if (!hasAttestedToEmail.value) return;
  page.value = 3;
  if (!primary.value.address) {
    page.value = 1;
    return;
  } else if (!primary.value.city) {
    page.value = 1;
    return;
  } else if (!primary.value.state) {
    page.value = 1;
    return;
  } else if (!primary.value.zip) {
    page.value = 1;
    return;
  } else if (!primary.value.country) {
    page.value = 1;
    return;
  } else if (secondary.value?.id) {
    if (primary.value.id == secondary.value?.id) {
      page.value = 1;
      return;
    }
  } else if (isAccreditedInvestor.value && dt.value.files.length < 1) {
    page.value = 1;
    noDocuments.value = true;
    return;
  }

  // Create account in TAPI
  const tapi_account_id = await accountsStore.createAccountInTapi(
    primary.value,
    registration.value.value,
    secondary.value?.id,
  );

  // Create account row in supabase
  const account = await accountsStore.createAccountInSupabase(
    tapi_account_id,
    userStore.user.profiles_protected.partner_id,
    primary.value.id,
    registration.value.value,
    name.value,
    secondary.value?.id,
    !isSubjectToWitholding.value,
  );

  // Create primary link in TAPI
  await accountsStore.createLinkInTapi(
    tapi_account_id,
    primary.value.tapi_party_id,
    account.id,
  );

  // RequestCustodialAccount (We don't need to wait for this to return right)
  accountsStore.requestCustodialAccount(account.id, tapi_account_id);

  //Send accredited documents if investor is accredited
  if (isAccreditedInvestor.value)
    await uploadAccreditedDocs(
      account.id,
      tapi_account_id,
      userStore.user.id,
      dt.value,
    );

  //Add additional account info in BARC (We do not need to wait for this to return)
  accountsStore.createCustodyAccountBarc(tapi_account_id, name.value);

  //If this is a joint account link the second party
  if (
    registration.value.value == "joint" ||
    registration.value.value == "jtwros" ||
    registration.value.value == "joint tic"
  ) {
    await accountsStore.createLinkInTapi(
      tapi_account_id,
      secondary.value?.tapi_party_id,
      account.id,
      "owner",
      0,
    );
  }

  // Send notification email to custody team
  emailsStore.sendCustodyAccountCreatedNotification(
    tapi_account_id,
    primary.value.first_name,
    primary.value.last_name,
    primary.value.contact_email,
  );

  //Close successfully
  emit("next", account);
  page.value = 1;
  hasAttested.value = false;
};

async function uploadAccreditedDocs(account_id, tapi_account_id, user_id, dt) {
  // Update AI Request Status
  await accountsStore.requestAiVerification(account_id, tapi_account_id);

  const promises = [];

  for (let newFile of dt.files) {
    // Push upload to tapi promise to array
    const promise = accountsStore.uploadAccreditationDocumentToTapi(
      account_id,
      tapi_account_id,
      newFile,
    );
    promises.push(promise);
    // Push upload to supabase promise to array
    const promise2 = accountsStore.uploadAccreditationDocumentToSupabase(
      user_id,
      account_id,
      newFile,
    );
    promises.push(promise2);
  }

  // Wait for all promises to resolve
  await Promise.all(promises);
}

// Simple Functions
const showNext = () => {
  page.value = 2;
};

const showPrevious = () => {
  page.value = 1;
};

const updateDTObj = (dtObj) => {
  dt.value = dtObj;
  if (dt.value.files.length > 0) {
    noDocuments.value = false;
  }
};

// Watchers
watch(registration, (value) => {
  if (
    value.value == "joint" ||
    value.value == "jtwros" ||
    value.value == "joint tic"
  ) {
    secondary.value = partiesStore.parties[1];
    showSecondary.value = true;
  } else showSecondary.value = false;
});
</script>
