<template>
  <div>
    <!-- Loading -->
    <div
      v-if="loading"
      class="w-full md:h-[60vh] h-[50vh] flex items-center justify-center"
    >
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Transactions Table -->
    <TablePrimary
      v-else
      :rows="transactions"
      :columns="columns"
      :is-clickable="false"
      export_title="transactions"
      empty_message="No Transactions"
      class="-mt-5"
    />
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";
import { useTransactionsStore } from "@/stores/transactions";
// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import TablePrimary from "@/components/applicationui/tables/LegacyTablePrimary.vue";
// Props
const props = defineProps({
  account: {
    type: Object,
    required: true,
    default: null,
  },
});
// Stores
const transactionsStore = useTransactionsStore();
// Globals
const transactions = ref([]);
const loading = ref(true);
const columns = [
  { key: "offerings.name", label: "Offering" },
  { key: "status", label: "Status", type: "badge" },
  { key: "type", label: "Type", type: "badge" },
  { key: "amount", label: "Amount", type: "money" },
  { key: "price_per_unit", label: "Price Per Unit", type: "money" },
  { key: "price_per_unit", label: "Total", type: "total", key2: "amount" },
  { key: "units", label: "Units", type: "badge" },
  { key: "created_at", label: "Purchase Date", type: "date" },
];
// Mounted
onMounted(async () => {
  await setTransactions(props.account.id);
  loading.value = false;
});

// Functions
async function setTransactions(account_id) {
  //For now I like to keep this function here instead of passing a prop because I like that is keeps the data fresh
  transactions.value =
    await transactionsStore.getAllTransactionByAccountId(account_id);
  if (!transactions.value) transactions.value = []; //TODO handle the potential error better
  transactions.value = transactions.value.map((transaction) => {
    if (!transaction.offerings && transaction.units == "cash")
      transaction.offerings = { name: "$ Cash" };
    return transaction;
  });
}
</script>
