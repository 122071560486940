<template>
  <div
    class="relative max-w-lg min-w-72 overflow-hidden rounded-lg bg-white dark:bg-neutral-800 dark:border border-neutral-700 px-4 pt-5 shadow dark:shadow-none sm:px-6 sm:pt-6"
  >
    <dt>
      <div class="absolute rounded-md bg-operacolor p-2">
        <component
          :is="props.icon"
          class="size-8 text-white"
          aria-hidden="true"
        />
      </div>
      <p
        class="ml-16 truncate text-sm font-medium text-gray-500 dark:text-neutral-400"
      >
        {{ props.title }}
      </p>
    </dt>
    <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
      <p class="text-2xl font-semibold text-gray-900 dark:text-white">
        {{ props.value }}
      </p>
      <div
        :class="[
          props.type === 'increase' ? 'text-green-600' : 'text-red-600',
          'ml-2 flex items-baseline text-sm font-semibold',
        ]"
      >
        <ArrowUpIcon
          v-if="props.type === 'increase'"
          class="size-5 shrink-0 self-center text-green-500"
          aria-hidden="true"
        />
        <ArrowDownIcon
          v-else-if="props.type === 'decrease'"
          class="size-5 shrink-0 self-center text-red-500"
          aria-hidden="true"
        />
        <div
          v-else
          class="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-400/10 dark:ring-gray-400/20 dark:text-gray-400 shrink-0"
          aria-hidden="true"
        >
          No change
        </div>
        <span class="sr-only">
          {{ props.type === "increase" ? "Increased" : "Decreased" }} by
        </span>
        {{ computedChangeValue }}
      </div>
    </dd>
  </div>
</template>

<script setup>
import { computed } from "vue";

// Libraries
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/vue/20/solid";

// Props
const props = defineProps({
  title: { type: String, required: true },
  value: { type: String, required: true },
  change: { type: String, required: false, default: '' },
  type: { type: String, required: false, default: 'no-change' },
  icon: { type: [Object, Function], required: true },
});

// Computed
const computedChangeValue = computed(() => {
  if (props.type === "increase" || props.type === "decrease") {
    return props.change;
  }
  return "";
});
</script>
