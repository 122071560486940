<template>
  <Combobox v-model="selectedModel" as="div">
    <ComboboxLabel
      class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300 mb-2"
      >{{ props.label }}</ComboboxLabel
    >
    <div class="relative mt-2">
      <ComboboxInput
        :placeholder="props.placeholder"
        class="w-full rounded-md border-0 bg-white dark:bg-neutral-800 py-1.5 pl-3 pr-12 text-gray-900 dark:text-gray-200 ring-1 ring-gray-300 dark:ring-neutral-700 shadow-sm focus:ring-2 focus:ring-inset focus:ring-operacolor sm:text-sm sm:leading-6"
        :display-value="(item) => getSelectedString(item)"
        @change="query = $event.target.value"
      />
      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none"
      >
        <ChevronUpDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredItems.length > 0"
        class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg dark:bg-neutral-800 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="item in filteredItems"
          :key="item"
          v-slot="{ active, selected }"
          :value="item"
          as="template"
        >
          <li
            :class="[
              'relative select-none py-2 pl-3 pr-9 cursor-pointer',
              active
                ? 'bg-operacolor text-white'
                : 'text-gray-900 dark:text-neutral-300',
            ]"
          >
            <div class="flex">
              <span :class="['whitespace-nowrap', selected && 'font-semibold']">
                {{ getFilteredPrimaryString(item) }}
              </span>
              <span
                :class="[
                  'ml-2 truncate text-gray-500',
                  active ? 'text-indigo-200' : 'text-gray-500',
                ]"
              >
                {{ getFilteredSecondaryString(item) }}
              </span>
            </div>

            <span
              v-if="selectedModel"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-operacolor',
              ]"
            >
              <CheckIcon class="w-5 h-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup>
// Essentials
import { ref, computed } from "vue";

// Libraries
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

// Model
const selectedModel = defineModel({ type: Object });

// Props
const props = defineProps({
  items: { type: Array, required: true, default: null },
  label: { type: String, required: false, default: "" },
  type: { type: String, required: false, default: "text" },
  placeholder: { type: String, required: false, default: "" },
});

const getSelectedString = (item) => {
  if (props.type == "offering") return item?.name;
  return item;
};

const getFilteredPrimaryString = (item) => {
  if (props.type == "offering") return item?.name;
  return item;
};

const getFilteredSecondaryString = (item) => {
  if (props.type == "offering") return item?.id;
  return "";
};

// Search
const query = ref("");
const filteredItems = computed(() => {
  return query.value === ""
    ? props.items
    : props.items.filter((item) => {
        if (props.type == "offering")
          return item.name.toLowerCase().includes(query.value.toLowerCase());
        else return item.toLowerCase().includes(query.value.toLowerCase());
      });
});
</script>
