<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Popups -->
    <!-- Create Partner -->
    <div v-if="showPartnerPopup">
      <PartnerEdit
        :partner="newPartner"
        @closed="cleanAndClosePartnerEdit"
        @refresh="setPartners()"
      />
    </div>
    <!-- Popups -->

    <!-- Loading -->
    <div
      v-if="loading"
      class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center"
    >
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Empty State -->
    <div v-else-if="partners.length <= 0" class="text-center">
      <UserGroupIcon class="w-12 h-12 mx-auto text-gray-400" />
      <h3
        class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300"
      >
        No Partners
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Get started by creating a new partner.<br />You must fill out your
        profile information first.
      </p>
      <div class="mt-6">
        <button
          type="button"
          class="button_primary"
          @click="showPartnerPopup = true"
        >
          Create Partner
          <PlusIcon class="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
    </div>

    <!-- Partners -->
    <div v-else>
      <!-- Header -->
      <div>
        <div
          class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 flex-wrap dark:border-neutral-700"
        >
          <!-- Tab Header -->
          <h3 class="primary_header">Partners</h3>

          <div class="flex items-center gap-4 flex-wrap">
            <!-- Search -->
            <div class="flex items-center gap-2">
              <div>
                <div class="relative rounded-md">
                  <div
                    class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                  >
                    <MagnifyingGlassIcon
                      class="w-4 h-4 text-gray-400 dark:text-neutral-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    v-model="query"
                    type="text"
                    name="search"
                    class="pl-8 input"
                    placeholder="Quick Search"
                  />
                </div>
              </div>
            </div>
            <!-- Create Investor -->
            <div class="button_secondary" @click="quickCreate('dib')">
              Quick DIB
              <BeakerIcon class="w-4 h-5 text-green-600" />
            </div>
            <div class="button_secondary" @click="quickCreate('dab')">
              Quick DAB
              <BeakerIcon class="w-4 h-5 text-pink-600" />
            </div>
            <div class="button_secondary" @click="quickCreate('maas')">
              Quick MAAS
              <BeakerIcon class="w-4 h-5 text-blue-600" />
            </div>
            <div class="button_primary" @click="showPartnerPopup = true">
              New Partner
              <PlusIcon class="w-4 h-5" />
            </div>
          </div>
        </div>
        <div class="flex">
          <SwitchGroup as="div" class="flex items-center justify-between w-fit">
            <Switch
              v-model="isTable"
              class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-operacolor focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-0 dark:focus:ring-operacolor dark:focus:ring-offset-0"
            >
              <span class="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                class="pointer-events-none absolute h-full w-full rounded-md bg-gray-50 dark:bg-neutral-950"
              ></span>
              <span
                aria-hidden="true"
                :class="[
                  isTable ? 'bg-operacolor' : 'bg-gray-200 dark:bg-neutral-700',
                  'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                ]"
              ></span>
              <span
                aria-hidden="true"
                :class="[
                  isTable ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                ]"
              ></span>
            </Switch>
            <SwitchLabel as="span" class="ml-3 text-sm">
              <span class="font-medium text_color_primary">Table View</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
      </div>

      <!-- Partner Table -->
      <TablePrimary
        v-if="isTable"
        :rows="filteredPartners"
        :columns="columns"
        :is-clickable="true"
        class="-mt-5"
        export_title="partners"
        @click-primary="openPartnerEdit"
        @click-secondary="copyLink"
      />

      <!-- Partner Cards -->
      <div v-else class="flex flex-wrap gap-10 mt-4">
        <div v-for="p in filteredPartners" :key="p.id">
          <PartnerCard :partner="p" @clicked="openPartnerEdit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted, computed } from "vue";
import { usePartnerStore } from "@/stores/partner";
// Components
import PartnerCard from "@/components/partners/PartnerCard.vue";
import TablePrimary from "@/components/applicationui/tables/LegacyTablePrimary.vue";
import PartnerEdit from "@/components/partners/PartnerEdit.vue";
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
// Libraries
import { UserGroupIcon, PlusIcon, BeakerIcon } from "@heroicons/vue/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import { SwitchGroup, Switch, SwitchLabel } from "@headlessui/vue";
// Stores
const partnersStore = usePartnerStore();
// Globals
const isTable = ref(true);
if (window.innerWidth < 1024) isTable.value = false;
const query = ref("");
const partners = ref([]);
const loading = ref(true);
const showPartnerPopup = ref(false);
const newPartnerDefaults = {
  title: "",
  meta_name: "",
  logo_text: null,
  title_color: "#000000",
  title_color_dark: "#FFFFFF",
  primary_color: "#4f46e5",
  primary_color_light: "#6366f1",
  primary_color_dark: "#3730a3",
  allow_advisors: false,
  allow_custody_accounts: false,
  show_disclosure: false,
  collect_income_data: false,
  collect_employer_data: false,
  partner_scores: false,
  show_scores_for_advisors: false,
  send_accreditation_notifications: true,
  use_additional_signin_content: false,
  send_welcome_email: false,
  disable_signup: false,
  redirect_url: "/dashboard/investing",
  partners_protected: {
    partner_type: "dib",
    kyc_disabled: false,
    use_custom_disclosure: false,
    managed_kyc_aml: false,
    trade_status_admin: false,
    // allowed_signin_methods: ['magiclink'],
  },
  credentials: { client_id: "", key: "", issuer_id: "" },
};
const newPartner = ref(newPartnerDefaults);
const columns = [
  { key: "", label: "Partner", type: "partner-logo" },
  { key: "partners_protected.partner_type", label: "Type", type: "badge" },
  { key: "Copy Link", label: "Copy Link", type: "button-secondary" },
  { key: "meta_name", label: "Meta Tag", type: "bold" },
  { key: "created_at", label: "Created", type: "date" },
];

// Mounted
onMounted(async () => {
  // Get Partners
  await setPartners();
  // Ready
  loading.value = false;
});

// Computed
const filteredPartners = computed(() => {
  return query.value === ""
    ? partners.value
    : partners.value.filter((partner) => {
        if (
          partner?.logo_text?.toLowerCase().includes(query.value.toLowerCase())
        )
          return true;
        if (
          partner?.meta_name?.toLowerCase().includes(query.value.toLowerCase())
        )
          return true;
        if (partner?.title?.toLowerCase().includes(query.value.toLowerCase()))
          return true;
        if (partner?.id?.toLowerCase().includes(query.value.toLowerCase()))
          return true;
        if (
          partner?.partners_protected?.partner_type
            ?.toLowerCase()
            .includes(query.value.toLowerCase())
        )
          return true;
      });
});

// Functions
async function setPartners() {
  partners.value = await partnersStore.getAllPartners();
}
// Simple Functions
const copyLink = (row) => {
  const loginLink = `${window.location.origin}/login?partner=${row.meta_name}`;
  navigator.clipboard.writeText(loginLink);
};

const quickCreate = (type) => {
  if (type === "dib") {
    newPartner.value.redirect_url = "/dashboard/investing";
    newPartner.value.partners_protected.partner_type = "dib";
    newPartner.value.partners_protected.kyc_disabled = false;
    newPartner.value.partners_protected.investor_tabs = [1, 2, 3, 4];
    newPartner.value.partners_protected.advisor_tabs = [];
    newPartner.value.partners_protected.admin_tabs = [
      21, 22, 23, 24, 25, 27, 28,
    ];
  } else if (type === "dab") {
    newPartner.value.redirect_url = "/dashboard/documents";
    newPartner.value.partners_protected.partner_type = "dab";
    newPartner.value.partners_protected.kyc_disabled = true;
    newPartner.value.show_disclosure = false;
    newPartner.value.partners_protected.investor_tabs = [4];
    newPartner.value.partners_protected.advisor_tabs = [];
    newPartner.value.partners_protected.admin_tabs = [21, 22, 25, 27, 28];
  } else if (type === "maas") {
    newPartner.value.redirect_url = "/dashboard/investing";
    newPartner.value.allow_advisors = true;
    newPartner.value.partners_protected.partner_type = "maas";
    newPartner.value.partners_protected.kyc_disabled = false;
    newPartner.value.partners_protected.investor_tabs = [1, 2, 3, 4];
    newPartner.value.partners_protected.advisor_tabs = [11, 12];
    newPartner.value.partners_protected.admin_tabs = [
      21, 22, 23, 24, 25, 27, 28,
    ];
  }
  // Open Popup
  showPartnerPopup.value = true;
};

const openPartnerEdit = (partner) => {
  newPartner.value = partner;
  showPartnerPopup.value = true;
};

const cleanAndClosePartnerEdit = () => {
  newPartner.value = newPartnerDefaults;
  showPartnerPopup.value = false;
};
</script>
