<template>
  <AppTooltip
    content="This chart shows the progress of investors through the onboarding process. Each value represents the number of investors who have completed that step but got no further."
  >
    <div
      class="flex-col items-center justify-center w-[29%] min-w-96 gap-2 bg-white rounded-lg shadow-md py-2.5 ring-1 ring-gray-100 dark:bg-neutral-800 dark:ring-neutral-700 dark:shadow-none"
    >
      <div class="font-semibold border-b border-dashed border-gray-200 mx-5 pb-2 mb-1 text-center dark:border-neutral-600 dark:text-neutral-100">
        Investor Progress
      </div>
      <!-- Loading -->
      <div v-if="loading" class="px-2 pb-10 pt-2 w-full min-w-96 h-[500px]">
        <div class="flex flex-grow items-center justify-center h-full">
          <div class="w-32 h-32 -mt-10">
            <SpinnerFlip />
          </div>
        </div>
      </div>
      <!-- Empty State -->
      <div v-else-if="noTransactions" class="h-[500px]">
        <div class="text-center mt-20">
          <ChartPieIcon class="mx-auto size-12 text-gray-400" />
          <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-white">No Investors Yet</h3>
          <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">
            Once investors start logging in,<br />
            their progress will be displayed here.
          </p>
        </div>
      </div>
      <!-- Chart -->
      <div v-else class="w-full min-w-96 h-[500px] flex items-center justify-center">
        <Doughnut :data="pie_chart_data" :options="pie_chart_options" />
      </div>
    </div>
  </AppTooltip>
</template>

<script setup>
// Essentials
import { ref, onMounted } from "vue";

// Components
import SpinnerFlip from "@/components/loading/SpinnerFlip.vue";
import AppTooltip from "@/components/applicationui/AppTooltip.vue";

// Stores
import { useProfilesStore } from "@/stores/profiles";
const profilesStore = useProfilesStore();
import { useTransactionsStore } from "@/stores/transactions";
const transactionsStore = useTransactionsStore();
import { useAccountsStore } from "@/stores/accounts";
const accountsStore = useAccountsStore();
import { usePartnerStore } from "@/stores/partner";
const partnerStore = usePartnerStore();

// Libraries
import { ChartPieIcon } from "@heroicons/vue/24/outline";
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale } from "chart.js";
import { Doughnut } from "vue-chartjs";
ChartJS.register(CategoryScale, LinearScale, Title, Tooltip, Legend);

// Props
const props = defineProps({
  partner_id: { type: String, required: true },
});

// Pie Chart START
const pie_chart_data = ref();
const pie_chart_options = {
  responsive: true,
  layout: { padding: 5 },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          // Format the dataset value as dollars
          const value = tooltipItem.raw;
          return ` ${value} Investors (${((value / pie_chart_data.value.datasets[0].data.reduce((a, b) => a + b, 0)) * 100).toFixed(2)}%)`;
        },
      },
    },
    legend: {
      display: true,
    },
  },
};
const buildDoughnutChart = async (partner) => {
  // Set Labels & Data
  const labels = ["Viewed Only", "Completed Personal Info", "Added Payment Method", "Invested"];
  const data = [viewed_only.value, completed_personal_info.value, added_payment_info.value, invested.value];

  // Add Completed Investor Verification if KYC is enabled
  if (!partner.ky_disabled) {
    labels.splice(2, 0, "Completed Investor Verification");
    data.splice(2, 0, completed_investor_verification.value);
  }

  // Set the background colors
  const backgroundColor = labels.map((p, i) => {
    const colors = [
      "rgb(253, 186, 116)", // orange 300
      "rgb(251, 191, 36)", // amber 400
      "rgb(234, 179, 8)", // yellow 500
      "rgb(101, 163, 13)", // lime-600
      "rgb(21, 128, 61)", // green-700
    ];
    return colors[i % colors.length];
  });

  const theme = localStorage.theme;
  let border_color = "#ffffff";
  if (theme === "dark") border_color = "#404040";

  const datasets = [
    {
      clip: 0,
      data,
      backgroundColor,
      borderColor: border_color,
      borderWidth: 2,
      hoverOffset: 4,
    },
  ];

  // ChartJS.defaults.plugins.legend.position = 'top';
  pie_chart_data.value = { labels, datasets };
};
// Pie Chart END

// Get Payment Method Data START
const getPaymentMethodData = async (users) => {
  // Create an array of all API call promises
  const userPromises = users.map(async (user) => {
    if (!user.parties || !user.parties.accounts) return user; // No accounts = no payment method

    // Run all Plaid & Stripe API calls at once for all accounts
    const apiCalls = user.parties.accounts.flatMap((account) => [
      accountsStore.getPlaid(account.id, account.tapi_account_id),
      accountsStore.getStripe(account.id, account.tapi_account_id),
    ]);

    // Wait for all API calls to finish
    const results = await Promise.all(apiCalls);

    // Check if any account has a valid payment method
    const has_payment_method = results.some((data) => data && (data.statusCode === "715" || data.statusCode === "710"));

    return has_payment_method ? null : user; // Return user only if they have no payment method
  });

  // Wait for all users' API checks to complete
  const resolvedUsers = await Promise.all(userPromises);

  // Filter out null values (users who have a payment method)
  return resolvedUsers.filter(Boolean);
};
// Get Payment Method Data END

// Set Investor Data START
const noTransactions = ref(false);
const invested = ref(0);
const added_payment_info = ref(0);
const completed_investor_verification = ref(0);
const completed_personal_info = ref(0);
const viewed_only = ref(0);
const setInvestorData = async () => {
  // Get partner from prop.partner_id
  const partner = await partnerStore.getPartner(props.partner_id);

  // Get all users for partner
  const all_users_for_partner = await profilesStore.getAllProfilesForPartnerFillPartyAccount(props.partner_id);

  if (all_users_for_partner.length <= 1) {
    noTransactions.value = true;
    return;
  }

  // Get all unique account_ids for settled transactions (Gets an array of invested users)
  const settled_transactions = await transactionsStore.getAllSettledTransactionsForPartnerFillAccountData(props.partner_id);
  const unique_account_ids = [...new Set(settled_transactions.map((transaction) => transaction.account_id))];

  // Set "Invested"
  const not_invested = all_users_for_partner.filter((user) => {
    return !user.parties?.accounts?.some((account) => unique_account_ids.includes(account.id));
  });
  invested.value = all_users_for_partner.length - not_invested.length;

  // Set "Added Payment Method"
  const no_payment_added = await getPaymentMethodData(not_invested);
  added_payment_info.value = not_invested.length - no_payment_added.length;

  // Set Completed Investor Verification
  let incomplete_kyc_aml = [];
  if (!partner.ky_disabled) {
    incomplete_kyc_aml = no_payment_added.filter((user) => {
      if (!user.parties) return true;
      if (!user.parties_protected) return true;
      if (user.parties_protected.kyc != "approved") return true;
      if (user.parties_protected.aml != "approved") return true;
    });
    completed_investor_verification.value = no_payment_added.length - incomplete_kyc_aml.length;
  }

  // Set Completed Personal Info
  const incomplete_personal_info = incomplete_kyc_aml.filter((user) => {
    if (!user.parties) return true;
  });
  completed_personal_info.value = incomplete_kyc_aml.length - incomplete_personal_info.length;

  // Set "Viewed Only"
  viewed_only.value = incomplete_personal_info.length;

  // Build the doughnut chart
  await buildDoughnutChart(partner);
};
// Set Investor Data END

// Mounted START
const loading = ref(true);
onMounted(async () => {
  await setInvestorData();

  // Ready
  loading.value = false;
});
// Mounted END
</script>
